// import "babel-polyfill";
// import cssVars from "css-vars-ponyfill";

import React from 'react'
import ReactDOM from 'react-dom'

import App from './app/App'
import { SnackbarProvider } from 'notistack'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import { RepairContextProvider } from './app/contexts/RepairContext'
import { Store } from './app/redux/Store'
import { AppProvider } from './app/contexts/AppContext'
import { Provider } from 'react-redux'
import { FeatureFlagsProvider } from 'app/contexts/FeatureFlags'
import 'app/i18n/config'

// cssVars();

ReactDOM.render(
    <SnackbarProvider maxSnack={3}>
        <FeatureFlagsProvider>
        <AppProvider>
            <RepairContextProvider>
                <Provider store={Store}>
                    <SettingsProvider>
                        <App />
                    </SettingsProvider>
                </Provider>
            </RepairContextProvider>
        </AppProvider>
        </FeatureFlagsProvider>
    </SnackbarProvider>,
    document.getElementById('root')
)

// for IE-11 support un-comment cssVars() and it's import in this file
// and in MatxTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

