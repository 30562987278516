import React from 'react'
import profileRoutes from './views/dashboard/profile/ProfileRoutes'
import orderRoutes from './views/dashboard/orders/OrderRoutes'
import invoiceRoutes from './views/invoice/InvoioceRoutes'

import { Redirect } from 'react-router-dom'



const errorRoute = [
    {
       // component: () => <Redirect to="/session/404" />,
        component: () => <Redirect to="/" />,
    },
]

const routes = [
    {
        path: `/:country/sell-your-phone`,
        component: React.lazy(() => import('./views/Home/HomePage')),
    },
    {
        path: `/:country/sell-your-device`,
        exact: true,
        component: React.lazy(() =>
            import('./views/SellYourDevice/SellYourDevice')
        ),
    },
    {
        path: `/:country/stores`,
        exact: true,
        component: React.lazy(() => import('./views/stores/Stores')),
    },
    {
        path: `/:country/device-details`,
        exact: true,
        component: React.lazy(() =>
            import('./views/DeviceDetails/deviceDetails')
        ),
    },
    {
        path: `/:country/device-details/:_id`,
        exact: true,
        component: React.lazy(() =>
            import('./views/DeviceDetails/deviceDetails')
        ),
    },
    {
        exact: true,
        path: `/:country/how-it-works`,
        component: React.lazy(() => import('./views/howItWorks/HowItWorks')),
    },
    {
        path: `/:country/careers`,
        exact: true,
        component: React.lazy(() => import('./views/careers/Careers')),
    },
    {
        path: `/:country/repair-phone`,
        exact: true,
        component: React.lazy(() => import('./views/repairPhone/RepairPhone')),
    },
    {
        path: `/:country/register-phone`,
        exact: true,
        component: React.lazy(() =>
            import('./views/registerPhone/RegisterPhone')
        ),
    },
    {
        path: `/register-phone`, //route to handle warranty bar codes
        exact: true,
        component: React.lazy(() =>
            import('./views/registerPhone/RegisterPhone')
        ),
    },
    {
        path: `/:country/partners/:_partner`,
        exact: true,
        component: React.lazy(() => import('./views/partners/Samsung')),
    },
    {
        path: `/:country/samsung/all-phones`,
        exact: true,
        component: React.lazy(() => import('./views/partners/SamsungPhones')),
    },
    {
        path: `/:country/sell-old-mobile-phone/select-brand/:brand/:_id`,
        exact: true,
        component: React.lazy(() =>
            import('./views/SelectDeviceModel/SelectDeviceModel')
        ),
    },
    {
        path: `/:country/sell-old-mobile-phone/select-variant/:model_id`,
        exact: true,
        component: React.lazy(() =>
            import('./views/SelectDeviceModel/SelectDeviceVariant')
        ),
    },
    {
        path: `/:country/dealers/register`,
        exact: true,
        component: React.lazy(() => import('./views/dealers/RegisterDealers')),
    },
    {
        path: `/:country/careers`,
        exact: true,
        component: React.lazy(() => import('./views/careers/Careers')),
    },
    {
        path: `/:country/payment-success`,
        exact: true,
        component: React.lazy(() => import('./views/paymentSuccess/paymentSuccess')),
    },
    ...invoiceRoutes,
    ...profileRoutes,
    ...orderRoutes,
    ...errorRoute,
    
]

export default routes
