import React from 'react'
const country = localStorage.getItem('country')

const orderRoutes = [
    {
        path: `/${country}/orders/:_id`,
        component: React.lazy(() => import('./OrderList')),
    },
    {
        path: `/${country}/orders`,
        component: React.lazy(() => import('./Orders')),
    },
    {
        path: `/${country}/purchase-orders/:_id`,
        component: React.lazy(() => import('./Orders')),
    },
]

export default orderRoutes
