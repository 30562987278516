import React, { useState, useEffect } from 'react'
import {
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    Button,
    CircularProgress,
} from '@material-ui/core'
import { MatxLogo, MatxDivider } from 'app/components'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import useAuth from 'app/hooks/useAuth'
import history from 'history.js'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    cardHolder: {
        // background: '#1A2038',
    },

    card: {
        maxWidth: 800,
        margin: '1rem',
    },
    cardLeft: {
        background: '#42c8b7 url(/assets/images/circles.png) no-repeat',
        backgroundSize: 'cover',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            minWidth: 200,
        },
    },
    logo: {
        display: 'flex',
        textAlign: '',
        justifyContent: 'center',
        '& span': {
            fontSize: 16,
            lineHeight: 1.3,
            fontWeight: 800,
            color: '#fff',
        },
        '& small': {
            display: 'block',
            fontWeight: 'normal',
        },
    },
    mainTitle: {
        fontSize: 18,
        lineHeight: 1.3,
        marginBottom: 5,
        marginTop: 20,
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    signupButton: {
        background: '#42c8b7',
        marginTop: '20px',
        color: '#fff',

        '&:hover': {
            backgroundColor: '#42c8b7',
            color: '#fff',
            opacity: 0.8,
        },
    },
}))

const JwtRegister = () => {
    const [state, setState] = useState({})
    const classes = useStyles()
    const { register } = useAuth()
    const [loading, setLoading] = useState(false)

    const handleChange = ({ target: { name, value } }) => {
        setState({
            ...state,
            [name]: value,
        })
    }

    let { fname, lname, email, password, agreement, phone, c_password } = state

    useEffect(() => {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== password) {
                return false
            }
            return true
        })
    }, [password])

    const handleFormSubmit = async (event) => {
        event.preventDefault()
        try {
            const registered = await register(state)

            if (registered){
                return history.push('/session/signin')
            }
            else{
                console.log("could not register")
            }
        } catch (e) {
            console.log(e)
            setState({
                ...state,
                fname: '',
                lname: '',
                email: '',
                password: '',
                agreement: false,
                phone: '',
                c_password: '',
            })
        }
    }

    return (
        <div
            className={clsx(
                'flex justify-center items-center  min-h-full-screen',
                classes.cardHolder
            )}
        >
            <Card className={classes.card}>
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <div
                            className={clsx({
                                'py-8 px-14 h-full': true,
                                [classes.cardLeft]: true,
                            })}
                        >
                            <Link className={classes.logo} to="/">
                                <MatxLogo className="mr-2 mb-3" />{' '}
                                <span>
                                    Badili <small>Uza Chapchap</small>
                                </span>
                            </Link>
                            <h1 className={classes.mainTitle}>Register!</h1>
                            <p>
                                Welcome to the Badili SIgn up page. 
                                Its simple and easy. Please Input your details.
                            </p>
                            <img
                                className="w-full"
                                src="/assets/images/illustrations/posting_photo.svg"
                                alt=""
                            />
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <div className="p-8 h-full">
                            <ValidatorForm onSubmit={handleFormSubmit}>
                                {/* <Grid container spacing={1}>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextValidator
                                            className="mb-6 w-full"
                                            variant="outlined"
                                            size="small"
                                            label="First Name"
                                            onChange={handleChange}
                                            type="text"
                                            name="fname"
                                            value={fname || ''}
                                            validators={['required']}
                                            errorMessages={[
                                                'this field is required',
                                            ]}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        xs={12}
                                        style={{ gap: '1rem' }}
                                    >
                                        <TextValidator
                                            className="mb-6 w-full"
                                            variant="outlined"
                                            size="small"
                                            label="Last Name"
                                            onChange={handleChange}
                                            type="text"
                                            name="lname"
                                            value={lname || ''}
                                            validators={['required']}
                                            errorMessages={[
                                                'this field is required',
                                            ]}
                                        />
                                    </Grid>
                                </Grid> */}
                                <TextValidator
                                    className="mb-6 w-full"
                                    variant="outlined"
                                    size="small"
                                    label="Email"
                                    onChange={handleChange}
                                    type="email"
                                    name="email"
                                    value={email.toLowerCase() || ''}
                                    validators={['required', 'isEmail']}
                                    errorMessages={[
                                        'this field is required',
                                        'email is not valid',
                                    ]}
                                />
                                <TextValidator
                                    className="mb-6 w-full"
                                    variant="outlined"
                                    size="small"
                                    label="Phone"
                                    onChange={handleChange}
                                    type="text"
                                    name="phone"
                                    value={phone || ''}
                                    validators={['required', 'isNumber']}
                                    errorMessages={[
                                        'this field is required',
                                        'phone must be a number',
                                    ]}
                                />
                                <TextValidator
                                    className="mb-4 w-full"
                                    label="Password"
                                    variant="outlined"
                                    size="small"
                                    onChange={handleChange}
                                    name="password"
                                    type="password"
                                    value={password || ''}
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                />
                                <TextValidator
                                    className="mb-4 w-full"
                                    label="Confirm Password"
                                    variant="outlined"
                                    size="small"
                                    onChange={handleChange}
                                    name="c_password"
                                    type="password"
                                    value={c_password || ''}
                                    validators={['required', 'isPasswordMatch']}
                                    errorMessages={[
                                        'this field is required',
                                        'passwords do not match',
                                    ]}
                                />
                                <FormControlLabel
                                    className="mb-4"
                                    name="agreement"
                                    onChange={(e) =>
                                        handleChange({
                                            target: {
                                                name: 'agreement',
                                                value: e.target.checked,
                                            },
                                        })
                                    }
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={agreement || false}
                                        />
                                    }
                                    label="I have read and agree to the terms of service."
                                />
                                <div className="flex items-center">
                                    <Button
                                        variant="contained"
                                        disableElevation
                                        color="primary"
                                        disabled={loading}
                                        type="submit"
                                        fullWidth
                                        className={clsx(
                                            'capitalize ',
                                            classes.signupButton
                                        )}
                                    >
                                        Sign up{' '}
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </Button>
                                </div>
                                <MatxDivider
                                    className="my-6 px-8"
                                    text="Already have an account ?"
                                />
                                Already have an account? Sign in
                                {''}
                                <Link to="/session/signin">
                                    {''} <u>here</u>
                                </Link>
                            </ValidatorForm>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </div>
    )
}

export default JwtRegister
