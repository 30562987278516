import React, { createContext, useEffect, useReducer } from 'react'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import { API_URL, API_KEY, API_SECRET } from 'utils/api_utils'
import { getProfile } from 'app/views/dashboard/profile/ProfileService'

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
    errorMessage: '',
    authMessage: '',
    serverMessage: '',
    // phone: '',
    // password: '',
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }

    if (accessToken) {
        return true
    }
    // ere we will check validity of token by sending it to API

    // const decodedToken = jwtDecode(accessToken)
    // const currentTime = Date.now() / 1000
    // return decodedToken.exp > currentTime
}

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
                authMessage: '',
            }
        }
        case 'OTP_LOGIN': {
            const { user } = action.payload
            return {
                ...state,
                isAuthenticated: true,
                user,
                authMessage: '',
            }
        }
        case 'VERIFY': {
            const { msg } = action.payload
            return {
                ...state,
                isAuthenticated: false,
                authMessage: msg,
            }
        }
        case 'VERIFYFAILED': {
            const { msg } = action.payload
            return {
                ...state,
                isAuthenticated: false,
                errorMessage: msg,
                authMessage: '',
            }
        }
        case 'TOKENVERIFICATIONFAILED': {
            const { msg } = action.payload

            return {
                ...state,
                isAuthenticated: false,
                errorMessage: msg,
                authMessage: msg,
            }
        }
        case 'LOGINFAILED': {
            const { msg } = action.payload
            return {
                ...state,
                isAuthenticated: false,
                authMessage: msg,
            }
        }

        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload
          

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'RESET': {
            return {
                ...state,
                isAuthenticated: false,
                authMessage: '',
                errorMessage: '',
                serverMessage: '',
                phone: '',
                password: '',
            }
        }
        case 'UPDATEUSER': {
            const { name, value } = action.payload
            return {
                ...state,
                [name]: value,
            }
        }

        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => {},
    otpLogin: () => {},
    register: () => Promise.resolve(),
    sendOtp: () => Promise.resolve(),
    checkIfCustomer: () => Promise.resolve(),
    resetNumber: () => Promise.resolve(),
    handleUserChange: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const country = localStorage.getItem('country')
    const [state, dispatch] = useReducer(reducer, initialState)

    const headers = {
        'Content-Type': 'application/json',
        Key: API_KEY,
        Secret: API_SECRET,
        country,
    }

    const checkIfCustomer = async (phone) => {
        //dirty way to check if a customer already has an account
        //todo: ask backend team to develop cleaner way to check if customer exists
        const { success } = await axios.post(
            API_URL + 'register',
            { phone },
            { headers: headers }
        )
        return !success
    }

    const handleUserChange = ({ target: { name, value } }) => {
        dispatch({
            type: 'UPDATEUSER',

            payload: {
                name,
                value,
            },
        })
    }

    const login = async (phone, password) => {
        try {
            const {
                data: { user, token, msg, success },
            } = await axios.post(
                API_URL + 'auth',
                {
                    auth: phone,
                    password: password,
                },
                { headers: headers }
            )
            if (success) {
                //user login successful take to dashboard
                setSession(token)
                dispatch({
                    type: 'LOGIN',
                    payload: {
                        user,
                    },
                })

                return true
            }
            if (!success) {
                //user does not exist
                dispatch({
                    type: 'LOGINFAILED',
                    payload: {
                        msg,
                    },
                })

                return false
            }

            // VERIFY OTP LOGIC DONT DELETE UNTIL CONFIRMED**********************
            // const {
            //     data: { success, msg },
            // } = await axios.post(
            //     API_URL + 'verify_otp',
            //     {
            //         phone: phone,
            //         random: otp,
            //     },
            //     { headers: headers }
            // )

            // if (success) {
            //     const {
            //         data: { user, token, msg, success },
            //     } = await axios.post(
            //         API_URL + 'auth',
            //         {
            //             auth: phone,
            //             password: '1234',
            //         },
            //         { headers: headers }
            //     )
            //     if (success) {
            //         setSession(token)
            //         dispatch({
            //             type: 'LOGIN',
            //             payload: {
            //                 user,
            //             },
            //         })
            //     }
            //     if (!success) {
            //         dispatch({
            //             type: 'LOGINFAILED',
            //             payload: {
            //                 msg,
            //             },
            //         })
            //     }
            // } else {
            //     dispatch({
            //         type: 'TOKENVERIFICATIONFAILED',
            //         payload: {
            //             msg,
            //         },
            //     })
            // }
        } catch ({ message }) {
            // all network errors
            dispatch({
                type: 'LOGINFAILED',
                payload: {
                    msg: message,
                },
            })
        }
    }

    const otpLogin = async (user) => {
        const country = localStorage.getItem('country')
        try {
            //user login successful take to dashboard
            setSession(user.token)
            dispatch({
                type: 'OTP_LOGIN',
                payload: {
                    user,
                },
            })

            //initUser()
            const headers = {
                'Content-Type': 'application/json',
                Key: API_KEY,
                Secret: API_SECRET,
                country,
                Authorization: `${user.token}`,
            }

            const _profile = await axios.get(API_URL + 'profile', {
                headers: headers,
            })

            dispatch({
                type: 'INIT',
                payload: {
                    isAuthenticated: true,
                    user: {
                        ..._profile.data.user,
                        userId: _profile.data.user._id,
                    },
                },
            })

            if (!user) return false
            return true
        } catch ({ message }) {
         
            dispatch({
                type: 'LOGINFAILED',
                payload: {
                    msg: message,
                },
            })

            return false
        }
    }

    const sendOtp = async (phone) => {
        const response = await axios.post(
            API_URL + 'send_otp',
            {
                phone: phone,
            },
            { headers: headers }
        )
        const { msg, success } = response.data

        if (success) {
            dispatch({
                type: 'VERIFY',
                payload: {
                    msg,
                    success,
                },
            })
        }
        if (!success) {
            dispatch({
                type: 'VERIFYFAILED',
                payload: {
                    msg,
                },
            })
        }
    }

    const register = async ({ fname, lname, password, phone, email }) => {
        const response = await axios.post(
            API_URL + 'register',
            {
                fname,
                lname,
                phone,
                email,
                password,
            },
            { headers: headers }
        )

     
        const { accessToken, user } = response.data

        setSession(accessToken)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })

        return response.data.success
    }

    const logout = () => {
        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }

    const resetNumber = () => {
        setSession(null)
        dispatch({
            type: 'RESET',
        })
    }

    const initUser = async () => {
        try {
            const accessToken = window.localStorage.getItem('accessToken')
          

            if (accessToken && isValidToken(accessToken)) {
              

                const {
                    data: { user },
                } = await getProfile()

              

                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: true,
                        user,
                    },
                })
            } else {
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        } catch (err) {
         
            dispatch({
                type: 'INIT',
                payload: {
                    isAuthenticated: false,
                    user: null,
                },
            })
        }
    }

    useEffect(() => {
        initUser()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                otpLogin,
                logout,
                register,
                sendOtp,
                checkIfCustomer,
                resetNumber,
                handleUserChange,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
