import React from 'react'
import styled from 'styled-components'
import { devices } from 'utils/screen_sizes'
import { Card, Hidden } from '@material-ui/core'
import SkeletonElement from './SkeletonElement'
import Shimmer from './Shimmer'

const StyledMobileWrapper = styled.div`
    @media ${devices.mobileS} {
        display: flex;
       img{
        flex-basis:33.33%; 
       }
       div{
        flex-basis:66.66%;
        margin-left: 15px;
       }
    }
    @media ${devices.mobileL} {
        display: flex;
        flex-direction: column;
       img{
        flex-basis:33.33%; 
       }
       div{
        flex-basis:100%;
        margin-left: 0px;
       }
    }
    @media ${devices.laptop} {
        display: block
        margin-left: 0px;
    }
`

const StyledStoreCard = styled(Card)`
    /* background-image: url('/assets/images/landing-page/BadiliStore.jpg'); */
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: right;
    padding: 1rem;
    color: #fff;

    &.background-tint {
        position: relative;
    }

    &.background-tint::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        margin: auto;
    }
    .u-relative {
        position: relative;
        z-index: 1;
    }

    .tag {
        padding: 0.5rem 2.6rem;
        font-size: 12px;
        color: #fff;
        border-radius: 4px;
        width: fit-content;
        background: #ebebeb;
    }
    h6 {
        font-size: 15px;
        margin-bottom: 0;
    }
    p {
        font-size: 12px;
    }
    a {
        display: flex;
        align-items: center;
        gap: 5px;
        text-decoration: none;
        font-size: 13px;

        color: #fff;

        &:hover {
            text-decoration: underline;
        }
    }
`

const SkeletonDealerCard = ({ theme }) => {
    const themeClass = theme || 'light'
    return (
        <StyledStoreCard>
            <StyledMobileWrapper>
                <div className="u-relative">
                    <div className="tag" />

                    <h6>
                        <SkeletonElement type="title" />
                    </h6>
                    <p>
                        <SkeletonElement type="text" />
                        <SkeletonElement type="text" />
                        <SkeletonElement type="text" />
                        <SkeletonElement type="text" />
                    </p>

                    <div className="tag" />
                </div>
                {/* <Shimmer /> */}
            </StyledMobileWrapper>
        </StyledStoreCard>
    )
}

export default SkeletonDealerCard
