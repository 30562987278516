import React, { useState } from 'react'
import routes from '../RootRoutes'
import ecommerceRoutes from '../ecommerceComponents/EcommerceRoutes'
import repairRoutes from '../repair/RepairRoutes'

export const AppContext = React.createContext()

export const AppProvider = ({ children }) => {
    const [inventory, setInventory] = useState([])
    const [filter, setFilter] = useState('')
    const [country, setCountry] = useState('')
    const [count, setCount] = useState(0)
    const [page, setPage] = useState(1)
    const [reload, setReload] = useState(true)
    const [loadingInventory, setLoadingInventory] = useState(false)
    const PER_PAGE = 24
    const [filterBrand, setFilterBrand] = useState("");
    const [filterPrice, setFilterPrice] = useState('');

    const [currentPage, setCurrentPage] = useState(1)
    const maxPage = Math.ceil(inventory? inventory.length / PER_PAGE :1)

    function currentData() {
        let end = page * PER_PAGE
        let begin = end - PER_PAGE
        if (inventory.slice(begin, end).length === 0) {
            return inventory
        } else {
            return inventory.slice(begin, end)
        }
    }

    function next() {
        setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage))
    }

    function prev() {
        setCurrentPage((currentPage) => Math.max(currentPage - 1, 1))
    }

    function jump(page) {
        const pageNumber = Math.max(1, page)
        setPage((currentPage) => Math.min(pageNumber, maxPage))
    }

    const updateInv = (inventory ) => {
        
        setInventory(inventory)

        
       
    }

    const value = {
        // getInv,
        PER_PAGE,
        inventory,
        setInventory,
        filter,
        setFilter,
        count,
        setCount,
        page,
        setPage,
        routes,
        ecommerceRoutes,
        repairRoutes,
        reload,
        setReload,
        loadingInventory,
        setLoadingInventory,
        jump,
        next,
        prev,
        currentData,
        country,
        setCountry,
        updateInv,
        filterBrand,
        setFilterBrand,
        setFilterPrice,
        filterPrice
    }
    return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}
