import React, { useState, useEffect, useContext } from 'react'
import {
    Button,
    Grid,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    CircularProgress,
} from '@material-ui/core'
import styled from 'styled-components'
import { MatxLoading, MatxSnackbar, AddToCartButton } from 'app/components'
import { useDidMount } from 'app/hooks/useDidMount'
import BuyNowButton from 'app/components/BuyNowButton/BuyNowButton'

import { Link, useLocation } from 'react-router-dom'

import {
    StyledContainer,
    NoCalculationsCard,
} from '../../styles/deviceDetails.style'
import { makeStyles } from '@material-ui/core/styles'

import { useSelector, useDispatch } from 'react-redux'
import {
    getProductList,
    getCategoryList,
    getRatingList,
    getBrandList,
    getInventory,
} from 'app/redux/actions/EcommerceActions'

import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { getInventoryItem } from 'app/redux/actions/EcommerceActions'
import { makeHttpRequest } from 'utils/api_utils'

import { StyledPreviousPrice } from '../../styles/Ecommerce.style'

import {
    StyledBadiliTrust,
    IconWrapper,
    StyledProductTitle,
    StyledPriceSpan,
    StyledProductSpecifications,
    StyledImageWrapper,
    StyledAccordion,
    StyledAccordionTitle,
    StyledProductDetailsPage,
    StyledRefurbishedSuperb,
    StyledHorizontalLine,
    StyledModelHorizontalLine,
    StyledSelectedImage,
    StyledGalleryWrapper,
    StyledButtonWrapper,
    StyledGalleryThumbs,
    StyledVariants,
    StyledVariantsTitle,
    StyledVariantsWrapper,
    StyledYellowTitle,
    StyledProductGrid,
    StyledLoader,
} from '../../styles/product-detail.style'

import { useHistory } from 'react-router-dom'

import { ProductsGrid } from '../../ecommerceComponents/productsGrid/productsGrid'

import ErrorComponent from 'app/components/ErrorComponent/ErrorComponent'

import { handleImageError } from 'utils/imageUtils'

import { titleCase } from '../../../utils/string_utils'
import { AppContext } from 'app/contexts/AppContext'
import { getCountryFromUrl } from 'utils/CountryUtils'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    root: {
        alert: {
            width: '80%',
            marginBottom: '1.2em',

            '& a': {
                textDecoration: 'underline',
                color: '#42c8b7',
            },
        },
        '& .modalIcons': {
            color: '#42c8b7',
        },
    },
}))

const SpecificationsPanel = (props) => {
    const { modelName, specs = {} } = props

    const [expanded, setExpanded] = React.useState(false)

    const handleChange = (panel) => (isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const filterSpecs = (specs = {}) => {
        return Object.keys(specs).filter((key) => {
            return (
                key !== '_id' &&
                key !== '__v' &&
                key !== 'active' &&
                key !== 'createdAt' &&
                key !== 'updatedAt' &&
                key !== 'modelId'
            )
        })
    }

    const renderAccordion = (specs = {}) => {
        return filterSpecs(specs).map((key) => {
            return (
                <StyledAccordion
                    key={key}
                    expanded={expanded === key}
                    onChange={handleChange(key)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <StyledAccordionTitle>
                            {titleCase(key)}
                        </StyledAccordionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                        <StyledProductSpecifications>
                            {renderSpecifications(specs[key])}
                        </StyledProductSpecifications>
                    </AccordionDetails>
                </StyledAccordion>
            )
        })
    }
    const renderHighlights = (specs = {}) => {
        return renderSpecifications(specs.highlights)
    }

    const StyledLi = styled.li`
        color: #444;
        margin-bottom: 0.5em;
    `
    const StyledTitle = styled.span`
        color: #555;
        margin-right: 0.5em;
        font-weight: 800;
    `
    const StyledDescription = styled.p`
        color: #444;
        display: initial;
    `

    const renderSpecifications = (specs = {}) => {
        return Object.keys(specs).map((key) => {
            const spec = specs[key]
            return (
                <StyledLi>
                    <StyledTitle>{titleCase(key)}</StyledTitle>
                    <StyledDescription>{spec}</StyledDescription>
                </StyledLi>
            )
        })
    }

    return (
        <>
            <p>{modelName} Features and Specifications</p>

            <ul>{renderHighlights(specs)}</ul>
        </>
    )
}

const ProductDetails = ({
    location: {
        state: { _id },
    },
}) => {
    const dispatch = useDispatch()
    const didMount = useDidMount()
    const classes = useStyles()
    const history = useHistory()
    const { pathname } = useLocation()
    let country = getCountryFromUrl(pathname)||"ke"

    const { inventory = [] } = useSelector((state) => state.ecommerce)

    const [loading, setLoading] = useState(false)
    const [product, setProduct] = useState(null)
    const [image, setImage] = useState('')
    const [_modelName, _setModelName] = useState('')
    const [_variants, setVariants] = useState([])
    const [pricing, setPricing] = useState({ price: 0, discounted: 0 })
    const [message, setMessage] = useState({
        msg: '',
        severity: '',
    })
    const [error, setError] = useState({ status: false, msg: '' })
    const [selectedVariant, selectVariant] = useState({
        price: 0,
        discounted: 0,
    })
    const [showSnackBar, setShowSnackBar] = useState(false)
    const [loadingQuantity, setLoadingQuantity] = useState(false)
    const [loadingVariants, setLoadingVariants] = useState(false)
    const [gallery, setGallery] = useState([])
    const [stock, setStock] = useState(0)
    const [availableColors, setAvailableColors] = useState([])
    // const country = lcoalStorage.getItem('country')

    const getItem = async () => {
        try {
            setLoading(true)
            const item = await getInventoryItem(_id)

            const { variants = [], gallery, modelName = '' } = item

            setGallery(gallery)

            if (modelName) {
                _setModelName(modelName)
            }

            if (variants && variants.length > 0) {
                selectVariant(
                    variants.find((variant) => variant.priority === true) ||
                        variants[0]
                )
                let colors = [
                    ...new Set(variants.map((variant) => variant.color)),
                ]
                setAvailableColors(colors)
                await getVariantsByColor(variants[0].color, _id) //fetch by first variant color

                //  getQuantity(selectedVariant)
                const [{ discounted, saleprice }] = variants
                setPricing({ saleprice, discounted })
            }

            setProduct(item)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
            setMessage({
                msg: error.message,
                severity: 'error',
            })
            setError({
                status: true,
                msg: 'could not fetch item',
            })
        }
    }
    const getQuantity = async () => {
        setLoadingQuantity(true)

        try {
            const { success, quantity } = await makeHttpRequest(
                `get_quantity_by_variant/${selectedVariant._id}`,
                'GET'
            )

            if (success) {
                setStock(quantity)
                setLoadingQuantity(false)
            } else {
                setStock(0)
                setLoadingQuantity(false)
            }
        } catch (err) {
            console.log(err)
        }
    }
    const getVariantsByColor = async (color, modelId) => {
        setLoadingVariants(true)

        try {
            const { success, variant } = await makeHttpRequest(
                `get_variant_by_color/${color}/${modelId}
                `,
                'GET'
            )

            if (success) {
                setVariants(variant)
                let tempArr = []
                for (let i = 0; i < variant.length; i++) {
                    if (variant[i].priority) {
                        tempArr.push(variant[i])
                        break
                    } else {
                        tempArr.push(variant[0])
                        break
                    }
                }

                selectVariant(tempArr[0])

                setLoadingVariants(false)
            } else {
                setVariants([])
                setLoadingVariants(false)
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getItem()
    }, [_id])

    useEffect(() => {
        dispatch(getProductList())
        dispatch(getInventory())
        dispatch(getCategoryList())
        dispatch(getRatingList())
        dispatch(getBrandList())
    }, [dispatch])

    useEffect(() => {
        if (didMount) {
            return
        } else {
            getQuantity()
        }
    }, [selectedVariant])

    const closeSnackbar = () => {
        setShowSnackBar(false)
    }

    const Gallery = ({ gallery = [] }) => {
        const [selectedImage, setSelectedImage] = useState('')

        useEffect(() => {
            if (gallery.length > 0) {
                setSelectedImage(gallery[0].upload)
            }
        }, [gallery])

        const placeholderImage =
            'https://media.istockphoto.com/vectors/smartphone-icon-vector-id1137284756?b=1&k=20&m=1137284756&s=170667a&w=0&h=S8OxtjTNSFmreNwBP6_LdbeHN1H2lgIgKcqh3Z5aAIg='

        return (
            <>
                {selectedImage && (
                    <StyledGalleryWrapper>
                        <span>
                            <StyledSelectedImage
                                src={selectedImage}
                                alt="smartphone"
                                onError={(e) =>
                                    handleImageError(e, placeholderImage)
                                }
                            />
                        </span>
                        <StyledGalleryThumbs>
                            {gallery.map((image, key) => (
                                <img
                                    src={image.upload}
                                    onError={(e) =>
                                        handleImageError(e, placeholderImage)
                                    }
                                    alt="laptop"
                                    key={key}
                                    onClick={() =>
                                        setSelectedImage(image.upload)
                                    }
                                />
                            ))}
                        </StyledGalleryThumbs>
                    </StyledGalleryWrapper>
                )}
                {gallery.length < 1 && (
                    <div className="flex-column justify-center items-center">
                        <StyledGalleryWrapper>loading...</StyledGalleryWrapper>
                    </div>
                )}
            </>
        )
    }

    const handleSelectVariant = (variant) => {
        selectVariant(variant)
        setPricing({
            price: variant?.saleprice,
            discounted: variant?.discounted,
        })
    }

    const StyledSubTitle = styled.p`
        font-size: 1.4rem;
        font-weight: 800;
        color: #34314c;
        margin: 1.5rem 1rem 1rem 1rem;

        span {
            color: #f5bb22;
        }
    `

    return (
        <>
            {!loading && message.msg && (
                <>
                    <MatxSnackbar
                        open={showSnackBar}
                        message={message.msg}
                        type={message.severity}
                        handleClose={closeSnackbar}
                    />
                    <ErrorComponent error={error}>
                        <Link to={`/${country}/ecommerce/shop`}>
                            <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                            >
                                Back To Shop
                            </Button>
                        </Link>
                    </ErrorComponent>
                </>
            )}
            {loading && !message.msg && (
                <div style={{ minHeight: '100vh' }}>
                    <MatxLoading />
                </div>
            )}
            {!loading && !message.msg && (
                <StyledProductDetailsPage>
                    <StyledProductGrid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            sm={5}
                            md={5}
                            style={{ width: '100%' }}
                        >
                            <StyledImageWrapper>
                                <Gallery gallery={gallery} />
                                <StyledButtonWrapper>
                                    <AddToCartButton
                                        product={{
                                            ...product,
                                            price: selectedVariant?.saleprice,
                                            variant: selectedVariant,
                                            modelId: _id,
                                        }}
                                        disabled={
                                            stock < 1 ||
                                            loadingVariants ||
                                            loadingQuantity
                                        }
                                    />
                                    <BuyNowButton
                                        product={{
                                            ...product,
                                            price: selectedVariant?.saleprice,
                                            variant: selectedVariant,
                                            modelId: _id,
                                        }}
                                        disabled={
                                            stock < 1 ||
                                            loadingVariants ||
                                            loadingQuantity
                                        }
                                    />
                                </StyledButtonWrapper>
                            </StyledImageWrapper>
                        </Grid>
                        <Grid item xs={12} sm={7} md={7}>
                            <StyledProductTitle>
                                {_modelName}
                            </StyledProductTitle>
                            <StyledModelHorizontalLine />
                            <StyledPriceSpan>
                                KSh{' '}
                                {selectedVariant?.discounted &&
                                    selectedVariant?.discounted?.toLocaleString()}
                            </StyledPriceSpan>
                            <StyledPreviousPrice>
                                KSh{' '}
                                {selectedVariant?.saleprice &&
                                    selectedVariant?.saleprice?.toLocaleString()}
                            </StyledPreviousPrice>
                            <SpecificationsPanel
                                modelName={_modelName}
                                _variants={_variants}
                                specs={product.specs}
                                setPricing={setPricing}
                                selectedVariant={selectedVariant}
                                selectVariant={selectVariant}
                                handleSelectVariant={handleSelectVariant}
                            />

                            <StyledVariantsTitle>
                                NB: Prices are subject to change without notice
                            </StyledVariantsTitle>
                            <p>
                                Comes with : Badili branded box, charger,
                                charging cable, warranty card and a thankyou
                                card
                            </p>
                            {availableColors && (
                                <>
                                    <StyledVariantsTitle>
                                        Available Colors
                                    </StyledVariantsTitle>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            name="color"
                                            required
                                            defaultValue={availableColors[0]}
                                        >
                                            {availableColors.map(
                                                (color, index) => (
                                                    <FormControlLabel
                                                        className="h-32"
                                                        value={color}
                                                        control={
                                                            <Radio color="primary" />
                                                        }
                                                        label={`${color}`}
                                                        required
                                                        key={index}
                                                        onClick={() =>
                                                            getVariantsByColor(
                                                                color,
                                                                _id
                                                            )
                                                        }
                                                    />
                                                )
                                            )}
                                        </RadioGroup>
                                    </FormControl>
                                </>
                            )}
                            <StyledHorizontalLine />
                            {loadingVariants && (
                                <span>
                                    <StyledLoader size={14} className="mr-1" />{' '}
                                    Fetching available variants...
                                </span>
                            )}
                            {_variants &&
                                _variants.length > 0 &&
                                !loadingVariants && (
                                    <StyledVariants>
                                        <StyledVariantsTitle>
                                            Internal Storage
                                        </StyledVariantsTitle>
                                        <StyledVariantsWrapper>
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    name="status"
                                                    required
                                                    defaultValue={
                                                        _variants[0]._id
                                                    }
                                                >
                                                    {_variants &&
                                                    _variants.length > 0
                                                        ? _variants.map(
                                                              (
                                                                  _variant,
                                                                  index
                                                              ) => (
                                                                  <FormControlLabel
                                                                      className="h-32"
                                                                      value={
                                                                          _variant._id
                                                                      }
                                                                      control={
                                                                          <Radio color="primary" />
                                                                      }
                                                                      label={`${_variant.ram}GB Ram | ${_variant.storage} GB Storage`}
                                                                      required
                                                                      key={
                                                                          index
                                                                      }
                                                                      onClick={() =>
                                                                          handleSelectVariant(
                                                                              _variant
                                                                          )
                                                                      }
                                                                  />
                                                              )
                                                          )
                                                        : ''}
                                                </RadioGroup>
                                            </FormControl>
                                        </StyledVariantsWrapper>
                                    </StyledVariants>
                                )}
                            <StyledHorizontalLine />
                            <StyledVariantsTitle>
                                Availability
                            </StyledVariantsTitle>
                            {(loadingQuantity || loadingVariants) && (
                                <span>
                                    <StyledLoader size={14} className="mr-1" />{' '}
                                    Checking for availability...
                                </span>
                            )}
                            {!loadingQuantity && !loadingVariants && (
                                <p
                                    style={{
                                        color:
                                            stock < 1 ? '#d9534f ' : '#5cb85c',
                                    }}
                                >
                                    {stock < 1 ? 'Out of stock' : 'In stock'}
                                </p>
                            )}

                            <StyledHorizontalLine />
                            <StyledRefurbishedSuperb>
                                <StyledYellowTitle>
                                    As Good As New'
                                </StyledYellowTitle>
                                <span
                                    onClick={() =>
                                        history.push(
                                            `/${country}/ecommerce/condition-guide`
                                        )
                                    }
                                >
                                    Condition Guide
                                </span>
                                <p>
                                    Minimal signs of usage and 6 months
                                    warranty.
                                </p>
                                <iframe
                                    width="240"
                                    height="150"
                                    src="https://www.youtube.com/embed/Fet_Jl7mFjk"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                />
                            </StyledRefurbishedSuperb>
                            <StyledBadiliTrust>
                                <StyledHorizontalLine />
                                <StyledYellowTitle>
                                    Badili Trust
                                </StyledYellowTitle>
                                <IconWrapper>
                                    <img
                                        src="/assets/images/ecommerce-icons/as-good.svg"
                                        alt="device"
                                    />
                                    <img
                                        src="/assets/images/ecommerce-icons/off-price.svg"
                                        alt="device"
                                    />
                                    <img
                                        src="/assets/images/ecommerce-icons/certified-quality.svg"
                                        alt="device"
                                    />
                                    <img
                                        src="/assets/images/ecommerce-icons/6-months.svg"
                                        alt="device"
                                    />
                                </IconWrapper>
                            </StyledBadiliTrust>
                        </Grid>
                    </StyledProductGrid>
                    <StyledSubTitle>You May Also Like </StyledSubTitle>
                    <ProductsGrid inventory={inventory} />
                </StyledProductDetailsPage>
            )}

            {!loading && error.status && (
                <>
                    <MatxSnackbar
                        open={showSnackBar}
                        message={message.msg}
                        type={message.severity}
                        handleClose={closeSnackbar}
                    />
                    <StyledContainer maxWidth={false} className={classes.root}>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <NoCalculationsCard>
                                    <h5>
                                        Oops! Looks An error occurred, try again
                                        later
                                    </h5>
                                </NoCalculationsCard>
                            </Grid>
                        </Grid>
                    </StyledContainer>
                </>
            )}
        </>
    )
}

export default ProductDetails
