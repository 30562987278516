    export const phoneSummaryQuestions = [            
        {
            title:"Are you able to switch your phone on/off?", 
            variableName: "deviceOn",
            description:"Check if your phone is able to start/restart.",
            expectedAnswer:"yes",
            evaluation:{
                null:"",
                yes:"Can turn on/off",
                no:"Cannot turn on/off"
            }
        },           
        {
            title:"Are you able to make and receive calls?", 
            variableName: "workingPhone",
            description:"Check  device for cellular network connectivity issues.",
            expectedAnswer:"yes",
            evaluation:{
                null:"",
                yes:"Can make calls",
                no:"Cannot make calls"
            }
        }    
        
    ]
    export const functionalPhysicalQuestions = [             
        {
            title:"Volume buttons not working", 
            variableName: "volumeButtons",
            icon:require('../../assets/images/icons/webIcons-03.svg'),
            expectedAnswer:"yes"
        },
        {
            title:"Fingerprint scanner not working", 
            variableName: "fingerScanner",
            icon:require('../../assets/images/icons/webIcons-04.svg'),
            expectedAnswer:"yes"
        },
        // {
        //     title:"WiFi not working",
        //     variableName: "WiFi",
        //     icon:require('../../assets/images/icons/webIcons-05.svg'),
        //     expectedAnswer:"yes"
        // },        
        // {
        //     title:"infrared not working",
        //     variableName: "infrared",
        //     icon:require('../../assets/images/icons/webIcons_Infrared.svg'),
        //     expectedAnswer:"yes"
        // },        
        {
            title:"Simcard Tray missing",
            variableName: "simcardTray",
            icon:require('../../assets/images/icons/webIcons_simCardTray.svg'),
            expectedAnswer:"yes"
        },        
        // {
        //     title:"Flashlight not working",
        //     variableName: "flashlight",
        //     icon:require('../../assets/images/icons/webIcons_flashLight.svg'),
        //     expectedAnswer:"yes"
        // },        
        // {
        //     title:"Face sensor not working",
        //     variableName: "facesensor",
        //     icon:require('../../assets/images/icons/webIcons-10.svg'),
        //     expectedAnswer:"yes"
        // },        
        {
            title:"Speakers not working", 
            variableName: "speakers",
            icon:require('../../assets/images/icons/webIcons-12.svg'),
            expectedAnswer:"yes"
        },
        {
            title:"Earpiece not working", 
            variableName: "earpiece",
            icon:require('../../assets/images/icons/webIcons_earpiece.svg'),
            expectedAnswer:"yes"
        },
        {
            title:"Power button not working", 
            variableName: "powerButton",
            icon:require('../../assets/images/icons/webIcons-08.svg'),
            expectedAnswer:"yes"
        },
        {
            title:"Charging port not working", 
            variableName: "chargingPort",
            icon:require('../../assets/images/icons/webIcons-09.svg'),
            expectedAnswer:"yes"
        },
        // {
        //     title:"Bluetooth not working", 
        //     variableName: "bluetooth",
        //     icon:require('../../assets/images/icons/webIcons-14.svg'),
        //     expectedAnswer:"yes"
        // },        
        // {
        //     title:"Phone vibrator not working", 
        //     variableName: "vibrator",
        //     icon:require('../../assets/images/icons/webIcons-15.svg'),
        //     expectedAnswer:"yes"
        // },
        // {
        //     title:"Microphone not working", 
        //     variableName: "microphone",
        //     icon:require('../../assets/images/icons/webIcons-16.svg'),
        //     expectedAnswer:"yes"
        // },
        {
            title:"Main camera not working", 
            variableName: "mainCamera",
            icon:require('../../assets/images/icons/webIcons-02.svg'),
            expectedAnswer:"yes"
        },
        {
            title:"Selfie camera not working", 
            variableName: "selfieCamera",
            icon:require('../../assets/images/icons/webIcons-01.svg'),
            expectedAnswer:"yes"
        },
        // {
        //     title:"Silent button not working",
        //     variableName: "silentButton",
        //     icon:require('../../assets/images/icons/webIcons_silentButton.svg'),
        //     expectedAnswer:"yes"
        // }
    ]
    export const functionalScreenQuestions = [   
        {
            title:"Screen is scratched", 
            variableName: "scratchedScreen",
            icon:require('../../assets/images/icons/webIcons-26.svg'),
            expectedAnswer:"no"
        },
        {
            title:"Screen has spots or lines",
            variableName: "spotsOnScreen",
            icon:require('../../assets/images/icons/webIcons-30.svg'),
            expectedAnswer:"no"
        },
        {
            title:"Screen is broken",
            variableName: "brokenScreen",
            icon:require('../../assets/images/icons/webIcons-24.svg'),
            expectedAnswer:"no"
        },
        {
            title:"Camera glass broken", 
            variableName: "cameraGlass",
            icon:require('../../assets/images/icons/webIcons-13.svg'),
            expectedAnswer:"no"
        },
        {
            title:"Back cover/glass broken or scratched", 
            variableName: "backglass",
            icon:require('../../assets/images/icons/webIcons-48_backglass.png'),
            expectedAnswer:"no"
        },
        // {
        //     title:"Battery will be replaced", 
        //     variableName: "battery",
        //     icon:require('../../assets/images/icons/webIcons-06.svg'),
        //     expectedAnswer:"no"
        // },
        {
            title:"Phone's body dented/broken",
            variableName: "dentedPhone",
            icon:require('../../assets/images/icons/webIcons_phoneDented.svg'),
            expectedAnswer:"no"
        },
        {
            title:"Phone's body is scratched", 
            variableName: "scratchedBody",
            icon:require('../../assets/images/icons/webIcons-25.svg'),
            expectedAnswer:"no"
        },
    ]
    export const originalItems = [       
        {
            title:"Original charger plug of device present",
            variableName: "charger",
            icon:require('../../assets/images/icons/webIcons-17.svg'),
            expectedAnswer:"yes"
        },
        {
            title:"Original charger cable of device present",
            variableName: "cable",
            icon:require('../../assets/images/icons/webIcons_cable-40.svg'),
            expectedAnswer:"yes"
        },
        {
            title:"Receipt with same IMEI present", 
            variableName: "reciept",
            icon:require('../../assets/images/icons/webIcons-36.svg'),
            expectedAnswer:"yes"
        },
        {
            title:"Original Box present",
            variableName: "box",
            icon:require('../../assets/images/icons/webIcons_box.svg'),
            expectedAnswer:"yes"
        },
        {
            title:"Phone under brand warranty", 
            variableName: "warranty",
            icon:require('../../assets/images/icons/webIcons-35.svg'),
            expectedAnswer:"yes",
             evaluation:{
                null:"",
                yes:"Device is under brand warranty",
                no:"Device is not under brand warranty"
            }
        },
        // {
        //     title:"Earphones present", 
        //     variableName: "earphones",
        //     icon:require('../../assets/images/icons/webIcons-18.svg'),
        //     expectedAnswer:"yes"
        // },
        // {
        //     title:"Stylus available", 
        //     variableName: "pen",
        //     icon:require('../../assets/images/icons/webIcons_stylus.svg'),
        //     expectedAnswer:"yes"
        // }
    ] 
    export const allQuestions= [
        ...functionalPhysicalQuestions,
        ...functionalScreenQuestions
    ]
