import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n
    // .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true, // this will log errors with loading translations
        fallbackLng: 'en',
        lng: 'en',
        resources: {
            en: {
                translations: require('./locales/en/translations.json'),
            },
            lg: {
                translations: require('./locales/lg/translations.json'),
            },
        },
        ns: ['translations'],
        defaultNS: 'translations',
    })

i18n.languages = ['en', 'lg']

export default i18n
