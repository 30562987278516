import styled from 'styled-components'
import { Container, Card, Grid } from '@material-ui/core'
import { devices } from 'utils/screen_sizes'

export const StyledContainer = styled(Container)`
    font-family: 'open-sans';

    @media ${devices.mobileS} {
        margin-top: 2rem;
    }
    @media ${devices.laptop} {
        margin-top: 5rem;
    }
`

export const StyledGraySection = styled.div`
    background: #f3f5f7;
    margin: 3rem 0;
    border-radius: 8px;

    .MuiCardContent-root {
        @media ${devices.mobileS} {
            padding: 16px;
        }
        @media ${devices.laptop} {
            padding: 16px;
        }
    }

    @media ${devices.mobileS} {
        padding: 0;
        background: transparent;
    }
    @media ${devices.laptop} {
        padding: 2rem;
        background: #f3f5f7;
    }

    h2 {
        text-transform: capitalize;
        margin-top: 0;
        font-family: 'playfair';

        @media ${devices.mobileS} {
            padding-top: 1rem;
            margin-bottom: 2rem;
        }
        @media ${devices.laptop} {
            padding-top: 0;
            margin-bottom: 3rem;
        }
    }
    .product-condition-title {
        font-size: 1.4rem;
        font-family: 'playfair';
        margin-top: 0;
    }
`

export const StyledAdvertWrapper = styled.div`
    @media ${devices.mobileS} {
        margin: 4rem 0;
    }
    @media ${devices.laptop} {
        margin: 5rem 0;
    }
`

export const StyledBestSellerHeader = styled.h4`
    font-size: 1.15rem;
    font-family: 'playfair';
    margin-top: 0;
    span {
        color: #777;
        font-size: 14px;
        font-family: 'open-sans';
    }
`
export const StyledSectionHeader = styled.h3`
    font-size: 1.35rem;
    font-family: 'playfair';

    @media ${devices.mobileS} {
        margin-top: 3rem;
    }
    @media ${devices.laptop} {
        margin-top: 5rem;
    }
`

export const StyledDescriptionTitle = styled.h3`
    font-size: 2rem;

    font-family: 'playfair';

    @media ${devices.mobileS} {
        margin-top: 0rem;
    }
    @media ${devices.laptop} {
        margin-top: 2rem;
    }

    .strike-through {
        color: #fff;
        text-decoration: line-through;
    }
`

export const StyledDescriptionCard = styled(Card)`
    padding: 1rem 2rem;

    @media ${devices.mobileS} {
        padding: 1rem;
    }
    @media ${devices.laptop} {
        padding: 1rem 2rem;
    }

    p {
        font-size: 16px;
        font-family: 'open-sans';
    }
`

export const StyledConditionGrid = styled(Grid)`
    .header-section {
        display: flex;
        align-items: center;

        @media ${devices.mobileS} {
            flex-direction: column;
            align-items: flex-start;
        }
        @media ${devices.laptop} {
            flex-direction: row;
            align-items: center;
        }

        img {
            max-width: 95px;
            /* max-height: 65px; */
        }
        h4 {
        
            margin: 0;
            color: ${(props) =>
                props.textcolor ? props.textcolor : 'rgb(102, 183, 99)'};
            @media ${devices.mobileS} {
                font-size: 13px;
            }
            @media ${devices.laptop} {
                font-size: 15px;
            }
        }
        .badge {
            background: ${(props) =>
                props.bgcolor ? props.bgcolor : 'rgba(102, 183, 99,.3)'};

            font-size: 9px;
            color: ${(props) =>
                props.textcolor ? props.textcolor : 'rgb(102, 183, 99)'};
            padding: 0.2rem 0.7rem;
            width: fit-content;
            margin-top: 6px;
            border-radius: 2px;
            text-transform: uppercase;
            @media ${devices.mobileS} {
                margin-bottom: 16px;
            }
            @media ${devices.laptop} {
                margin-bottom: 0px;
            }
        }
    }
    .content-section {
        h5 {
            font-size: 12px;
            margin-bottom: 0;
            margin-top: 0;
            @media ${devices.mobileS} {
                font-size: 12px;
            }
            @media ${devices.laptop} {
                font-size: 14px;
            }
        }
        p {
            font-size: 11px;
            margin-top: 5px;
            @media ${devices.mobileS} {
                font-size: 11px;
            }
            @media ${devices.laptop} {
                font-size: 13px;
            }
        }
    }
`
export const StyledFooterWrapper = styled.div`
    background: #000;
    font-family: 'open-sans';
`
export const StyledCenterGrid = styled.div`
    display: flex !important;
    justify-content: center;
    align-items: center;
    background: url('/assets/images/landing-page/GradientBox2.svg');
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;

    @media ${devices.mobileS} {
        margin-bottom: 2rem;
        background-size: cover;
    }
    @media ${devices.laptop} {
        margin-bottom: 0rem;
        background-size: contain;
    }

    img {
        /* max-height: 270px;
        margin-bottom: 3.5rem; */

        @media ${devices.mobileS} {
            max-height: 180px;
            margin-bottom: 2rem;
            margin-top: 0px;
        }
        @media ${devices.laptop} {
            max-height: 255px;
            margin-bottom: 2rem;
            margin-top: 25px;
        }
    }
    h4,
    h6,
    p {
        color: #000;
        text-align: center;
        margin: 0;
    }
    h4 {
        font-size: 1.2rem;
        font-family: 'open-sans';
    }
    p {
        font-family: 'open-sans';
        opacity: 0.6;
        text-decoration: line-through;
    }
    h6 {
        color: #42c8b7;
        font-size: 0.9rem;
    }
`
export const StyledImageWrapper = styled.div`
    height: 250px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
`
export const StyledButtonLeftWrapper = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;


    button {
        opacity: ${(props) =>
            props.hasPrev ? "1" : '0.3'};
        height: 20px;
        width: 20px;
        border-radius: 50%;
        color: #fff;
        background: #000;
        border: none;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
`
export const StyledButtonRightWrapper = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;

    button {
        opacity: ${(props) => (props.hasNext ? '1' : '0.3')};
        height: 20px;
        width: 20px;
        border-radius: 50%;
        color: #fff;
        background: #000;
        border: none;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
`
