export const API_URL = process.env.REACT_APP_API_URL,
    API_KEY = '5MECTQX-SSC4Q0N-JPRAWFH-7KHQKJN',
    API_SECRET = '9c6a2691-6630-4859-a707-083d0ddd41c1'



export const makeHttpRequest = async (path, method, body, options) => {
    let headers = {
        'Content-Type': 'application/json',
        Key: API_KEY,
        Secret: API_SECRET,
        country: localStorage.getItem('country'),
    }

    if (options) {
        for (const key in options) {
            if (key in headers) {
                if (key === 'Content-Type') {
                    delete headers[key]
                } else {
                    headers[key] = options[key]
                }
            }
        }
    }
    try {
        
        const response = await fetch(API_URL + path, {
            method,
            headers,
            body:
                body && !headers['Content-Type']
                    ? body
                    : body && headers['Content-Type']
                    ? JSON.stringify(body)
                    : null,
        })
        const json = await response.json()

        return json
    } catch (error) {
        // setError({
        //     status: true,
        //     msg: `Network Error when attempting to fetch resource.`,
        // })
    }
}
