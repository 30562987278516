import React from 'react'
import { Button, Icon } from '@material-ui/core'
import clsx from 'clsx'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import useAuth from 'app/hooks/useAuth'
import {
    addProductToCart,
    updateCartQuantity,
} from 'app/redux/actions/EcommerceActions'
import { getCountryFromUrl } from 'utils/CountryUtils'

const StyledAddToCart = styled.div`
    button {
        background-color: #f68937;
        color: #fff;
    }

    p {
        display: flex;
        margin: 0;
    }
`

const BuyNowButton = ({ className, color, product, disabled }) => {
    const history = useHistory()
    const { user } = useAuth()
    // const country = localStorage.getItem('country')

    const { cartList } = useSelector((state) => state.ecommerce)
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    let country = getCountryFromUrl(pathname)||"ke"

    const handleAddProduct = () => {
        console.log('button')
        dispatch(addProductToCart(cartList, product))
        history.push(`/${country}/ecommerce/checkout`)
        // if (user) {
        //     dispatch(addProductToCart(cartList, product))
        //     history.push(`/${country}/ecommerce/checkout`)
        // } else {
        //     dispatch(addProductToCart(cartList, product))
        //     window._toggleDrawer(`/${country}/ecommerce/checkout`)
        // }
    }

    return (
        <StyledAddToCart>
            {!disabled && (
                <Button
                    variant="contained"
                    color={'secondary'}
                    onClick={handleAddProduct}
                    disabled={disabled}
                >
                    <p>Buy Now</p>
                </Button>
            )}
            {disabled && (
                <Button disabled={disabled}>
                    <p>Buy Now</p>
                </Button>
            )}
        </StyledAddToCart>
    )
}

export default BuyNowButton
