import React from 'react'

export const MatxLayouts = {
    layout1: React.lazy(() => import('./Layout1/Layout1')),
    layout2: React.lazy(() => import('./Layout2/Layout2')),
    layout3: React.lazy(() => import('./Layout3/Layout3')),
    layout4: React.lazy(() => import('./Layout3/Layout4')),
    layout5: React.lazy(() => import('./Layout3/Layout5')),
    layoutGeneral: React.lazy(() => import('./Layout3/Layout3General')),
    repairGeneral: React.lazy(() => import('./Layout3/RepairLayout')),
    repairGeneral2: React.lazy(() => import('./Layout3/RepairLayout2')),
   
}
