import { Card, CardContent, Typography } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import { devices } from 'utils/screen_sizes'

const StyledCard = styled(Card)`
    display: flex;
    padding: 1rem;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: ${(props) => (props.margin ? '2rem' : '0rem')};
    border: none;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    .MuiTypography-subtitle1 {
        font-size: 13px;
        color: #000;
        font-weight: normal;
        font-family: 'open-sans';

        @media ${devices.mobileS} {
            text-align: left;
        }
        @media ${devices.laptop} {
            text-align: center;
        }
    }

    h6 {
        font-weight: 600;
        font-family: 'open-sans';
        font-size: 0.85rem;
        color: #42c8b7;

        @media ${devices.mobileS} {
            text-align: left;
        }
        @media ${devices.laptop} {
            text-align: center;
        }
    }

    img {
        max-width: 200px;
        max-height: 95px;
        object-fit: contain;

        @media ${devices.mobileS} {
            max-width: 95px;
            max-height: 95px;
        }
        @media ${devices.laptop} {
            max-width: 200px;
            max-height: 95px;
        }
    }
    .MuiCardContent-root:last-child {
      
        @media ${devices.mobileS} {
            padding: 16px 0 0;
        }
        @media ${devices.laptop} {
            padding: 16px 16px 0 16px;
        }
    }
`

const GridCard = ({
    title,
    description,
    imageUrl,
    margin = false,
    action = () => console.log('no action passed'),
}) => {
    return (
        <StyledCard onClick={() => action()} margin={margin}>
            <img src={imageUrl} alt="phone" />
            <CardContent>
                <Typography component="h6" variant="h6">
                    {title}
                </Typography>
                <Typography variant="subtitle1">{description}</Typography>
            </CardContent>
        </StyledCard>
    )
}

export default GridCard
