import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Drawer } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import history from 'history.js'

import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'
import { useState, useContext } from 'react'
import useSettings from 'app/hooks/useSettings'
import useAuth from 'app/hooks/useAuth'

import SellDeviceFormProvider from '../../contexts/SellDeviceFormContext'
import { devices } from 'utils/screen_sizes'
import { API_URL, API_KEY, API_SECRET, makeHttpRequest } from 'utils/api_utils'
import { getCountryFromUrl } from 'utils/CountryUtils'

const StyledValidatorForm = styled(ValidatorForm)`
    background: #fff;
    max-width: 84vw;
    min-height: 100%;

    @media ${devices.tablet} {
        max-width: 400px;
    }
`

const StyledChangePhone = styled.span`
    cursor: pointer;
`

const StyledSignUpImage = styled.img`
    width: 16rem;
    height: 16rem;
`

const useStyles = makeStyles(({ palette, ...theme }) => ({
    notification: {
        width: 360,
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
        },
        '& .notification__topbar': {
            height: 'var(--topbar-height)',
        },
    },
    notificationCard: {
        '&:hover': {
            '& .delete-button': {
                cursor: 'pointer',
                display: 'unset',
                right: 0,
                marginTop: 6,
                top: 0,
                zIndex: 2,
            },
            '& .card__topbar__time': {
                display: 'none',
            },
        },
        '& .delete-button': {
            display: 'none',
            position: 'absolute',
            right: 0,
            marginTop: 9,
        },
        '& .card__topbar__button': {
            borderRadius: 15,
            opacity: 0.9,
        },
    },
}))

//  const country = localStorage.getItem('country')

const PhoneNumberDrawer = ({
    panelOpen,
    handleDrawerToggle,
    match,
    page="buyback",
    nextPath = `/`,
}) => {
    const { pathname } = useLocation()
    let country = getCountryFromUrl(pathname)||"ke"
    const classes = useStyles()
    const { settings } = useSettings()
    const { errorMessage, sendOtp, authMessage, user, otpLogin } = useAuth()
    const location = useLocation()

    const [error, setError] = useState({ error: false, msg: '' })
    const [loading, setLoading] = useState(false)
    const [showSnackBar, setShowSnackBar] = useState(false)
    const [wrongPhoneNumber, setWrongPhoneNumber] = useState(true)
    const [signUp, setSignUp] = useState(false)
    const [userInfo, setUserInfo] = useState({
        phone: '',
        password: '',
        email: '',
        fname: '',
        lname: '',
    })

    const SellDeviceFormContext = useContext(SellDeviceFormProvider)
    const { verifyOTP, handleDeviceEstimateFormSubmit } = SellDeviceFormContext

    const [sideDrawerMessage, setSideDrawerMessage] = useState(
        'You’re just 1-step away from buying your device.'
    )
    const [sideDrawerImage, setSideDrawerImage] = useState(
        '/assets/images/phone-drawer.png'
    )

    const handleFormSubmit = async () => {
        setLoading(true)
        try {
            if (authMessage && !wrongPhoneNumber) {
                const otpAuthResponse = await verifyOTP({
                    phone: userInfo.phone,
                    random: userInfo.password,
                })
                if (!otpAuthResponse.success) {
                    setShowSnackBar(true)
                    setError({ error: true, msg: 'incorrect OTP...' })
                    setLoading(false)
                    return console.log('incorrect OTP...')
                }

                const res = {
                    ...otpAuthResponse.user,
                    token: otpAuthResponse.token,
                }
                const otpLoginAttempt = otpLogin(res)
                setLoading(false)

                if (res.signupComplete && otpLoginAttempt) {
                    if (location.pathname === '/sell-your-device') {
                        const calculation_response =
                            await handleDeviceEstimateFormSubmit(res.userId)
                        if (!calculation_response.success) {
                            handleDrawerToggle()

                            return //history.push('/device-details')
                        }
                    }

                    handleDrawerToggle()
                    return history.push(nextPath)
                } else {
                    return setSignUp(true)
                }
            }
            if (!authMessage || wrongPhoneNumber) {
                setError({ error: false, msg: '' })
                await sendOtp(userInfo.phone)
                if (errorMessage) {
                    setShowSnackBar(true)
                    setLoading(false)
                }
                setWrongPhoneNumber(false)
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
        }
    }
    const handleSignUpFormSubmit = async () => {
        setLoading(true)
        try {
            const candidate = {
                ...user,
                ...userInfo,
                userId: user.userId,
                token: window.localStorage.getItem('accessToken'),
            }
            const path = 'register/'
            try {
                const response = await makeHttpRequest(
                    path + candidate._id,
                    'PUT',
                    candidate
                )
                // const json = await response.json()
                if (!response.success) {
                    return
                }

                const otpLogInAttempt = await otpLogin(candidate)
                if (otpLogInAttempt) {
                    if (location.pathname === '/sell-your-device') {
                        const calculation_response =
                            await handleDeviceEstimateFormSubmit(
                                candidate.userId
                            )

                        if (!calculation_response.success) {
                            //show error message here
                            handleDrawerToggle()

                            return history.push(`/${country}`)
                        }
                        handleDrawerToggle()
                        return history.push(`/${country}/device-details`)
                    }

                    handleDrawerToggle()
                    return history.push(nextPath)
                    //return history.push(location.pathname)
                }
                //setLoading(false)

                //return json
            } catch (error) {
                console.log({ error })
                return { success: false }
            }
        } catch (e) {
            setLoading(false)
        }
    }
    const handleChange = ({ target: { name, value } }) => {
        let temp = { ...userInfo }
        temp[name] = value
        setUserInfo(temp)
    }
    const handleWrongPhoneNumber = () => {
        setWrongPhoneNumber(true)
        setError({ error: false, msg: '' })
    }
    const handleResendOtp = async () => {
        setLoading(true)
        try {
            if (!authMessage || wrongPhoneNumber) {
                setError({ error: false, msg: '' })
                await sendOtp(userInfo.phone)
                if (errorMessage) {
                    setShowSnackBar(true)
                    setLoading(false)
                }
                setWrongPhoneNumber(false)
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
        }
    }

    useEffect(() => {
       
        if (page==="ecommerce") {
            setSideDrawerMessage(
                'You’re just 1-step away from  buying your refurbished smartphone.'
            )
            setSideDrawerImage('/assets/images/ecommerce/signup.svg')
        }
        if (page==="repair") {
            setSideDrawerMessage(
                'You’re just 1-step away from repairing your smartphone.'
            )
            setSideDrawerImage('/assets/images/ecommerce/signup.svg')
        }

        return function cleanUp() {
         
        }
    }, [])

    return (
        <ThemeProvider theme={settings.themes[settings.activeTheme]}>
            <Drawer
                width={'100px'}
                variant="temporary"
                anchor={'right'}
                open={panelOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                {!signUp && (
                    <StyledValidatorForm onSubmit={handleFormSubmit}>
                        <div>
                            <div
                                className="notification__topbar flex items-center p-4"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'left',
                                    height: '100%',
                                }}
                            >
                                <StyledSignUpImage
                                    src={sideDrawerImage}
                                    alt="sign in"
                                />
                                <h4
                                    className="ml-2 my-0 font-medium"
                                    style={{
                                        margin: '24px 8px',
                                        color: '#555',
                                    }}
                                >
                                    {sideDrawerMessage}
                                </h4>
                                <label style={{ margin: '24px 8px' }}>
                                    Please enter your phone number
                                </label>
                                <div style={{ width: '100%' }}>
                                    <TextValidator
                                        className={`w-full ${
                                            authMessage ? '' : 'mb-4'
                                        }`}
                                        variant="outlined"
                                        size="small"
                                        label="Mobile Number"
                                        onChange={handleChange}
                                        disabled={!wrongPhoneNumber}
                                        type="text"
                                        name="phone"
                                        value={userInfo.phone}
                                        validators={['required']}
                                        errorMessages={[
                                            'this field is required',
                                        ]}
                                    />
                                    {authMessage && !wrongPhoneNumber && (
                                        <React.Fragment>
                                            <small
                                                className="mb-4 mt-1"
                                                style={{
                                                    textAlign: 'right',
                                                    display: 'block',
                                                }}
                                            >
                                                Wrong phone number?{' '}
                                                <StyledChangePhone
                                                    className="text-primary"
                                                    onClick={
                                                        handleWrongPhoneNumber
                                                    }
                                                >
                                                    change
                                                </StyledChangePhone>
                                            </small>
                                            <TextValidator
                                                className="mb-3 w-full"
                                                label="OTP"
                                                variant="outlined"
                                                size="small"
                                                onChange={handleChange}
                                                name="password"
                                                type="text"
                                                value={userInfo.password}
                                                validators={['required']}
                                                errorMessages={[
                                                    'this field is required',
                                                ]}
                                            />
                                            <small
                                                className="mb-4 mt-1"
                                                style={{
                                                    textAlign: 'right',
                                                    display: 'block',
                                                }}
                                            >
                                                Did not get a text from us?{' '}
                                                <StyledChangePhone
                                                    className="text-primary"
                                                    onClick={
                                                        handleWrongPhoneNumber
                                                    }
                                                >
                                                    resend
                                                </StyledChangePhone>
                                            </small>
                                        </React.Fragment>
                                    )}

                                    {authMessage &&
                                        !error.msg &&
                                        !errorMessage &&
                                        !wrongPhoneNumber && (
                                            <>
                                                <p className="text-green">
                                                    {authMessage}
                                                </p>
                                            </>
                                        )}
                                    {error.msg && authMessage && (
                                        <p className="text-error">
                                            {error.msg}
                                        </p>
                                    )}
                                    <div className="relative">
                                        <Button
                                            variant="contained"
                                            disableElevation
                                            color="primary"
                                            disabled={loading}
                                            type="submit"
                                            fullWidth
                                            className={classes.signinButton}
                                        >
                                            {authMessage && !wrongPhoneNumber
                                                ? 'Verify OTP'
                                                : 'Request OTP'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </StyledValidatorForm>
                )}
                {signUp && (
                    <StyledValidatorForm onSubmit={handleSignUpFormSubmit}>
                        <div>
                            <div
                                className="notification__topbar flex items-center p-4"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'left',
                                    height: '100%',
                                }}
                            >
                                <StyledSignUpImage
                                    src={sideDrawerImage}
                                    alt="sign up"
                                />
                                <div style={{ width: '100%' }}>
                                    <TextValidator
                                        className={`w-full ${
                                            authMessage ? '' : 'mb-4'
                                        }`}
                                        variant="outlined"
                                        size="small"
                                        label="Mobile Number"
                                        onChange={handleChange}
                                        disabled={!wrongPhoneNumber}
                                        type="text"
                                        name="phone"
                                        value={userInfo.phone}
                                        validators={['required']}
                                        errorMessages={[
                                            'this field is required',
                                        ]}
                                    />
                                    <React.Fragment>
                                        <p
                                            style={{
                                                margin: '24px 8px',
                                                color: '#555',
                                            }}
                                        >
                                            <b>Please sign up to continue.</b>
                                        </p>
                                        <TextValidator
                                            className="mb-3 w-full"
                                            label="Email"
                                            variant="outlined"
                                            size="small"
                                            onChange={handleChange}
                                            name="email"
                                            type="email"
                                            value={userInfo.email.toLowerCase()}
                                            validators={['required']}
                                            errorMessages={[
                                                'this field is required',
                                            ]}
                                        />
                                        <TextValidator
                                            className="mb-3 w-full"
                                            label="First Name"
                                            variant="outlined"
                                            size="small"
                                            onChange={handleChange}
                                            name="fname"
                                            type="text"
                                            value={userInfo.fname}
                                            validators={['required']}
                                            errorMessages={[
                                                'this field is required',
                                            ]}
                                        />
                                        {/* <TextValidator
                                            className="mb-3 w-full"
                                            label="Last Name"
                                            variant="outlined"
                                            size="small"
                                            onChange={handleChange}
                                            name="lname"
                                            type="text"
                                            value={userInfo.lname}
                                            validators={['required']}
                                            errorMessages={[
                                                'this field is required',
                                            ]}
                                        /> */}
                                        <div className="relative">
                                            <Button
                                                variant="contained"
                                                disableElevation
                                                color="primary"
                                                disabled={loading}
                                                type="submit"
                                                fullWidth
                                                className={classes.signinButton}
                                            >
                                                Sign Up
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                </div>
                            </div>
                        </div>
                    </StyledValidatorForm>
                )}
            </Drawer>
        </ThemeProvider>
    )
}

export default PhoneNumberDrawer
