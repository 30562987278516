import {devices} from '../../../utils/screen_sizes'
import styled from 'styled-components'
import {Card} from '@material-ui/core'

import {solidBlack} from '../../../utils/colors'

export const StyledSection = styled.div`   
    display:grid;
    grid-template-rows: 1fr;
    margin:1rem;

   

    @media ${devices.tabletL} {
        grid-template-columns: 2fr 1fr;
        grid-gap:1rem;
        grid-template-rows: none;
        margin:4rem auto;        
        width: 90vw;
    }

    @media ${devices.desktop} {      
        width: 70vw;
    }
`
export const StyledCard = styled(Card)`
    margin: auto;
    padding: 16px;
    height: 100%;
    width: 100%;
    box-shadow:none;

    h4,h5, p, li{
        color:${solidBlack};
    }

    
    &:hover{
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.06),0px 1px 1px 0px rgba(0, 0, 0, 0.042),0px 1px 3px 0px rgba(0, 0, 0, 0.036);
    }

    @media ${devices.tablet} and (max-width:1000px)  {
    }
    
    @media ${devices.laptop} {
        padding: 32px;
        .input-select{
            width:24rem;
        }
    }

`
export const StyledSelectModel = styled(Card)`
    margin: auto;
    padding: 16px;
    height: 100%;
    width: 100%;
    box-shadow:none;
    display:flex;
    flex-direction:column;

    h5{
        color:${solidBlack};
    }

    p, li{
        color:${solidBlack};
    }

    
    &:hover{
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.06),0px 1px 1px 0px rgba(0, 0, 0, 0.042),0px 1px 3px 0px rgba(0, 0, 0, 0.036);
    }

    @media ${devices.tablet} {
        .input-select{
            width:24rem;
            margin:auto;
        }
    }
    
    @media ${devices.laptop} {
        padding: 32px;
        
    }

`
export const StyledToggleForm = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;

    div {
        border-radius: 0 0 4px 4px;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        &:hover {
            box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.06),
                0px 1px 1px 0px rgba(0, 0, 0, 0.042),
                0px 1px 3px 0px rgba(0, 0, 0, 0.036);
        }
    }

    img {
        width: 100%;
    }

    @media ${devices.tablet} {
        grid-template-columns: repeat(4, 1fr);

        img {
            width: 60%;
            margin: auto;
        }
    }

    @media ${devices.laptop} {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1rem;

        img {
            width: 60%;
            margin: auto;
        }
    }
`
export const StyledFormGraphics = styled.div`
    display:none;

    @media ${devices.tabletL} {
        display:flex;
    }
`