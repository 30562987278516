class localStorageService {
    ls = window.localStorage

    setItem(key, value) {

        try{
            value = JSON.stringify(value)
            this.ls.setItem(key, value)
            return true

        }
        catch(err){
   
            return null
        }
    }

    getItem(key) {
        let value = this.ls.getItem(key)
        try {
            return JSON.parse(value)
        } catch (e) {
            return null
        }
    }

   loadState (key){
        try {
          const serializedState = localStorage.getItem(key);
          if (serializedState === null) {
          
            return [];
          }
          return JSON.parse(serializedState);
        } catch (err) {
      
          return null;
        }
    };
    
    saveState (key, value) {
        try {
            //const {cartList} = state
          const serializedState = JSON.stringify(value);
          localStorage.setItem(key, serializedState);
        } catch (err) {
          // ignore write errors
         
        }
      };
}

export default new localStorageService()
