import styled from 'styled-components'
import { devices } from 'utils/screen_sizes'

export const StyledMapWrapper = styled.div`
    position: relative;
    min-height: 600px;
    width: 100%;
    /* @media ${devices.mobileS} {
        min-height: 450px;
        width: 95vw;
    }
    @media ${devices.mobileM} {
        min-height: 450px;
        width: 95vw;
    }
    @media ${devices.mobileL} {
        min-height: 450px;
        width: 80vw;
    }
    @media ${devices.laptop} {
        width: 70vw;
        min-height: 650px;
    }

    @media ${devices.desktop} {
        height: 20vh;
    } */
`
