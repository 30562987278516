import RepairLandingPage from './RepairLandingPage'
import RepairAddressPage from './RepairAddressPage'
import RepairProPage from './RepairProPage'
import RepairSummaryPage from './RepairProSummary'

const repairRoutes = [
    {
        path: `/:country/repair/address`,
        component: RepairAddressPage,
        exact: true,
    },
    {
        path: `/:country/repair/summary`,
        component: RepairSummaryPage,
        exact: true,
    },
    {
        path: `/:country/repair/:id`,
        component: RepairLandingPage,
        exact: true,
    },
    {
        path: `/:country/repair-pro`,
        component: RepairProPage,
        exact: true,
    },
]

export default repairRoutes
