import React from 'react'
import { Card, Button, Icon, Grid } from '@material-ui/core'
import {Link} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
    addProductToCart,
    updateCartQuantity,
} from 'app/redux/actions/EcommerceActions'
import Rating from '@material-ui/lab/Rating'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { AddToCartButton } from 'app/components'
import useAuth from 'app/hooks/useAuth'
import styled from 'styled-components'
import {replaceWhiteSpaceWithHyphen} from 'utils/string_utils'

const StyledListImg = styled.img` 
    max-height: 180px;
`

const StyledProductTitle = styled.p` 
    font-size: 1.6rem;
    cursor:pointer;
    color: #333;
`

const StyledProductPrice = styled.span` 
    font-size:1.5rem;
`

const StyledList = styled.ul` 
    padding-left:0.8rem;
`

const useStyles = makeStyles(({ palette, ...theme }) => ({
    productCard: {
        '& .product-price': {
            position: 'absolute',
            right: 0,
            top: 24,
            borderTopLeftRadius: 26,
            borderBottomLeftRadius: 26,
            zIndex: 4,
        },
        '& .image-box-overlay': {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: 'rgba(0, 0, 0, 0.74)',
            zIndex: 2,
            opacity: 0,
            transition: 'all 250ms ease-in-out',
        },
        '&:hover': {
            '& .image-box-overlay': {
                opacity: 1,
            },
        },
    },
}))

export const ListProductCard = ({ product, image }) => {
   
    const classes = useStyles()
    const { user } = useAuth()
    const { cartList } = useSelector((state) => state.ecommerce)
    const dispatch = useDispatch()

    const quantity = cartList?.find((p) => p.id === product.id)?.quantity || 0
    const {model:{modelName}} = product

    const handleUpdateCart = (productQuantity) => {
        dispatch(updateCartQuantity(user.id, product.id, productQuantity))
    }

    const handleAddProduct = () => {
        dispatch(addProductToCart(user.id, product.id))
    }

   

    return (
        <Card
            elevation={3}
            className={clsx('p-4 relative h-full', classes.productCard)}
        >
            <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <div className="flex justify-center items-center relative">
                        <StyledListImg
                            src={image}
                            alt={product.title}
                        />
                        {/* <div className="image-box-overlay flex justify-center items-center">
                            <Button
                                variant="outlined"
                                className="bg-default"
                                onClick={() =>
                                    handleAddProduct(user.id, product.id)
                                }
                            >
                                <Icon className="mr-2">shopping_cart</Icon>
                                <span>Add to cart</span>
                            </Button>
                        </div> */}
                    </div>
                </Grid>
                <Grid item lg={8} md={8} sm={12} xs={12} className="p-6">
                    <StyledProductTitle className="m-0 mb-3">{modelName}</StyledProductTitle>
                    <div className="flex justify-between mb-4">
                        {/* <StyledProductPrice className="text-muted">
                            ${product.price.toFixed(2)}
                        </StyledProductPrice> */}
                        <Rating
                            size="small"
                            readOnly={true}
                            name="half-rating"
                            value={product.rating}
                            precision={0.5}
                        />
                    </div>
                    {/*<AddToCartButton
                        className="mb-4"
                        quantity={quantity}
                        totalUnit={product.totalUnit}
                        handleAddProduct={handleAddProduct}
                        handleUpdateCart={handleUpdateCart}
                    />
                     <p className="m-0 text-muted">
                        {product.description.substring(0, 200)}
                    </p> */}
                    <StyledList>
                        <li><p>4 GB RAM | 64 GB ROM | Expandable Upto 512 GB</p></li>
                        <li><p>16.59 cm (6.53 inch) HD+ Display</p></li>
                        <li><p>13MP + 2MP + 2MP | 5MP Front Camera</p></li>
                        <li><p>5000 mAh Lithium-ion Polymer Battery</p></li>
                        <li><p>MediaTek Helio G35 Processo</p></li>
                    </StyledList>
                </Grid>
            </Grid>
        </Card>
    )
}
export const ListModelCard = ({ product, image }) => {
    const classes = useStyles()
    const { user } = useAuth()
    const { cartList } = useSelector((state) => state.ecommerce)
    const dispatch = useDispatch()

    const {model:{modelName}} = product

    const handleUpdateCart = (productQuantity) => {
        dispatch(updateCartQuantity(user.id, product.id, productQuantity))
    }

    const handleAddProduct = () => {
        dispatch(addProductToCart(user.id, product.id))
    }



    return (
        <Card
            elevation={3}
            className={clsx('p-4 relative h-full', classes.productCard)}
        >
            <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <div className="flex justify-center items-center relative">
                        <StyledListImg src={image} alt={product.title} />
                    </div>
                </Grid>
                <Grid item lg={8} md={8} sm={12} xs={12} className="p-6">
                    <StyledProductTitle className="m-0 mb-3">
                        <Link
                            to={{
                                pathname: `/ecommerce/product-details/${replaceWhiteSpaceWithHyphen(
                                    modelName
                                )}`,
                                state: { _id: product._id },
                            }}
                           
                        >
                            {modelName}
                        </Link>
                    </StyledProductTitle>
                    <div className="flex justify-between mb-4">
                        {/* <StyledProductPrice className="text-muted">
                            ${product.price.toFixed(2)}
                        </StyledProductPrice> */}
                        <Rating
                            size="small"
                            readOnly={true}
                            name="half-rating"
                            value={product.rating}
                            precision={0.5}
                        />
                    </div>
                    <StyledList>
                        <li>
                            <p>4 GB RAM | 64 GB ROM | Expandable Upto 512 GB</p>
                        </li>
                        <li>
                            <p>16.59 cm (6.53 inch) HD+ Display</p>
                        </li>
                        <li>
                            <p>13MP + 2MP + 2MP | 5MP Front Camera</p>
                        </li>
                        <li>
                            <p>5000 mAh Lithium-ion Polymer Battery</p>
                        </li>
                        <li>
                            <p>MediaTek Helio G35 Processo</p>
                        </li>
                    </StyledList>
                </Grid>
            </Grid>
        </Card>
    )
}


