import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { devices } from 'utils/screen_sizes'

export const StyledInput = styled.div`
    input {
        font-weight: 400;
        font-size: 1rem;
        border-radius: 4px;
        padding: 8.5px 14px;
        border: ${(props) =>
            props.borderColor
                ? `1px solid ${props.borderColor}`
                : '1px solid red'};

        line-height: 1.4375em;
        width: ${(props) => (props.width ? props.width : '100%')};

        @media ${devices.mobileS} {
            font-size: 1rem;
        }
        @media ${devices.laptop} {
            width: ${(props) => (props.width ? props.width : '100%')};
        }

        :focus {
            border: ${(props) =>
                props.borderColor
                    ? `1px solid ${props.borderColor}`
                    : '1px solid red'};
        }
        :hover {
            border: ${(props) =>
                props.borderColor
                    ? `1px solid ${props.borderColor}`
                    : '1px solid red'};
        }
    }
`

let autoComplete

const loadScript = (url, callback) => {
    let script = document.createElement('script')
    script.type = 'text/javascript'

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (
                script.readyState === 'loaded' ||
                script.readyState === 'complete'
            ) {
                script.onreadystatechange = null
                callback()
            }
        }
    } else {
        script.onload = () => callback()
    }

    script.src = url
    document.getElementsByTagName('head')[0].appendChild(script)
}

function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,
        { types: ['(cities)'], componentRestrictions: { country: 'us' } }
    )
    autoComplete.setFields(['address_components', 'formatted_address'])
    autoComplete.addListener('place_changed', () =>
        handlePlaceSelect(updateQuery)
    )
}

async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace()
    const query = addressObject.formatted_address
    updateQuery(query)
   
}

function SearchLocationInput({ borderColor = '', width = '' }) {
    const [query, setQuery] = useState('')
    const autoCompleteRef = useRef(null)

    useEffect(() => {
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
            () => handleScriptLoad(setQuery, autoCompleteRef)
        )
    }, [])

    return (
        <StyledInput
            className="search-location-input"
            borderColor={borderColor}
            width={width}
        >
            <input
                ref={autoCompleteRef}
                onChange={(event) => setQuery(event.target.value)}
                placeholder="Enter a City"
                value={query}
            />
        </StyledInput>
    )
}

export default SearchLocationInput
