import styled from 'styled-components'
import { SimpleCard } from 'app/components'
import Accordion from '@material-ui/core/Accordion'

import Grid from '@material-ui/core/Grid'

import { GreyStyledCard } from './deviceDetails.style'
import Rating from '@material-ui/lab/Rating'
import { devices } from 'utils/screen_sizes'
import { CircularProgress } from '@material-ui/core'

export const PaymentWrapper = styled(GreyStyledCard)`
    border: 0;
    display: block;

    ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
`

export const StyledCommentsSimpleCard = styled(SimpleCard)`
    height: 100%;
    margin-bottom: 4rem;
`

export const StyledBadiliTrust = styled.div`
    p {
        margin-bottom: 0;
    }
`

export const IconWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 1rem 0 2rem 0;

    @media ${devices.laptop} {
        grid-template-columns: repeat(4, 1fr);
    }

    img {
        width: 5em;
        height: 5em;
        object-fit: contain;
        margin: auto;
    }
`

export const StyledPhoneModel = styled.p`
    font-size: 1.2em;
    color: #000;
`

export const StyledProductTitle = styled.p`
    font-size: 1.6rem;
    cursor: pointer;
    color: #333;
    margin-bottom: 1rem;
`

export const StyledPriceSpan = styled.p`
    color: #0c8085 !important;
    font-size: 2.4em;
    font-weight: bold !important;
    margin: 1rem 0;
`

export const StyledPreviousPrice = styled.h4`
    text-decoration: line-through;
    margin-left: 0.4rem;
    color: #ccc !important;
`

export const StyledProductDescription = styled.p`
    line-height: 2rem;
`

export const StyledProductDetails = styled.div`
    margin-top: 2rem;
`

export const StyledRating = styled(Rating)`
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
`

export const StyledProductSpecifications = styled.div`
    margin: 1.5rem 0;
    width: 100%;
`

export const StyledProductSpecification = styled.div`
    display: grid;
    grid-template-rows: repeat(2, auto);
    padding: 0.2rem;

    @media ${devices.laptop} {
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 5fr;
    }
`

export const StyledImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 4rem;
    bottom: 0;
    z-index: 2;
    align-self: flex-start;
    scroll-snap-align: start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-radius: 0.4rem;
    width: 100%;

    button {
        margin-top: 2rem;
        padding: 0.3rem 0.8rem;
        width: 100%;

        @media ${devices.laptop} {
            width: auto;
        }
    }
`
export const StyledGalleryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid #eee;
    width: 100%;

    span {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        @media ${devices.laptop} {
        }
    }
`

export const StyledProductSpecificationTitle = styled.div`
    p {
        margin: 0 !important;
        font-weight: bold !important;
    }
`

export const StyledProductSpecificationWrapper = styled.div`
    p {
        padding: 0 2rem;
        margin-top: 0;
    }

    fieldset,
    label {
        margin: 0 1rem;
    }
`

export const StyledAccordion = styled(Accordion)`
    width: 100%;
    margin: 0.4rem 0;
    border-radius: 0.4rem;
`

export const StyledAccordionTitle = styled.p`
    margin: 0;
    font-weight: bold !important;
`

export const StyledProductDetailsPage = styled.div`
    * {
        font-family: lato;
    }
    margin: auto;
    width: 100vw;
    border-radius: 0.4rem;
    background: #fff;
    padding: 0.8em;
    margin: 2rem auto;
    color: #444;

    .MuiRadio-colorPrimary.Mui-checked {
        color: #0c8085 !important;
    }

    .MuiButton-contained.Mui-disabled {
        color: rgba(0, 0, 0, 0.26);
        box-shadow: none;
        background-color: #58cab9 !important;
    }

    @media ${devices.tablet} {
        width: 96vw;
    }

    @media ${devices.laptop} {
        max-width: 1100px;
        width: 80vw;
    }
`

export const StyledRefurbishedSuperb = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 1.2rem 1.2rem 1.2rem;
    background-color: #f1f1f1;
    border-radius: 0.4rem;
    width: 100%;

    span {
        color: #fff;
        background-color: #f68937;
        border-radius: 1rem;
        font-size: 0.7rem;
        width: 12rem;
        text-align: center;
        padding: 0.4rem;
        cursor: pointer;
    }
`

export const StyledBadge = styled.div`
    display: flex;
    align-items: center;
    height: 100%;

    span {
        border: 1px solid #58cab9;
        border-radius: 0.6rem;
        width: fit-content;
        padding: 0.4rem 0.8rem;
        margin: 0.2rem;
        cursor: pointer;
        transition: 0.3s ease-in-out;

        &:hover {
            background-color: #58cab9;
            color: #fff;
            transition: 0.3s ease-in-out;
        }
    }
`

export const StyledWarranty = styled.div`
    display: flex;
    flex-direction: column;

    h4 {
        margin: 1.25rem 0 0 0;
    }

    li {
        color: #666;
    }
`

export const StyledModelHorizontalLine = styled.hr`
    width: 100%;
    background: #eee;
    border: none;
    height: 1px;
`

export const StyledHorizontalLine = styled.hr`
    margin: 2rem 0;
    width: 100%;
    background: #eee;
    border: none;
    height: 1px;
`

export const StyledSelectedImage = styled.img`
    margin: auto;
    max-width: 80%;
    max-height: 80%;
`
export const StyledGalleryThumbs = styled.div`
    overflow: scroll hidden;
    display: flex;
    flex-direction: row;
    height: 5rem;
    width: 100%;

    img {
        border: 1px solid #eee;
        cursor: pointer;
    }
`

export const StyledButtonWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    gap: 0.2em;

    button {
        width: 100%;
    }
`

export const StyledYellowTitle = styled.p`
    color: #f6b81b !important;
    font-size: 1.6rem;
    font-weight: 800 !important;
`

export const StyledVariants = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media ${devices.laptop} {
        flex-direction: row;
    }
`
export const StyledVariantsTitle = styled.p`
    margin-right: 1rem;
    font-weight: bold !important;
    /* margin:.5rem 1rem .5rem .5rem;  */
`
export const StyledVariantsWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    margin-right: 1rem;

    label {
        border: 1px solid #eee;
        padding: 1rem 1rem 1rem 0;
        border-radius: 0.4rem;
        color: #666;
        margin-bottom: 1rem;
    }
`

export const StyledProductGrid = styled(Grid)`
    width: 100%;
`
export const StyledLoader = styled(CircularProgress)`
    color: #f68937;
`
