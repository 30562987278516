import React from 'react'
import { Button, Icon } from '@material-ui/core'
import clsx from 'clsx'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import {
    addProductToCart,
    updateCartQuantity,
} from 'app/redux/actions/EcommerceActions'
import {addToCartEvent} from 'utils/FacebookPixelEvents'

const StyledAddToCart = styled.div`
    button{
        color: #F68937;
        border-color: #F68937;
    }

    p{   
        display: flex;      
        margin: 0;
    }
`

const StyledQuantity = styled.span`
    margin-left: .2em;
    background-color: #ff9e43;
    color: #fff;
    width: 1.8em;
    height: 1.8em;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-content: center;    
`

const AddToCartButton = ({
    className,
    color,
    product,
    disabled
}) => {
    
    const { cartList } = useSelector((state) => state.ecommerce)
    const dispatch = useDispatch()
    
    const quantity = cartList?.find((p) => product.variant._id === p.variant._id)?.variant.quantity || 0
    
    const handleQuantityIncrease = () => {
        handleUpdateCart(quantity + 1)
    }
    const handleQuantityDecrease = () => {
        handleUpdateCart(quantity - 1)
    }

    const handleUpdateCart = (productQuantity) => {
        dispatch(updateCartQuantity(cartList, product.id, productQuantity))
    }

    const handleAddProduct = () => {
   
        dispatch(addProductToCart(cartList, product))
        window._toggleShoppingCart()
        // addToCartEvent()
        
    }

    return (
        <StyledAddToCart>
            <Button
                className={className}
                variant="outlined"
                color="secondary"
                onClick={handleAddProduct}
                disabled={disabled}
            >                
                {/* <Icon className="mr-2" fontSize="small">
                    shopping_cart 
                </Icon> */}
                <p>
                    Add To Cart {quantity > 0 && (<StyledQuantity>{quantity} </StyledQuantity>)} 
                </p>
            </Button>
            {/* 
            {quantity === 0 && (
                <Button
                    className={className}
                    variant="contained"
                    color="primary"
                    onClick={handleAddProduct}
                >
                    <Icon className="mr-2" fontSize="small">
                        shopping_cart
                    </Icon>
                    Add To Cart
                </Button>
            )}
            {quantity > 0 && (
                <div className={clsx('flex justify-between w-160', className)}>
                    <Button
                        className="p-2 min-w-32"
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleQuantityDecrease}
                    >
                        <Icon fontSize="small">remove</Icon>
                    </Button>
                    <div className="flex-grow flex justify-center items-center bg-paper">
                        {quantity}
                    </div>
                    <Button
                        className="p-2 min-w-32"
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleQuantityIncrease}
                        disabled={quantity === totalUnit}
                    >
                        <Icon fontSize="small">add</Icon>
                    </Button>
                </div>
            )} 
            */}
        </StyledAddToCart>
    )
}

export default AddToCartButton
