import React from 'react'
import { NavLink, Link, useLocation } from 'react-router-dom'
import { Icon } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { solidBlack } from '../../../utils/colors'

import styled from 'styled-components'
import { getCountryFromUrl } from 'utils/CountryUtils'

const StyledHorizontalNav = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: 1;
    color: ${solidBlack};
`

const StyledComingSoon = styled.span`
    background-color: #58cab9;
    color: #fff;
    padding: 0 2px;
    border-radius: 4px;
    margin: -24px -20px 0 -16px;
    font-size: 0.7rem;
`

const StyledDisabledLink = styled(NavLink)`
    cursor: disabled;
    cursor: not-allowed;
`

const MatxHorizontalNav = ({ max, color = '#333', navigation }) => {
   
    const { pathname } = useLocation()
    let country = getCountryFromUrl(pathname)||"ke"

    if (!navigation || !navigation.length) {
        return null
    }

    if (max && navigation.length > max) {
        let childItem = {
            name: 'More',
            icon: 'more_vert',
            children: navigation.slice(max, navigation.length),
        }
        navigation = navigation.slice(0, max)
        navigation.push(childItem)
    }

    function renderLevels(levels) {
        return levels.map((item, key) => {
            if (item.type === 'label') return null
            if (item.children) {
                return (
                    <li key={key}>
                        <NavLink
                            to={item.path ? item.path : `/${country}`}
                            style={{ color: color }}
                        >
                            {item.icon && (
                                <Icon className="text-18 align-middle">
                                    {item.icon}
                                </Icon>
                            )}
                            {item.name}
                        </NavLink>
                        <ul>{renderLevels(item.children)}</ul>
                    </li>
                )
            } else if (item.name === 'Repair Your Phone') {
                return (
                    <li key={key}>
                        <StyledDisabledLink
                            to={item.path}
                            style={{ color: color }}
                        >
                            {item.icon && (
                                <Icon className="text-18 align-middle">
                                    {item.icon}
                                </Icon>
                            )}
                            {item.name}
                            <StyledComingSoon>Coming soon</StyledComingSoon>
                        </StyledDisabledLink>
                    </li>
                )
            } else if (item.name === 'Store')
                return (
                    <li key={key}>
                        <NavLink
                            to={item.path}
                            style={{ color: color }}
                            target="_blank"
                        >
                            {item.icon && (
                                <Icon className="text-18 align-middle">
                                    {item.icon}
                                </Icon>
                            )}
                            {item.name}
                        </NavLink>
                    </li>
                )
            else {
                return (
                    <li key={key}>
                        <NavLink to={item.path} style={{ color: color }}>
                            {item.icon && (
                                <Icon className="text-18 align-middle">
                                    {item.icon}
                                </Icon>
                            )}
                            {item.name}
                        </NavLink>
                    </li>
                )
            }
        })
    }

    return (
        <StyledHorizontalNav className={'horizontal-nav'}>
            <ul className={'menu'}>{renderLevels(navigation)}</ul>
        </StyledHorizontalNav>
    )
}

export default MatxHorizontalNav
