const blogs = [
    {
        id: 1,
        title: 'Why You Should Consider A Refurbished Smartphone Over A New One.',
        excerpt:'Flagship devices have pushed the price tag upwards in recent years.',
        src:'https://gadgets-africa.com/wp-content/uploads/2021/04/IMG_9940-800x400.jpg',
        link:'https://gadgets-africa.com/2021/04/08/why-you-should-consider-a-refurbished-phone-over-a-new-one/',
    },
    {
        id: 2,
        title: 'Here Are The Top Three Smartphones Sold At Half Price.',
        excerpt:'Buying a new phone is a great feeling, but can be quite expensive experience.',
        src:"https://gadgets-africa.com/wp-content/uploads/2022/04/IMG_9988-800x400.jpg",
        link:'https://gadgets-africa.com/2021/04/08/here-are-the-top-three-smartphones-sold-at-half-price-with-badili/'
    }
]

export default blogs