import React, { createContext, useReducer } from 'react'
import {
    API_URL,
    API_KEY,
    API_SECRET,
    makeHttpRequest,
} from '../../utils/api_utils'
import localStorageService from 'app/services/localStorageService'
import { defaultRequestBody } from '../views/SellYourDevice/defaultRequestBody'


const initState = {
    phoneNumber: '',
    step: '',
    OTP: '',
    userId: '',
    requestBody: defaultRequestBody,
    models: [],
    buyModels: [],
    selectedModel: { price: 0 },
    selectedBuyModel: { price: 0 },
    variants: [],
    colors: [],
}
const country = localStorage.getItem('country')


const reducer = (state, action) => {
    switch (action.type) {
        case 'SUBMIT_REQUEST_BODY': {
            return {
                ...state,
                requestBody: {
                    ...state.requestBody,
                    ...action.payload,
                },
            }
        }
        case 'RESET_REQUEST_BODY': {
           
            return {
                ...state,
                requestBody: defaultRequestBody,
            }
        }
        case 'SUBMIT_MODELS': {
            return {
                ...state,
                models: action.payload,
            }
        }
        case 'SUBMIT_BUY_MODELS': {
            return {
                ...state,
                buyModels: action.payload,
            }
        }
        case 'SUBMIT_VARIANTS': {
            return {
                ...state,
                variants: action.payload,
            }
        }
        case 'SUBMIT_COLORS': {
            return {
                ...state,
                colors: action.payload,
            }
        }
        case 'SUBMIT_SELECTED_MODEL': {
            return {
                ...state,
                selectedModel: action.payload,
            }
        }
        case 'SUBMIT_SELECTED_BUY_MODEL': {
            return {
                ...state,
                selectedBuyModel: action.payload,
            }
        }
        case 'SUBMIT_PHONENUMBER': {
            return {
                ...state,
                phoneNumber: action.payload,
            }
        }
        case 'SUBMIT_OTP': {
            return {
                ...state,
                OTP: action.payload,
            }
        }
        case 'SUBMIT_USER_ID': {
            return {
                ...state,
                userId: action.payload,
            }
        }
        case 'SUBMIT_STEP': {
         
            return {
                ...state,
                step: action.payload,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const SellDeviceFormContext = createContext({
    phoneNumber: '',
    step: '',
    OTP: '',
    userId: '',
    requestBody: {},
    models: [],
    buyModels: [],
    selectedModel: { price: 0, _id: '' },
    selectedBuyModel: { price: 0, _id: '' },
    variants: {},
    colors: [],
    setPhoneNumber: () => {},
    setStep: () => {},
    setOTP: () => {},
    setUserId: () => {},
    setRequestBody: () => {},
    setRequestBuyBody: () => {},
    resetRequestBody: () => {},
    setModels: () => {},
    setBuyModels: () => {},
    setSelectedModel: () => {},
    setSelectedBuyModel: () => {},
    setVariants: () => {},
    handleSetVariant: () => {},
    handleSetBuyVariant: () => {},
    handleDeviceEstimateFormSubmit: () => {},
    fetchVariants: () => {},
    setColors: () => {},
})

export const SellDeviceFormProvider = ({ settings, children }) => {
    const [state, dispatch] = useReducer(reducer, initState)
    const { loadState, saveState } = localStorageService

    const setRequestBody = (requestBody) => {
        try {
            dispatch({
                type: 'SUBMIT_REQUEST_BODY',
                payload: requestBody,
            })
        } catch (e) {
            console.error(e)
        }
    }
    const setRequestBuyBody = (requestBody) => {
        try {
            dispatch({
                type: 'SUBMIT_REQUEST_BUY_BODY',
                payload: requestBody,
            })
        } catch (e) {
            console.error(e)
        }
    }
    const resetRequestBody = () => {
        try {
            dispatch({
                type: 'RESET_REQUEST_BODY',
            })
        } catch (e) {
            console.error(e)
        }
    }
    const setModels = (models) => {
        try {
            dispatch({
                type: 'SUBMIT_MODELS',
                payload: models,
            })
        } catch (e) {
            console.error(e)
        }
    }
    const setBuyModels = (buyModels) => {
        try {
            dispatch({
                type: 'SUBMIT_BUY_MODELS',
                payload: buyModels,
            })
        } catch (e) {
            console.error(e)
        }
    }
    const setVariants = (variants) => {
        try {
            dispatch({
                type: 'SUBMIT_VARIANTS',
                payload: variants,
            })
        } catch (e) {
            console.error(e)
        }
    }
    const setColors = (colors) => {
        try {
            dispatch({
                type: 'SUBMIT_COLORS',
                payload: colors,
            })
        } catch (e) {
            console.error(e)
        }
    }
    const setSelectedModel = (selectedModel) => {
        saveState('selectedModel', selectedModel)
        try {
            dispatch({
                type: 'SUBMIT_SELECTED_MODEL',
                payload: selectedModel,
            })
        } catch (e) {
            console.error(e)
        }
    }
    const setSelectedBuyModel = (selectedBuyModel) => {
        saveState('selectedBuyModel', selectedBuyModel)
        try {
            dispatch({
                type: 'SUBMIT_SELECTED_BUY_MODEL',
                payload: selectedBuyModel,
            })
        } catch (e) {
            console.error(e)
        }
    }
    const setPhoneNumber = (phone) => {
        try {
            dispatch({
                type: 'SUBMIT_PHONENUMBER',
                payload: phone,
            })
        } catch (e) {
            console.error(e)
        }
    }
    const sendOTP = async (phone) => {
        try {
            const res = await makeHttpRequest('send_otp', 'POST', { phone })

            if (!res.success) {
                return null
            } else {
                return res
            }
        } catch (e) {
            console.error(e)
        }
    }
    const verifyOTP = async (_body) => {
        const res = await makeHttpRequest('verify_otp', 'POST', _body)

        if (!res.success) {
            console.log({ status: true, msg: res.msg })
            return res
        } else {
            dispatch({
                type: 'SUBMIT_USER_ID',
                payload: res.userId,
            })
            return res
        }
    }
    const setStep = (step) => {
        try {
            dispatch({
                type: 'SUBMIT_STEP',
                payload: step,
            })
        } catch (e) {
            console.error(e)
        }
    }

    const fetchVariants = async (_id) => {
        try {
            
          //  const { success, color, variants, } = await makeHttpRequest(
                const res = await makeHttpRequest(
               
                `get_variant_model/${_id}`,
                'GET',
                

            )
            

            if (!res.success) {
                return null
            } else {
             
                Array.isArray(res.color?.highlights?.colors)
                    ? setColors(res.color?.highlights?.colors)
                    : setColors([])
                 setVariants(res.variants)
            }
        } catch (error) {
           
            console.log({ error })
        }
    }

    const handleSetVariant = (variant) => {
        setSelectedModel({ ...state.selectedModel, ...variant })

        setRequestBody({
            variantID: variant._id,
        })
    }
    const handleSetBuyVariant = (variant) => {
        setSelectedBuyModel({ ...state.selectedBuyModel, ...variant })

        setRequestBuyBody({
            variantID: variant._id,
        })
    }
    const handleDeviceEstimateFormSubmit = async (_id) => {
        if (!_id) {
            return { success: false }
        }

        const user_id = _id

        const _requestBody = { ...state.requestBody, user_id }

        try {
            const response = await makeHttpRequest(
                'calculate',
                'POST',
                _requestBody
            )

            return response
        } catch (error) {
            return { success: false }
        }
    }

    return (
        <SellDeviceFormContext.Provider
            value={{
                requestBody: state.requestBody,
                models: state.models,
                colors: state.colors,
                buyModels: state.buyModels,
                selectedModel: state.selectedModel,
                selectedBuyModel: state.selectedBuyModel,
                phoneNumber: state.phoneNumber,
                step: state.step,
                userId: state.userId,
                variants: state.variants,
                setRequestBody,
                setRequestBuyBody,
                resetRequestBody,
                setModels,
                setBuyModels,
                setSelectedModel,
                setSelectedBuyModel,
                fetchVariants,
                setVariants,
                handleSetVariant,
                handleSetBuyVariant,
                handleDeviceEstimateFormSubmit,
                setPhoneNumber,
                sendOTP,
                verifyOTP,
                setStep,
            }}
        >
            {children}
        </SellDeviceFormContext.Provider>
    )
}

export default SellDeviceFormContext
