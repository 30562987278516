import React from 'react'
import styled from 'styled-components'
import { Chip, Card, Icon } from '@material-ui/core'
import { Link } from 'react-router-dom'
import StoreModal from './StoreModal'

const StyledStoreCard = styled(Card)`
    background-image: url('/assets/images/landing-page/BadiliStore.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: right;
    padding: 1rem;
    color: #fff;

    &.background-tint {
        position: relative;
    }

    &.background-tint::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        margin: auto;
    }
    .u-relative {
        position: relative;
        z-index: 1;
    }

    .tag {
        padding: 0.2rem 0.6rem;
        background: #42c8b7;
        font-size: 12px;
        color: #fff;
        border-radius: 4px;
        width: fit-content;
    }
    h6 {
        font-size: 15px;
        margin-bottom: 0;
    }
    p {
        font-size: 12px;
    }
    a {
        display: flex;
        align-items: center;
        gap: 5px;
        text-decoration: none;
        font-size: 13px;

        color: #fff;

        &:hover {
            text-decoration: underline;
        }
    }
`
export const StyledButton = styled.a`
    display: flex;
    align-items: center;
    gap: 5px;
    text-decoration: none;
    font-size: 13px;
    color: #fff;

    &:hover {
        text-decoration: underline;
    }
`
const StoreCard = ({ name, region, country, description }) => {
    const [open, setOpen] = React.useState(false)

    const handleModalOpen = () => {
        setOpen(true)
    }

    const handleModalClose = () => {
        setOpen(false)
    }
    return (
        <StyledStoreCard className="background-tint">
            <StoreModal
                open={open}
                handleModalClose={handleModalClose}
                data={{ name, region, country, description }}
            />
            <div className="u-relative">
                <div className="tag">
                    {region[0]?.name || country?.toUpperCase()}
                </div>

                <h6>{name}</h6>
                <p style={{  overflow: 'hidden' ,
                 maxHeight:'30px' ,
                 textOverflow:'ellipsis' ,
                 whiteSpace:'nowrap',
                 width:'auto' }}>{description || 'Badili is a mobile store dealing in new and pre-loved quality smartphones as well as other accessories like screens, earphones, etc. Visit us at the location below for quality devices and the best customer experience. Our partnership with Badili Africa makes it easy for customers to access a variety of devices at affordable prices with a 12 months warranty and repair services'}</p>
                <StyledButton onClick={handleModalOpen}>
                    View Details{'  '}
                    <Icon style={{ fontSize: 14 }}>arrow_forward_ios</Icon>
                </StyledButton>
            </div>
        </StyledStoreCard>
    )
}

export default StoreCard
