
import {
    Card,
    Grid,
    Button
} from '@material-ui/core'
import styled from 'styled-components'

import { devices } from 'utils/screen_sizes'

export const StyledCartPage = styled.section` 
    width:80vw;
    margin:4rem auto;
`

export const StyledCheckoutGrid = styled(Grid)`  

    div{
        transform:none !important;
    }
    

    form{
        width: 100%;
    }


    .MuiAccordion-root.Mui-disabled{
        background-color: #fefefe;
    }

    .MuiAccordion-rounded:first-child {
        border-top-left-radius: .4rem;
        border-top-right-radius: .4rem;
    }
    .MuiAccordion-rounded:last-child {
        border-bottom-left-radius: .4rem;
        border-bottom-right-radius: .4rem;
    }

    form h6{
        font-size:1rem;
    }

    @media ${devices.laptop} {           
        max-width:1080px;
        margin: 4rem auto;
    }
`
export const StyledCheckoutCard = styled(Card)` 
    padding:1rem;
    border-radius: .4rem;
    
    
   

    .MuiAccordion-root.Mui-disabled {
        background-color: #fff;
      }
      

`
export const StyledExpandIcon= styled.label` 
    font-size:0.875rem;
`
export const StyledHeader = styled.div` 
    font-weight:800;
    h6{
        width:100%;
    }
`
export const FormStyledHeader = styled.p` 
    font-size:1rem;
`

export const StyledFooter = styled.div` 
    height:6em;
    display:flex;
    justify-content:center;
    border-radius: .4rem;
    color:#0c8b7f;

    h6{
        width:100%;
    }
`
export const StyledCheckoutItem = styled.div`
    display:flex;
    flex-direction:row;
    border-bottom: 1px solid #eee;
    padding: .4rem;
    
    span{
        display:flex;
        flex-direction:column;
        width:100%
    }

    hr{
        width: 100%;
    }
`
export const StyledCartTitle = styled.span` 
    display:flex;
    flex-direction:column;
    padding:.4rem;

    div{
        display:flex;
        flex-direction:row;
        font-weight:800;
        color:#444;
    }

    hr{
        margin: .2rem;
    }
    p, span{        
        padding: 0 .5rem;
    }

    p{
        margin: 0;
    }
`
export const StyledImage = styled.img`
    width:52px
`
export const StyledDelivery = styled.div` 
    display:flex;
    flex-direction:column;
    border-bottom: 1px solid #eee;
`    
export const StyledDeliveryText = styled.div`
    margin:0 2rem;

    h6{
        color:#f68b1e;
        font-size:1rem;
        margin:2rem 0;
    }

    span{
        margin-right:1rem;
        color: #eee;
    }
`
export const StyledDeliveryBox = styled.div` 

    margin:0 2rem;
    border: 1px solid #eee;
    padding: 1rem;

    h6{
        color:#f68b1e;
        font-size:1.2rem;
        margin:2rem 0;
    }

    span{
        margin-right:1rem;
    }

`
export const StyledBoxTitle  = styled.div` 
    border-bottom: 1px solid #eee;
    padding:1rem 1rem 1rem 0;

`
export const StyledDeliverySummary = styled.div` 
    margin:0 2rem;
    padding: 1rem;

    display:flex;
    flex-direction: column;

    span{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`
export const StyledShipmentDetails = styled.div`

`
export const StyledPickupStation = styled.h6` 
    cursor:pointer;
`
export const StyledProductImage = styled.img`
    margin-bottom: auto;
    height:80px;

    @media ${devices.laptop} {           
       height: 160px;
    }
`
export const RoundModelImage = styled.div`
    margin-bottom: auto;
    height:80px;
    width:80px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #ddd;
    overflow: hidden;
    
    img {
        width: 100%;
        height: 100%;
        margin-top: 1.6rem;
      }
`

export const CartItem = styled.div`
    border-bottom: 1px solid #eee;
`
export const StyledShoppingCartImage = styled.img` 
    width:48px;
    height:48px;
`
export const Loading = styled.div` 
    height: 400px;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: 48px;
      }
`
export const Error = styled.div` 
    height: 400px;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledPromo = styled.div` 
    display:flex;
    justify-content: start;
    font-weight:800;

    span{
        color: #0c8b7f;
        margin-right: .5rem;
    }
`

export const StyledPaymentsPanel = styled.div`
    height:fit-content;
    justify-content: center;
    display: flex;
    width: 100%;

    form {
        width: 100%;
        display: flex;
        margin: .5rem;
        justify-content: center;
    }
    fieldset > div{ 
        display: flex;
        justify-content: center;
    }
    @media ${devices.laptop} {form {
        margin: 2rem;
    }

    }
    
`

export const PriceSpan = styled.span`
    text-align: right; 

`

export const StyledCheckoutSection = styled.section` 
    p, div, input, label, button{
        font-family: lato;
    }
    margin:1rem .5rem;
`

export const StyledCheckoutFooter = styled.ul`
    display:none; 
    @media ${devices.laptop} {
        display:flex;
        flex-direction:row;

        li{
            list-style:none;
            color:#58cab9;
            margin:1rem;
        }
    }
`

export const StyledPaymentOptionsGrid = styled(Grid)`
    text-align: center;
    margin: .5rem auto;
    flex-direction: column;
    border: 1px solid #eee;
    width: 100%;
    padding:2rem; 
    
    a{
        margin:1rem;
        text-decoration:underline;
        cursor:pointer;
        color:#58cab9;
    }
    
    @media ${devices.laptop} {
        width: 32rem;
        margin: 1rem auto;
    }
`

export const StyledLipaLaterLogo = styled.img`
    width: 100%;
    margin: 1rem auto;
`


