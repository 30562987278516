import React, { useState } from 'react'
import { Hidden } from '@material-ui/core'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import brand_utils from 'utils/devices_utils'
import { getCountryFromUrl } from 'utils/CountryUtils'
import RepairModal from './RepairModal'

export const StyledWrapper = styled.div`
    background: #000;
    padding: 1rem;
    z-index: 42;
    gap: 20px;
    font-family: 'lato';
    position: sticky;
    top: 0;

    > div {
        display: flex;
        align-items: center;
        width: 80vw;
        margin: 0 auto;
    }
`
const StyledNavList = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    flex: 1;
    gap: 2rem;

    li:first-child {
        a {
            color: ${(props) =>
                props.page && props.page === 'buyback'
                    ? '#42c8b7'
                    : props.page && props.page === 'ecommerce'
                    ? ''
                    : '#fff'};
        }
    }

    a {
        text-decoration: none;
        color: #fff;
        font-size: 14px;

        &:hover {
            color: ${(props) =>
                props.page && props.page === 'buyback'
                    ? '#42c8b7'
                    : props.page && props.page === 'ecommerce'
                    ? '#f68937'
                    : '#fff'};
        }
    }
`

export const StyledButton = styled.a`
    padding: 0.4rem 1rem;
    border-radius: 1px;
    border: 1px solid rgb(76, 175, 80);
    background: transparent;
    color: #fff;
    opacity: 0.75;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    text-decoration: none;

    .MuiSvgIcon-root {
        color: rgb(76, 175, 80);
    }
    &:hover {
        background: rgb(76, 175, 80);
        opacity: 1;

        .MuiSvgIcon-root {
            color: #fff;
        }
    }
`

const SecondaryNavbar = ({ page = 'buyback' }) => {
    const [openRepairModal, setOpenRepairModal] = useState(false)

    const { pathname } = useLocation()
    let country = getCountryFromUrl(pathname)||"ke"

    const closeModal = () => {
        setOpenRepairModal(false)
    }
    const topMenus = [
        {
            label: 'Sell your Phone',
            route: `/${country}/sell-your-phone`,
            target: '',
        },
        // { label: 'All Phones', route: '/' },
        {
            label: 'Apple',
            route: `/${country}/sell-old-mobile-phone/select-brand/iPhone/${brand_utils.iphone._id}`,
            target: '',
        },
        {
            label: 'Samsung',
            route: `/${country}/sell-old-mobile-phone/select-brand/Samsung/${brand_utils.samsung._id}`,
            target: '',
        },
        {
            label: 'Oppo',
            route: `/${country}/sell-old-mobile-phone/select-brand/Oppo/${brand_utils.oppo._id}`,
            target: '',
        },
        {
            label: 'Vivo',
            route: `/${country}/sell-old-mobile-phone/select-brand/Vivo/${brand_utils.vivo._id}`,
            target: '',
        },
        {
            label: 'Xiaomi',
            route: `/${country}/sell-old-mobile-phone/select-brand/Xiaomi/${brand_utils.xiaomi._id}`,
            target: '',
        },
        {
            label: 'Realme',
            route: `/${country}/sell-old-mobile-phone/select-brand/Xiaomi/${brand_utils.realme._id}`,
            target: '',
        },
        {
            label: 'Infinix',
            route: `/${country}/sell-old-mobile-phone/select-brand/Xiaomi/${brand_utils.infinix._id}`,
            target: '',
        },
       
    ]
    const topEcommMenus = [
        {
            label: 'Home',
            route: `/${country}`,
            target: '',
        },
        {
            label: 'About Us',
            route: `/${country}/sell-old-mobile-phone/select-brand/iPhone/${brand_utils.iphone._id}`,
            target: '',
        },
        {
            label: 'Sell Phone',
            route: `/${country}/sell-your-phone`,
            target: '',
        },
        {
            label: 'Store',
            route: `/${country}/ecommerce/shop`,
            target: '',
        },
       
        {
            label: 'Help',
            route: `/${country}/how-it-works`,
            target: '',
        },
       
    ]
    const topRepairMenus = [
        {
            label: 'Home',
            route: `/${country}`,
            target: '',
        },
        {
            label: 'About Us',
            route: `/${country}/sell-old-mobile-phone/select-brand/iPhone/${brand_utils.iphone._id}`,
            target: '',
        },
        {
            label: 'Sell Phone',
            route: `/${country}/sell-your-phone`,
        },
        {
            label: 'Store',
            route: `/${country}/ecommerce/shop`,
            target: '',
        },
       
        {
            label: 'Help',
            route: `/${country}/how-it-works`,
            target: '',
        },
    ]
    const renderTopMenus = (page) => {
        switch (page) {
            case 'buyback':
                return topMenus
            case 'ecommerce':
                return topEcommMenus
            case 'repair':
                return topRepairMenus
            default:
                return null
        }
    }

    const handleRepairClick = (event) => {
        event.preventDefault();
        
        setOpenRepairModal(true);
      };
    return (
        <Hidden mdDown>
            <RepairModal open={openRepairModal} handleModalClose={closeModal} />
            <StyledWrapper>
                <div>
                    <StyledNavList page={page}>
                        {renderTopMenus(page).map(({ label, route }, i) => (
                            <li key={i}>
                               
                                <Link to={route}>{label}</Link>
                                <Link to={{ pathname: { route } }}></Link>
                            </li>
                        ))}

                        <li>
                            <a
                                href="https://blog.badili.africa/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Blog
                            </a>
                        </li>

                        {page !== 'repair' && (
          <li style={{ cursor: 'pointer' }}>
            <a href="#" onClick={handleRepairClick}>
              Repair Your Phone
            </a>
          </li>
        )}
                    </StyledNavList>
                    <StyledButton
                        href="https://api.whatsapp.com/send/?phone=254725626262&text&type=phone_number"
                        target="_blank"
                    >
                        <WhatsAppIcon style={{ fontSize: 20 }} />
                        +254 725 626 262
                    </StyledButton>
                </div>
            </StyledWrapper>
        </Hidden>
    )
}

export default SecondaryNavbar
