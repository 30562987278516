import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
} from '@material-ui/core'

import PersonIcon from '@material-ui/icons/Person'
import PhoneIcon from '@material-ui/icons/Phone'
import ClockIcon from '@material-ui/icons/AccessTime'
import LocationIcon from '@material-ui/icons/Place'
import { devices } from 'utils/screen_sizes'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { makeHttpRequest } from 'utils/api_utils'
import { replaceWhiteSpaceWithHyphen } from 'utils/string_utils'
import { useLocation, useHistory } from 'react-router-dom'
import { getCountryFromUrl } from 'utils/CountryUtils'

export const StyledDialog = styled(Dialog)`




    h2 {
        font-family: 'playfair-bold';
        font-size: 15px;
    }
    p {
        font-family: 'open-sans';
    }
    h4 {
        margin-top: 0;
        color: #000;
        margin-bottom: 8px;
    }
`
export const StyledDialogContent = styled(DialogContent)`
.MuiInputLabel-outlined.MuiInputLabel-marginDense, .MuiInputBase-root{

    font-family: 'open-sans';
    font-weight:bold;
}

.MuiList-root{
    li{
        font-family: 'open-sans';
    font-weight:bold;
    }
}
`
export const StyledDialogImage = styled.img`
    max-width: 300px;
    flex: 1;
    object-fit: contain;
`
export const StyledList = styled.ul`
    list-style-type: none;
    font-family: 'open-sans';
    margin: 1rem 0 0 0;
    padding: 0;

    li {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
        color: rgba(52, 49, 76, 0.74);
        span {
            font-weight: bold;
            font-family: 'playfair';
        }
    }
`

const RepairModal = ({ open, handleModalClose }) => {
    const [repairDetails, setRepairDetails] = useState({
        brand: '',
        model: '',
        color: '',
        variant: '',
    })
    const [brands, setBrands] = useState([])
    const [models, setModels] = useState([])
    const [fetchingModels, setFetchingModels] = useState(false)
    const [variants, setVariants] = useState([])
    const [fetchingVariants, setFetchingVariants] = useState(false)
    const [colors, setColors] = useState([])
    const history = useHistory()

    const { pathname } = useLocation()
    let country = getCountryFromUrl(pathname)||"ke"

    const handleChange = (e) => {
       
        const { value, name } = e.target
        setRepairDetails({ ...repairDetails, [name]: value })
    }

    const fetchBrands = async (_id) => {
        const { success, brands } = await makeHttpRequest(
            `get_device/${_id}`,
            'GET'
        )
        if (success) {
            setBrands(brands)
        } else {
            console.log('error')
            //    setError({
            //        status: true,
            //        msg: 'an error occurred that prevented the brands from loading...',
            //    })
            return
        }
    }

    const fetchModels = async (brandId) => {
        setFetchingModels(true)
        try {
            let { success, deviceModels } = await makeHttpRequest(
                `get_available_by_brands/${brandId}`,
                'GET'
            )
            if (success) {
                setModels(deviceModels)
                setFetchingModels(false)
            } else {
                setFetchingModels(false)
            }
        } catch (err) {
            setFetchingModels(false)
        }
    }

    const fetchVariants = async (_id) => {
        setFetchingVariants(true)
        try {
            const { success, color, variants } = await makeHttpRequest(
                `get_variant_model/${_id}`,
                'GET'
            )

            if (!success) {
                setFetchingVariants(false)
                return null
            } else {
                Array.isArray(color?.highlights?.colors)
                    ? setColors(color?.highlights?.colors)
                    : setColors([])
                setVariants(variants)
                setFetchingVariants(false)
            }
        } catch (error) {
            setFetchingVariants(false)
            console.log({ error })
        }
    }
    const goToRepairPage = () => {
       
        history.push({
            pathname: `/${country}/repair/${replaceWhiteSpaceWithHyphen(
                repairDetails.model
            )}`,

            state: {
                _id: repairDetails.model,
                modelId: repairDetails.model,
                colorId: repairDetails.color,
            },
        })
    }
    useEffect(() => {
        fetchBrands('6165c8453c6ca93814dc21aa')
    }, [])
    return (
        <StyledDialog
            maxWidth="md"
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleModalClose(event, reason)
                }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
                <ValidatorForm onSubmit={goToRepairPage}>
            <DialogTitle id="alert-dialog-title">
                Select The Phone You Wish To Repair
            </DialogTitle>
            <StyledDialogContent>
                    <TextValidator
                        className="mb-4 w-full"
                        variant="outlined"
                        label="Select your brand"
                        onChange={handleChange}
                        select
                        name="brand"
                        size="small"
                        value={repairDetails.brand}
                        validators={['required']}
                        errorMessages={['this field is required']}
                    >
                        {brands.map((brand) => (
                            <MenuItem
                                key={brand._id}
                                value={brand.brand}
                                onClick={() => fetchModels(brand._id)}
                            >
                                {brand.brand}
                            </MenuItem>
                        ))}
                    </TextValidator>
                    <TextValidator
                        className="mb-4 w-full"
                        variant="outlined"
                        label="Select your model"
                        onChange={handleChange}
                        select
                        name="model"
                        size="small"
                        disabled={models.length < 1}
                        value={repairDetails.model}
                        validators={['required']}
                        errorMessages={['this field is required']}
                    >
                        {fetchingModels ? (
                            <MenuItem value="">
                                <em>Fetching Models</em>
                            </MenuItem>
                        ) : (
                            models.map(({ _id, modelName }) => (
                                <MenuItem
                                    key={_id}
                                    value={_id}
                                    onClick={() => fetchVariants(_id)}
                                >
                                    {modelName}
                                </MenuItem>
                            ))
                        )}
                    </TextValidator>
                    <TextValidator
                        className="mb-4 w-full"
                        variant="outlined"
                        label="Select your color"
                        onChange={handleChange}
                        select
                        name="color"
                        size="small"
                        disabled={colors.length < 1}
                        value={repairDetails.color}
                        validators={['required']}
                        errorMessages={['this field is required']}
                    >
                        {fetchingVariants ? (
                            <MenuItem value="">
                                <em>Fetching Colors</em>
                            </MenuItem>
                        ) : (
                            colors.map(({ value, label }) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            ))
                        )}
                    </TextValidator>
                
            </StyledDialogContent>
            <DialogActions>
                <Button onClick={handleModalClose} color="secondary" autoFocus>
                    Close
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    // disabled={!repairDetails.model || !repairDetails.color}
                >
                    Proceed
                </Button>
            </DialogActions>
            </ValidatorForm>
        </StyledDialog>
    )
}

export default RepairModal
