import { Button } from '@material-ui/core'
import styled from 'styled-components'

export const StyledButton = styled(Button)`
    background: #42c8b7;
    color: #fff;
    display: block;
    cursor: pointer;
    width: 100%;
    padding: 9px 8px;

    &:hover {
        background-color: #42c8b7;
        color: #fff;
    }
    span {
        color: #fff;
    }
`
