import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import EcommerceContainer from 'app/ecommerceComponents/containers/EcommerceContainer'
import EcommerceLayoutGeneral from 'app/components/MatxLayout/EcommerceLayoutGeneral'
import InventoryContainer from '../../ecommerceComponents/containers/InventoryContainer'

const ShopInventoryPage = (props) => {
  

    return (
        <EcommerceLayoutGeneral>
            <EcommerceContainer>
                <InventoryContainer />
            </EcommerceContainer>
        </EcommerceLayoutGeneral>
    )
}

export default ShopInventoryPage
