const brand_utils = {
    samsung:{_id: "616838bac2b02e10d6c12a21", icon:"", banners:{}},
    iphone:{_id: "6165c8683c6ca93814dc21ad", icon:"", banners:{}},
    apple:{_id: "6165c8683c6ca93814dc21ad", icon:"", banners:{}},
    xiaomi:{_id:"61a4b4b3d77752cf2973c8bc", icon:"", banners:{}},
    vivo:{_id:"61a483d73bfb13413a15cc79", icon:"", banners:{}},
    oppo:{_id:"61a484103bfb13413a15cc87", icon:"", banners:{}},
    realme:{_id:"61ae2331ac84a81ec55a1030", icon:"", banners:{}},
    tecno:{_id:"61eeb930721558df7bc17624", icon:"", banners:{}},
    infinix:{_id:"61f106abd1ab89b8fe2ef79b", icon:"", banners:{}},
}

export default brand_utils;