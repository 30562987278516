import React, { useState, useEffect } from 'react'
import { StyledImage } from './StyledImage'

const SmoothImage = ({ src, alt }) => {
    const [imageLoaded, setImageLoaded] = useState(true)

    useEffect(() => {
        return function cleanUp() {
            setImageLoaded(false)
        }
    }, [])

    return (
        <StyledImage className="smooth-image-wrapper">
            <img
                src={src}
                alt={alt}
                loading="lazy"
                className={`smooth-image image-${
                    imageLoaded ? 'visible' : 'hidden'
                }`}
            />
        </StyledImage>
    )
}

export default SmoothImage
