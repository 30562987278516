import React, { Component } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import { devices } from 'utils/screen_sizes'

const StyledSlider = styled(Slider)`
    padding: 0 1rem;
    .slick-prev:before,
    .slick-next:before {
        color: ${(props) => {
            return props.buttoncolor ? props.buttoncolor : '#f68937'
        }};
        font-size: 25px;
        opacity: 1;
    }
    .slick-prev.slick-disabled:before,
    .slick-next.slick-disabled:before {
        opacity: 0.25;
    }

    .slick-slide > div {
        margin: 0 10px;
    }
    .slick-list {
        margin: 0 -10px;
    }
    .slick-dots li button::before {
        color: rgb(230, 231, 232);
        opacity: 1;
        /* opacity: 0.7; */
    }

    .slick-dots li.slick-active button::before {
        color: #f5bb22 !important;
        opacity: 1;
    }
    @media ${devices.mobileM} {
        padding: 0 1rem;
    }
`

function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
            }}
            onClick={onClick}
        />
    )
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        />
    )
}

const SliderComponent = ({
    settings = {
        dots: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: false,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    dots: false,
                    arrows: false,
                    infinite: false,
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    // nextArrow: <SampleNextArrow />,
                    // prevArrow: <SamplePrevArrow />,
                },
            },
            {
                breakpoint: 320,
                settings: {
                    dots: false,
                    arrows: true,
                    infinite: false,
                    slidesToShow: 1.2,
                    slidesToScroll: 1,
                    nextArrow: <SampleNextArrow />,
                    prevArrow: <SamplePrevArrow />,
                },
            },
        ],
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    },
    buttoncolor,
    children,
}) => {
    return (
        <StyledSlider {...settings} buttoncolor={buttoncolor}>
            {children}
        </StyledSlider>
    )
}
export default SliderComponent
