import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const SearchResultContainer = styled.div`
    width: 100%;
    height: 4em;
    display: flex;
    border-bottom: 1px solid #d8d8d878;
    padding: 6px 8px;
    align-items: center;
    cursor: pointer;
`

const Thumbnail = styled.div`
    width: auto;
    height: 100%;
    display: flex;
    flex: 0.1;
    img {
        width: auto;
        height: 100%;
    }
`
const Name = styled.div`
    font-size: 14px;
    color: #000;
    margin-left: 5px;
    display: flex;
    flex: 1;
`
const TotalDevices = styled.div`
    font-size: 10px;
    background: ${(props) =>
        props.available ? 'rgb(44, 179, 74)' : '#f68937'};
    text-align: center;
    padding: 0.2rem 0.2rem;
    color: #fff;
    border-radius: 4px;
`

const SearchResult = (props) => {
    const { thumbnailSrc, name, inStock, reroute } = props
    return (
        <SearchResultContainer onClick={() => reroute()}>
            <Thumbnail>
                <img src={thumbnailSrc} alt="thumbnail" />
            </Thumbnail>
            <Name>{name}</Name>

            <TotalDevices available={inStock}>
                {inStock ? 'In Stock' : 'Out Of Stock'}
            </TotalDevices>
        </SearchResultContainer>
    )
}

export default SearchResult
