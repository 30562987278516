import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Card,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import styled from 'styled-components'
import { devices } from 'utils/screen_sizes'

const StyledCard = styled(Card)`
    padding: 2rem 1rem;
    .MuiPaper-elevation1 {
        box-shadow: none;
    }
    .MuiAccordionSummary-root {
        @media ${devices.mobileM} {
            padding: 0px;
        }
        @media ${devices.laptop} {
            padding: 0px 16px;
        }
    }
`

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: 'lato',
    },
    description: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightNormal,
        fontFamily: 'lato',
    },
}))

const faqs = [
    {
        title: 'What criteria did you use to evaluate my phone?',
        description:
            'Our evaluation is based on an algorithm that values your phone according to its current physical & functional condition.',
    },
    {
        title: 'What happens if my phone model/variant is not listed?',
        description:
            'The device may not be elligible for purchase since we only buy devices whose year of release is 2018 and later.',
    },
    {
        title: 'Which phone brands are accepted for trade in?',
        description:
            'We accept iPhone, Samsung, Infinix, Realme, Vivo, Redmi and Techno brands.',
    },
    {
        title: 'Why do I have to sign the mobile phone purchasing agreement and the affidavit?',
        description:
            'Purchasing document represents willingness to sell your device and affidavit is a legal binding document for the device.',
    },
    {
        title: 'Why do I have to reset my device after trading in?',
        description: 'To clear your personal data on the device.',
    },
    {
        title: 'How long does it take to trade in/sell my device?',
        description:
            'It takes about 8-10 minutes to trade in your device and another 10 minutes to receive payment.',
    },
    {
        title: 'What happens to my private and confidential information after signing the documents? ',
        description:
            'Customer information is private and confidential and will not be disclosed outside of business.',
    },
]

const FaqSection = () => {
    const classes = useStyles()
    return (
        <StyledCard>
            <div className={classes.root}>
                {faqs.map(({ title, description }, i) => (
                    <Accordion key={title + i}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${i}a-content`}
                            id={`panel${i}a-header`}
                        >
                            <Typography className={classes.heading}>
                                {title}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className={classes.description}>
                                {description}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </StyledCard>
    )
}

export default FaqSection
