import { Button, Card, CardActions, CardContent, CircularProgress, Grid } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import styled from "styled-components"
import { devices } from "utils/screen_sizes"

export const StyledSummaryList = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-family: 'lato';
    li {
        margin: 1rem 0;
        font-weight: bold;
        font-family: 'lato';
        display: flex;
        align-items: center;
        gap: 20px;

        > span:first-child {
            flex: 1;
        }

        .MuiOutlinedInput-input {
            padding: 5px;
            margin: 5px;
            padding-bottom: 6px;
            flex: 1;
        }

        span {
            font-weight: bold;

            .delivery-charges {
                text-decoration: line-through;
                color: #777;
            }
        }
        :last-child {
            font-size: 1.1rem;
            margin-top: 1.3rem;
            text-transform: uppercase;

            span {
                color: #f68937;
                font-size: 1.1rem;

                font-weight: bold;
            }
        }
    }
`

export const StyledCouponButton = styled.button`
    color: #fff;
    background-color: ${(props) => (props.disabled ? '#777' : '#f68937')};
    padding: 8px 10px !important;
    height: 100% !important;
    border-radius: 8px;
    font-weight: bold;
    border: #f68937;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    font-size: 0.85rem;
    min-width: 100px;
`

export const StyledCheckoutButton = styled.button`
    color: #fff;
    background: ${(props) => (props.bgColor ? props.bgColor : '')};
    border-radius: 10px;
    padding: 12px 40px;
    font-weight: bold;
    border: #f68937;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    margin: ${(props) =>
        props.mobile ? '1rem auto 1rem' : '3rem auto 1rem'} !important;
    display: block;
    font-size: 1rem;
`
export const StyledQuantityController = styled.div`
    display: flex;
    margin: 1rem 0;
    .increment {
        width: 28px;
        height: 28px;
        border-radius: 0px 4px 4px 0px;
        font-size: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgb(231, 233, 234);
        cursor: pointer;
        color: #000;
    }
    .decrement {
        width: 28px;
        height: 28px;
        border-radius: 4px 0 0 4px;
        font-size: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgb(231, 233, 234);
        cursor: pointer;
        color: #000;
    }
    span {
        width: 35px;
        height: 28px;
        border: 1px solid rgb(231, 233, 234);
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

export const StyledFlexGrid = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        max-height: 85px;
    }
`
export const StyledCloseIcon = styled.a`
    position: absolute;
    right: 5px;
    top: 5px;
    text-transform: lowercase;
    font-weight: bold;
    cursor: pointer;
`
export const StyledGrid = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
    button {
        padding: 5px 40px;
        margin: 0 10px;
    }
`

export const StyledPaymentOption = styled.a`
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: start;
    background: ${(props) => (props.bg ? props.bg : `rgb(231, 233, 234)`)};
    cursor: pointer;
    border-radius: 4px;
    padding: 0.5rem 1rem 0.5rem 0;
    width: 100%;
    font-family: 'open-sans';
    font-weight: bold;
    @media ${devices.mobileS} {
        font-size: 0.7rem;
    }
    @media ${devices.mobileM} {
        font-size: 0.85rem;
    }
    @media ${devices.laptop} {
        font-size: 0.95rem;
    }
    @media ${devices.laptopL} {
        font-size: 0.95rem;
    }

    img {
        /* max-height: 25px; */
        max-height: ${(props) => (props.imgHeight ? props.imgHeight : '25px')};
        @media ${devices.mobileS} {
            max-height: ${(props) =>
                props.imgHeightSmall ? props.imgHeightSmall : '25px'};
        }
        @media ${devices.mobileM} {
            max-height: ${(props) =>
                props.imgHeight ? props.imgHeight : '25px'};
        }
    }
`

export const StyledAlert = styled(Alert)`
    margin: 2rem;
    width: 100%;
    @media ${devices.mobileS} {
        margin: 1rem;
        .MuiAlert-icon {
            display: none;
        }
    }
    @media ${devices.laptop} {
        margin: 2rem;
        .MuiAlert-icon {
            display: flex;
        }
    }

    .MuiAlertTitle-root {
        font-weight: 700;
        margin-bottom: 20px;
    }
    p {
        margin: 0;
        padding: 0.3rem 0;
        font-weight: 600;
        span {
            font-weight: normal;
            padding-left: 5px;
        }
    }
`
export const StyledMessageAlert = styled(Alert)`
    margin: 2rem 0;
    width: 100%;
    @media ${devices.mobileS} {
        margin: 1rem 0;
        .MuiAlert-icon {
            display: none;
        }
    }
    @media ${devices.laptop} {
        margin: 2rem 0;
        .MuiAlert-icon {
            display: flex;
        }
    }

    .MuiAlertTitle-root {
        font-weight: 700;
        margin-bottom: 20px;
    }

    .MuiAlert-icon p {
        margin: 0;
        padding: 0.3rem 0;
        font-weight: 600;
        span {
            font-weight: normal;
            padding-left: 5px;
        }
    }
`
export const StyledSlide = styled.div`
    display: flex;
    justify-content: center;
    background-color: #fff;

    .smooth-image-wrapper {
        width: 100%;
    }

    a {
        position: absolute;
        z-index: 1;
        color: #fff;
        bottom: 2em;
        left: 4em;
        font-size: 0.8rem;
    }

    @media ${devices.mobileL} {
        a {
            font-size: 1rem;
        }
    }
    @media ${devices.tablet} {
        a {
            font-size: 1rem;
            left: 8em;
        }
    }
    @media ${devices.laptop} {
        a {
            left: unset;
            bottom: 4em;
        }
    }
    @media ${devices.desktop} {
        a {
            left: unset;
            bottom: 5em;
        }
    }
`

export const StyledGreyCard = styled(Card)`
    background: rgb(241, 241, 241);
    margin: 2rem 0;
    font-family: 'lato';
    border-radius: 2px 2px 0 0;

    h4 {
        font-size: 1rem;
        font-weight: bold;
        color: #000;
        margin-bottom: 2rem;
        font-family: 'open-sans';
    }
    .MuiOutlinedInput-input {
        background: #fff;
        border-radius: 4px;
    }
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: #58cab9;
    }
    .MuiSelect-icon {
        color: #000;
        z-index: 12;
    }
`

export const StyledSummaryCard = styled(Card)`
    margin: 2rem 0;
    border-radius: 2px;
    box-shadow: none;

    .MuiCardHeader-root {
        background: #000;
    }

    .MuiTypography-h5 {
        font-size: 1rem;
        font-family: 'open-sans';
        font-weight: bold;
        color: #fff;
    }
    .MuiCardHeader-subheader {
        display: inline;
        float: right;
        color: #fff;
    }
    .MuiCardHeader-title {
        display: inline;
        float: left;
    }
    .shipment-disclaimer {
        color: #f68937;
        font-weight: bold;
        margin: 2rem 0;
        text-align: center;
    }
`
export const StyledSummaryCardContent = styled(CardContent)`
    border: 1px solid rgb(231, 233, 234);
    > * {
        border-bottom: 1px solid rgb(231, 233, 234);
    }

    .cart-item {
        position: relative;
        display: flex;
        align-items: center;
        padding: 1rem 0;
        > * {
            flex: 1 auto;
        }

        .cart-item-image {
            object-fit: contain;
            max-height: 85px;
            max-width: 85px;
            margin-right: 1rem;
        }
        .price {
            font-size: 1.1rem;
            font-family: 'lato';
            font-weight: bold;
            color: #f68937;
        }
        .model {
            font-weight: lighter;
        }
    }
`
export const StyledSummaryCardFooter = styled(CardActions)`
    background: rgb(241, 241, 241);
    border-radius: 0 0px 10px 10px;
    display: block;
    padding: 1rem 2rem;
`

export const StyledLoading = styled.div`
    height: 400px;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: 48px;
    }
`
export const StyledLoader = styled(CircularProgress)`
    color: #f68937;
`

export const StyledOTPButton = styled(Button)`
    margin: 0 auto;
    display: block;
    padding: 5px 40px;
`

export const StyledCheckoutWrapper = styled.div`
    .MuiInputBase-root,
    .MuiTypography-body1,
    .MuiFormLabel-root,
    .MuiMenuItem-root {
        font-family: 'open-sans' !important;
    }
    .MuiOutlinedInput-notchedOutline {
        border-color: #58cab9;
    }

    @media ${devices.laptop} {
        margin: 6px 24px;
    }
    @media ${devices.laptopL} {
        margin: 8px 30px;
    }
    @media ${devices.desktop} {
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
    }
    @media ${devices.desktopL} {
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
    }
`