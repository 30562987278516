import brand_utils from '../utils/devices_utils'
import { isDevelopment, isStaging } from '../utils/dev_utils'



export const handleNavigations = (type = 'sell', country) => {

    const nav = [
        {
            name: 'About Us',
            path: `/${country}/how-it-works`,
        },
        {
            name: 'Sell Phone',
            path: `/${country}`,
        },
        {
            name: 'Store',
            path: `/${country}/ecommerce/shop`,
        },
        {
            name: 'Help',
            path: `/${country}/how-it-works`,
        },
    ]
    const navs2 = [
        {
            name: 'Home',
            path: `/${country}`,
            //icon: 'description',
        },
        {
            name: 'How It Works',
            path: `/${country}/how-it-works`,
            //icon: 'description',
        },
        {
            name: 'Buy Phone',
            path: `/${country}/ecommerce/shop`,
        },
        // {
        //     name: 'Repair Your Phone',
        //     path: '/repair-phone',
        //     //icon: 'description',
        // },
        {
            name: 'Sell Phone',
            // //icon: 'people',
            children: [
                {
                    name: 'iPhones',
                    path: `/${country}/sell-old-mobile-phone/select-brand/iPhone/${brand_utils.iphone._id}`,
                    //iconText: 'CL',
                },
                {
                    name: 'Samsung',
                    path: `/${country}/sell-old-mobile-phone/select-brand/Samsung/${brand_utils.samsung._id}`,
                    //iconText: 'CL',
                },
                {
                    name: 'Xiaomi',
                    path: `/${country}/sell-old-mobile-phone/select-brand/Xiaomi/${brand_utils.xiaomi._id}`,
                    //iconText: 'CL',
                },
                {
                    name: 'Vivo',
                    path: `/${country}/sell-old-mobile-phone/select-brand/Vivo/${brand_utils.vivo._id}`,
                    //iconText: 'CL',
                },
                {
                    name: 'Oppo',
                    path: `/${country}/sell-old-mobile-phone/select-brand/Oppo/${brand_utils.oppo._id}`,
                    //iconText: 'CL',
                },
            ],
        },
        {
            name: 'Dealers',
            path: `/${country}/dealers/register`,
        },
    ]

    return type === 'buyback' ? navs2 : nav
}

export const navigations = handleNavigations('sell')

export const footer_navigations = [
    {
        name: 'Home',
        path: `/`,
        //icon: 'description',
    },
    {
        name: 'About Us',
        path: `/`,
        //icon: 'description',
    },
    {
        name: 'Blog',
        path: '/',
        //icon: 'description',
    },
    {
        name: 'News',
        path: '/',
        //icon: 'description',
    },
    {
        name: 'Testimonials',
        path: '/',
        //icon: 'description',
    },
    {
        name: 'Contact Us',
        path: '/',
        //icon: 'description',
    },
]
