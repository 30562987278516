import Box from '@material-ui/core/Box'
import styled from 'styled-components'
import { devices } from '../../../utils/screen_sizes'

export const StyledContent = styled.div`
    overflow-y: auto;
    overflow-x: hidden;

    .MuiAccordionSummary-root {
        background-color: #f9fafb;
    }
`
export const StyledHeader = styled.p`
    font-size: 1.25rem;
`
export const StyledHero = styled.img`
    @media ${devices.mobileS} {
        width: 95vw;
        margin: auto;
        display: grid;
        padding-top: 20px;
        padding-bottom: 20px;

    }
    @media ${devices.laptop} {
        width: 95vw;
        margin: 2rem auto ;
        display: grid;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    @media ${devices.desktop} {
        width: 90vw;
        // margin: auto;
        display: grid;
        padding-top: 40px;
        padding-bottom: 40px;
    }
`
export const StyledSection = styled.section`
    color: #444;
    background: #fff;
`
export const StyledBody = styled.div`
    margin: 16px;

    .MuiTabs-root {
        overflow: unset;
    }

    .MuiTabs-scrollable {
        width: 200px;
    }

    @media ${devices.laptop} {
        width: 90vw;
        margin: 12px auto;
    }

    @media ${devices.laptop} {
        width: 60vw;
        margin: 12px auto;
    }
`
export const StyledBox = styled(Box)`
    flexgrow: 1;
    bgcolor: #fff;
    display: flex;
    flex-direction: column;

    @media ${devices.laptop} {
        flex-direction: row;
    }
`

export const StyledVideo = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`
