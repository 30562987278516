import React, { useState, useEffect, useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { withStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import useAuth from 'app/hooks/useAuth'
import Swiper from 'swiper'
import {
    deleteProductFromCart,
    updateCartQuantity,
} from 'app/redux/actions/EcommerceActions'
import { clearCart } from 'app/redux/actions/EcommerceActions'
import {
    Hidden,
    TextField,
    Card,
    CardContent,
    FormControlLabel,
    FormControl,
    RadioGroup,
    Radio,
    CardHeader,
    MenuItem,
    Checkbox,
    Button,
} from '@material-ui/core'

import {
    getdealers,
    postNewOrder,
    getstores,
    authenticateLipaLater,
    verifyOtpLipaLater,
    authenticateCash,
    addCoupon,
} from '../../services/ecommerceService'
import ErrorComponent from 'app/components/ErrorComponent/ErrorComponent'
import EcommerceLayout from 'app/components/MatxLayout/EcommerceCheckoutLayout'
import { useDispatch, useSelector } from 'react-redux'
import { MatxSnackbar } from 'app/components'
import { AlertTitle } from '@material-ui/lab'
import {
    StyledSummaryCard,
    StyledGreyCard,
    StyledSummaryCardContent,
    StyledAlert,
    StyledMessageAlert,
    StyledOTPButton,
    StyledPaymentOption,
    StyledLoader,
    StyledFlexGrid,
    StyledCloseIcon,
    StyledQuantityController,
    StyledSummaryCardFooter,
    StyledSummaryList,
    StyledCouponButton,
    StyledCheckoutButton,
    StyledCheckoutWrapper,
    StyledLoading,
} from './styles'

const GreenRadio = withStyles({
    root: {
        color: '#58cab9',
        '&$checked': '#58cab9',
    },
    checked: {},
})((props) => {
    return <Radio color="default" size="small" {...props} />
})

const MpesaRadio = withStyles({
    root: {
        color: 'rgb(44, 179, 74)',
        '&$checked': 'rgb(44, 179, 74)',
    },
    checked: {},
})((props) => {
    return <Radio color="default" size="small" {...props} />
})
const LipaLaterRadio = withStyles({
    root: {
        color: 'rgb(37,63,129)',
        '&$checked': 'rgb(37,63,129)',
    },
    checked: {},
})((props) => {
    return <Radio color="default" size="small" {...props} />
})
const CashRadio = withStyles({
    root: {
        color: '#f68937',
        '&$checked': '#f68937',
    },
    checked: {},
})((props) => {
    return <Radio color="default" size="small" {...props} />
})

const GreenCheckbox = withStyles({
    root: {
        color: '#58cab9',

        '&$checked': {
            color: '#58cab9',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />)

var code
var coupon_quantity

const CheckoutPage = (props) => {
    const dispatch = useDispatch()

    const { user } = useAuth()
    const [state, setState] = useState({ ...user })
    let { email, phone } = state
    const { cartList = [] } = useSelector((state) => state.ecommerce)
    const history = useHistory()

    const getTotalCost = () => {
        let totalCost = 0
        cartList.forEach(({ variant: { quantity = 0, price = 0 } }) => {
            totalCost += quantity * price
        })

        return totalCost
    }

    const [paymentDetails, setPaymentDetails] = useState({
        paymentMethod: 'mpesa',
        alerts: false,
        address: '',
        ...(state?.fname
            ? {
                name: state?.fname + ' ' + state?.lname || '',
            }
            : { name: '' }),

        deliveryMethod: 'dropoff',
    })
    const [lipaLaterDetails, setLipaLatrDetails] = useState({
        phone: phone,
        otp: '',
        loadingOTPRequest: false,
        loadingVerifyRequest: false,
        otpRequested: false,
        otpVerified: false,
        verificationPassed: false,
        limitAuthorized: false,
        id_number: '',
        message: '',
        amount: getTotalCost().toLocaleString(),
    })

    const [cashDetails, setCashDetails] = useState({
        auth_code: '',
        loadingCodeRequest: false,
        active: false,
    })
    const [accountNumber, setAccountNumber] = useState(phone)
    const [retryCount, setRetryCount] = useState(0)
    const [loadingDealers, setLoadingDealers] = useState(false)
    const [dealers, setDealers] = useState([])
    const [loadingOrder, setLoadingOrder] = useState(false)
    const [loadingDiscount, setLoadingDiscount] = useState(false)
    const [error, setError] = useState({ status: false, msg: '' })
    const [stores, setStores] = useState([])
    const [showSnackBar, setShowSnackBar] = useState(false)
    const [message, setMessage] = useState(false)
    const [showLink, setShowLink] = useState(false)
    const [clientDetails, setClientDetails] = useState({})
    const [coupon, setCoupon] = useState({})
    const [discount, setDiscount] = useState(0)
    const [hasCoupon, setHascoupon] = useState(false)
    const [discountTotal, setDiscountTotal] = useState(false)
    const handleClearCart = () => dispatch(clearCart())
    const [modelQuantity, setModelQuantity] = useState()
    const incrementRetryCount = () => setRetryCount(retryCount + 1)
    const disabled = retryCount > 3

    const closeSnackbar = () => {
        setShowSnackBar(false)
    }

    const getDealers = async () => {
        setLoadingDealers(true)
        try {
            const {
                data: { stores },
            } = await getstores('get_stores')
            const {
                data: { dealers, success },
            } = await getdealers('get_dealers')

            if (success) {
                setDealers(dealers)
                setStores(stores)
            }
            // } else {
            //     setError({
            //         status: true,
            //         msg: 'An error occured while fetching dealers, please try again later',
            //     })
            // }
        } catch (err) {
            setError({
                status: true,
                msg: 'An error occured while fetching dealers, please try again later',
            })
        } finally {
            setLoadingDealers(false)
        }
    }

    const swiper = useRef(null)

    const handleAddressChange = (event) => {
        const {
            target: { value },
        } = event
        setPaymentDetails({ ...paymentDetails, address: value })
    }
    useEffect(() => {
        getDealers()
        console.log('err check', error);
    }, [user])
    useEffect(() => {
        swiper.current = new Swiper('.swiper-container', {
            direction: 'horizontal',
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            autoplay: {
                delay: 6000,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            slidesPerView: 1,
            slidesPerColumn: 1,
            loop: false,
            reverseDirection: false,
            stopOnLastSlide: false,
            loopAdditionalSlides: 1000,
            preloadImages: true,
            updateOnImagesReady: true,
            effect: 'fade',
        })

        return function cleanUp() {
            swiper.current = null
        }
    }, [])

    const handleChange = (event) => {
        event.persist()
        setState({ ...state, [event.target.name]: event.target.value })
    }

    function isEmpty(obj) {
        return Object.keys(obj).length === 0
    }

    const handleOTPRequest = async (e) => {
        e.preventDefault()
        let body = {
            id_number: lipaLaterDetails.id_number,
            phone_number: lipaLaterDetails.phone,
        }
        setLipaLatrDetails({ ...lipaLaterDetails, loadingOTPRequest: true })

        try {
            let { data } = await authenticateLipaLater(body)

            if (data.status === 200) {
                setLipaLatrDetails({
                    ...lipaLaterDetails,
                    otpRequested: true,
                    loadingOTPRequest: false,
                })

                setMessage({
                    msg: data.data.message,
                    severity: 'success',
                })
                setShowSnackBar(true)
            } else {
                setMessage({
                    msg:
                        data.data.message ||
                        'An error for lipa later  occurred. Try again later',
                    severity: 'error',
                })
                setShowSnackBar(true)
            }
        } catch (err) {
            setMessage({
                msg: err.message || 'An error for lipa later occurred. Try again later',
                severity: 'error',
            })
            setLipaLatrDetails({
                ...lipaLaterDetails,
                loadingOTPRequest: false,
            })
            setShowSnackBar(true)
        } finally {
            // setLipaLatrDetails({
            //     ...lipaLaterDetails,
            //     loadingOTPRequest: false,
            // })
        }
    }

    const handleAuthCodeRequest = async (e) => {
        e.preventDefault()
        let body = {
            code: cashDetails.auth_code,
        }
        setCashDetails({
            ...cashDetails,
            loadingCodeRequest: true,
        })

        try {
            let {
                data: { success, code, msg },
            } = await authenticateCash(body)

            if (success) {
                setCashDetails({
                    ...cashDetails,
                    active: true,
                    loadingCodeRequest: false,
                    auth_code: code,
                })

                setMessage({
                    msg,
                    severity: 'success',
                })
                setShowSnackBar(true)
            } else {
                setCashDetails({
                    ...cashDetails,

                    loadingCodeRequest: false,
                    auth_code: '',
                })
                setMessage({
                    msg: msg || 'An authorization error occurred. Try again later',
                    severity: 'error',
                })
                setShowSnackBar(true)
            }
        } catch (err) {
            setMessage({
                msg: err.message || 'An error  occurred during authorization. Try again later',
                severity: 'error',
            })
            setCashDetails({
                ...cashDetails,
                loadingCodeRequest: false,
                auth_code: '',
            })
            setShowSnackBar(true)
        } finally {
            // setLipaLatrDetails({
            //     ...lipaLaterDetails,
            //     loadingOTPRequest: false,
            // })
        }
    }

    const handleVerifyLipaLaterOTP = async (e) => {
        e.preventDefault()
        setLipaLatrDetails({
            ...lipaLaterDetails,
            loadingVerifyRequest: true,
        })
        try {
            let body = {
                id_number: lipaLaterDetails.id_number,
                item_value: getTotalCost(),
                otp: lipaLaterDetails.otp,
                phone_number: lipaLaterDetails.phone,
            }

            let res = await verifyOtpLipaLater(body)

            if (res.status === 200 && !isEmpty(res.data.data)) {
                if (!isEmpty(res.data.data.payment_terms)) {
                    setLipaLatrDetails({
                        ...lipaLaterDetails,
                        otpVerified: true,
                        verificationPassed: true,
                        loadingVerifyRequest: false,
                        limitAuthorized: true,
                    })
                    setMessage({
                        msg: res.data.message,
                        severity: 'success',
                    })
                    setClientDetails(res.data.data)
                    // setShowSnackBar(true)
                } else {
                    setLipaLatrDetails({
                        ...lipaLaterDetails,
                        otpVerified: true,
                        verificationPassed: true,
                        loadingVerifyRequest: false,
                    })
                    setMessage({
                        msg: res.data.message,
                        severity: 'success',
                    })
                    setClientDetails(res.data.data)
                    // setShowSnackBar(true)
                }
            } else {
                setMessage({
                    msg: res.data.message,
                    severity: 'success',
                })
                setLipaLatrDetails({
                    ...lipaLaterDetails,
                    otpVerified: true,
                    loadingVerifyRequest: false,
                    message: res.data.message,
                })
                setShowLink(true)
                setShowSnackBar(true)
            }
        } catch (err) {
            setMessage({
                msg: err.message || 'error occurred',
                severity: 'error',
            })
            setShowSnackBar(true)
            setLipaLatrDetails({
                ...lipaLaterDetails,
                loadingVerifyRequest: false,
            })
        } finally {
            // setLipaLatrDetails({
            //     ...lipaLaterDetails,
            //     loadingVerifyRequest: false,
            // })
        }

        //   checkComplete()
    }

    const resetPaymentDetails = () => {
        setLipaLatrDetails({
            phone: phone,
            otp: '',
            otpRequested: false,
            otpVerified: false,
            verificationPassed: false,
            limitAuthorized: false,
            id_number: '',
            amount: getTotalCost().toLocaleString(),
        })
        setAccountNumber('')
    }

    const handleFormSubmit = async () => {
        if(!user){window._toggleDrawer(`/ke/ecommerce/checkout`)}
        setError({ status: false, msg: '' });
        const cart = cartList.map((item) => {
            return {
                modelId: item.modelId,
                variant: {
                    _id: item.variant._id,
                    quantity: item.variant.quantity,
                    color: item.variant.color,
                },
            };
        });

        try {
            if(!user){
                window._toggleDrawer(`/ke/ecommerce/checkout`)
            }
           
            setLoadingOrder(true);
            let payload = {
                shipping: {
                    method: paymentDetails.deliveryMethod,
                    ...(paymentDetails.deliveryMethod === 'dropoff' && {
                        name: paymentDetails.address,
                    }),
                    ...(paymentDetails.deliveryMethod === 'pickup' && {
                        _id: paymentDetails.address,
                    }),
                    ...(paymentDetails.deliveryMethod === 'store' && {
                        _id: paymentDetails.address,
                    }),
                },
                paymentMethod: {
                    method: paymentDetails.paymentMethod,
                    phone,
                    email,
                    clientName: paymentDetails.name,
                    ...(paymentDetails.paymentMethod === 'lipalater' && {
                      id_number: lipaLaterDetails.id_number,
                    }),
                    ...(paymentDetails.paymentMethod === 'cash' && {
                      code: cashDetails.auth_code,
                    }),
                  },
                  cart,
                  alerts: paymentDetails.alerts,
                  userId: await user._id?user._id:'',
                  code,
                  hasCoupon,
                  coupon_quantity,

            };
            


            incrementRetryCount();
            const {
                data: { success = false, order = '' },
            } = await postNewOrder(payload);

            setLoadingOrder(false);
          
            if (success) {
                history.replace(`/ke/invoice/${order}`, {
                    coupon_quantity: coupon_quantity,
                })
                setError({ status: false, msg: '' })

                handleClearCart()
            }
          
        } catch (err) {
            
            setError({
                status: true,
                msg: 'Order failed, please try again later',
            })
            
        } finally {
            setLoadingOrder(false)
            resetPaymentDetails()
        }
        if(user){
            window.location.reload()
        }
    }

    const handleDelivery = (e) => {
        e.preventDefault()
        setPaymentDetails({
            ...paymentDetails,
            deliveryMethod: e.target.value,
            address: '',
        })
    }

    const handleDiscountChange = ({ target: { name, value } }) => {
        setCoupon({
            ...coupon,
            [name]: value,
        })

        setDiscountTotal(false)

        setHascoupon(true)
    }

    var cartDetail = cartList
    var assignedModels = cartDetail.map((model) => model.modelId)
    var cartListDetails = Object.assign({}, cartList[1])

    var modelQ

    var doubleCoupon = []
    const handleDiscount = async (e) => {
        console.log('discount', discount)
        setHascoupon(true)
        e.preventDefault()
        code = coupon.code
        try {
            setLoadingDiscount(true)
            for (let i = 0; i < cartList.length; i++) {
                const {
                    data: { coupon, success, msg },
                } = await addCoupon({ code, assignedModels, coupon_quantity })

                if (success) {
                    if (coupon.assignedModels[0] == cartList[i].modelId) {
                        doubleCoupon.push(cartList[i].variant.quantity)
                        coupon_quantity = doubleCoupon.reduce(
                            (accumulator, currentValue) =>
                                accumulator + currentValue
                        )
                    } else {
                        console.log('unavailable')
                    }

                    setDiscount(coupon.value)

                    setCoupon({})
                    console.log('test', coupon.value, coupon_quantity)
                    //setDiscount(coupon.value * modelQ)
                    setDiscountTotal(true)
                    setMessage({
                        msg: `discount coupon added successfully`,
                        severity: 'success',
                    })
                    setShowSnackBar(true)
                } else {
                    setMessage({
                        status: true,
                        msg: msg || 'A Discount error occurred. Try again later',
                        severity: 'error',
                    })
                    //  setShowSnackBar(true)
                }
            }
        } catch (error) {

            setMessage({
                status: true,
                msg: error.message || 'error occurred',
                severity: 'error',
            })
            //  setShowSnackBar(true)
        } finally {
            setLoadingDiscount(false)
        }
    }
    return (
        <EcommerceLayout>
            {loadingDealers && (
                <StyledLoading>
                    <img src="/assets/images/loader.gif" alt="" />
                </StyledLoading>
            )}

            {error.status && (
                <ErrorComponent error={error}>
                    {
                        <Button
                            className="w-full"
                            color="primary"
                            variant="contained"
                            onClick={() => handleFormSubmit()}
                            disabled={loadingOrder || disabled}
                        >
                            {loadingOrder ? 'Please wait...' : 'Retry Order'}
                        </Button>
                    }

                    <>
                        <p>Payment failed, please contact support for help</p>
                        <p>Customer Care number +254 725 62 62 62</p>
                    </>
                </ErrorComponent>
            )}

            {!error.status && !loadingDealers && (
                <StyledCheckoutWrapper className="shop">
                    <MatxSnackbar
                        open={showSnackBar}
                        message={message.msg}
                        type={message.severity}
                        handleClose={closeSnackbar}
                    />
                    <Hidden mdUp>
                        <StyledSummaryCard>
                            <CardHeader
                                title="Order Summary"
                                subheader={
                                    cartList.length !== 0 && (
                                        <small>
                                            {cartList.length}{' '}
                                            {cartList.length === 1
                                                ? 'item'
                                                : 'items'}
                                        </small>
                                    )
                                }
                            />
                            <StyledSummaryCardContent>
                                {cartList.length === 0 && (
                                    <p
                                        className="text-center"
                                        style={{ border: 0 }}
                                    >
                                        No items in cart
                                    </p>
                                )}

                                {cartList.length !== 0 &&
                                    cartList.map(
                                        (
                                            {
                                                modelName,
                                                modelId,
                                                modelImage,
                                                variant,
                                                media,
                                            },
                                            index
                                        ) => (
                                            <div
                                                className="cart-item"
                                                key={index}
                                            >
                                                <StyledCloseIcon
                                                    onClick={() =>
                                                        dispatch(
                                                            deleteProductFromCart(
                                                                cartList,
                                                                variant._id,
                                                                variant?.color
                                                            )
                                                        )
                                                    }
                                                >
                                                    X
                                                </StyledCloseIcon>
                                                <img
                                                    className="cart-item-image"
                                                    src={modelImage}
                                                    // onError={({ target }) =>
                                                    //     handleFetchNewImageUrl(
                                                    //         target,
                                                    //         modelId,
                                                    //          media
                                                    //     )
                                                    // }
                                                    alt={modelName}
                                                />
                                                <div>
                                                    <h4 className="price">
                                                        Ksh.{' '}
                                                        {(
                                                            variant?.price *
                                                            variant?.quantity
                                                        ).toLocaleString()}
                                                    </h4>
                                                    <h6 className="model">
                                                        {modelName}{' '}
                                                        <span>{`( ${variant?.ram
                                                            }GB | ${variant?.storage
                                                            }GB ${variant?.color &&
                                                                Object.keys(
                                                                    variant?.color
                                                                ).length !== 0
                                                                ? `- ${variant?.color?.label}`
                                                                : ''
                                                            })`}</span>
                                                    </h6>

                                                    <StyledQuantityController>
                                                        <button
                                                            className="decrement"
                                                            disabled={
                                                                !(
                                                                    variant.quantity -
                                                                    1
                                                                )
                                                            }
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                setModelQuantity(
                                                                    modelQ
                                                                )
                                                                setDiscount(
                                                                    coupon?.value *
                                                                    modelQ
                                                                )

                                                                dispatch(
                                                                    updateCartQuantity(
                                                                        cartList,
                                                                        modelId,
                                                                        variant,
                                                                        variant.quantity -
                                                                        1,
                                                                        variant?.color
                                                                    )
                                                                )
                                                            }}
                                                        >
                                                            -
                                                        </button>
                                                        <span>
                                                            {variant?.quantity}
                                                        </span>
                                                        <button
                                                            className="increment"
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                setModelQuantity(
                                                                    modelQ
                                                                )
                                                                setDiscount(
                                                                    coupon?.value *
                                                                    modelQ
                                                                )
                                                                dispatch(
                                                                    updateCartQuantity(
                                                                        cartList,
                                                                        modelId,
                                                                        variant,
                                                                        variant.quantity +
                                                                        1,
                                                                        variant?.color
                                                                    )
                                                                )
                                                            }}
                                                        >
                                                            +
                                                        </button>
                                                    </StyledQuantityController>
                                                </div>
                                            </div>
                                        )
                                    )}
                            </StyledSummaryCardContent>
                            {cartList.length !== 0 && (
                                <StyledSummaryCardFooter>
                                    <StyledSummaryList>
                                        <li>
                                            <span>Items Total </span>
                                            <span>
                                                Ksh.{' '}
                                                {getTotalCost().toLocaleString()}
                                            </span>
                                        </li>
                                        <li>
                                            <span>Delivery</span>
                                            <span>
                                                <b className="delivery-charges">
                                                    Ksh. 250
                                                </b>{' '}
                                                FREE
                                            </span>
                                        </li>
                                        {discount ? (
                                            <li>
                                                <span>Discount Coupon</span>
                                                <span>{discount} </span>
                                            </li>
                                        ) : (
                                            ''
                                        )}
                                        <li>
                                            <TextField
                                                label="Discount Code"
                                                id="outlined-size-small"
                                                variant="outlined"
                                                size="small"
                                                name="code"
                                                value={coupon.code}
                                                onChange={handleDiscountChange}
                                                helperText={error.msg}
                                            />
                                            <span>
                                                <StyledCouponButton
                                                    disabled={
                                                        loadingDiscount ||
                                                        disabled
                                                    }
                                                    onClick={handleDiscount}
                                                >
                                                    {loadingDiscount ? (
                                                        <StyledLoader
                                                            size={16}
                                                            thickness={5}
                                                            className="mr-1"
                                                            style={{
                                                                color: 'white',
                                                            }}
                                                        />
                                                    ) : (
                                                        'Apply Code'
                                                    )}
                                                </StyledCouponButton>
                                            </span>
                                        </li>
                                        {message.status === true ? (
                                            <li style={{ color: '#EF684B' }}>
                                                {message.msg}
                                            </li>
                                        ) : (
                                            ''
                                        )}

                                        <li>
                                            <span>Total</span>
                                            <span>
                                                Ksh.{' '}
                                                {getTotalCost().toLocaleString()}
                                            </span>
                                        </li>
                                    </StyledSummaryList>
                                </StyledSummaryCardFooter>
                            )}
                            <p className="shipment-disclaimer">
                                All Orders Within Nairobi Will Be Delivered
                                <br /> Within 24 Hours
                            </p>
                        </StyledSummaryCard>
                    </Hidden>
                    <ValidatorForm
                        onSubmit={() => handleFormSubmit()}
                        onError={(errors) => null}
                        id="checkout"
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={8}>
                                <StyledGreyCard>
                                    <CardContent>
                                        <h4>Delivery Address</h4>

                                        <Card style={{ borderRadius: '4px' }}>
                                            <CardContent>
                                                <Grid container spacing={3}>
                                                    <StyledFlexGrid
                                                        item
                                                        xs={12}
                                                        sm={3}
                                                    >
                                                        {' '}
                                                        <img
                                                            src="/assets/images/ecommerce/House-icon.png"
                                                            alt="home"
                                                        />
                                                    </StyledFlexGrid>
                                                    <Grid item xs={12} sm={9}>
                                                        <FormControl component="fieldset">
                                                            <RadioGroup name="deliveryMethod">
                                                                <FormControlLabel
                                                                    value="end"
                                                                    control={
                                                                        <GreenRadio
                                                                            style={{
                                                                                display:
                                                                                    'block',
                                                                            }}
                                                                            checked={
                                                                                paymentDetails?.deliveryMethod ===
                                                                                'dropoff'
                                                                            }
                                                                            onChange={
                                                                                handleDelivery
                                                                            }
                                                                            value="dropoff"
                                                                            name="deliveryMethod"
                                                                        />
                                                                    }
                                                                    label={
                                                                        <span
                                                                            style={{
                                                                                color:
                                                                                    paymentDetails?.deliveryMethod ===
                                                                                        'home'
                                                                                        ? '#58cab9'
                                                                                        : '#000',
                                                                            }}
                                                                        >
                                                                            At
                                                                            Your
                                                                            Home/Office
                                                                            (Anywhere
                                                                            in
                                                                            Kenya)
                                                                        </span>
                                                                    }
                                                                />
                                                                {paymentDetails?.deliveryMethod ===
                                                                    'dropoff' && (
                                                                        <>
                                                                            <TextValidator
                                                                                variant="outlined"
                                                                                label="Home/Office Address"
                                                                                onChange={
                                                                                    handleAddressChange
                                                                                }
                                                                                type="text"
                                                                                name="address"
                                                                                placeholder="Chiromo Court, Westlands"
                                                                                size="small"
                                                                                value={
                                                                                    paymentDetails.address ||
                                                                                    ''
                                                                                }
                                                                                validators={[
                                                                                    'required',
                                                                                ]}
                                                                                errorMessages={[
                                                                                    'this field is required',
                                                                                ]}
                                                                                fullWidth
                                                                                className="mb-5 mt-3"
                                                                            />
                                                                        </>
                                                                    )}
                                                                <FormControlLabel
                                                                    value="end"
                                                                    control={
                                                                        <GreenRadio
                                                                            style={{
                                                                                display:
                                                                                    'block',
                                                                            }}
                                                                            checked={
                                                                                paymentDetails?.deliveryMethod ===
                                                                                'pickup'
                                                                            }
                                                                            onChange={
                                                                                handleDelivery
                                                                            }
                                                                            value="pickup"
                                                                            name="deliveryMethod"
                                                                        />
                                                                    }
                                                                    label={
                                                                        <span
                                                                            style={{
                                                                                color:
                                                                                    paymentDetails?.deliveryMethod ===
                                                                                        'pickup'
                                                                                        ? '#58cab9'
                                                                                        : '#000',
                                                                            }}
                                                                        >
                                                                            At
                                                                            Your
                                                                            Nearest
                                                                            pickup
                                                                            point
                                                                        </span>
                                                                    }
                                                                />
                                                                {paymentDetails?.deliveryMethod ===
                                                                    'pickup' && (
                                                                        <TextValidator
                                                                            label="Select your nearest pickup point"
                                                                            select
                                                                            name="address"
                                                                            variant="outlined"
                                                                            value={
                                                                                paymentDetails.address ||
                                                                                ''
                                                                            }
                                                                            onChange={
                                                                                handleAddressChange
                                                                            }
                                                                            fullWidth
                                                                            className="mb-5 mt-3"
                                                                            size="small"
                                                                            validators={[
                                                                                'required',
                                                                            ]}
                                                                            errorMessages={[
                                                                                'this field is required',
                                                                            ]}
                                                                        >
                                                                            {dealers.map(
                                                                                ({
                                                                                    name,
                                                                                    _id,
                                                                                    region,
                                                                                }) => (
                                                                                    <MenuItem
                                                                                        value={
                                                                                            _id
                                                                                        }
                                                                                        key={
                                                                                            _id
                                                                                        }
                                                                                    >
                                                                                        {`${name} (${region[0]?.name})`}
                                                                                    </MenuItem>
                                                                                )
                                                                            )}
                                                                        </TextValidator>
                                                                    )}
                                                                <FormControlLabel
                                                                    value="end"
                                                                    control={
                                                                        <GreenRadio
                                                                            style={{
                                                                                display:
                                                                                    'block',
                                                                            }}
                                                                            checked={
                                                                                paymentDetails?.deliveryMethod ===
                                                                                'store'
                                                                            }
                                                                            onChange={
                                                                                handleDelivery
                                                                            }
                                                                            value="store"
                                                                            name="deliveryMethod"
                                                                        />
                                                                    }
                                                                    label={
                                                                        <span
                                                                            style={{
                                                                                color:
                                                                                    paymentDetails.deliveryMethod ===
                                                                                        'store'
                                                                                        ? '#58cab9'
                                                                                        : '#000',
                                                                            }}
                                                                        >
                                                                            At a
                                                                            Badili
                                                                            Store
                                                                        </span>
                                                                    }
                                                                />
                                                                {paymentDetails?.deliveryMethod ===
                                                                    'store' && (
                                                                        <TextValidator
                                                                            label="Select a store to pickup device"
                                                                            select
                                                                            name="address"
                                                                            variant="outlined"
                                                                            value={
                                                                                paymentDetails.address ||
                                                                                ''
                                                                            }
                                                                            onChange={
                                                                                handleAddressChange
                                                                            }
                                                                            fullWidth
                                                                            className="mb-5 mt-3"
                                                                            size="small"
                                                                            validators={[
                                                                                'required',
                                                                            ]}
                                                                            errorMessages={[
                                                                                'this field is required',
                                                                            ]}
                                                                        >
                                                                            {stores.map(
                                                                                ({
                                                                                    name,
                                                                                    _id,
                                                                                }) => (
                                                                                    <MenuItem
                                                                                        value={
                                                                                            _id
                                                                                        }
                                                                                        key={
                                                                                            _id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            name
                                                                                        }
                                                                                    </MenuItem>
                                                                                )
                                                                            )}
                                                                        </TextValidator>
                                                                    )}
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </CardContent>
                                </StyledGreyCard>

                                <StyledGreyCard>
                                    <CardContent>
                                        <h4>Select Payment Method</h4>
                                        <Card style={{ borderRadius: '4px' }}>
                                            <CardContent>
                                                <Grid container spacing={3}>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        sm={6}
                                                        md={3}
                                                    >
                                                        {' '}
                                                        <StyledPaymentOption
                                                            bg="rgb(44, 179, 74,.2)"
                                                            imgHeight="60px"
                                                            imgHeightSmall="35px"
                                                            onClick={() => {
                                                                resetPaymentDetails()
                                                                setPaymentDetails(
                                                                    {
                                                                        ...paymentDetails,
                                                                        paymentMethod:
                                                                            'mpesa',
                                                                    }
                                                                )
                                                                setCashDetails({
                                                                    ...cashDetails,
                                                                    active: false,
                                                                })
                                                            }}
                                                        >
                                                            <MpesaRadio
                                                                checked={
                                                                    paymentDetails.paymentMethod ===
                                                                    'mpesa'
                                                                }
                                                                onChange={() => {
                                                                    resetPaymentDetails()
                                                                    setPaymentDetails(
                                                                        {
                                                                            ...paymentDetails,
                                                                            paymentMethod:
                                                                                'mpesa',
                                                                        }
                                                                    )
                                                                    setCashDetails(
                                                                        {
                                                                            ...cashDetails,
                                                                            active: false,
                                                                        }
                                                                    )
                                                                }}
                                                                value="mpesa"
                                                                name="radio-button-demo"
                                                                inputProps={{
                                                                    'aria-label':
                                                                        'mpesa',
                                                                }}
                                                            />
                                                            <img
                                                                src="/assets/images/ecommerce/mpesa-payment.png"
                                                                alt="mpesa-logo"
                                                            />
                                                        </StyledPaymentOption>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        sm={6}
                                                        md={3}
                                                    >
                                                        <StyledPaymentOption
                                                            bg="rgb(37,63,129,.3)"
                                                            imgHeightSmall="16px"
                                                            onClick={() => {
                                                                setPaymentDetails(
                                                                    {
                                                                        ...paymentDetails,
                                                                        paymentMethod:
                                                                            'lipalater',
                                                                    }
                                                                )
                                                                setCashDetails({
                                                                    ...cashDetails,
                                                                    active: false,
                                                                })
                                                            }}
                                                        >
                                                            <LipaLaterRadio
                                                                checked={
                                                                    paymentDetails.paymentMethod ===
                                                                    'lipalater'
                                                                }
                                                                onChange={() => {
                                                                    setPaymentDetails(
                                                                        {
                                                                            ...paymentDetails,
                                                                            paymentMethod:
                                                                                'lipalater',
                                                                        }
                                                                    )
                                                                    setCashDetails(
                                                                        {
                                                                            ...cashDetails,
                                                                            active: false,
                                                                        }
                                                                    )
                                                                }}
                                                                value="lipalater"
                                                                name="radio-button-demo"
                                                                inputProps={{
                                                                    'aria-label':
                                                                        'lipalater',
                                                                }}
                                                            />
                                                            <img
                                                                src="/assets/images/ecommerce/lipa-later-logo.png"
                                                                alt="lipalater-logo"
                                                            />
                                                        </StyledPaymentOption>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        sm={6}
                                                        md={3}
                                                    >
                                                        <StyledPaymentOption
                                                            bg="rgba(246, 137, 55, 0.3)"
                                                            imgHeightSmall="16px"
                                                            onClick={() =>
                                                                setPaymentDetails(
                                                                    {
                                                                        ...paymentDetails,
                                                                        paymentMethod:
                                                                            'cash',
                                                                    }
                                                                )
                                                            }
                                                        >
                                                            <CashRadio
                                                                checked={
                                                                    paymentDetails.paymentMethod ===
                                                                    'cash'
                                                                }
                                                                onChange={() =>
                                                                    setPaymentDetails(
                                                                        {
                                                                            ...paymentDetails,
                                                                            paymentMethod:
                                                                                'cash',
                                                                        }
                                                                    )
                                                                }
                                                                value="cash"
                                                                name="radio-button-demo"
                                                                inputProps={{
                                                                    'aria-label':
                                                                        'cash',
                                                                }}
                                                            />
                                                            Pay Cash
                                                            {/* <img
                                                                src="/assets/images/ecommerce/lipa-later-logo.png"
                                                                alt="lipalater-logo"
                                                            /> */}
                                                        </StyledPaymentOption>
                                                    </Grid>

                                                    {paymentDetails.paymentMethod ===
                                                        'cash' &&
                                                        cashDetails.active && (
                                                            <StyledAlert
                                                                variant="outlined"
                                                                severity="success"
                                                            >
                                                                <AlertTitle>
                                                                    Authorization
                                                                    Code
                                                                    Verification
                                                                    Successful
                                                                </AlertTitle>
                                                                <p>
                                                                    You can now
                                                                    proceed to
                                                                    place your
                                                                    order
                                                                </p>
                                                            </StyledAlert>
                                                        )}
                                                    {paymentDetails.paymentMethod ===
                                                        'cash' &&
                                                        !cashDetails.active && (
                                                            <ValidatorForm
                                                                onSubmit={() =>
                                                                    handleOTPRequest()
                                                                }
                                                                onError={(
                                                                    errors
                                                                ) => null}
                                                                id="authCodeRequest"
                                                                style={{
                                                                    padding:
                                                                        '0 16px',
                                                                    margin: '1.2rem 0',
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <Grid
                                                                    container
                                                                    spacing={3}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={3}
                                                                    ></Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={6}
                                                                    >
                                                                        <small
                                                                            style={{
                                                                                fontWeight:
                                                                                    'bold',
                                                                            }}
                                                                        >
                                                                            *To
                                                                            use
                                                                            this
                                                                            option
                                                                            kindly
                                                                            visit
                                                                            our
                                                                            store
                                                                            or
                                                                            authorized
                                                                            dealer
                                                                        </small>
                                                                        <TextValidator
                                                                            type="password"
                                                                            className=" w-full mt-4"
                                                                            label="Authorization Code"
                                                                            variant="outlined"
                                                                            size="small"
                                                                            onChange={({
                                                                                target: {
                                                                                    value,
                                                                                },
                                                                            }) =>
                                                                                setCashDetails(
                                                                                    {
                                                                                        ...cashDetails,
                                                                                        auth_code:
                                                                                            value,
                                                                                    }
                                                                                )
                                                                            }
                                                                            name="auth_code"
                                                                            value={
                                                                                cashDetails.auth_code
                                                                            }
                                                                            validators={[
                                                                                'required',
                                                                            ]}
                                                                            errorMessages={[
                                                                                'this field is required',
                                                                            ]}
                                                                        />
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={12}
                                                                    >
                                                                        {' '}
                                                                        <StyledOTPButton
                                                                            className=""
                                                                            color="primary"
                                                                            variant="contained"
                                                                            onClick={(
                                                                                e
                                                                            ) =>
                                                                                handleAuthCodeRequest(
                                                                                    e
                                                                                )
                                                                            }
                                                                            // type="submit"
                                                                            // form="otpRequest"
                                                                            disabled={
                                                                                !cashDetails.auth_code ||
                                                                                cashDetails.loadingCodeRequest
                                                                            }
                                                                        >
                                                                            {!cashDetails.loadingCodeRequest
                                                                                ? 'Verify Code'
                                                                                : 'Please wait...'}
                                                                        </StyledOTPButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </ValidatorForm>
                                                        )}

                                                    {paymentDetails.paymentMethod ===
                                                        'lipalater' && (
                                                            <>
                                                                {!lipaLaterDetails.otpRequested && (
                                                                    <ValidatorForm
                                                                        onSubmit={() =>
                                                                            handleOTPRequest()
                                                                        }
                                                                        onError={(
                                                                            errors
                                                                        ) => null}
                                                                        id="otpRequest"
                                                                        style={{
                                                                            padding:
                                                                                '0 16px',
                                                                            margin: '1.2rem 0',
                                                                        }}
                                                                    >
                                                                        <Grid
                                                                            container
                                                                            spacing={
                                                                                3
                                                                            }
                                                                        >
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    12
                                                                                }
                                                                                sm={
                                                                                    12
                                                                                }
                                                                                md={
                                                                                    6
                                                                                }
                                                                            >
                                                                                <TextValidator
                                                                                    type="text"
                                                                                    className=" w-full"
                                                                                    label="Enter ID/Passport number"
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    onChange={({
                                                                                        target: {
                                                                                            value,
                                                                                        },
                                                                                    }) =>
                                                                                        setLipaLatrDetails(
                                                                                            {
                                                                                                ...lipaLaterDetails,
                                                                                                id_number:
                                                                                                    value,
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                    name="nationalId"
                                                                                    value={
                                                                                        lipaLaterDetails.id_number
                                                                                    }
                                                                                    validators={[
                                                                                        'required',
                                                                                    ]}
                                                                                    errorMessages={[
                                                                                        'this field is required',
                                                                                    ]}
                                                                                />
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    12
                                                                                }
                                                                                sm={
                                                                                    12
                                                                                }
                                                                                md={
                                                                                    6
                                                                                }
                                                                            >
                                                                                <TextValidator
                                                                                    type="text"
                                                                                    className="mb-6 w-full"
                                                                                    label="Enter phone number"
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    onChange={({
                                                                                        target: {
                                                                                            value,
                                                                                        },
                                                                                    }) => {
                                                                                        setLipaLatrDetails(
                                                                                            {
                                                                                                ...lipaLaterDetails,
                                                                                                phone: value,
                                                                                            }
                                                                                        )
                                                                                        setAccountNumber(
                                                                                            value
                                                                                        )
                                                                                    }}
                                                                                    name="mpesa number"
                                                                                    value={
                                                                                        lipaLaterDetails.phone
                                                                                    }
                                                                                    validators={[
                                                                                        'required',
                                                                                    ]}
                                                                                    errorMessages={[
                                                                                        'this field is required',
                                                                                    ]}
                                                                                />
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    12
                                                                                }
                                                                                sm={
                                                                                    12
                                                                                }
                                                                                md={
                                                                                    12
                                                                                }
                                                                            >
                                                                                {' '}
                                                                                <StyledOTPButton
                                                                                    className=""
                                                                                    color="primary"
                                                                                    variant="contained"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) =>
                                                                                        handleOTPRequest(
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                    // type="submit"
                                                                                    // form="otpRequest"
                                                                                    disabled={
                                                                                        lipaLaterDetails.otpRequested ||
                                                                                        lipaLaterDetails.loadingOTPRequest
                                                                                    }
                                                                                >
                                                                                    {!lipaLaterDetails.loadingOTPRequest
                                                                                        ? 'Request OTP'
                                                                                        : 'Please wait...'}
                                                                                </StyledOTPButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </ValidatorForm>
                                                                )}

                                                                {lipaLaterDetails.otpRequested &&
                                                                    !lipaLaterDetails.otpVerified && (
                                                                        <ValidatorForm
                                                                            onSubmit={() =>
                                                                                handleVerifyLipaLaterOTP()
                                                                            }
                                                                            onError={(
                                                                                errors
                                                                            ) =>
                                                                                null
                                                                            }
                                                                            id="otpVerify"
                                                                            style={{
                                                                                padding:
                                                                                    '0 16px',
                                                                                margin: '1.2rem 0',
                                                                            }}
                                                                        >
                                                                            <Grid
                                                                                container
                                                                                spacing={
                                                                                    3
                                                                                }
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                    sm={
                                                                                        12
                                                                                    }
                                                                                    md={
                                                                                        6
                                                                                    }
                                                                                >
                                                                                    <TextValidator
                                                                                        type="text"
                                                                                        className=" w-full"
                                                                                        label="LipaLater OTP"
                                                                                        variant="outlined"
                                                                                        size="small"
                                                                                        onChange={({
                                                                                            target: {
                                                                                                value,
                                                                                            },
                                                                                        }) =>
                                                                                            setLipaLatrDetails(
                                                                                                {
                                                                                                    ...lipaLaterDetails,
                                                                                                    otp: value,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                        name="lipaLaterOTP"
                                                                                        value={
                                                                                            lipaLaterDetails.otp
                                                                                        }
                                                                                        validators={[
                                                                                            'required',
                                                                                        ]}
                                                                                        errorMessages={[
                                                                                            'this field is required',
                                                                                        ]}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                    sm={
                                                                                        12
                                                                                    }
                                                                                    md={
                                                                                        6
                                                                                    }
                                                                                >
                                                                                    <TextValidator
                                                                                        type="text"
                                                                                        className="mb-6 w-full"
                                                                                        label="Amount"
                                                                                        variant="outlined"
                                                                                        size="small"
                                                                                        disabled
                                                                                        name="amount"
                                                                                        value={
                                                                                            lipaLaterDetails.amount
                                                                                        }
                                                                                        validators={[
                                                                                            'required',
                                                                                        ]}
                                                                                        errorMessages={[
                                                                                            'this field is required',
                                                                                        ]}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                    sm={
                                                                                        12
                                                                                    }
                                                                                    md={
                                                                                        12
                                                                                    }
                                                                                >
                                                                                    {' '}
                                                                                    <StyledOTPButton
                                                                                        className=""
                                                                                        color="primary"
                                                                                        variant="contained"
                                                                                        // type="submit"
                                                                                        // form="otpVerify"
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleVerifyLipaLaterOTP(
                                                                                                e
                                                                                            )
                                                                                        }
                                                                                        disabled={
                                                                                            !lipaLaterDetails.otp ||
                                                                                            lipaLaterDetails.loadingVerifyRequest
                                                                                        }
                                                                                    >
                                                                                        {lipaLaterDetails.loadingVerifyRequest
                                                                                            ? '  Please wait..'
                                                                                            : 'Verify OTP.'}
                                                                                    </StyledOTPButton>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </ValidatorForm>
                                                                    )}
                                                                {lipaLaterDetails.otpVerified &&
                                                                    !showLink && (
                                                                        <>
                                                                            <StyledAlert
                                                                                variant="outlined"
                                                                                severity="success"
                                                                            >
                                                                                <AlertTitle>
                                                                                    Lipa
                                                                                    Later
                                                                                    Verification
                                                                                    Successful
                                                                                </AlertTitle>
                                                                                <p>
                                                                                    Client
                                                                                    Name:
                                                                                    <span>
                                                                                        {
                                                                                            clientDetails?.first_name
                                                                                        }
                                                                                    </span>
                                                                                </p>
                                                                                <p>
                                                                                    Credit
                                                                                    Limit
                                                                                    Status:
                                                                                    <span>
                                                                                        {
                                                                                            clientDetails?.credit_limit_status
                                                                                        }
                                                                                    </span>
                                                                                </p>
                                                                                <p
                                                                                    style={{
                                                                                        color:
                                                                                            clientDetails?.credit_limit ===
                                                                                                0
                                                                                                ? '#FF3D57'
                                                                                                : '',
                                                                                    }}
                                                                                >
                                                                                    Credit
                                                                                    Limit
                                                                                    <span>
                                                                                        {
                                                                                            clientDetails?.credit_limit
                                                                                        }
                                                                                    </span>
                                                                                </p>
                                                                                <p
                                                                                    style={{
                                                                                        color:
                                                                                            clientDetails?.available_limit ===
                                                                                                0
                                                                                                ? '#FF3D57'
                                                                                                : '',
                                                                                    }}
                                                                                >
                                                                                    Available
                                                                                    Limit
                                                                                    <span>
                                                                                        {
                                                                                            clientDetails?.available_limit
                                                                                        }
                                                                                    </span>
                                                                                </p>
                                                                                {!lipaLaterDetails.limitAuthorized &&
                                                                                    clientDetails?.available_limit <
                                                                                    getTotalCost() && (
                                                                                        <>
                                                                                            <br />

                                                                                            <p
                                                                                                style={{
                                                                                                    color:
                                                                                                        clientDetails?.available_limit <
                                                                                                            getTotalCost()
                                                                                                            ? '#FF3D57'
                                                                                                            : '',
                                                                                                }}
                                                                                            >
                                                                                                {`Your credit Limit is ${clientDetails?.available_limit}, kindly use another payment option`}{' '}
                                                                                            </p>
                                                                                        </>
                                                                                    )}
                                                                                {lipaLaterDetails.limitAuthorized && (
                                                                                    <>
                                                                                        <p>
                                                                                            Principal
                                                                                            Amount
                                                                                            <span>
                                                                                                {
                                                                                                    clientDetails
                                                                                                        ?.payment_terms
                                                                                                        ?.principal_amount
                                                                                                }
                                                                                            </span>
                                                                                        </p>
                                                                                        <p>
                                                                                            Upfront
                                                                                            Fees
                                                                                            <span>
                                                                                                {
                                                                                                    clientDetails
                                                                                                        ?.payment_terms
                                                                                                        ?.upfront_fees
                                                                                                }
                                                                                            </span>
                                                                                        </p>
                                                                                        <p>
                                                                                            First
                                                                                            Installment
                                                                                            <span>
                                                                                                {
                                                                                                    clientDetails
                                                                                                        ?.payment_terms
                                                                                                        ?.first_installment
                                                                                                }
                                                                                            </span>
                                                                                        </p>
                                                                                        <p>
                                                                                            Minimum
                                                                                            Payment
                                                                                            <span>
                                                                                                {
                                                                                                    clientDetails
                                                                                                        ?.payment_terms
                                                                                                        ?.minimum_payment
                                                                                                }
                                                                                            </span>
                                                                                        </p>
                                                                                    </>
                                                                                )}
                                                                            </StyledAlert>
                                                                        </>
                                                                    )}
                                                            </>
                                                        )}
                                                </Grid>
                                                {lipaLaterDetails.otpVerified &&
                                                    showLink && (
                                                        <>
                                                            <StyledMessageAlert
                                                                variant="outlined"
                                                                severity="success"
                                                            >
                                                                <AlertTitle>
                                                                    {
                                                                        lipaLaterDetails.message
                                                                    }
                                                                </AlertTitle>
                                                            </StyledMessageAlert>
                                                            <a
                                                                href="https://www.app.lipalater.com/ke/customer/signup"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{
                                                                    display:
                                                                        'block',
                                                                    textDecoration:
                                                                        'underline',
                                                                    textAlign:
                                                                        'center',
                                                                    margin: '2rem 0',
                                                                    color: 'rgb(37,63,129)',
                                                                }}
                                                            >
                                                                Proceed to
                                                                signup for lipa
                                                                later
                                                            </a>
                                                        </>
                                                    )}
                                            </CardContent>
                                        </Card>
                                    </CardContent>
                                </StyledGreyCard>
                                <StyledGreyCard>
                                    <CardContent>
                                        <h4>Personal Details</h4>
                                        <TextValidator
                                            variant="outlined"
                                            label="Name"
                                            onChange={(e) =>
                                                setPaymentDetails({
                                                    ...paymentDetails,
                                                    name: e.target.value,
                                                })
                                            }
                                            type="text"
                                            name="name"
                                            size="small"
                                            value={paymentDetails?.name || ''}
                                            validators={['required']}
                                            errorMessages={[
                                                'this field is required',
                                            ]}
                                            fullWidth
                                            className="mb-5"
                                        />
                                        <TextValidator
                                            variant="outlined"
                                            label="Phone Number"
                                            onChange={handleChange}
                                            type="text"
                                            name="phone"
                                            size="small"
                                            value={phone || ''}
                                            validators={['required']}
                                            errorMessages={[
                                                'this field is required',
                                            ]}
                                            fullWidth
                                            className="mb-5"
                                        />

                                        <TextValidator
                                            variant="outlined"
                                            label="Email Address"
                                            // className="mb-5"
                                            onChange={handleChange}
                                            type="email"
                                            name="email"
                                            value={email?.toLowerCase() || ''}
                                            validators={['isEmail']}
                                            errorMessages={[
                                                'this field is required',
                                                'email is not valid',
                                            ]}
                                            fullWidth
                                            size="small"
                                        // helperText="Some important text"
                                        />
                                        <small className="d-block mb-5 pl-2 mt-1">
                                            * Please provide a valid email. An
                                            invoice will be sent to the provided
                                            email
                                        </small>
                                        <FormControlLabel
                                            control={
                                                <GreenCheckbox
                                                    checked={
                                                        paymentDetails.alerts
                                                    }
                                                    onChange={(e) =>
                                                        setPaymentDetails({
                                                            ...paymentDetails,
                                                            alerts: e.target
                                                                .checked,
                                                        })
                                                    }
                                                    name="alerts"
                                                />
                                            }
                                            label="Tick the box if you would also like to receive other news and offer alerts from Badili.Africa"
                                        />
                                    </CardContent>
                                </StyledGreyCard>
                            </Grid>
                            <Hidden mdDown>
                                <Grid item xs={12} sm={4}>
                                    <StyledSummaryCard>
                                        <CardHeader
                                            title="Order Summary"
                                            subheader={
                                                cartList.length !== 0 && (
                                                    <small>
                                                        {cartList.length}{' '}
                                                        {cartList.length === 1
                                                            ? 'item'
                                                            : 'items'}
                                                    </small>
                                                )
                                            }
                                        />
                                        <StyledSummaryCardContent>
                                            {cartList.length === 0 && (
                                                <p
                                                    className="text-center"
                                                    style={{ border: 0 }}
                                                >
                                                    No items in cart
                                                </p>
                                            )}
                                            {cartList.length !== 0 &&
                                                cartList.map(
                                                    (
                                                        {
                                                            modelName,
                                                            modelId,
                                                            modelImage,
                                                            variant,
                                                            media,
                                                        },
                                                        index
                                                    ) => (
                                                        <div
                                                            className="cart-item"
                                                            key={index}
                                                        >
                                                            <StyledCloseIcon
                                                                onClick={() =>
                                                                    dispatch(
                                                                        deleteProductFromCart(
                                                                            cartList,
                                                                            variant._id,
                                                                            variant?.color
                                                                        )
                                                                    )
                                                                }
                                                            >
                                                                X
                                                            </StyledCloseIcon>
                                                            <img
                                                                className="cart-item-image"
                                                                src={modelImage}
                                                                // onError={({
                                                                //     target,
                                                                // }) =>
                                                                //     handleFetchNewImageUrl(
                                                                //         target,
                                                                //          modelId,
                                                                //         media
                                                                //     )
                                                                // }
                                                                alt={modelName}
                                                            />
                                                            <div>
                                                                <h4 className="price">
                                                                    Ksh.{' '}
                                                                    {(
                                                                        variant?.price *
                                                                        variant?.quantity
                                                                    ).toLocaleString()}
                                                                </h4>
                                                                <h6 className="model">
                                                                    {modelName}{' '}
                                                                    <span>{`( ${variant?.ram
                                                                        }GB | ${variant?.storage
                                                                        }GB ${variant?.color &&
                                                                            Object.keys(
                                                                                variant?.color
                                                                            )
                                                                                .length !==
                                                                            0
                                                                            ? `- ${variant?.color?.label}`
                                                                            : ''
                                                                        })`}</span>
                                                                </h6>

                                                                <StyledQuantityController>
                                                                    <button
                                                                        className="decrement"
                                                                        disabled={
                                                                            !(
                                                                                variant.quantity -
                                                                                1
                                                                            )
                                                                        }
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.preventDefault()
                                                                            setModelQuantity(
                                                                                modelQ
                                                                            )
                                                                            setDiscount(
                                                                                coupon
                                                                                    ? coupon.value
                                                                                    : 0 *
                                                                                    modelQ
                                                                            )

                                                                            dispatch(
                                                                                updateCartQuantity(
                                                                                    cartList,
                                                                                    modelId,
                                                                                    variant,
                                                                                    variant.quantity -
                                                                                    1,
                                                                                    variant?.color
                                                                                )
                                                                            )
                                                                        }}
                                                                    >
                                                                        -
                                                                    </button>
                                                                    <span>
                                                                        {
                                                                            variant?.quantity
                                                                        }
                                                                    </span>
                                                                    <button
                                                                        className="increment"
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.preventDefault()
                                                                            setModelQuantity(
                                                                                modelQ
                                                                            )
                                                                            setDiscount(
                                                                                coupon?.value *
                                                                                modelQ
                                                                            )
                                                                            dispatch(
                                                                                updateCartQuantity(
                                                                                    cartList,
                                                                                    modelId,
                                                                                    variant,
                                                                                    variant.quantity +
                                                                                    1,
                                                                                    variant?.color
                                                                                )
                                                                            )
                                                                        }}
                                                                    >
                                                                        +
                                                                    </button>
                                                                </StyledQuantityController>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                        </StyledSummaryCardContent>
                                        {cartList.length !== 0 && (
                                            <StyledSummaryCardFooter>
                                                <StyledSummaryList>
                                                    <li>
                                                        <span>Items Total</span>
                                                        <span>
                                                            Ksh.{' '}
                                                            {getTotalCost().toLocaleString()}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>Delivery </span>
                                                        <span>
                                                            <b className="delivery-charges">
                                                                Ksh. 250
                                                            </b>{' '}
                                                            FREE
                                                        </span>
                                                    </li>
                                                    {discount > 0 ? (
                                                        <li>
                                                            <span>
                                                                Discount Coupon
                                                            </span>{' '}
                                                            <span>
                                                                {discount}{' '}
                                                            </span>
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}

                                                    <li
                                                        style={{
                                                            padding: '2px',
                                                        }}
                                                    >
                                                        <TextField
                                                            label="discount coupon"
                                                            id="outlined-size-small"
                                                            variant="outlined"
                                                            name="code"
                                                            size="small"
                                                            onChange={
                                                                handleDiscountChange
                                                            }
                                                        />

                                                        <span>
                                                            <StyledCouponButton
                                                                variant="contained"
                                                                onClick={
                                                                    handleDiscount
                                                                }
                                                                // disabled={
                                                                //     loadingDiscount ||
                                                                //     disabled
                                                                // }
                                                                style={{
                                                                    paddingLeft:
                                                                        '16px',
                                                                    paddingRight:
                                                                        '16px',
                                                                    margin: '0px',
                                                                    height: '36px',
                                                                }}
                                                                // onClick={
                                                                //     handleDiscount
                                                                // }
                                                                disabled={
                                                                    loadingDiscount ||
                                                                    disabled ||
                                                                    discountTotal
                                                                }
                                                            >
                                                                {loadingDiscount ? (
                                                                    <StyledLoader
                                                                        size={
                                                                            18
                                                                        }
                                                                        thickness={
                                                                            5
                                                                        }
                                                                        className="mr-1"
                                                                        style={{
                                                                            color: 'white',
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    'Apply Code'
                                                                )}
                                                            </StyledCouponButton>
                                                        </span>
                                                    </li>

                                                    {message.status === true ? (
                                                        <li
                                                            style={{
                                                                color: '#EF684B',
                                                            }}
                                                        >
                                                            {message.msg}
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}
                                                    <li>
                                                        {console.log('discount', discount)}
                                                        <span>Total </span>
                                                        <span>
                                                            Ksh.{' '}
                                                            {discount
                                                                ? getTotalCost() -
                                                                discount
                                                                : getTotalCost()}
                                                        </span>
                                                    </li>
                                                </StyledSummaryList>

                                                <StyledCheckoutButton
                                                    form="checkout"
                                                    disabled={
                                                        loadingOrder ||
                                                        (paymentDetails.paymentMethod ===
                                                            'lipalater' &&
                                                            !lipaLaterDetails.limitAuthorized) ||
                                                        (paymentDetails.paymentMethod ===
                                                            'lipalater' &&
                                                            Number(
                                                                clientDetails?.available_limit
                                                            ) <
                                                            getTotalCost()) ||
                                                        (paymentDetails.paymentMethod ===
                                                            'cash' &&
                                                            !cashDetails.active)
                                                    }
                                                    type="submit"
                                                    bgColor={
                                                        loadingOrder ||
                                                            (paymentDetails.paymentMethod ===
                                                                'lipalater' &&
                                                                !lipaLaterDetails.limitAuthorized) ||
                                                            (paymentDetails.paymentMethod ===
                                                                'lipalater' &&
                                                                Number(
                                                                    clientDetails?.available_limit
                                                                ) <
                                                                getTotalCost()) ||
                                                            (paymentDetails.paymentMethod ===
                                                                'cash' &&
                                                                !cashDetails.active)
                                                            ? 'gainsboro'
                                                            : '#f68937'
                                                    }
                                                >
                                                    {loadingOrder ? (
                                                        <StyledLoader
                                                            size={18}
                                                            thickness={5}
                                                            className="mr-1"
                                                        />
                                                    )  : (
                                                        'Place Order'
                                                    )}
                                                </StyledCheckoutButton>

                                                <small
                                                    className="mx-0 "
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    By clicking the button, an
                                                    STK Push will be sent to
                                                    your phone to complete
                                                    payment
                                                </small>
                                            </StyledSummaryCardFooter>
                                        )}
                                        <p className="shipment-disclaimer">
                                            All Orders Within Nairobi Will Be
                                            Delivered
                                            <br /> Within 24 Hours
                                        </p>
                                    </StyledSummaryCard>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </ValidatorForm>
                    <Hidden mdUp>
                        {user? 
                        <StyledCheckoutButton
                            form="checkout"
                            disabled={
                                loadingOrder ||
                                (paymentDetails.paymentMethod === 'lipalater' &&
                                    !lipaLaterDetails.limitAuthorized) ||
                                (paymentDetails.paymentMethod === 'cash' &&
                                    !cashDetails.active)
                            }
                            type="submit"
                            mobile={true}
                            bgColor={
                                loadingOrder ||
                                    (paymentDetails.paymentMethod === 'lipalater' &&
                                        !lipaLaterDetails.limitAuthorized) ||
                                    (paymentDetails.paymentMethod === 'cash' &&
                                        !cashDetails.active)
                                    ? 'gainsboro'
                                    : '#f68937'
                            }
                        >
                            {loadingOrder ? (
                                <StyledLoader
                                    size={18}
                                    thickness={5}
                                    className="mr-1"
                                />
                            ) : (
                                'Place Order'
                            )}
                        </StyledCheckoutButton> :window._toggleDrawer(`/ke/ecommerce/checkout`)}

                        {paymentDetails.paymentMethod === 'mpesa' && (
                            <div className="px-3">
                                <small className="mx-0 text-center ">
                                    By clicking the button, an STK Push will be
                                    sent to your phone to complete payment
                                </small>
                            </div>
                        )}
                    </Hidden>
                </StyledCheckoutWrapper>
            )}
        </EcommerceLayout>
    )
}

export default CheckoutPage
