export const titleCase = (str = "") => {

    try{
        return str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
    }
    catch(e){
        console.log(e);
        return ''
    }
}

export const replaceWhiteSpaceWithHyphen=(str)=>{
    let tempStr = String(str)
    return tempStr.replace(/\s+/g, '-').toLowerCase()
}