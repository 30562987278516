import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import styled from 'styled-components'
import {
    MatxLoading,
    MatxSnackbar,
    SimpleCard,
    MatxListItem2,
} from 'app/components'
import RepairWrapper from 'app/components/MatxLayout/RepairLayoutWrapper2'

import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import useAuth from 'app/hooks/useAuth'
import { withStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'

import AuthGuard from 'app/auth/AuthGuard'

import {
    ImageWrapper,
    StyledGrid,
    RepairIconWrapper,
    StyledContainer,
    GreyStyledCard,
    StyledSwitch,
    NoCalculationsCard,
} from 'app/styles/deviceDetails.style'
import { makeStyles } from '@material-ui/core/styles'
import { banks, summary } from './staticData'
import clsx from 'clsx'

import {
    phoneSummaryQuestions,
    functionalPhysicalQuestions,
    functionalScreenQuestions,
    originalItems,
} from 'app/views/SellYourDevice/sellYourDeviceService'
import { makeHttpRequest } from 'utils/api_utils'
import { useLocation } from 'react-router-dom'
import RoomIcon from '@material-ui/icons/Room';
import ScheduleIcon from '@material-ui/icons/Schedule';
import StoreIcon from '@material-ui/icons/Store';

const PaymentWrapper = styled(GreyStyledCard)`
    border: 0;
    height:auto;
    
    
`

const DeviceSummary = ({ items = [] }) => {
    return (
        <SimpleCard title={<b>Defects Summary</b>}>
            <MatxListItem2 listItems={mapQuestions(items)} />
        </SimpleCard>
    )
}

const useStyles = makeStyles(({ palette, ...theme }) => ({
    root: {
        fontFamily: 'lato',
        alert: {
            width: '80%',
            marginBottom: '1.2em',

            '& a': {
                textDecoration: 'underline',
                color: '#42c8b7',
            },
        },
        '& .modalIcons': {
            color: '#42c8b7',
        },
        '& .MuiSwitch-colorSecondary.Mui-checked': {
            color: '#42c8b7',
        },
        '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track ': {
            backgroundColor: '#42c8b7',
        },
    },
}))

const mapQuestions = (data) => {
    const all = [
        ...phoneSummaryQuestions,
        ...functionalPhysicalQuestions,
        ...functionalScreenQuestions,
        ...originalItems,
    ]

    const summary = []

    Object.entries(data).map(([key, value]) => {
        if (value < 1) {
            switch (key) {
                case 'workingPhone':
                    summary.push({ title: 'cannot make calls' })
                    break
                case 'deviceOn':
                    summary.push({ title: 'can turn on/off' })
                    break
                case 'reciept':
                    summary.push({ title: 'receipt present' })
                    break
                case 'cable':
                case 'charger':
                case 'warranty':
                case 'earphones':
                case 'box':
                    summary.push({ title: `${key} present` })
                    break
                default:
                    return
            }
        } else
            all.map((item) => {
                const { title, variableName, icon } = item

                if (key === variableName) {
                    switch (variableName) {
                        case 'workingPhone':
                            summary.push({ title: item.evaluation.yes })
                            break
                        case 'deviceOn':
                            summary.push({ title: item.evaluation.no })
                            break
                        case 'charger':
                        case 'reciept':
                        case 'warranty':
                        case 'box':
                        case 'pen':
                        case 'earphones':
                            summary.push({ title: `no ${key}` })
                            break
                        default:
                            summary.push({ title, icon })
                    }
                }
                return
            })

        return
    })

    return summary

    /*
    // map functional defects
    Object.entries(data).map(([key, value]) => {
      functional_defects.map((item) => {
        const { description, image, name } = item;
        if (key ==== name) {
          let tempItem = {
            description,
            image,
            checked: value >= 1,
            name,
          };
          f_Defects.push(tempItem);
        }
      });
    });
    setFunctionalDefects(f_Defects);
    // map physical defects
    Object.entries(data).map(([key, value]) => {
      physical_defects.map((item) => {
        const { description, image, name } = item;
        if (key ==== name) {
          let tempItem = {
            description,
            image,
            checked: value >= 1,
            name,
          };
          p_Defects.push(tempItem);
        }
      });
    });
    setPhysicalDefects(p_Defects);
    // map extras
    Object.entries(data).map(([key, value]) => {
      extras.map((item) => {
        const { description, image, name } = item;
        if (key ==== name) {
          let tempItem = {
            description,
            image,
            checked: value === 0,
            name,
          };
          ext.push(tempItem);
        }
      });
    });
    setExtra(ext);
    */
}

function DeviceDetailsComponent({ _id }) {
    const { user } = useAuth()
    const classes = useStyles()
    const {
        state: { repairProId },
    } = useLocation()

    const [summary, setSummary] = useState(null)
    const [loading, setLoading] = useState(false)

    const [message, setMessage] = useState({
        msg: '',
        severity: '',
    })

    const [showSnackBar, setShowSnackBar] = useState(false)

    const closeSnackbar = () => {
        setShowSnackBar(false)
    }
  

    const getSummary = async () => {

        setLoading(true)
        try {
            const { success, devices } = await makeHttpRequest(
                `get_single_repair_pro_requests/${repairProId}`,
                'GET'
            )
            if (success) {
                setSummary(devices[0])
                setLoading(false)
            } else {
                setLoading(false)
            }
        } catch (err) {
            setLoading(false)
        }
    }

    useEffect(() => {
        getSummary()
    }, [user])

    return (
        <React.Fragment>
            {loading && !summary ? (
                <div style={{ minHeight: '100vh' }}>
                    <MatxLoading />
                </div>
            ) : (
                <RepairWrapper>
                    <MatxSnackbar
                        open={showSnackBar}
                        message={message.msg}
                        type={message.severity}
                        handleClose={closeSnackbar}
                    />

                    <StyledContainer maxWidth={false} className={classes.root}>
                        <Grid container spacing={2}>
                            <Grid item md={8}>
                                <SimpleCard>
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            md={5}
                                            style={{
                                                
                                            }}
                                        >
                                            <ImageWrapper>
                                                <img
                                                    src={summary?.image}
                                                    alt="device"
                                                />
                                            </ImageWrapper>

                                            <RepairIconWrapper style={{ marginTop:'40px'}}>
                                                <div style={{ verticalAlign: 'middle' ,margin:'0px' }}>
                                                    <img
                                                        src="/assets/images/repair/assured-warranty.png"
                                                        alt="device"
                                                        style={{ verticalAlign: 'middle'  }}
                                                    />
                                                    <span style={{ verticalAlign: 'middle' }}> Assured Warranty </span>
                                                </div>

                                                <div style={{ verticalAlign: 'middle' }}>
                                                    <img
                                                        src="/assets/images/repair/certified-parts.png"
                                                        alt="device"
                                                        style={{ verticalAlign: 'middle' }}
                                                    />
                                                    <span style={{ verticalAlign: 'middle' }}>Certified Parts</span>
                                                </div>
                                                <div style={{ verticalAlign: 'middle' }}>
                                                    <img
                                                        src="/assets/images/repair/money-back.png"
                                                        alt="device"
                                                        style={{ verticalAlign: 'middle' }}
                                                    />
                                                    <span style={{ verticalAlign: 'middle' }}>
                                                        7 Day Money Back
                                                        Guarantee
                                                    </span>
                                                </div>
                                            </RepairIconWrapper>
                                        </Grid>
                                        <StyledGrid item md={7}>
                                            {/* <p>
                                                Your repair ticket number is{' '}
                                                <span
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    R
                                                    {summary?.variant
                                                        ?.orderNumber +
                                                        summary?.variant?.country.toUpperCase()}
                                                </span>
                                            </p> */}
                                            <h2>
                                                {
                                                    summary?.variant?.model
                                                        ?.modelName
                                                }
                                                -{summary?.variant?.color?.name}
                                            </h2>
                                            <span>
                                                <p>Estimated Repair Cost</p>
                                                <h3 className="mb-6">
                                                    {summary?.variant
                                                        ?.currency +
                                                        ' ' +
                                                        summary?.variant?.cost?.toLocaleString(
                                                            'en-US'
                                                        )}
                                                </h3>
                                            </span>

                                            {/* <Button
                                                className="px-7"
                                                variant="contained"
                                                color="primary"
                                                disabled={
                                                    handleDisabled() ||
                                                    userDetails?.details
                                                        ?.calculation
                                                        ?.valueToShowCustomer <=
                                                        0
                                                }
                                                onClick={() => getPaid()}
                                            >
                                           
                                                Checkout
                                            </Button> */}
                                            
                                            
                                            <Alert
                                                severity="info"
                                                className={classes.alert}
                                            >
                                                <AlertTitle>
                                                    Thank you for choosing Badili 
                                                     <p>
                                                Your repair ticket number is{' '}
                                                <span
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    R
                                                    {summary?.variant
                                                        ?.orderNumber +
                                                        summary?.variant?.country.toUpperCase()}
                                                </span>
                                            </p>
                                                </AlertTitle>
                                                {/* {`Kindly visit ${summary?.variant?.shop?.name} from Monday - Saturday, 9.00am-6.00pm for a comprehensive diagnosis of your phone`} */}
                                            </Alert>

                                            {/* {showStatus(userDetails?.details)} */}
                                            <RepairIconWrapper>
                                                <div style={{verticalAlign:'middle'}}>
                                                    
                                                    <RoomIcon fontSize='medium' style={{ verticalAlign: 'middle' , marginBottom:'12px'}}/>
                                                    <span style={{marginLeft:'10px',verticalAlign:'middle'}}>
                                                    {summary?.variant?.shop?.location}
                                                    </span>
                                                </div>
                                                <div style={{verticalAlign:'middle'}}>
                                                    <StoreIcon fontSize='medium' style={{ verticalAlign: 'middle' , marginBottom:'12px' }}/>
                                                    <span style={{marginLeft:'10px',verticalAlign:'middle'}}>
                                                        {summary?.variant?.shop?.name}
                                                    </span>
                                                </div>
                                                <div style={{verticalAlign:'middle'}}>
                                                    <ScheduleIcon fontSize='medium' style={{ verticalAlign: 'middle' , marginBottom:'12px' }} />

                                                    <span style={{marginLeft:'10px',verticalAlign:'middle'}}>
                                                    {summary?.variant?.shop?.openingHours}
                                                    </span>
                                                </div>


                                            </RepairIconWrapper>


                                            <PaymentWrapper style={{}}>
                                                <h6>Payment Options</h6>
                                                <ul>
                                                    <li
                                                        style={{
                                                            backgroundImage: `url(${process.env
                                                                    .PUBLIC_URL +
                                                                '/assets/images/payment-methods/mpesa.png'
                                                                })`
                                                        }}
                                                    >

                                                    </li>
                                                    <li
                                                        style={{
                                                            backgroundImage: `url(${
                                                                process.env
                                                                    .PUBLIC_URL +
                                                                '/assets/images/payment-methods/wallet_icon-01.png'
                                                            })`
                                                        }}
                                                    ></li>
                                                    {/* <li
                                                        style={{
                                                            backgroundImage: `url(${
                                                                process.env
                                                                    .PUBLIC_URL +
                                                                '/assets/images/payment-methods/visa.png'
                                                            })`,
                                                        }}
                                                    ></li>
                                                    <li
                                                        style={{
                                                            backgroundImage: `url(${
                                                                process.env
                                                                    .PUBLIC_URL +
                                                                '/assets/images/payment-methods/master-card.png'
                                                            })`,
                                                        }}
                                                    ></li> */}
                                                </ul>
                                            </PaymentWrapper>




                                        </StyledGrid>
                                    </Grid>
                                </SimpleCard>
                            </Grid>
                            <Grid item md={4}>
                                {/* <SimpleCard title="Price Summary">
                                            <MatxListItem1
                                                listItems={priceSummary}
                                                hasButton={false}
                                                btnText="Schedule Pick up"
                                                btnIcon="->"
                                                actionFn={getPaid}
                                            />
                                        </SimpleCard> */}
                                <DeviceSummary
                                    items={summary?.variant.deductions}
                                />

                                {/* <PaymentWrapper>
                                            <h6>Payment Options</h6>
                                            <ul>
                                                <li
                                                    style={{
                                                        backgroundImage: `url(${
                                                            process.env.PUBLIC_URL +
                                                            '/assets/images/payment-methods/mpesa.png'
                                                        })`,
                                                    }}
                                                ></li>
                                                <li
                                                    style={{
                                                        backgroundImage: `url(${
                                                            process.env.PUBLIC_URL +
                                                            '/assets/images/payment-methods/visa.png'
                                                        })`,
                                                    }}
                                                ></li>
                                                <li
                                                    style={{
                                                        backgroundImage: `url(${
                                                            process.env.PUBLIC_URL +
                                                            '/assets/images/payment-methods/airtel.png'
                                                        })`,
                                                    }}
                                                ></li>
                                            </ul>
                                        </PaymentWrapper> */}
                            </Grid>
                        </Grid>
                    </StyledContainer>
                </RepairWrapper>
            )}
        </React.Fragment>
    )
}

const DeviceDetails = (props) => (
    <AuthGuard>
        <DeviceDetailsComponent _id={props.match.params._id} />
    </AuthGuard>
)

export default DeviceDetails
