import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(() => ({
    loading: {
        position: 'fixed',
        left: 0,
        right: 0,
        top: 'calc(50% - 20px)',
        margin: 'auto',
        '& img': {
            position: 'absolute',
            height: '48px',
            width: 'auto',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
        },
    },
    loadingMini: {       
        margin: 'auto',        
        '& img': {
            height: '32px',
            width: 'auto',
            margin: 'auto',
        },
    },
}))

const Loading = () => {
    const classes = useStyles()

    return (
        <div className={classes.loading}>
           <img src="/assets/images/loader.gif" alt="" /> 
            {/* <CircularProgress /> */}
        </div>
    )
}
export const LoadingMini = () => {
    const classes = useStyles()

    return (
        <div className={classes.loadingMini}>
           <img src="/assets/images/loader.gif" alt="" /> 
            {/* <CircularProgress /> */}
        </div>
    )
}

export default Loading
