import React from 'react'
const country = localStorage.getItem('country')

const profileRoutes = [
    {
        path: `/${country}/profile`,
        component: React.lazy(() => import('./Profile')),
    },
]

export default profileRoutes
