import React, { useState, useEffect, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import { useHistory, useLocation } from 'react-router-dom'
import { devices } from '../../../utils/screen_sizes'
import { makeStyles } from '@material-ui/core/styles'
import { Card, Container } from '@material-ui/core'
import styled from 'styled-components'
import { getFeatured, getDeals } from '../../services/ecommerceService'
import { MatxLoading, MatxSnackbar } from 'app/components'
import EcommerceDetailsLayout from 'app/components/MatxLayout/EcommerceDetailsLayout'
import { getInventoryItem } from 'app/redux/actions/EcommerceActions'
import { useDidMount } from 'app/hooks/useDidMount'
import { AppContext } from 'app/contexts/AppContext'
import { getCountryFromUrl } from 'utils/CountryUtils'

const StyledShopWrapper = styled.div`
    @media ${devices.laptop} {
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
    }

    @media ${devices.desktopL} {
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
    }
`

const StyledContentBox = styled.div`
    width: 100%;
    background: rgb(230, 231, 232);
    border-radius: 5px;
    padding: 1rem;
    cursor: pointer;

    text-align: right;
    img {
        max-height: 220px;
        object-fit: contain;
        width: 100%;
    }
    p {
        color: #f68937;
        font-weight: 600;
    }
`
const StyledRightGrid = styled(Grid)`
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const StyledProductCard = styled(Card)`
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1rem;
    margin-top: 5px;
    box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);

    :hover {
        cursor: pointer;
    }

    .bestseller {
        background-color: rgb(230, 231, 232);
        padding: 2px 1rem;
        border-radius: 0 0 4px 0;
        font-size: 12px;
        font-weight: 600;
        position: absolute;
        top: 0px;
        left: 0px;
    }
    img {
        object-fit: contain;
        width: 100%;
        max-height: 130px;
        /* margin-top: 40px; */
    }
    h4 {
        margin-top: 1.2rem;
        font-size: 1rem;
    }
    p {
        margin: 0;
        font-size: 13px;
        :last-child {
            color: #777;
            margin-top: 1rem;
            span {
                text-decoration: line-through;
                margin-right: 10px;
            }
            b {
                color: #000;
                font-weight: 600;
            }
        }
    }
`

const StyledTitle = styled.h4` 
    font-size:1.5rem;
    font-weight: 800;
    color:#000;
    margin 0;
    font-family:'open-sans';

    span{
        color:#F68937;
        
    }
    p{
font-size: 1.1rem;
margin: .5rem 0 1rem 0;
 font-weight: 500;

    }
`
const StyledSectionTitle = styled.h4`
    font-size: 1.3rem;
    font-weight: 300;
    color: #000;
    text-align: center;
    margin: 3rem 0 2rem 0;
    font-family: 'open-sans';
`

const SmartDealsSection = styled(Grid)`
    color: #fff;
    h3 {
        margin: 0;
    }
    h4 {
        font-family: 'open-sans';
        font-weight: 600;
    }
`
const StyledDealImage = styled.img`
    object-fit: contain;
    width: 100%;
    /* max-height: 200px; //medium screen */
    max-height: 130px;
    @media ${devices.mobileS} {
        max-height: 85px;
    }
    @media ${devices.laptop} {
        max-height: 100px;
    }
`
export const StyledContainer = styled(Container)`
    margin: 20px 0;
    width: 100%;

    .px-7 {
        margin-bottom: 2em;
    }

    @media ${devices.laptop} {
        width: 90vw;
        margin: 20px auto;
    }

    @media ${devices.desktop} {
        width: 70vw;
    }
`
export const NoCalculationsCard = styled(Card)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 80vh;
`

export const StyledSlide = styled.div`
    display: flex;
    justify-content: center;
    background-color: #fff;

    .smooth-image-wrapper {
        width: 100%;
    }

    a {
        position: absolute;
        z-index: 1;
        color: #fff;
        bottom: 2em;
        left: 4em;
        font-size: 0.8rem;
    }

    @media ${devices.mobileL} {
        a {
            font-size: 1rem;
        }
    }
    @media ${devices.tablet} {
        a {
            font-size: 1rem;
            left: 8em;
        }
    }
    @media ${devices.laptop} {
        a {
            left: unset;
            bottom: 4em;
        }
    }
    @media ${devices.desktop} {
        a {
            left: unset;
            bottom: 5em;
        }
    }
`

const StyledDealsDescription = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    height: 100%;
    margin-left: 3rem;
    h3 {
        font-family: 'open-sans';
        font-weight: 600;
        font-size: 1.3rem;
    }
    @media ${devices.mobileS} {
        margin-left: 1rem;
        h3 {
            font-size: 1.1rem;
        }
    }
    @media ${devices.laptopL} {
        margin-left: 2.15rem;
    }
`

const useeStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}))

const ShopDetailsPage = (props) => {
    const { pathname } = useLocation()
    let country = getCountryFromUrl(pathname)||"ke"
    const [loading, setLoading] = useState(false)
    const [_modelName, _setModelName] = useState('')
    const [_variants, setVariants] = useState([])
    const [featuredDevices, setFeaturedDevices] = useState([])
    const [gallery, setGallery] = useState([])
    const [selectedVariant, selectVariant] = useState({
        price: 0,
        discounted: 0,
        saleprice: 0,
        color: '',
    })
    const [product, setProduct] = useState({})
    const [error, setError] = useState({ status: false, msg: '' })
    const [availableColors, setAvailableColors] = useState([])
    const [pricing, setPricing] = useState({ price: 0, discounted: 0 })
    const [message, setMessage] = useState({
        msg: '',
        severity: '',
    })
    const [showSnackBar, setShowSnackBar] = useState(false)
    const didMount = useDidMount()
    const [loadingQuantity, setLoadingQuantity] = useState(false)
    const [loadingVariants, setLoadingVariants] = useState(false)
    const [smartDeals, setSmartDeals] = useState([])
    const classes = useeStyles()
    const history = useHistory()

    const handleSelectVariant = (el) => {
        selectVariant(el)
        setPricing({
            price: el?.variant?.saleprice,
            discounted: el?.variant?.discounted,
        })
    }

    const closeSnackbar = () => {
        setShowSnackBar(false)
    }

    const fetchFeatured = async () => {
        const {
            data: { devices },
        } = await getFeatured()
        setFeaturedDevices(devices)
    }

    const fetchDeals = async () => {
        const {
            data: { devices },
        } = await getDeals()
        setSmartDeals(devices)
    }

    const getItem = async () => {
        try {
            setLoading(true)
            // const {
            //     data: { success, variants },
            // } = await getVariants(props?.location?.state?._id)
            const item = await getInventoryItem(props?.location?.state?._id)

            const { gallery, modelName = '', variants } = item

            setGallery(gallery)

            if (modelName) {
                _setModelName(modelName)
            }

            if (variants && variants.length > 0) {
                let sortedVar = variants.sort(function (a, b) {
                    return (
                        parseFloat(a.variant.storage) -
                        parseFloat(b.variant.storage)
                    )
                })

                selectVariant(
                    // variants.find(
                    //     (el) =>
                    //         el?.variant?._id ===
                    //         props?.location?.state?.variantId
                    // ) || //get clicked variant
                    sortedVar.find((el) => el?.instock) || //get variant which is in stock
                        variants[0] //otherwise get first variant
                )

                setVariants(variants)

                const [
                    {
                        variant: { discounted, saleprice },
                    },
                ] = variants

                setPricing({ saleprice, discounted })
            }

            setProduct(item)
        } catch (error) {
            console.log(error)

            setMessage({
                msg: error.message,
                severity: 'error',
            })
            setError({
                status: true,
                msg: 'could not fetch item',
            })
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        fetchDeals()
        fetchFeatured()
    }, [])
    useEffect(() => {
        if (props?.location?.state?._id) {
            window.scrollTo(0, 0)
            getItem()
        } else {
            history.push(`/${country}/ecommerce/shop`)
        }
    }, [props?.location?.state?._id])

    // useEffect(() => {
    //     if (didMount) {
    //         return
    //     } else {
    //         getQuantity()
    //     }
    // }, [selectedVariant])

    return (
        <>
            {((loading && !message.msg) || !product) && (
                <div style={{ minHeight: '100vh' }}>
                    <MatxLoading />
                </div>
            )}
            {!loading && !message.msg && product && (
                <EcommerceDetailsLayout
                    id={props?.location?.state?._id}
                    selectedVariant={selectedVariant}
                    selectVariant={selectVariant}
                    handleSelectVariant={handleSelectVariant}
                    setPricing={setPricing}
                    product={product}
                    _modelName={_modelName}
                    _variants={_variants}
                    gallery={gallery}
                    availableVariants={[..._variants].sort(
                        (a, b) => a?.variant?.storage - b?.variant?.storage
                    )}
                    setVariants={setVariants}
                    setLoadingVariants={setLoadingVariants}
                    selectedVariantId={props?.location?.state?.variantId}
                >
                    <StyledShopWrapper className="shop">
                        {/* <StyledPriceSection>
                    <StyledFlexedSection>
                        <div>
                            {' '}
                            <h3>
                                <span>38,000</span>Ksh. 25,000
                            </h3>
                            <p>Save Ksh. 13,000</p>
                        </div>
                        <StyledPriceImage
                            src="/assets/images/ecommerce-icons/off-price.svg"
                            alt="icon"
                        />
                    </StyledFlexedSection>

                    <div className="btn-wrapper">
                        {' '}
                        <button class="add-cart">
                            <span>Add To Cart</span>
                        </button>
                        <button className="buy-now">
                            <span>Buy Now</span>
                        </button>
                    </div>

                    <h5>Free delivery: 26 Jun- 28 Jun</h5>
                </StyledPriceSection> */}
                        {/* {smartDeals && smartDeals.length > 3 && (
                            <>
                                <StyledSectionTitle>
                                    {' '}
                                    SmartPhone Deals
                                </StyledSectionTitle>
                                <Grid container spacing={3}>
                                    {smartDeals
                                        .reverse()
                                        .slice(-2)
                                        .map((el, i) => (
                                            <Grid item xs={12} sm={6} key={i}>
                                                {' '}
                                                <StyledContentBox
                                                    onClick={() =>
                                                        history.push({
                                                            pathname: `/ecommerce/details/${replaceWhiteSpaceWithHyphen(
                                                                el?.variant
                                                                    ?.model
                                                                    ?.modelName
                                                            )}`,
                                                            state: {
                                                                _id: el?.variant
                                                                    ?.model
                                                                    ?._id,
                                                                variantId:
                                                                    el?.variant
                                                                        ?._id,
                                                            },
                                                        })
                                                    }
                                                >
                                                    <Grid container spacing={3}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={4}
                                                        >
                                                            <img
                                                                src={el?.image}
                                                                alt=""
                                                            />
                                                        </Grid>
                                                        <StyledRightGrid
                                                            item
                                                            xs={12}
                                                            sm={8}
                                                        >
                                                            {' '}
                                                            <StyledTitle>
                                                                {`Buy ${el?.variant?.model?.modelName}
                                                                Now!!`}
                                                            </StyledTitle>
                                                            <p>
                                                                {`Get upto 50% off
                                                                on the ${el?.variant?.model?.modelName} for `}{' '}
                                                                <b>
                                                                    {formatCurrency(
                                                                        el
                                                                            ?.variant
                                                                            ?.discounted
                                                                    )}
                                                                </b>
                                                            </p>
                                                        </StyledRightGrid>
                                                    </Grid>
                                                </StyledContentBox>
                                            </Grid>
                                        ))}
                                </Grid>
                            </>
                        )} */}
                        {/* <StyledSectionTitle>
                            {' '}
                            Refurbished Fair
                        </StyledSectionTitle>

                        <Slider>
                            {featuredDevices.reverse().map((el, i) => (
                                <StyledProductCard
                                    key={i}
                                    onClick={() =>
                                        history.push({
                                            pathname: `/ecommerce/details/${replaceWhiteSpaceWithHyphen(
                                                el.details?.modelName
                                            )}`,
                                            state: {
                                                _id: el.details._id,
                                                variantId:
                                                    el.details.priorityVariant
                                                        ._id,
                                            },
                                        })
                                    }
                                >
                                    {' '}
                                    <div className="bestseller">bestseller</div>
                                    <img
                                        src={el.image}
                                        alt="iphone"
                                        style={{ marginTop: '40px' }}
                                    />
                                    <h4> {el.details?.modelName}</h4>
                                    <p>
                                        {' '}
                                        {`${el.details?.priorityVariant?.ram}GB | ${el.details?.priorityVariant?.storage}GB - `}
                                        Unlocked
                                    </p>
                                    <p>Warranty : 12months</p>
                                    <p>
                                        Starting from
                                        <br />
                                        <span>
                                            Ksh{' '}
                                            {el.details?.priorityVariant?.saleprice?.toLocaleString()}
                                        </span>
                                        <b>
                                            Ksh{' '}
                                            {el.details?.priorityVariant?.discounted?.toLocaleString()}
                                        </b>
                                    </p>
                                </StyledProductCard>
                            ))}
                        </Slider> */}

                        <StyledSectionTitle>Badili Trust</StyledSectionTitle>
                        <div className={classes.root}>
                            <SmartDealsSection container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <div
                                        style={{
                                            background: 'rgb(165,211,154)',
                                            padding: '1.2rem',
                                        }}
                                    >
                                        <Grid container spacing={3}>
                                            <Grid item sm={5} xs={5} md={4}>
                                                <StyledDealImage
                                                    src="/assets/images/ecommerce-icons/12-months.svg"
                                                    alt="icon"
                                                />
                                            </Grid>
                                            <Grid item sm={7} xs={7} md={8}>
                                                <StyledDealsDescription>
                                                    <h3>12 Months Warranty</h3>
                                                </StyledDealsDescription>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div
                                        style={{
                                            background: 'rgb(234,33,76)',
                                            padding: '1.2rem',
                                        }}
                                    >
                                        <Grid container spacing={3}>
                                            <Grid item sm={5} xs={5} md={4}>
                                                <StyledDealImage
                                                    src="/assets/images/ecommerce-icons/off-price.svg"
                                                    alt="icon"
                                                />
                                            </Grid>
                                            <Grid item sm={7} xs={7} md={8}>
                                                <StyledDealsDescription>
                                                    {' '}
                                                    <h3>
                                                        For 50% Off The Price
                                                    </h3>
                                                </StyledDealsDescription>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div
                                        style={{
                                            background: 'rgb(21,70,106)',
                                            padding: '1.2rem',
                                        }}
                                    >
                                        <Grid container spacing={3}>
                                            <Grid item sm={5} xs={5} md={4}>
                                                <StyledDealImage
                                                    src="/assets/images/ecommerce-icons/certified-quality.svg"
                                                    alt="icon"
                                                />
                                            </Grid>
                                            <Grid item sm={7} xs={7} md={8}>
                                                <StyledDealsDescription>
                                                    {' '}
                                                    <h3>Certified Quality</h3>
                                                </StyledDealsDescription>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div
                                        style={{
                                            background: 'rgb(113,204,222)',
                                            padding: '1.2rem',
                                        }}
                                    >
                                        <Grid container>
                                            <Grid item sm={5} xs={5} md={4}>
                                                <StyledDealImage
                                                    src="/assets/images/ecommerce-icons/as-good.svg"
                                                    alt="icon"
                                                />
                                            </Grid>
                                            <Grid item sm={7} xs={7} md={8}>
                                                <StyledDealsDescription>
                                                    {' '}
                                                    <h3>As Good As New</h3>
                                                </StyledDealsDescription>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </SmartDealsSection>
                        </div>
                        {/* <StyledSectionTitle>Highlights</StyledSectionTitle>
                <StyledHighlightsSection>
                    <ul>
                        <li>5.8 inches OLED Screen</li>
                        <li>Apple A11 Bionic Processor</li>
                        <li>2716 mAh Battery</li>
                        <li>12MP + 12MP Rear Camera</li>
                        <li>7MP Front Camera</li>
                    </ul>
                </StyledHighlightsSection> */}
                    </StyledShopWrapper>
                </EcommerceDetailsLayout>
            )}

            {!loading && error.status && (
                <>
                    <MatxSnackbar
                        open={showSnackBar}
                        message={message.msg}
                        type={message.severity}
                        handleClose={closeSnackbar}
                    />
                    <StyledContainer maxWidth={false} className={classes.root}>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <NoCalculationsCard>
                                    <h5>
                                        Oops! Looks An error occurred, try again
                                        later
                                    </h5>
                                </NoCalculationsCard>
                            </Grid>
                        </Grid>
                    </StyledContainer>
                </>
            )}
        </>
    )
}

export default ShopDetailsPage
