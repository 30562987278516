import React, { Fragment, useContext, useState } from 'react'
import { Icon, Badge, IconButton, Drawer, Button } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import {
    getCartList,
    deleteProductFromCart,
    updateCartQuantity,
} from 'app/redux/actions/EcommerceActions'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useSettings from 'app/hooks/useSettings'
import useAuth from 'app/hooks/useAuth'
import styled from 'styled-components'

import { StyledHorizontalLine } from '../../styles/product-detail.style'

import { handleFetchNewImageUrl } from 'app/services/appService'

import { StyledShoppingCartImage } from '../../styles/checkout.style'
import { ContinuousColorLegend } from 'react-vis'
import { AppContext } from 'app/contexts/AppContext'
import { getCountryFromUrl } from 'utils/CountryUtils'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    width: '320px',
    miniCart: {
        width: 'var(--sidenav-width)',
        '& .cart__topbar': {
            height: 'var(--topbar-height)',
        },
        '& .mini-cart__item': {
            transition: 'background 300ms ease',
            '&:hover': {
                background: 'transparent',
            },
        },
    },
}))

const StyledModelName = styled.h6`
    width: 180px;
`

const StyledCartItem = styled.div`
    border-bottom: 1px solid #eee;
    font-family: lato;
`

const StyledCallToAction = styled.div`
    display: flex;
    align-items: center;
    margin: auto;
    margin: 2rem auto;
`

let cartListLoaded = false

function ShoppingCart({ container, cartcolor, label }) {
    const { pathname } = useLocation()
    let country = getCountryFromUrl(pathname)||"ke"
    const [totalCost, setTotalCost] = useState(0)
    const [panelOpen, setPanelOpen] = useState(false)

    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const { user } = useAuth()
    const { cartList } = useSelector((state) => state.ecommerce)
    const { settings } = useSettings()

    if (!cartListLoaded) {
        dispatch(getCartList())
        cartListLoaded = true
    }

    const handleDrawerToggle = () => {
        setPanelOpen(!panelOpen)
    }

    const handleCheckoutClick = () => {
        if (user && cartList.length > 0) {
            setPanelOpen(false)
            history.push(`/${country}/ecommerce/checkout`)
        } else {
            setPanelOpen(false)
            window._toggleDrawer(`/${country}/ecommerce/checkout`)
        }
    }

    useEffect(() => {
        let total = 0

        cartList.forEach((el) => {
            total += el?.variant?.price * el?.variant?.quantity
        })

        setTotalCost(total)
    }, [cartList])

    useEffect(() => {
        window._toggleShoppingCart = handleDrawerToggle
    }, [])

    return (
        <Fragment>
            <IconButton
                style={{ backgroundColor: 'transparent' }}
                onClick={handleDrawerToggle}
                disableRipple={true}
                disableFocusRipple={true}
            >
                <Badge color="secondary" badgeContent={cartList.length}>
                    <Icon style={{ color: cartcolor ? cartcolor : '#fff' }}>
                        shopping_cart
                    </Icon>
                </Badge>
            </IconButton>
            {label ? (
                <span
                    style={{
                        fontFamily: 'lato',
                        fontSize: '13px',
                        opacity: '.85',
                    }}
                >
                    Cart
                </span>
            ) : null}

            <ThemeProvider theme={settings.themes[settings.activeTheme]}>
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor={'right'}
                    open={panelOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                >
                    <div
                        className={clsx(
                            'flex-column h-full ja',
                            classes.miniCart
                        )}
                        style={{ fontFamily: 'Lato !important' }}
                    >
                        <div className="cart__topbar elevation-z6 flex items-center p-1 mb-2 pl-4">
                            <Icon color="primary" style={{ color: '#0C8085' }}>
                                shopping_cart
                            </Icon>
                            <h5 className="ml-2 my-0 font-medium">Cart</h5>
                        </div>

                        <div className="overflow-auto">
                            {cartList.map(
                                (
                                    {
                                        modelName,
                                        modelId,
                                        modelImage,
                                        variant,
                                        media,
                                    },
                                    index
                                ) => {
                                    return (
                                        <StyledCartItem
                                            key={`${index}${variant?._id}`}
                                            className="mini-cart__item flex items-center py-2 px-2"
                                        >
                                            <div className="flex flex-column mr-1">
                                                <IconButton
                                                    size="small"
                                                    onClick={() =>
                                                        dispatch(
                                                            updateCartQuantity(
                                                                cartList,
                                                                modelId,
                                                                variant,
                                                                variant?.quantity +
                                                                    1,
                                                                variant?.color
                                                            )
                                                        )
                                                    }
                                                >
                                                    <Icon className="cursor-pointer">
                                                        keyboard_arrow_up
                                                    </Icon>
                                                </IconButton>
                                                <IconButton
                                                    disabled={
                                                        !(variant?.quantity - 1)
                                                    }
                                                    size="small"
                                                    onClick={() =>
                                                        dispatch(
                                                            updateCartQuantity(
                                                                cartList,
                                                                modelId,
                                                                variant,
                                                                variant?.quantity -
                                                                    1,
                                                                variant?.color
                                                            )
                                                        )
                                                    }
                                                >
                                                    <Icon className="cursor-pointer">
                                                        keyboard_arrow_down
                                                    </Icon>
                                                </IconButton>
                                            </div>
                                            <div className="mr-2 text-center flex-grow flex-column">
                                                <div className="mr-2">
                                                    <StyledShoppingCartImage
                                                        src={modelImage}
                                                        onError={({ target }) =>
                                                            handleFetchNewImageUrl(
                                                                target,
                                                                modelId,
                                                                media
                                                            )
                                                        }
                                                        alt={modelName}
                                                    />
                                                </div>
                                                <StyledModelName className="m-0 mb-1 ellipsis">
                                                    {modelName}
                                                </StyledModelName>
                                                <>
                                                    {variant?.ram}GB|
                                                    {variant?.storage}GB
                                                    {variant?.color &&
                                                    Object.keys(variant?.color)
                                                        .length !== 0
                                                        ? `(${variant?.color?.label})`
                                                        : ''}
                                                </>
                                                <small className="text-muted">
                                                    Ksh{' '}
                                                    {variant &&
                                                        variant?.price &&
                                                        variant?.price?.toLocaleString()}{' '}
                                                    x {variant?.quantity}
                                                </small>
                                            </div>
                                            <IconButton
                                                size="small"
                                                onClick={() =>
                                                    dispatch(
                                                        deleteProductFromCart(
                                                            cartList,
                                                            variant?._id,
                                                            variant?.color
                                                        )
                                                    )
                                                }
                                            >
                                                <Icon fontSize="small">
                                                    clear
                                                </Icon>
                                            </IconButton>
                                        </StyledCartItem>
                                    )
                                }
                            )}
                        </div>

                        <Button
                            className="w-full border-radius-0"
                            variant="contained"
                            color="primary"
                            style={{ backgroundColor: `#0C8085` }}
                            onClick={handleCheckoutClick}
                        >
                            Checkout (Ksh {totalCost.toLocaleString()})
                        </Button>

                        <StyledHorizontalLine />
                        <StyledCallToAction>
                            <a onClick={handleDrawerToggle}>
                                Continue Shopping
                            </a>
                        </StyledCallToAction>
                    </div>
                </Drawer>
            </ThemeProvider>
        </Fragment>
    )
}

export default ShoppingCart
