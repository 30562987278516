import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import {
    Card,
    RadioGroup,
    FormControl,
    FormControlLabel,
    Radio,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { devices } from 'utils/screen_sizes'
import { RepairContext } from 'app/contexts/RepairContext'

export const StyledAddressOption = styled(Card)`
    padding: 1rem;
    box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
    margin: 2rem 0;
    cursor: pointer;
`

export const StyledContentSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    /* margin-left: 30px; */

    @media ${devices.mobileS} {
        display: block;
    }
    @media ${devices.laptop} {
        display: flex;
    }

    div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
       
        img {
            max-height: 70px;
            /* margin-right: 1rem; */
        }
        p {
            flex: 1;
            color: #777;
            font-size: 13px;
            margin: 0;
            text-transform: capitalize;
        }
    }
`

const PurpleRadio = withStyles({
    root: {
        color: 'rgb(111,101,173)',
        '&$checked': 'rgb(111,101,173)',
    },

    checked: {},
})((props) => {
    return <Radio color="default" size="small" {...props} />
})

const AddressCard = () => {
    const { addressDetails, setAddressDetails } = useContext(RepairContext)

    const handleAddressOption = (e) => {
        e.preventDefault()
        setAddressDetails({
            ...addressDetails,
            option: e.target.value,
            
        })
    }
    return (
        <>
            <StyledAddressOption
                onClick={() =>
                    setAddressDetails({
                        ...addressDetails,
                        option: 'store',
                    })
                }
            >
                <FormControl component="fieldset">
                    <RadioGroup name="deliveryMethod">
                        <FormControlLabel
                            value="end"
                            control={
                                <PurpleRadio
                                    style={{
                                        display: 'block',
                                    }}
                                    checked={addressDetails?.option === 'store'}
                                    onChange={handleAddressOption}
                                    value="store"
                                    name="option"
                                />
                            }
                            label={
                                <>
                                    <span
                                        style={{
                                            fontWeight: 700,
                                            fontSize: '16px',
                                        }}
                                    >
                                        Visit the store to get your device
                                        diagnosed and repaired
                                    </span>
                                </>
                            }
                        />
                    </RadioGroup>
                    <StyledContentSection>
                        <div>
                            <img
                                src="/assets/images/repair/store.png"
                                alt="icon"
                            />
                            <p>Visit the nearby Badili store</p>
                        </div>
                        <div>
                            {' '}
                            <img
                                src="/assets/images/repair/qr_code.png"
                                alt="icon"
                            />
                            <p>
                                Scan the Service code generated when placing the
                                order
                            </p>
                        </div>
                        <div>
                            {' '}
                            <img
                                src="/assets/images/repair/pay.png"
                                alt="icon"
                            />
                            <p>
                                Pay after service at the store (Discount amount)
                            </p>
                        </div>
                    </StyledContentSection>
                </FormControl>
            </StyledAddressOption>
            {/* <StyledAddressOption
                onClick={() =>
                    setAddressDetails({
                        ...addressDetails,
                        option: 'home',
                    })
                }
            >
                <FormControl component="fieldset">
                    <RadioGroup name="deliveryMethod">
                        <FormControlLabel
                            value="end"
                            control={
                                <PurpleRadio
                                    style={{
                                        display: 'block',
                                    }}
                                    checked={addressDetails?.option === 'home'}
                                    onChange={handleAddressOption}
                                    value="home"
                                    name="option"
                                />
                            }
                            label={
                                <>
                                    <span
                                        style={{
                                            fontWeight: 700,
                                            fontSize: '16px',
                                        }}
                                    >
                                        Get Doorstep Repair Service
                                    </span>
                                </>
                            }
                        />
                    </RadioGroup>
                    <StyledContentSection>
                        <div>
                            <img
                                src="/assets/images/repair/calendar.png"
                                alt="icon"
                            />
                            <p>Select Convenient Date & Time</p>
                        </div>
                        <div>
                            {' '}
                            <img
                                src="/assets/images/repair/technician.png"
                                alt="icon"
                            />
                            <p>
                                A technician will visit the address and start
                                the repair
                            </p>
                        </div>
                        <div>
                            {' '}
                            <img
                                src="/assets/images/repair/pay2.png"
                                alt="icon"
                            />
                            <p>Pay after service to the technician</p>
                        </div>
                    </StyledContentSection>
                </FormControl>
            </StyledAddressOption> */}
        </>
    )
}

export default AddressCard
