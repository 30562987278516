import React from 'react'
import styled from 'styled-components'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Icon,
} from '@material-ui/core'

import PersonIcon from '@material-ui/icons/Person'
import PhoneIcon from '@material-ui/icons/Phone'
import ClockIcon from '@material-ui/icons/AccessTime'
import LocationIcon from '@material-ui/icons/Place'
import { devices } from 'utils/screen_sizes'

export const StyledDialog = styled(Dialog)`
    h2 {
        font-family: 'playfair';
    }
    p {
        font-family: 'open-sans';
    }
    h4 {
        margin-top: 0;
        color: #000;
        margin-bottom: 8px;
    }
`
export const StyledDialogContent = styled(DialogContent)`
    display: flex;
    gap: 20px;

    @media ${devices.mobileS} {
        flex-direction: column;
    }
    @media ${devices.laptop} {
        flex-direction: row;
    }
`
export const StyledDialogImage = styled.img`
    max-width: 300px;
    flex: 1;
    object-fit: contain;
`
export const StyledList = styled.ul`
    list-style-type: none;
    font-family: 'open-sans';
    margin: 1rem 0 0 0;
    padding: 0;

    li {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
        color: rgba(52, 49, 76, 0.74);
        span {
            font-weight: bold;
            font-family: 'playfair';
        }
    }
`


const StoreModal = ({
    open,
    handleModalClose,
    
    data: { name, region, country, description },
}) => {
  
    return (
        <StyledDialog
            maxWidth="md"
            open={open}
            // onClose={handleModalClose}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleModalClose(event, reason)
                }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{name} Store</DialogTitle>
            <StyledDialogContent>
                <div>
                    <StyledDialogImage
                        src="assets/images/landing-page/BadiliStore.jpg"
                        alt="store image"
                    />
                </div>
                <DialogContentText id="alert-dialog-description">
                    <h4>Store Highlights</h4>
                    {description ||
                        `${name} is a mobile store dealing in new and pre-loved quality smartphones as well as other accessories 
                        like screens, earphones, etc. Visit us at the location below for quality devices and the best customer 
                        experience. Our partnership with Badili Africa makes it easy for customers to access a variety of devices at
                         affordable prices with a 12 months warranty and repair services`}

                    {/* <h4 style={{ marginTop: '1rem' }}>
                        Contact and Location Details
                    </h4>
                    <StyledList>
                        <li>
                            <PersonIcon style={{ fontSize: 14 }} /> Contact
                            Person: <span>Kelvin Kamau</span>
                        </li>
                        <li>
                            <PhoneIcon style={{ fontSize: 14 }} />
                            Phone Number: <span>0716614921</span>
                        </li>
                        <li>
                            <ClockIcon style={{ fontSize: 14 }} /> Working
                            Hours: <span>All Days 10:00 AM to 09:00 PM</span>
                        </li>
                        <li>
                            <LocationIcon style={{ fontSize: 14 }} />
                            Location: <span>Moi Avenue HH Towers</span>
                        </li>
                    </StyledList> */}
                </DialogContentText>
            </StyledDialogContent>
            <DialogActions>
                <Button onClick={handleModalClose} color="primary">
                    Close
                </Button>
                {/* <Button onClick={handleClose} color="primary" autoFocus>
                    Agree
                </Button> */}
            </DialogActions>
        </StyledDialog>
    )
}

export default StoreModal
