
import React, { useState, createContext } from 'react'
import useAuth from 'app/hooks/useAuth'
import { useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { makeHttpRequest } from 'utils/api_utils'


const RepairContext = createContext()
const RepairContextProvider = (props) => {
    let enumerateDaysBetweenDates = function (startDate, endDate) {
        var now = startDate,
            dates = []
        const options = { weekday: 'long' }

        function isWeekend(date = new Date()) {
            return date.getDay() === 6 || date.getDay() === 0
        }

        while (now <= endDate) {
            dates.push({
                date: now.getDate(),
                day: now.toLocaleDateString(undefined, options),
                isWeekend: isWeekend(now),
            })
            now.setDate(now.getDate() + 1)
        }
        return dates
    }

    let from = new Date()
    let to = new Date()
    to.setDate(to.getDate() + 4)

    let dates = enumerateDaysBetweenDates(from, to)

    const [cart, setCart] = useState([])
    const [repairProId, setRepairProId] = useState('')
    const [addressDetails, setAddressDetails] = useState({
        option: 'store',
        location: '',
        appointment_date: dates[0].date,
    })
    const [total, setTotal] = useState(null)
    const [terms, setTerms] = useState(false)
    // const { state } = useLocation()
    // const { user } = useAuth()
   //  let { id } = useParams()

    const addComponentToCart = (el) => {
        setTotal(null)
        let existingIndex = cart.findIndex((item) => item.id === el.id)

        if (existingIndex >= 0) {
            return setCart(cart.filter((item) => item.id !== el.id))
        } else {
            return setCart([...cart, el])
        }
    }

    // const recalculate = async () => {
    //     // let deductions = cart.reduce((el, obj) => {
    //     //     return { ...el, [obj.component_name]: 'yes' }
    //     // }, {})

    //     let userId = user?._id || user?.userId
    //     try {
    //         let payload = {
    //             userId,
    //             modelId: id,
    //             //colorId: state?.colorId,
    //            // deductions,
    //             //repairProId,
    //         }
    //         const { success, msg, warranty, cost } = await makeHttpRequest(
    //             `estimate_repair_value`,
    //             'POST',
    //             payload
    //         )
    //         if (success) {
    //             setTotal(cost.toString())
    //         }
    //     } catch (err) {}
    // }

    const removeFromCart = (id) => {
        return setCart(cart.filter((item) => item.id !== id))
    }

    return (
        <RepairContext.Provider
            value={{
                
                cart,
                setCart,
                addComponentToCart,
                addressDetails,
                setAddressDetails,
                dates,
                removeFromCart,
                total,
                setTotal,
                terms,
                setTerms,
                setRepairProId,
                repairProId,
                // recalculate,
            }}
        >
            {props.children}
        </RepairContext.Provider>
    )
}

const RepairConsumer = RepairContext.Consumer
export { RepairContextProvider, RepairConsumer, RepairContext }
