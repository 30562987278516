import React, { useState, useEffect, useContext } from 'react'
import {
    TextField,
    Divider,
    MenuItem,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormControl,
    Button,
} from '@material-ui/core'
import {
    StyledSection,
    StyledCard,
    StyledSelectModel,
    StyledToggleForm,
    StyledFormGraphics,
} from './sellYourDeviceForm.style'
import history from 'history.js'
import useAuth from 'app/hooks/useAuth'
import {
    phoneSummaryQuestions,
    functionalPhysicalQuestions,
    functionalScreenQuestions,
    originalItems,
    allQuestions,
} from './sellYourDeviceService'
import SellDeviceFormProvider from '../../contexts/SellDeviceFormContext'

import { makeHttpRequest } from 'utils/api_utils'
import { AppContext } from 'app/contexts/AppContext'
import { useLocation } from 'react-router-dom'
import { getCountryFromUrl } from 'utils/CountryUtils'
import PhoneNumberDrawer from 'app/components/SideDrawer/PhoneNumberDrawer'

const SelectModel = (props) => {
    useEffect(() => {
        props.scrollToTop()
    }, [])

    return (
        <StyledSection>
            <StyledSelectModel>
                <h4>Sell Device Form</h4>
                <p>Please fill in the form below to receive a price estimate</p>
                <Divider className="mb-8" />
                {props.brands && props.brands.length > 0 ? (
                    <TextField
                        className="input-select mb-4"
                        label="Select Brand"
                        variant="outlined"
                        size="small"
                        fullWidth
                        defaultValue=""
                        select
                    >
                        {props.brands.map((brand) => (
                            <MenuItem
                                key={brand._id}
                                value={brand.brand}
                                onClick={() => props.fetchModels(brand._id)}
                            >
                                {brand.brand}
                            </MenuItem>
                        ))}
                    </TextField>
                ) : (
                    <TextField
                        className="input-select mb-4"
                        label="Select Brand"
                        variant="outlined"
                        size="small"
                        fullWidth
                        defaultValue=""
                        select
                        disabled
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                    </TextField>
                )}
                {props.models && props.models.length > 0 ? (
                    <TextField
                        className="input-select mb-4"
                        label="Select Model"
                        variant="outlined"
                        size="small"
                        fullWidth
                        defaultValue=""
                        select
                    >
                        {props.models.map((model) => {
                            return (
                                <MenuItem
                                    key={model.details.modelName}
                                    value={model.details.modelName}
                                    onClick={() =>
                                        props.handleSelectModel({
                                            ...model,
                                            ...model.image,
                                        })
                                    }
                                >
                                    {model.details.modelName}
                                </MenuItem>
                            )
                        })}
                    </TextField>
                ) : (
                    <TextField
                        className="input-select mb-4"
                        label="Select Model"
                        variant="outlined"
                        size="small"
                        fullWidth
                        defaultValue=""
                        select
                        disabled
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                    </TextField>
                )}
               
                {props.variants && props.variants.length > 0 ? (
                    <TextField
                        className="input-select mb-4"
                        label="Select Variant"
                        variant="outlined"
                        size="small"
                        fullWidth
                        defaultValue=""
                        select
                    >
                        {props.variants.map(({ variant, prices }) => {
                            return (
                                <MenuItem
                                    key={variant._id}
                                    value={variant.storage}
                                    onClick={() =>
                                        props.handleSetVariant({
                                            ...variant,
                                            prices,
                                        })
                                    }
                                >
                                    {`${variant.ram}GB/ ${variant.storage}GB`}
                                </MenuItem>
                            )
                        })}
                    </TextField>
                ) : (
                    <TextField
                        className="input-select mb-4"
                        label="Select Variant"
                        variant="outlined"
                        size="small"
                        fullWidth
                        defaultValue=""
                        select
                        disabled
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                    </TextField>
                )}
                <div className="flex justify-center">
                    <Button
                        className="px-7"
                        variant="contained"
                        color="primary"
                        disabled={
                            props.selectedModel &&
                            props.selectedModel.price &&
                            props.selectedModel.price > 0
                                ? false
                                : true
                        }
                        onClick={() => props.setStep('PhoneSummaryPage')}
                    >
                        Next
                    </Button>
                </div>
            </StyledSelectModel>
            <StyledCard>
                <img
                    className="block w-full"
                    src={props.selectedModel.image}
                    onError={(e) => {
                        e.target.onerror = null
                        e.target.src =
                            'https://media.istockphoto.com/vectors/smartphone-icon-vector-id1137284756?b=1&k=20&m=1137284756&s=170667a&w=0&h=S8OxtjTNSFmreNwBP6_LdbeHN1H2lgIgKcqh3Z5aAIg='
                    }}
                    alt=""
                    loading="lazy"
                    style={{
                        width: '8rem',
                        margin: '2rem auto',
                        backgroundColor: '#fafafa',
                    }}
                />
             
                {props.selectedModel &&
                props.selectedModel.price &&
                props.selectedModel.price > 0 ? (
                    <h4 style={{ textAlign: 'center' }}>
                        Max Price:{' '}
                        {props.selectedModel.prices.currency +
                            '  ' +
                            props.selectedModel.prices.price.toLocaleString()}
                    </h4>
                ) : (
                    <></>
                )}
                {props.error.status ? (
                    <div className="error-message">
                        <Divider className="mb-8" />
                        <p>***An error occurred*** - {props.error.msg}</p>
                    </div>
                ) : (
                    <></>
                )}
            </StyledCard>
        </StyledSection>
    )
}

const PhoneSummaryPage = (props) => {
    useEffect(() => {
        props.scrollToTop()
    }, [])

    return (
        <StyledSection>
            <StyledCard>
                {props.error.status ? (
                    <div className="error-message">
                        <p>***An error occurred*** - {props.error.msg}</p>
                    </div>
                ) : (
                    <></>
                )}
                <section
                    className="defects"
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr',
                        gridGap: '16px',
                    }}
                >
                    <h4 style={{ textAlign: 'center', margin: '24px' }}>
                        Tell us a few things about device!
                    </h4>

                    <FormControl component="fieldset" className="mb-4">
                        {props.questions.map((question, index) => {
                            return (
                                <div style={{}} key={index}>
                                    <div style={{ padding: '16px' }}>
                                        <h5 className="mt-0 mb-1">
                                            {index + 1}. {question.title}
                                        </h5>
                                        <p>{question.description}</p>
                                        <RadioGroup
                                            name="status"
                                            required
                                            default="yes"
                                            style={{ flexDirection: 'row' }}
                                            value={
                                                props.requestBody[
                                                    question.variableName
                                                ]
                                            }
                                            onChange={(e) =>
                                                props.handleOptionSelect(
                                                    {
                                                        ...props.requestBody,
                                                        [question.variableName]:
                                                            e.target.value,
                                                    },
                                                    question
                                                )
                                            }
                                        >
                                            <FormControlLabel
                                                className="h-32"
                                                value="yes"
                                                control={
                                                    <Radio color="primary" />
                                                }
                                                label="Yes"
                                                required
                                                style={{
                                                    border: '1px solid rgb(112, 112, 112, 0.2',
                                                    width: '200px',
                                                    height: '44px',
                                                    margin: '20px 20px 0 0',
                                                    padding: '16px',
                                                    borderRadius: '6px',
                                                }}
                                            />
                                            <FormControlLabel
                                                className="h-32"
                                                value="no"
                                                control={
                                                    <Radio color="secondary" />
                                                }
                                                label="No"
                                                required
                                                style={{
                                                    border: '1px solid rgb(112, 112, 112, 0.12',
                                                    width: '200px',
                                                    height: '44px',
                                                    margin: '20px 20px 0 0',
                                                    padding: '16px',
                                                    borderRadius: '6px',
                                                }}
                                            />
                                        </RadioGroup>
                                    </div>
                                </div>
                            )
                        })}
                    </FormControl>
                </section>
                <br />
                <Divider className="mb-8" />
                <div className="flex justify-center">
                    <Button
                        className="px-7"
                        variant="contained"
                        color="primary"
                        onClick={() => props.setStep(props._previousStep)}
                        style={{ marginRight: '16px' }}
                    >
                        Back
                    </Button>
                    <Button
                        className="px-7"
                        variant="contained"
                        color="primary"
                        disabled={
                            !props.requestBody.workingPhone ||
                            !props.requestBody.deviceOn
                        }
                        onClick={() => props.setStep(props._nextStep)}
                    >
                        Continue
                    </Button>
                </div>
            </StyledCard>
            {props.children}
        </StyledSection>
    )
}

const CustomSelectorReverse = (props) => {
    useEffect(() => {
        props.scrollToTop()
    }, [])

    const questionToggler = (option, question, toggleOption) => {
        if (
            option[question.variableName] === toggleOption ||
            !option[question.variableName]
        ) {
            if (option[question.variableName] === 'no') {
                option[question.variableName] = 'yes'
            } else {
                option[question.variableName] = 'no'
            }
        }

        props.handleOptionSelect(option, question)
    }

    return (
        <StyledSection>
            <StyledCard>
                <h4 style={{ textAlign: 'center', margin: '24px' }}>
                    Select Your Phone's Functional Problems
                </h4>
                {props.error.status ? (
                    <div className="error-message">
                        <p>***An error occurred*** - {props.error.msg}</p>
                    </div>
                ) : (
                    <></>
                )}
                <StyledToggleForm>
                    {props.questions.map((question, index) => {
                        let toggleOption =
                            props.requestBody[question.variableName]

                        const _red = '#FF6565'
                        const borderColor =
                            toggleOption === 'no' ? _red : '#ddd'

                        return (
                            <div
                                key={index}
                                style={{ border: `1px solid ${borderColor}` }}
                                onClick={() =>
                                    questionToggler(
                                        {
                                            ...props.requestBody,
                                            [question.variableName]: 'no',
                                        },
                                        question,
                                        toggleOption
                                    )
                                }
                            >
                                <div
                                    style={{
                                        height: 'fit-content',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <img
                                        src={question.icon}
                                        alt=""
                                        loading="lazy"
                                    />
                                </div>
                                <div
                                    style={{
                                        padding: '16px',
                                        flex: '1',
                                        background: `${
                                            borderColor === _red ? _red : '#fff'
                                        }`,
                                    }}
                                >
                                    <p
                                        className="mt-0 mb-1"
                                        style={{
                                            color: `${
                                                borderColor === _red
                                                    ? '#fff'
                                                    : '#000'
                                            }`,
                                        }}
                                    >
                                        {question.title}
                                    </p>
                                </div>
                            </div>
                        )
                    })}
                </StyledToggleForm>
                <br />
                <Divider className="mb-8" />
                <div className="flex justify-center">
                    <Button
                        className="px-7"
                        variant="contained"
                        color="primary"
                        onClick={() => props.setStep(props._previousStep)}
                        style={{ marginRight: '16px' }}
                    >
                        Back
                    </Button>
                    <Button
                        className="px-7"
                        variant="contained"
                        color="primary"
                        onClick={() => props.setStep(props._nextStep)}
                    >
                        Next
                    </Button>
                </div>
            </StyledCard>
            {props.children}
        </StyledSection>
    )
}

const CustomSelector = (props) => {
    useEffect(() => {
        props.scrollToTop()
    }, [])

    const QuestionToggler = (option, question, toggleOption) => {
        if (
            option[question.variableName] === toggleOption ||
            !option[question.variableName]
        ) {
            if (option[question.variableName] === 'yes') {
                option[question.variableName] = 'no'
            } else {
                option[question.variableName] = 'yes'
            }
        }

        props.handleOptionSelect(option, question)
    }

    return (
        <StyledSection>
            <StyledCard>
                <h4 style={{ textAlign: 'center', margin: '24px' }}>
                    {props.sectionTitle}
                </h4>
                {props.error.status ? (
                    <div className="error-message">
                        <p>***An error occurred*** - {props.error.msg}</p>
                    </div>
                ) : (
                    <></>
                )}
                <StyledToggleForm>
                    {props.questions.map((question, index) => {
                        let toggleOption =
                            props.requestBody[question.variableName]

                        const borderColor =
                            toggleOption === 'yes' ? props.frameColor : '#ddd'

                        return (
                            <div
                                style={{
                                    border: `1px solid ${borderColor}`,
                                    borderRadius: '4px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    cursor: 'pointer',
                                }}
                                key={index}
                                onClick={() =>
                                    QuestionToggler(
                                        {
                                            ...props.requestBody,
                                            [question.variableName]: 'yes',
                                        },
                                        question,
                                        toggleOption
                                    )
                                }
                            >
                                <div
                                    style={{
                                        height: 'fit-content',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <img
                                        src={question.icon}
                                        loading="lazy"
                                        alt=""
                                        style={{ width: '60%', margin: 'auto' }}
                                    />
                                </div>
                                <div
                                    style={{
                                        padding: '16px',
                                        flex: '1',
                                        background: `${
                                            borderColor === props.frameColor
                                                ? props.frameColor
                                                : '#fff'
                                        }`,
                                    }}
                                >
                                    <p
                                        className="mt-0 mb-1"
                                        style={{
                                            color: `${
                                                borderColor === props.frameColor
                                                    ? '#fff'
                                                    : '#000'
                                            }`,
                                        }}
                                    >
                                        {question.title}
                                    </p>
                                </div>
                            </div>
                        )
                    })}
                </StyledToggleForm>
                <br />

                <Divider className="mb-8" />
                <div className="flex justify-center">
                    <Button
                        className="px-7"
                        variant="contained"
                        color="primary"
                        onClick={() => props.setStep(props._previousStep)}
                        style={{ marginRight: '16px' }}
                    >
                        Back
                    </Button>
                    <Button
                        className="px-7"
                        variant="contained"
                        color="primary"
                        onClick={() => props.setStep(props._nextStep)}
                    >
                        Next
                    </Button>
                </div>
            </StyledCard>
            {props.children}
        </StyledSection>
    )
}

const OriginalBundle = (props) => {
    const QuestionToggler = (option, question, toggleOption) => {
        if (
            option[question.variableName] === toggleOption ||
            !option[question.variableName]
        ) {
            if (option[question.variableName] === 'yes') {
                option[question.variableName] = 'no'
            } else {
                option[question.variableName] = 'yes'
            }
        }

        props.handleOptionSelect(option, question)
    }

    useEffect(() => {
        props.scrollToTop()
    }, [])

    return (
        <StyledSection>
            <StyledCard>
                <h4 style={{ textAlign: 'center', margin: '24px' }}>
                    {props.sectionTitle}
                </h4>
                {props.error.status ? (
                    <div className="error-message">
                        <p>***An error occurred*** - {props.error.msg}</p>
                    </div>
                ) : (
                    <></>
                )}
                <StyledToggleForm>
                    {props.questions.map((question, index) => {
                        let toggleOption =
                            props.requestBody[question.variableName]

                        const borderColor =
                            toggleOption === 'yes' ? props.frameColor : '#ddd'

                        return (
                            <div
                                style={{
                                    border: `1px solid ${borderColor}`,
                                    borderRadius: '4px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    cursor: 'pointer',
                                }}
                                key={index}
                                onClick={() =>
                                    QuestionToggler(
                                        {
                                            ...props.requestBody,
                                            [question.variableName]: 'yes',
                                        },
                                        question,
                                        toggleOption
                                    )
                                }
                            >
                                <div
                                    style={{
                                        height: 'fit-content',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <img
                                        src={question.icon}
                                        alt=""
                                        loading="lazy"
                                        style={{ width: '60%', margin: 'auto' }}
                                    />
                                </div>
                                <div
                                    style={{
                                        padding: '16px',
                                        flex: '1',
                                        background: `${
                                            borderColor === props.frameColor
                                                ? props.frameColor
                                                : '#fff'
                                        }`,
                                    }}
                                >
                                    <p
                                        className="mt-0 mb-1"
                                        style={{
                                            color: `${
                                                borderColor === props.frameColor
                                                    ? '#fff'
                                                    : '#000'
                                            }`,
                                        }}
                                    >
                                        {question.title}
                                    </p>
                                </div>
                            </div>
                        )
                    })}
                </StyledToggleForm>
                <br />

                <Divider className="mb-8" />
                <div className="flex justify-center">
                    <Button
                        className="px-7"
                        variant="contained"
                        color="primary"
                        onClick={() => props.setStep(props._previousStep)}
                        style={{ marginRight: '16px' }}
                    >
                        Back
                    </Button>
                    <Button
                        className="px-7"
                        variant="contained"
                        color="primary"
                        onClick={() => props.handleCalculate()}
                        //onClick={() => props.setStep(props._nextStep)}
                    >
                        Next
                    </Button>
                </div>
            </StyledCard>
            {props.children}
        </StyledSection>
    )
}

const FormGraphics = ({
    selectedModel,
    requestBody,
    questions = [],
    originalItems = [],
    summary = [],
}) => {
    const phone_summary = []
    const defects = []
    const bundle = []

    questions.map((question) => {
  
        if (
            requestBody[question.variableName] &&
            question.expectedAnswer !== requestBody[question.variableName]
        ) {
            defects.push(<li key={question.variableName}>{question.title}</li>)
        }
    })
    summary.map((question, index) => {
      
        if (
            requestBody[question.variableName] &&
            question.expectedAnswer === requestBody[question.variableName]
        ) {
            phone_summary.push(
                <li key={index}>
                    {question.evaluation[requestBody[question.variableName]]}
                </li>
            )
        } else if (
            requestBody[question.variableName] !== '' &&
            requestBody[question.variableName] &&
            question.expectedAnswer !== requestBody[question.variableName]
        ) {
            phone_summary.push(
                <li key={index * 2}>
                    {question.evaluation[requestBody[question.variableName]]}
                </li>
            )
        } else {
        }
    })
    originalItems.map((originalItem) => {
      
        if (
            requestBody[originalItem.variableName] &&
            originalItem.expectedAnswer ===
                requestBody[originalItem.variableName]
        ) {
            bundle.push(
                <li key={originalItem.variableName}>{originalItem.title}</li>
            )
        }
    })

    return (
        <StyledCard>
            <div
                className="selected-model"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '1px solid #ddd',
                }}
            >
                <img
                    src={selectedModel.image}
                    onError={(e) => {
                        e.target.onerror = null
                        e.target.src =
                            'https://media.istockphoto.com/vectors/smartphone-icon-vector-id1137284756?b=1&k=20&m=1137284756&s=170667a&w=0&h=S8OxtjTNSFmreNwBP6_LdbeHN1H2lgIgKcqh3Z5aAIg='
                    }}
                    alt={selectedModel.modelName || 'phone'}
                    loading="lazy"
                    style={{ height: '90px', margin: '16px' }}
                />
                <h5>{`${selectedModel.modelName} (${selectedModel.storage}GB/${selectedModel.ram}GB)`}</h5>
            </div>
            <div className="device-evaluation" style={{ padding: '16px' }}>
                <h5 style={{ marginTop: '48px' }}>Device Evaluation</h5>
                <ul>
                    {phone_summary.map((item) => item)}
                    {defects.map((item) => item)}
                    {bundle.map((item) => item)}
                </ul>
            </div>
        </StyledCard>
    )
}

const SellYourDeviceForm = ({ scrollToTop }) => {
    const { pathname } = useLocation()
    let country = getCountryFromUrl(pathname)||"ke"
    const [error, setError] = useState({})
    const [brands, setBrands] = useState([])
    const { user } = useAuth()
    const [panelOpen, setPanelOpen] = useState(false)
    const [nextPath, setNextPath] = useState(`/${country}`)

    const SellDeviceFormContext = useContext(SellDeviceFormProvider)

    

    const {
        step,
        setStep,
        models,
        setModels,
        selectedModel,
        setSelectedModel,
        requestBody,
        setRequestBody,
        resetRequestBody,
        variants,
        fetchVariants,
        handleSetVariant,

        handleDeviceEstimateFormSubmit,
    } = SellDeviceFormContext

    const fetchBrands = async (_id) => {
        const { success, brands } = await makeHttpRequest(
            `get_device/${_id}`,
            'GET'
        )
        if (success) {
            setBrands(brands)
        } else {
            setError({
                status: true,
                msg: 'an error occurred that prevented the brands from loading...',
            })
            return
        }
    }
    const fetchModels = async (brandId) => {
        const { success, deviceModels } = await makeHttpRequest(
            `get_device_model/${brandId}`,
            'GET'
        )

        if (success) setModels(deviceModels)
    }
    const handleSelectModel = (model) => {
        setSelectedModel({ ...model.details, image: model.image })
        setRequestBody({
            ...requestBody,
            modelID: model.details._id,
        })

  
        fetchVariants(model.details._id)
    }
    const handleOptionSelect = (option, question) => {
        setRequestBody(option)
    }

       const handleDrawerToggle = (_nextPath = '') => {
           setPanelOpen(!panelOpen)
           setNextPath(_nextPath)

           return panelOpen
       }
    const handleCalculate = async () => {
    
      

        if (user) {
       
            const calculation_response = await handleDeviceEstimateFormSubmit(
                user._id || user.userId
            )
       
            if (!calculation_response.success) {
                return
            }
            return history.push(`/${country}/device-details`)
        } else {
         
          
            window._toggleDrawer = handleDrawerToggle(
                `/${country}/device-details`
            )
        }
    }

    useEffect(() => {
        fetchBrands('6165c8453c6ca93814dc21aa')
        scrollToTop()

        return function () {
            setStep('SelectModel')
            resetRequestBody()
            // setSelectedModel({})
            // setRequestBody({})
            // setModels({})
            // setVariants({})
        }
    }, [])

    switch (step) {
        case 'SelectModel':
            return (
                <SelectModel
                    brands={brands}
                    models={models}
                    selectedModel={selectedModel}
                    variants={variants}
                    scrollToTop={scrollToTop}
                    error={error}
                    setStep={setStep}
                    fetchModels={fetchModels}
                    handleSelectModel={handleSelectModel}
                    handleSetVariant={handleSetVariant}
                />
            )

        case 'PhoneSummaryPage':
            return (
                <PhoneSummaryPage
                    error={error}
                    requestBody={requestBody}
                    handleOptionSelect={handleOptionSelect}
                    scrollToTop={scrollToTop}
                    setStep={setStep}
                    _nextStep="Defects"
                    _previousStep="SelectModel"
                    questions={phoneSummaryQuestions}
                >
                    <StyledFormGraphics>
                        <FormGraphics
                            selectedModel={selectedModel}
                            requestBody={requestBody}
                            summary={phoneSummaryQuestions}
                        />
                    </StyledFormGraphics>
                </PhoneSummaryPage>
            )

        case 'Defects':
            return (
                <CustomSelectorReverse
                    error={error}
                    requestBody={requestBody}
                    handleOptionSelect={handleOptionSelect}
                    setStep={setStep}
                    scrollToTop={scrollToTop}
                    _nextStep="Defects2"
                    _previousStep="PhoneSummaryPage"
                    questions={functionalPhysicalQuestions}
                    allQuestions
                >
                    <StyledFormGraphics>
                        <FormGraphics
                            selectedModel={selectedModel}
                            requestBody={requestBody}
                            questions={allQuestions}
                            summary={phoneSummaryQuestions}
                        />
                    </StyledFormGraphics>
                </CustomSelectorReverse>
            )

        case 'Defects2':
            return (
                <CustomSelector
                    error={error}
                    requestBody={requestBody}
                    scrollToTop={scrollToTop}
                    handleOptionSelect={handleOptionSelect}
                    setStep={setStep}
                    questions={functionalScreenQuestions}
                    frameColor="#FF6565"
                    sectionTitle="Which of the following applies to your device?"
                    _nextStep="OriginalBundle"
                    _previousStep="Defects"
                    allQuestions
                >
                    <StyledFormGraphics>
                        <FormGraphics
                            selectedModel={selectedModel}
                            requestBody={requestBody}
                            questions={allQuestions}
                            summary={phoneSummaryQuestions}
                        />
                    </StyledFormGraphics>
                </CustomSelector>
            )

        case 'OriginalBundle':
            return (
                <OriginalBundle
                    error={error}
                    requestBody={requestBody}
                    scrollToTop={scrollToTop}
                    handleOptionSelect={handleOptionSelect}
                    setStep={setStep}
                    handleCalculate={handleCalculate}
                    questions={originalItems}
                    frameColor="#41C8B7"
                    sectionTitle="Do you have the following?"
                    _nextStep="OTP"
                    _previousStep="Defects2"
                    allQuestions
                >
                    <StyledFormGraphics>
                        <FormGraphics
                            selectedModel={selectedModel}
                            requestBody={requestBody}
                            questions={allQuestions}
                            originalItems={originalItems}
                            summary={phoneSummaryQuestions}
                        />
                        {panelOpen && (
                            <PhoneNumberDrawer 
                                panelOpen={panelOpen}
                                handleDrawerToggle={handleDrawerToggle}
                                nextPath={nextPath}
                            />
                        )}
                    </StyledFormGraphics>
                </OriginalBundle>
            )

        default:
            return (
                <SelectModel
                    brands={brands}
                    models={models}
                    scrollToTop={scrollToTop}
                    selectedModel={selectedModel}
                    variants={variants}
                    error={error}
                    setStep={setStep}
                    fetchModels={fetchModels}
                    handleSelectModel={handleSelectModel}
                    handleSetVariant={handleSetVariant}
                />
            )
    }
}

export default SellYourDeviceForm
