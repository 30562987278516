import { Card, CardContent, Hidden, Typography } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import { devices } from 'utils/screen_sizes'
import { PrimaryButton } from '.'

const StyledCard = styled.div`
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    overflow: hidden;
    background-color: ${(props) => (props.bg ? props.bg : '')};
    display: flex;
    flex-direction: row;
    padding: 1rem;
    border-radius: 8px;

    @media ${devices.mobileS} {
        flex-direction: column-reverse;
        align-items: center;
        padding: 0rem; //new
    }
    @media ${devices.laptop} {
        flex-direction: row;
        align-items: start;
        padding: 1rem;
    }

    .MuiCardContent-root {
        flex: 1;
    }

    h5 {
        color: ${(props) => (props.titlecolor ? props.titlecolor : '#fff')};
        font-family: 'playfair-bold';

        @media ${devices.mobileS} {
            font-size: 1.1rem; //new
        }
        @media ${devices.mobileM} {
            font-size: 1.1rem; //new
        }
        @media ${devices.laptop} {
            font-size: 1.5rem; //new
        }
    }

    img {
        width: 200px;
        height: 300px;
        object-fit: contain;

        @media ${devices.mobileS} {
            margin-bottom: 0;
            margin-left: 0;
            width: 100%;
            /* height: 145px; */
            height: 95px; //new
            margin-top: 1rem;
        }
        @media ${devices.mobileM} {
            margin-bottom: 0;
            margin-left: 0;
            margin-top: 1rem;
            width: 100%;
            height: 115px;
        }
        @media ${devices.laptop} {
            margin-bottom: -7rem;
            margin-left: 1rem;
            width: 200px;
            height: 300px;
            margin-top: 0;
        }
    }
`

const StyledTypography = styled.h6`
    color: ${(props) =>
        props.descriptioncolor ? props.descriptioncolor : '#fff'};
    font-size: 14px;
    font-weight: normal;
    font-family: 'open-sans';
    text-transform: capitalize;

    @media ${devices.mobileS} {
        margin: 0.5rem 0 2rem 0rem;
        font-size: 12px; //new
    }
    @media ${devices.laptop} {
        margin: 0.5rem 0 2rem 0rem;
        font-size: 14px;
    }
`

const BuyCard = ({
    title,
    description,
    imageUrl,
    background,
    btnLabel,
    btnAction,
    titleColor,
    descriptionColor,
    btnType,
}) => {
    return (
        <StyledCard bg={background} titlecolor={titleColor}>
            <CardContent>
                <Hidden mdUp>
                    <Typography component="h5" variant="h5">
                        {title} Phone
                    </Typography>
                </Hidden>
                <Hidden mdDown>
                    <Typography component="h5" variant="h5">
                        {title}
                        <br />
                        Phone
                    </Typography>
                </Hidden>
                <StyledTypography
                    variant="subtitle1"
                    descriptioncolor={descriptionColor}
                >
                    {description}
                </StyledTypography>
                <PrimaryButton
                    btnType={btnType}
                    label={btnLabel}
                    action={btnAction}
                />
            </CardContent>
            <img src={imageUrl} alt="phone" />
        </StyledCard>
    )
}

export default BuyCard
