import styled from 'styled-components'
import { Grid, Switch, Card, Container } from '@material-ui/core'
import { devices } from '../../utils/screen_sizes'

export const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    img {
        width: 80%;
        object-fit: cover;
        height:80%;
    }

    @media ${devices.laptop} {
        img {
            width: auto;
            max-width: 280px;
        }
    }
`

export const StyledGrid = styled(Grid)`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    h3 {
        color: #ff6565;
        font-size: 2.5em;
        font-weight: normal;
    }
    h4 {
        color: #ff6565;
    }
    p {
        color: #000;
        font-weight: 500;
        opacity: 0.7;
    }

    @media ${devices.laptop} {
        padding: 1.2rem !important;
    }
`
export const StyledLink = styled.a`
    color: #ff6565;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`
export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;

    div {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: start;
       
        margin: 20px 0;
        width: 50px;
        flex-direction: column;
      

        &:last-child {
            border-right: none;
        }
        p {
            padding: 10px 0;
        }
    }
    img {
        max-width: 50px;
        max-height: 50px;
        object-fit: contain;
    }
    span {
        font-weight: 500;
    }
`
export const RepairIconWrapper = styled.div`
    display: inline-block;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
    margin-top:10px;
    
  
    

    div {
        flex-grow: 1;
       
        align-items: center;
        justify-content: start;
        margin: 0px 0;
        
        flex-direction: column;
        &:last-child {
            border-right: none;
        }
        p {
            padding: 10px 0;
        }
    }
    img {
        max-width: 52px;
        max-height: 52px;
        object-fit: contain;
    }
    span {
        font-weight: 500;
    }
`

export const StyledContainer = styled(Container)`
    margin: 20px 0;
    width: 100%;

    .px-7 {
        margin-bottom: 2em;
    }

    @media ${devices.laptop} {
        width: 90vw;
        margin: 20px auto;
    }

    @media ${devices.desktop} {
        width: 70vw;
    }
`

export const GreyStyledCard = styled(Card)`
    background-color: #fafafa;
    padding: 8px 16px;
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
   

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        width: 100%;
    }
    ul li {
        flex-grow: 1;
        border: 1px solid #ddd;
        padding: 15px;
        border-radius: 5px;
        height: 80px;
        min-width: 120px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-origin: content-box;
    }
`

export const StyledSwitch = styled(Switch)`
    background-color: transparent !important;
    color: #42c8b7;
`

export const NoCalculationsCard = styled(Card)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 80vh;
`
