import React, { useState, useEffect } from 'react'
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Card,
} from '@material-ui/core'
import styled from 'styled-components'
import { devices } from 'utils/screen_sizes'
import { makeHttpRequest } from 'utils/api_utils'

export const StyledSearchStoreCard = styled(Card)`
    background-image: url('/assets/images/landing-page/find_stores_background.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
    padding: 1rem 2rem;

    @media ${devices.mobileS} {
        margin-right: 0;
    }
    @media ${devices.laptop} {
        margin-right: 1rem;
    }

    h5 {
        margin-top: 5.7rem;
        margin-bottom: 0;
        font-size: 15px;
    }
    .MuiInput-underline::before {
        border-bottom: none;
    }
    .MuiInput-underline:hover:not(.Mui-disabled)::before {
        border-bottom: none;
    }
`

const SearchStore = ({
    dealers,
    setDealers,
    filteredDealers,
    setFilteredDealers,
}) => {
    const [availableRegions, setAvailableRegions] = useState([])
    const [region, setRegion] = useState('')

    let tempDealers = [...dealers]
    
    const handleChange = (event) => {
        setFilteredDealers(dealers)
        setRegion(event.target.value)

        try {
            if (!event.target.value) return

            setFilteredDealers(
                tempDealers.filter(
                    (el) => el.region[0]?._id === event.target.value
                )
            )
        } catch (err) {}
    }
    const getRegions = async () => {
        try {
            const { success, regions } = await makeHttpRequest(
                `get_regions`,
                'GET',
                null
            )

            if (success) {
                setAvailableRegions(regions)
            } else {
                setAvailableRegions([])
            }
        } catch (err) {
            setAvailableRegions([])
        }
    }

    useEffect(() => {
        getRegions()
    }, [])
    return (
        <StyledSearchStoreCard>
            <h5>Find Badili Stores Near You</h5>
            <Card>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        Search your city
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={region}
                        label="Age"
                        onChange={handleChange}
                    >
                        <MenuItem value="">Select Store</MenuItem>
                        {availableRegions.map(({ name, _id }) => (
                            <MenuItem value={_id} key={_id}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Card>
        </StyledSearchStoreCard>
    )
}

export default SearchStore
