
import React, { useContext, useEffect, useState } from 'react'
import './App.css'
import { Router, Switch, Route,  Redirect } from 'react-router-dom'
import history from 'history.js'
import ecommerceRoutes from './ecommerceComponents/EcommerceRoutes'
import repairRoutes from './repair/RepairRoutes'
import {
    MatxSuspense,
    MatxTheme,
    MatxLayout,
    MatxLoading,
} from 'app/components'
import sessionRoutes from './views/sessions/SessionRoutes'
import { AuthProvider } from 'app/contexts/JWTAuthContext'
import { AppContext } from './contexts/AppContext'
import { useTranslation } from 'react-i18next'

import axios from 'axios'
import LandingPage from 'app/views/Home/landingPage/LandingPage'


const App = () => {
    const { setCountry, country } = useContext(AppContext)
    let selectedCountry = localStorage.getItem('country')
    const [checkingCountry, setCheckingCountry] = useState(false)

    const { i18n } = useTranslation()

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng)
    }

    const getUserCountry = async () => {
        axios
            .get('https://api64.ipify.org')
            .then((res) => {
                setCheckingCountry(true)
                axios
                    .get(`http://www.geoplugin.net/json.gp?ip=${res.data}`) //doesn't work in production to change to another endpoint
                    .then((response) => {
                        if (localStorage.getItem('country')) {
                            let cntry = localStorage.getItem('country')

                            if (cntry === 'tz') {
                                changeLanguage('lg')
                            }

                            setCountry(cntry)
                        } else {
                            let country =
                                response.data.geoplugin_countryCode.toLowerCase() ===
                                    'ke' ||
                                response.data.geoplugin_countryCode.toLowerCase() ===
                                    'tz'
                                    ? response.data.geoplugin_countryCode.toLowerCase()
                                    : 'ke'
                            localStorage.setItem('country', country)
                            setCountry(country)

                            if (country === 'tz') {
                                changeLanguage('lg')
                            }
                        }
                    })
                    .catch((e) => {
                        console.log('error', e)
                        localStorage.setItem('country', 'ke')
                        setCountry('ke')
                    })
                    .finally(() => setCheckingCountry(false))
            })
            .catch((e) => {
                console.log(e)

                setCheckingCountry(false)
            })
            .finally(() => setCheckingCountry(false))
    }

    useEffect(() => {
        if(window.location.hostname==='badili.africa'){
            localStorage.setItem('country', 'ke')
            setCountry('ke')
        }else if(window.location.hostname==='badili.co.tz'){
            localStorage.setItem('country', 'tz')
            setCountry('tz')
        }else if(window.location.hostname==='badili.ug'){
            localStorage.setItem('country', 'ug')
            setCountry('ug')
        }
        if (selectedCountry) return
        getUserCountry()
    }, [])

    return (
        <>
            {checkingCountry && <MatxLoading />}
            {!checkingCountry && (
                <>
                    <Router history={history}></Router>
                    <MatxTheme>
                        <Router history={history}>
                            <AuthProvider>
                                <MatxSuspense >
                                    <Switch>
                                    
                                        {/* <Route
                                            path={'/'}
                                            exact
                                            component={(props) => (
                                                <Redirect
                                                    to={`/${
                                                        localStorage.getItem(
                                                            'country'
                                                        ) || country
                                                    }`}
                                                />
                                            )}
                                        /> */}
                                        <Route
                                            path={`/`}
                                            exact
                                            component={(props) => (
                                                <LandingPage />
                                            )}
                                        />

                                        {sessionRoutes.map((item, i) => (
                                            <Route
                                                key={i}
                                                path={item.path}
                                                component={(props) =>
                                                    item.component(props)
                                                }
                                            />
                                        ))}

                                        {/* ecommerce routes */}
                                        {ecommerceRoutes.map((item, i) => (
                                            <Route
                                                key={i}
                                                path={item.path}
                                                component={(props) =>
                                                    item.component(props)
                                                }
                                            />
                                        ))}
                                        {/* repair routes */}
                                        {repairRoutes.map((item, i) => (
                                            <Route
                                                key={i}
                                                path={item.path}
                                                component={(props) =>
                                                    item.component(props)
                                                }
                                            />
                                        ))}

                                        <MatxLayout />
                                    </Switch>
                                </MatxSuspense>
                            </AuthProvider>
                        </Router>
                    </MatxTheme>
                </>
            )}
        </>
    )
}

export default App

