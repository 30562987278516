import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useClickOutside } from 'react-click-outside-hook'
import MoonLoader from 'react-spinners/MoonLoader'
import { useDebounce } from 'app/hooks/useDebounce'
import SearchResult from './SearchResult'
import { makeHttpRequest } from 'utils/api_utils'
import { useHistory, useLocation } from 'react-router-dom'
import { replaceWhiteSpaceWithHyphen } from 'utils/string_utils'
import { getCountryFromUrl } from 'utils/CountryUtils'

//const SearchBarContainer = styled(motion.div)`
const SearchBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    position: relative;
`
const LineSeparator = styled.span`
    display: flex;
    min-width: 100%;
    min-height: 2px;
    background-color: #d8d8d878;
`

const SearchContent = styled.div`
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    padding: 1em;
    color: #000;
    overflow-y: auto;
    position: absolute;
    background: #fff;
    top: 2.6rem;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.06),
        0px 1px 1px 0px rgba(0, 0, 0, 0.042),
        0px 1px 3px 0px rgba(0, 0, 0, 0.036);
    z-index: 3000;
`

const LoadingWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
const NoResult = styled.div`
    font-size: 16px;
    color: #a1a1a1;
    margin-left: 10px;
    display: flex;
    align-self: center;
    justify-self: center;
`

const useStyles = makeStyles(({ palette, ...theme }) => ({
    root: {
        backgroundColor: '#fff',
        color: '#333',
        '&::placeholder': {
            color: '#999',
            fontSize: '12px',
        },
    },

    searchBoxHolder: {
        position: 'relative',
        width: '100%',
        top: 0,
        left: 0,
        borderRadius: 6,
        height: '2.5rem',
    },
    searchBox: {
        outline: 'none',
        border: 'none',
        borderRadius: 6,
        fontSize: '1rem',
        height: 'calc(100% - 5px)',
        padding: '0 1rem',
    },
}))

const containerTransition = { type: 'spring', damping: 22, stiffness: 150 }

const MatxSearchBox = () => {
    const { pathname } = useLocation()
    let country = getCountryFromUrl(pathname)||"ke"
    const [isExpanded, setExpanded] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [parentRef, isClickedOutside] = useClickOutside()
    const [isLoading, setIsLoading] = useState(false)
    const [searchResults, setSearchResults] = useState([])
    const [noResults, setNoResults] = useState(false)
    const inputRef = useRef()
    const history = useHistory()

    const isEmpty = !searchResults || searchResults.length === 0

    const expandContainer = () => {
        setExpanded(true)
    }
    const collapseContainer = () => {
        setExpanded(false)
        setSearchQuery('')
        setSearchResults([])
        setNoResults(false)
        setIsLoading(false)
        if (inputRef.current) {
            inputRef.current.value = ''
        }
    }
    const onChangeQuery = (e) => {
        e.preventDefault()
        if (e.target.value.trim() === '') {
            setSearchResults([])

            setNoResults(false)
        }
        setSearchQuery(e.target.value)
    }

    const prepareSearchQuery = (query) => {
        const url = `${process.env.REACT_APP_API_URL}search?query=${query}`
        return encodeURI(url)
    }
    const searchModel = async (query) => {
        if (!searchQuery || searchQuery.trim() === '') return
        setIsLoading(true)
        setNoResults(false)

        const URL = prepareSearchQuery(searchQuery)
        const { success, results } = await makeHttpRequest(
            `search?query=${query}`,
            'POST'
        )

        if (success) {
            if (results.models && results.models.length === 0)
                setNoResults(true)
            setSearchResults(results.models)
        } else {
            setNoResults(true)
        }
        setIsLoading(false)
    }

    useDebounce(searchQuery, 500, () => searchModel(searchQuery))

    const containerVariants = {
        expanded: {
            height: '20em',
        },
        collapsed: {
            height: '2rem',
        },
    }
    const classes = useStyles()

    useEffect(() => {
        if (isClickedOutside) collapseContainer()
    }, [isClickedOutside])

    const reroute = (modelName, _id) => {
        collapseContainer()
        history.push(
            `/${country}/ecommerce/details/${replaceWhiteSpaceWithHyphen(
                modelName
            )}`,
            { _id }
        )
    }
    return (
        <React.Fragment>
            <SearchBarContainer
                animate={isExpanded ? 'expanded' : 'collapsed'}
                variants={containerVariants}
                transition={containerTransition}
                ref={parentRef}
            >
                <div
                    className={clsx(
                        'flex items-center',
                        classes.root,
                        classes.searchBoxHolder
                    )}
                >
                    <input
                        className={clsx(
                            'px-4 search-box w-full',
                            classes.root,
                            classes.searchBox
                        )}
                        type="text"
                        placeholder="Search device here to purchase..."
                        value={searchQuery}
                        onChange={onChangeQuery}
                        onFocus={expandContainer}
                    />
                </div>
                {isExpanded && <LineSeparator />}
                {isExpanded && (
                    <SearchContent>
                        {isLoading && (
                            <LoadingWrapper>
                                <MoonLoader laoding color="#f68937" size={20} />
                            </LoadingWrapper>
                        )}
                        {!isLoading && isEmpty && !noResults && (
                            <LoadingWrapper>
                                <NoResult>
                                    <small>Start typing to search</small>
                                </NoResult>
                            </LoadingWrapper>
                        )}

                        {!isLoading && noResults && (
                            <LoadingWrapper>
                                <NoResult>
                                    <small>No devices found</small>
                                </NoResult>
                            </LoadingWrapper>
                        )}

                        {!isLoading && !isEmpty && (
                            <>
                                {searchResults.map((model) => {
                                    return (
                                        <SearchResult
                                            key={model.models._id}
                                            thumbnailSrc={model.image}
                                            name={model.models.modelName}
                                            inStock={model.instock}
                                            reroute={() =>
                                                reroute(
                                                    model.models.modelName,
                                                    model.models._id
                                                )
                                            }
                                        />
                                    )
                                })}
                            </>
                        )}
                    </SearchContent>
                )}
            </SearchBarContainer>
        </React.Fragment>
    )
}

export default MatxSearchBox
