import React, { useContext, useState } from 'react'

import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { Link, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Card, Grid, Button, CircularProgress, Hidden } from '@material-ui/core'
import history from 'history.js'
import clsx from 'clsx'
import { MatxLogo, MatxDivider, MatxSnackbar } from 'app/components'
import useAuth from 'app/hooks/useAuth'
import { AppContext } from 'app/contexts/AppContext'
import { getCountryFromUrl } from 'utils/CountryUtils'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    cardHolder: {
        // background: '#1A2038',
    },
    card: {
        maxWidth: 800,
        margin: '1rem',
    },
    cardLeft: {
        background: '#42c8b7 url(/assets/images/circles.png) no-repeat',
        backgroundSize: 'cover',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            minWidth: 200,
        },
    },
    logo: {
        display: 'flex',
        textAlign: '',
        justifyContent: 'center',
        '& span': {
            fontSize: 16,
            lineHeight: 1.3,
            fontWeight: 800,
            color: '#fff',
        },
        '& small': {
            display: 'block',
            fontWeight: 'normal',
        },
    },
    mainTitle: {
        fontSize: 18,
        lineHeight: 1.3,
        marginBottom: 5,
        marginTop: 20,
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    signinButton: {
        background: '#42c8b7',
        marginTop: '20px',
        color: '#fff',

        '&:hover': {
            backgroundColor: '#42c8b7',
            color: '#fff',
            opacity: 0.8,
        },
    },
}))

const JwtLogin = () => {
       const { pathname } = useLocation()
       let country = getCountryFromUrl(pathname)||"ke"
  
    const [loading, setLoading] = useState(false)
    const [userInfo, setUserInfo] = useState({
        phone: '',
        password: '',
    })
    const [error, setError] = useState({ status: false, message: '' })
    const {
        authMessage,
        resetNumber,
        login,
    } = useAuth()

    const classes = useStyles()

    const closeSnackbar = () => {
        resetNumber()
    }

    const handleUserChange = ({ target: { name, value } }) => {
        setUserInfo({ ...userInfo, [name]: value })
    }
    const handleSubmit = async (e) => {
        setLoading(true)
        try {
            e.preventDefault()
            const authenticated_user = await login(
                userInfo.phone,
                userInfo.password
            )

            if (!authenticated_user) {
                setError({ status: true, message: 'log in failed' })
                console.log('log in failed...')
                setLoading(false)
                return
            }
            setLoading(false)
            setUserInfo({ ...userInfo, phone: '', password: '' })

            history.push(`/${country}/device-details`)
        } catch (err) {
            console.log(err)
            setLoading(false)
            setUserInfo({ ...userInfo, phone: '', password: '' })
        }
    }

 

    return (
        <div
            className={clsx(
                'flex justify-center items-center  min-h-full-screen',
                classes.cardHolder
            )}
        >
            {authMessage && (
                <MatxSnackbar
                    open={authMessage}
                    message={authMessage}
                    handleClose={closeSnackbar}
                />
            )}
            <Card className={classes.card}>
                <Grid container>
                    <Hidden>
                        <Grid item lg={6} md={6} sm={5} xs={12}>
                            <div
                                className={clsx({
                                    'py-8 px-14 h-full': true,
                                    [classes.cardLeft]: true,
                                })}
                            >
                                <Link className={classes.logo} to="/">
                                    <MatxLogo className="mr-2 mb-3" />{' '}
                                    <span>
                                        Badili <small>Uza Chapchap</small>
                                    </span>
                                </Link>
                                <h1 className={classes.mainTitle}>
                                    Welcome Back!
                                </h1>
                                <p>
                                    We missed you when you were gone. Sign in
                                    here to sell your device at the best price.
                                </p>
                                <img
                                    className="w-300 my-5"
                                    src="/assets/images/illustrations/posting_photo.svg"
                                    alt=""
                                />

                                <span className="flex-grow"></span>
                                <div className="flex items-center">
                                    <span className="text-12">
                                        Copyright &copy;{' '}
                                        {new Date().getFullYear()}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                    </Hidden>
                    <Grid item lg={6} md={6} sm={7} xs={12}>
                        <div className="px-8 pt-8 font-semibold ">
                            Sign In to enjoy more of our services
                        </div>

                        <div className="p-8 h-full relative">
                            <ValidatorForm
                                onError={(errors) => console.log(errors)}
                                onSubmit={handleSubmit}
                            >
                                <TextValidator
                                    className="mb-4 w-full"
                                    variant="outlined"
                                    size="small"
                                    label="Mobile Number"
                                    onChange={handleUserChange}
                                    type="text"
                                    name="phone"
                                    value={userInfo.phone}
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                />
                                <React.Fragment>
                                    {/* <small
                                            className="mb-4 mt-1"
                                            style={{
                                                textAlign: 'right',
                                                display: 'block',
                                            }}
                                        >
                                            Wrong phone?{' '}
                                            <Link
                                                to=""
                                                className="text-primary"
                                                onClick={() => {
                                                    resetNumber()
                                                }}
                                            >
                                                change
                                            </Link>
                                        </small> */}
                                    <TextValidator
                                        className="mb-3 w-full"
                                        label="Password"
                                        variant="outlined"
                                        size="small"
                                        onChange={handleUserChange}
                                        name="password"
                                        type="password"
                                        value={userInfo.password}
                                        validators={['required']}
                                        errorMessages={[
                                            'this field is required',
                                        ]}
                                    />
                                </React.Fragment>
                                <small>
                                    By continuing, you agree to Badili's{' '}
                                    <Link
                                        to={`/${country}/how-it-works`}
                                        className="text-primary"
                                    >
                                        Conditions of Use
                                    </Link>{' '}
                                    and{' '}
                                    <Link
                                        to={`/${country}/how-it-works`}
                                        className="text-primary"
                                    >
                                        Privacy Notice.
                                    </Link>
                                </small>
                                <div className="relative">
                                    <Button
                                        variant="contained"
                                        disableElevation
                                        color="primary"
                                        disabled={loading}
                                        type="submit"
                                        fullWidth
                                        className={classes.signinButton}
                                    >
                                        Login
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </Button>
                                </div>
                                {/* {authMessage && !errorMessage && (
                                    <p className="text-small text-center mt-1 mb-5">
                                        Didn't receive a code?
                                        <Link
                                            to=""
                                            onClick={(e) => {
                                                e.preventDefault()
                                                resendOtp()
                                            }}
                                        >
                                            {''} <u>resend</u>
                                        </Link>
                                    </p>
                                )} */}
                                <MatxDivider
                                    className="my-6 px-8"
                                    text="New to Badili ?"
                                />
                                Create your account
                                {''}
                                <Link to="/session/signup">
                                    {''} <u>here</u>
                                </Link>
                            </ValidatorForm>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </div>
    )
}

export default JwtLogin
