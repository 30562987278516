import { Card } from '@material-ui/core'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { getCountryFromUrl } from 'utils/CountryUtils'
import { devices } from 'utils/screen_sizes'
import { replaceWhiteSpaceWithHyphen } from 'utils/string_utils'

const StyledMobileWrapper = styled.div`
    @media ${devices.mobileS} {
        display: flex;
        flex-direction: column;
     
    }
    @media ${devices.mobileL} {
        display: flex;
        flex-direction: column;
       /* img{
        flex-basis:33.33%; 
       }
       div{
        flex-basis:100%;
        margin-left: 0px;
       } */
    }
    @media ${devices.laptop} {
        display: block
        margin-left: 0px;
    }
`

const StyledProductCard = styled(Card)`
    border-radius: 4px;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    position: relative;
    padding: 1rem;
    margin-top: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer

       @media ${devices.mobileM} {
            margin-top: 1.5rem
            font-size: 1rem;
        }
        @media ${devices.laptop} {
            margin-top: 1.2rem;
        }


    .bestseller {
        background-color: #f3f5f7;
        padding: 2px 0.5rem;
        /* box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2); */
        font-size: 12px;
        font-weight: 600;
        color: #000;
        width: fit-content;
        border-radius: 2px;
        font-family: 'lato';
        text-transform: capitalize;
    }
    img {
        object-fit: contain;
        /* width: fit-content; */
        max-height: 155px;
        margin: 1.5rem auto 0 auto;

        @media ${devices.mobileS} {
            width: 100%;
           margin: 1.3rem auto 0 auto;
               max-height: 110px;
        }
        @media ${devices.laptop} {
            width: 100%;
            margin: 1.5rem auto 0 auto;
               max-height: 155px;
        }
    }
    h4 {
        margin-top: 1.2rem;
        font-size: 1rem;
        margin-bottom: .5rem;
        
        @media ${devices.mobileS} {
            margin-top: 1.5rem
            font-size: 1rem;
        }
        @media ${devices.mobileM} {
            margin-top: 1.5rem
            font-size: 1rem;
        }
        @media ${devices.laptop} {
            margin-top: 1.2rem;
        }
    }
    p {
        margin: 0;
        font-size: 13px;
        @media ${devices.mobileS} {
            font-size: 0.9rem;
        }
        @media ${devices.mobileM} {
            font-size: 0.9rem;
        }
        :last-child {
            color: #777;
            /* margin-top: .5rem; */
            span {
                text-decoration: line-through;
                margin-right: 10px;
            }
            b {
                color: #000;
                font-weight: 600;
                margin-left: 5px;
                @media ${devices.mobileS} {
                    display: inline-block;
                    float: none;
                }
                @media ${devices.mobileM} {
                    display: inline-block;
                    float: none;
                }
            }
        }
    }
`

const ProductCard = ({
    imageUrl,
    title,
    initialPrice,
    discountedPrice,
    currency = 'KES.',
    _id,
    variantId,
}) => {
    const { pathname } = useLocation()
    let country = getCountryFromUrl(pathname)||"ke"
    const history = useHistory()
    return (
        <StyledProductCard
            available={true}
            onClick={() =>
                history.push({
                    pathname: `/${country}/ecommerce/details/${replaceWhiteSpaceWithHyphen(
                        title
                    )}`,
                    state: {
                        _id,
                        variantId,
                    },
                })
            }
        >
            <StyledMobileWrapper>
                <div className="bestseller">bestseller</div>
                <img src={imageUrl} alt="phone" />
                <div>
                    <h4> {title}</h4>

                    <p>Warranty : 12months</p>
                    <p>
                        Starting from
                        {/* <span>{`${
                            currency +
                            ' ' +
                            discountedPrice.toLocaleString('en-US')
                        }`}</span> */}
                        <b>{`${
                            currency +
                            ' ' +
                            initialPrice.toLocaleString('en-US')
                        }`}</b>
                    </p>
                </div>
            </StyledMobileWrapper>
        </StyledProductCard>
    )
}

export default ProductCard
