import React from 'react'
import styled, { keyframes } from 'styled-components'

const loading = keyframes`
   0% { transform: translateX(-150%) }
   25% { transform: translateX(-100%) }
  50% { transform: translateX(-60%) }
  75% { transform: translateX(100%) }
  100% { transform: translateX(150%) }
`

const StyledShimmerWrapper = styled.div`
    position: absolute;
    /* parent must be relative position & overflow hidden */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation-name: ${loading};
    animation-duration: 2s;
    animation-iteration-count: infinite;
`
const StyledShimmer = styled.div`
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255,0.2);
    transform: skewX(-20deg);
    box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.2);
    & .light {
        background: #f2f2f2;
    }
`

const Shimmer = () => {
    return (
        <StyledShimmerWrapper>
            <StyledShimmer />
        </StyledShimmerWrapper>
    )
}

export default Shimmer
