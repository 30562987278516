const Layout3Settings = {
    mode: 'contained', // boxed | contained | full
    topbar: {
        show: true,
        fixed: true,
        theme: 'slateDark1',
    },
    perfectScrollbar:true,
    navbar: {
        show: true,
        theme: 'badiliEcommerce',
    },
    leftSidebar: {
        show: true,
        mode: 'close', // full, close, compact, mobile,
        theme: 'badiliEcommerce', // View all valid theme colors inside MatxTheme/themeColors.js
        bgImgURL: '/assets/images/sidebar/sidebar-bg-dark.jpg',
    },
}

export default Layout3Settings
