
export const sizes = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    tabletL: '1000px',
    laptop: '1224px',
    laptopL: '1440px',
    desktop: '1800px',
    desktopL: '1400px',
};
export const devices = {
mobileS: `(min-width: ${sizes.mobileS})`,
mobileM: `(min-width: ${sizes.mobileM})`,
mobileL: `(min-width: ${sizes.mobileL})`,
tablet: `(min-width: ${sizes.tablet})`,
tabletL: `(min-width: ${sizes.tabletL})`,
laptop: `(min-width: ${sizes.laptop})`,
laptopL: `(min-width: ${sizes.laptopL})`,
desktop: `(min-width: ${sizes.desktop})`,
desktopL: `(min-width: ${sizes.desktopL})`,
};