import styled from 'styled-components'

export const StyledImage = styled.div`         
    .smooth-image {
        transition: opacity .5s ease-in-out;
    }
    .image-visible {
        opacity: 1;
    }
    .image-hidden {
        opacity: 0;
    }
`