import React from 'react'
import styled from 'styled-components'
import { devices } from 'utils/screen_sizes'
import { Card, Hidden } from '@material-ui/core'
import SkeletonElement from './SkeletonElement'
import Shimmer from './Shimmer'

const StyledMobileWrapper = styled.div`
    @media ${devices.mobileS} {
        display: flex;
       img{
        flex-basis:33.33%; 
       }
       div{
        flex-basis:66.66%;
        margin-left: 15px;
       }
    }
    @media ${devices.mobileL} {
        display: flex;
        flex-direction: column;
       img{
        flex-basis:33.33%; 
       }
       div{
        flex-basis:100%;
        margin-left: 0px;
       }
    }
    @media ${devices.laptop} {
        display: block}
        margin-left: 0px;
    }
`

const StyledProductCard = styled(Card)`
    border-radius: 4px;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    position: relative;
    padding: 1rem;
    margin: 5px 0.5rem 0 0.5rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

    :hover {
        cursor: pointer;
    }

    .bestseller {
        background-color: ${(props) =>
            props.available ? 'rgb(44, 179, 74)' : '#f68937'};
        padding: 2px 0.3rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

        font-size: 12px;
        font-weight: 600;
        position: absolute;
        top: 0px;
        right: 0px;
        color: #fff;
    }
    .img {
        min-height: 155px;
        margin: 1.5rem auto 0 auto;

        @media ${devices.mobileS} {
            width: 100%;
            margin: 1rem auto 0 auto;
            max-height: 110px;
        }
        @media ${devices.laptop} {
            width: 100%;
            margin: 1.5rem auto 0 auto;
            max-height: 155px;
        }
    }
    h4 {
        margin-top: 1.2rem;
        font-size: 1rem;
        @media ${devices.mobileS} {
            margin-top: 1rem;
            font-size: 1rem;
        }
        @media ${devices.mobileM} {
            margin-top: 1rem;
            font-size: 1rem;
        }
        @media ${devices.laptop} {
            margin-top: 1.2rem;
        }
    }
    p {
        margin: 0;
        font-size: 13px;
        @media ${devices.mobileS} {
            font-size: 0.9rem;
        }
        @media ${devices.mobileM} {
            font-size: 0.9rem;
        }
        :last-child {
            color: #777;
            margin-top: 1rem;
            span {
                text-decoration: line-through;
                margin-right: 10px;
            }
            b {
                color: #000;
                font-weight: 600;
                @media ${devices.mobileS} {
                    display: block;
                    float: right;
                }
                @media ${devices.mobileM} {
                    display: inline-block;
                    float: none;
                }
            }
        }
    }
`
const StyledSkeletonWrapper = styled.div`
    margin: 20px auto;
    padding: 10px 15px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    &.light {
        background: #f2f2f2;
    }
    &.dark {
        background: #444;
    }
`

const SkeletonProductCard = ({ theme }) => {
    const themeClass = theme || 'light'
    return (
        <StyledProductCard>
            <StyledMobileWrapper>
                {/* <Shimmer /> */}
                <SkeletonElement type="block-small" />
                <SkeletonElement type="block-big" />
                <SkeletonElement type="block-big" />
                <SkeletonElement type="block-small" />
                <SkeletonElement type="block-big" />
                <SkeletonElement type="block-big" />
            </StyledMobileWrapper>
        </StyledProductCard>
    )
}

export default SkeletonProductCard
