import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { devices } from 'utils/screen_sizes'
import Slider from 'react-slick'

export const StyledSlider = styled.div`
    clear: both;

    .slider-galeria-thumbs {
        /* float: left;
        width: 25%; */
        /* max-height: 350px; */
        /* display: flex;
        justify-content: center;
        align-items: center; */
        margin-top: 40px;

        .slick-list {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .slider-galeria {
        /* float: left;
        width: 70%; */
        margin: 0px 10px 0 10px;
        @media ${devices.mobileS} {
            /* width: 60%; */
            margin: 0 20px 0 20px;
        }
    }

    .slider-galeria div,
    .slider-galeria-thumbs div {
        outline: 0;
    }

    .slider-galeria img {
        display: block;
        max-height: 350px;
        width: 100%;
        line-height: 350px;
        /* margin-top: 40px; */
        /* margin: 0 10px 0 10px; */
        text-align: center;
        object-fit: contain;
    }

    .slider-galeria-thumbs img {
        /* display: block; */
        margin-bottom: 5px;
        height: 55px;
        width: 55px;
        object-fit: contain;
        cursor: pointer;
        /* width: 75px; */
        /* background: #3498db;
        color: #000; */
        font-size: 14px;
        line-height: 75px;
        text-align: center;

        @media ${devices.mobileS} {
            height: 55px;
            width: 55px;
            float: right;
        }
        @media ${devices.laptop} {
            height: 75px;
            width: 75px;
            float: right;
        }
    }
    /* .slick-slide {
        display: flex;
        align-items: center;
    } */

    .slider-galeria-thumbs .slick-current img {
        border: 1px solid #f68937;
        padding: 0.5rem;
    }

    /* .slick-prev {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 40px;
        text-indent: -9999px;
        background: transparent;
        border: 0;
        z-index: 1000;
        outline: 0;
    }

    .slick-prev:before {
        content: '<';
        color: #fff;
        font-size: 60px;
        line-height: 0;
        text-indent: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .slick-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 40px;
        text-indent: -9999px;
        background: transparent;
        border: 0;
        z-index: 1000;
        outline: 0;
    }

    .slick-next:before {
        content: '>';
        color: #fff;
        font-size: 60px;
        line-height: 0;
        text-indent: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    } */
`

export default function PhoneSlider({ slidesData }) {
    const [nav1, setNav1] = useState(null)
    const [nav2, setNav2] = useState(null)
    const [slider1, setSlider1] = useState(null)
    const [slider2, setSlider2] = useState(null)

    useEffect(() => {
        setNav1(slider1)
        setNav2(slider2)
    }, [slider1, slider2])

    const settingsMain = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        infinite: false,
        asNavFor: '.slider-nav',
    }

    const settingsThumbs = {
        slidesToShow: 10,
        slidesToScroll: 1,
        arrows: true,
        asNavFor: '.slider-for',
        // vertical: true,
        // verticalSwiping: true,
        focusOnSelect: true,
        infinite: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 10,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 10,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                },
            },
        ],
    }

    return (
        <StyledSlider>
            <div className="slider-galeria">
                <Slider
                    {...settingsMain}
                    asNavFor={nav2}
                    ref={(slider) => setSlider1(slider)}
                >
                    {slidesData.map((slide, i) => (
                        <div className="slick-slide" key={i}>
                            <img
                                className="slick-slide-image"
                                src={slide.upload}
                                alt=""
                            />
                        </div>
                    ))}
                </Slider>
            </div>
            <div className="slider-galeria-thumbs">
                <Slider
                    {...settingsThumbs}
                    asNavFor={nav1}
                    ref={(slider) => setSlider2(slider)}
                >
                    {slidesData.map((slide, i) => (
                        <div className="slick-slide" key={i}>
                            <img
                                className="slick-slide-image"
                                src={slide.upload}
                                alt=""
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        </StyledSlider>
    )
}
