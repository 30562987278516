import React from 'react'
import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { StyledButton } from './ListItem1.style'
import PropTypes from 'prop-types'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    root: {
        cursor: 'pointer',
        borderBottom: '1px dashed #ddd',
        transition: 'all 300ms ease',
        '& :last-child': {
            borderBottom: 'none',
        },
        '& h6': {
            color: '#000',
        },

        '&:hover': {
            paddingLeft: '8px',
            overflow: 'hidden',
            '& .action-icon, & .rectangle-box': {
                opacity: 1,
                background: 'transparent',
            },
        },
        '& .action-icon': {
            color: '#000',
        },
        '& .button': {
            display: 'none',
        },
    },
}))

const MatxListItem1 = ({
    listItems,
    actionFn,
    btnText,
    hasButton = false,
    btnIcon,
}) => {
    const classes = useStyles()

    return (
        <div>
            {listItems.map((listItem, index) => (
                <div
                    key={index}
                    className={clsx(
                        'py-2 flex items-center border-0',
                        classes.root
                    )}
                    id={`list${index}`}
                >
                    <div className="ml-4 flex-grow">
                        <h6 className="m-0 text-17 font-normal ">
                            {listItem.title}
                        </h6>
                    </div>

                    {(listItem.add_text || listItem.right_text) && (
                        <IconButton className="action-icon">
                            <h6 className="m-0 text-14 font-normal ">
                                <span
                                    className="mr-2"
                                    style={{
                                        color: '#42c8b7',
                                    }}
                                >
                                    {listItem.add_text}
                                </span>

                                {listItem.right_text}
                            </h6>
                        </IconButton>
                    )}
                </div>
            ))}

            {hasButton && (
                <StyledButton onClick={actionFn}>
                    {btnText}{' '}
                    {btnIcon && (
                        <span className="ml-2">
                            <i className={btnIcon} />
                        </span>
                    )}
                </StyledButton>
            )}
        </div>
    )
}

export default MatxListItem1

MatxListItem1.prototype = {
    listItems: PropTypes.array.isRequired,
    actionFn: PropTypes.function,
    btnText: PropTypes.string,
    hasButton: PropTypes.bool,
    btnIcon: PropTypes.string,
}
