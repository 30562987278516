import React, { useContext, useEffect } from 'react'
import {
    Grid,
    Divider,
    Card,
    TextField,
    IconButton,
    Icon,
    Button,
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import {
    deleteProductFromCart,
    updateCartQuantity,
} from 'app/redux/actions/EcommerceActions'
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import useAuth from 'app/hooks/useAuth'
import {
    StyledCartPage,
    StyledProductImage,
    CartItem,
} from '../../styles/checkout.style'
import { AppContext } from 'app/contexts/AppContext'
import { getCountryFromUrl } from 'utils/CountryUtils'

// import {handleFetchNewImageUrl} from 'app/services/appService'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    cart: {
        minWidth: 900,
    },
}))

const Cart = () => {
    const { cartList = [] } = useSelector((state) => state.ecommerce)
    const _user = useSelector((state) => state.user)
    const { user } = useAuth()
    const { pathname } = useLocation()
    let country = getCountryFromUrl(pathname)||"ke"

    const dispatch = useDispatch()
    const history = useHistory()
    const classes = useStyles()

    const getTotalCost = () => {
        let totalCost = 0
        cartList.forEach(({ variant: { quantity = 0, price = 0 } }) => {
            totalCost += quantity * price
        })
        return totalCost
    }

    const handleChange = (event, variant) => {
        let quantity = event.target.value
        dispatch(
            updateCartQuantity(
                cartList,
                variant._id,
                variant,
                Math.abs(quantity)
            )
        )
    }

    const handleDeleteFromCart = (cartList, _id) => {
        dispatch(deleteProductFromCart(cartList, _id))
    }

    const handleCheckoutClick = () => {
        if (user && cartList.length > 0) {
            history.push(`/${country}/ecommerce/checkout`)
        } else {
            window._toggleDrawer(`/${country}/ecommerce/checkout`)
        }
    }

    useEffect(() => {
        return () => {}
    }, [cartList])

    return (
        <StyledCartPage>
            <Card elevation={3} className={clsx(classes.cart)}>
                <div className="py-4 px-4">
                    <Grid container>
                        <Grid item lg={3} md={3} sm={3} xs={3}></Grid>
                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            <h6 className="m-0">Name</h6>
                        </Grid>
                        <Grid
                            item
                            lg={true}
                            md={true}
                            sm={true}
                            xs={true}
                            className="text-center"
                        >
                            <h6 className="m-0">saleprice</h6>
                        </Grid>
                        <Grid
                            item
                            lg={true}
                            md={true}
                            sm={true}
                            xs={true}
                            className="text-center"
                        >
                            <h6 className="m-0">Quantity</h6>
                        </Grid>
                        <Grid
                            item
                            lg={true}
                            md={true}
                            sm={true}
                            xs={true}
                            className="text-center"
                        >
                            <h6 className="m-0">Total</h6>
                        </Grid>
                    </Grid>
                </div>
                <Divider></Divider>

                {cartList.map(
                    ({
                        image,
                        model,
                        model: { modelName },
                        variant: {
                            storage,
                            ram,
                            color,
                            _id,
                            saleprice,
                            quantity,
                        },
                    }) => (
                        <CartItem key={_id} className="py-4 px-4">
                            <Grid container alignItems="center">
                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                    <div className="flex items-center">
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                handleDeleteFromCart(
                                                    cartList,
                                                    _id
                                                )
                                            }
                                        >
                                            <Icon fontSize="small">clear</Icon>
                                        </IconButton>
                                        <div className="px-4">
                                            <StyledProductImage
                                                src={image}
                                                alt={modelName}
                                                // onError={({target})=>handleFetchNewImageUrl(target, model)}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item lg={4} md={4} sm={4} xs={4}>
                                    <p>{modelName}</p>
                                    <p className="mt-2 m-0 text-muted">
                                        {`${ram}GB/${storage}GB (${color})`}
                                    </p>
                                </Grid>
                                <Grid
                                    item
                                    lg={true}
                                    md={true}
                                    sm={true}
                                    xs={true}
                                    className="text-center"
                                >
                                    <p>Ksh {saleprice.toLocaleString()}</p>
                                </Grid>
                                <Grid
                                    item
                                    lg={true}
                                    md={true}
                                    sm={true}
                                    xs={true}
                                    className="text-center"
                                >
                                    <TextField
                                        variant="outlined"
                                        name="quantity"
                                        type="number"
                                        size="small"
                                        value={quantity}
                                        onChange={(e) =>
                                            handleChange(e, { _id, quantity })
                                        }
                                        inputProps={{
                                            style: {
                                                // padding: "10px",
                                                width: '60px',
                                            },
                                        }}
                                    ></TextField>
                                </Grid>
                                <Grid
                                    item
                                    lg={true}
                                    md={true}
                                    sm={true}
                                    xs={true}
                                    className="text-center"
                                >
                                    <p>
                                        Ksh{' '}
                                        {(
                                            saleprice * quantity
                                        ).toLocaleString()}
                                    </p>
                                </Grid>
                            </Grid>
                        </CartItem>
                    )
                )}

                <div>
                    <Divider className="mb-12"></Divider>
                    <Grid container className="mb-12 px-4">
                        <Grid item lg={3} md={3} sm={3} xs={3}></Grid>
                        <Grid item lg={4} md={4} sm={4} xs={4}></Grid>
                        <Grid
                            item
                            lg={true}
                            md={true}
                            sm={true}
                            xs={true}
                        ></Grid>
                        <Grid
                            item
                            lg={true}
                            md={true}
                            sm={true}
                            xs={true}
                            className="text-center"
                        >
                            <h6 className="m-0">Total</h6>
                        </Grid>
                        <Grid
                            item
                            lg={true}
                            md={true}
                            sm={true}
                            xs={true}
                            className="text-center"
                        >
                            <h6 className="m-0">
                                Ksh {getTotalCost().toLocaleString()}
                            </h6>
                        </Grid>
                    </Grid>
                    <div className="flex items-center mb-4 px-4">
                        {/*  
                            <TextField
                                variant="outlined"
                                placeholder="Discount Coupon"
                                className="flex-grow"
                                size="small"
                        />
                            <Button
                                className="mx-3"
                                variant="contained"
                                color="secondary"
                            >
                                Apply
                            </Button>
                        */}
                        <span className="flex-grow" />
                        <span />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCheckoutClick}
                        >
                            Checkout
                        </Button>
                    </div>
                </div>
            </Card>
        </StyledCartPage>
    )
}

export default Cart
