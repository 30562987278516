import React, { useState, useEffect, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Hidden, Button, Icon, Drawer } from '@material-ui/core'

import ShopSidenav from '../ShopSidenav/ShopSidenav'

import { MatxLoading, MatxSidenav, MatxSidenavContent } from 'app/components'

import { useDispatch, useSelector } from 'react-redux'
import {
    getBrands,
    getInventory,
    updateInventory
} from 'app/redux/actions/EcommerceActions'
import { AppContext } from 'app/contexts/AppContext'

import styled from 'styled-components'

import { SimpleCard } from 'app/components'
import { devices } from '../../../utils/screen_sizes'
import { API_URL } from 'utils/api_utils'
import Axios from 'axios'
import { API_KEY, API_SECRET } from 'utils/api_utils'
import { useParams } from 'react-router-dom';

const country = localStorage.getItem('country')

const StyledShopWrapper = styled.div`
    @media ${devices.desktopL} {
        width: 90vw;
        margin-left: auto;
        margin-right: auto;
    }
`
const StyledLoadingSection = styled.div`
    min-height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        max-height: 48px;
    }
    @media ${devices.desktopL} {
        width: 90vw;
        margin-left: auto;
        margin-right: auto;
    }
`
const StyledButton = styled(Button)`
    background: #f68937;
    border-radius: 0 4px 4px 0;
    margin-bottom: 2rem;
    font-family: 'open-sans';
`

const useStyles = makeStyles({
    paper: {
        width: 250,
    },
})

const EcommerceContainer = ({ children }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const classes = useStyles()
    const { filter, setFilter, count, page, setPage, PER_PAGE, reload, setReload, updateInv ,filterBrand,filterPrice,setFilterBrand,setFilterPrice } =
        useContext(AppContext)
    const { inventory = [], loadingInventory } = useSelector(
        (state) => state.ecommerce
    )
    const [open, setOpen] = useState(false)
    const [country, setCountry] = useState('ke');

    const [currentPage, setCurrentPage] = useState(1)
    const maxPage = Math.ceil(inventory.length / PER_PAGE)
    const scrollRef = useRef(null)
    const executeScroll = () =>
        scrollRef.current.scrollIntoView({ behavior: 'smooth' })

    function currentData() {
        let end = page * PER_PAGE
        let begin = end - PER_PAGE
        if (inventory.slice(begin, end).length === 0) {
            return inventory
        } else {
            return inventory.slice(begin, end)
        }
    }



    function jump(page) {
        const pageNumber = Math.max(1, page)
        setPage((currentPage) => Math.min(pageNumber, maxPage))
    }

    const handlePageChange = (e, p) => {
        executeScroll()
        setPage(p)
        jump(p)
    }

    const toggleSidenav = () => {
        setOpen(!open)
    }
    const handleFilterChange = (event, value, page = 1,) => {

        setReload(false)
        getInv(value, page)

    }
   

   
    const { params } = useParams();

    useEffect(() => {
        if (window.location.hostname === 'badili.africa') {
            setCountry('ke');
          } else if (window.location.hostname === 'badili.co.tz') {
            setCountry('tz');
          }else if (window.location.hostname === 'badili.ug') {
            setCountry('ug');
          } else {
            setCountry('ke');
          }
      dispatch(getBrands())
        setReload(false)
        
    }, [])

    
    const handlePriceFilter = async (filterPrice) => {
      
      if(filterBrand){ filterPhones(filterBrand , filterPrice)}
      else if(!filterBrand){
          dispatch(getInventory(filterPrice))
      }
setFilterPrice(filterPrice)
        setFilter(filterPrice)    
          setReload(false)

    };

    const handleBrandFilter = async (filterBrand) => {
     
        console.log('see',filterPrice,filterBrand);
        setFilterBrand(filterBrand)
        setFilter(filterBrand);
        if(filterPrice){ filterPhones(filterBrand , filterPrice)}
        else if(!filterPrice){
            dispatch(getInventory(filterBrand))
        }
        
        setReload(false)
        

    };
    const headers = {
        'Content-Type': 'application/json',
        Key: API_KEY,
        Secret: API_SECRET,
        country,
        
    }

    const getInv = (value, page ,filterPrice ,filterBrand) => {
        console.log(value);
        setReload(false)
        dispatch(getInventory(value))

    }

    const filterPhones = async (filterPrice,filterBrand) => {
       
            const res = await Axios.get(API_URL + `available_models?${filterPrice}&${filterBrand}`, {
                headers: headers,
            })
            
            updateInv(res.data.inventory)
            dispatch(updateInventory( filterPrice,filterBrand))
            setReload(false)
            setPage(1)

    }
    console.log(inventory);
    return (
        <StyledShopWrapper className="shop  my-10 relative  flex h-full">
            {/* add class m-sm-30 next to h-full */}
            <Hidden mdDown>
                <MatxSidenav
                    width="288px"
                    open={open}
                    toggleSidenav={toggleSidenav}
                >
                    <ShopSidenav
                        filter={filter}
                        setFilter={setFilter}
                        loadingInventory={loadingInventory}
                        toggleSidenav={toggleSidenav}
                        handleFilterChange={handleFilterChange}
                        handleBrandFilter={handleBrandFilter}
                        handlePriceFilter={handlePriceFilter}
                        filterPrice={filterPrice}
                        filterBrand={filterBrand}
                        setFilterPrice={setFilterPrice}
                        setFilterBrand={setFilterBrand}


                    />
                </MatxSidenav>
            </Hidden>
            <Hidden mdUp>
                <Drawer
                    anchor="left"
                    open={open}
                    onClose={toggleSidenav}
                    classes={{ paper: classes.paper }}
                >
                    <ShopSidenav
                        filter={filter}
                        setFilter={setFilter}
                        loadingInventory={loadingInventory}
                        toggleSidenav={toggleSidenav}
                        handleFilterChange={handleFilterChange}
                        handleBrandFilter={handleBrandFilter}
                        handlePriceFilter={handlePriceFilter}
                        filterPrice={filterPrice}
                        filterBrand={filterBrand}
                        setFilterPrice={setFilterPrice}
                        setFilterBrand={setFilterBrand}

                    />
                </Drawer>
            </Hidden>
            <MatxSidenavContent>
                <div className="flex items-center justify-between ">
                    <Hidden mdUp>
                        <StyledButton
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={toggleSidenav}
                        // style={{ backgroundColor: '#0C8085 ' }}
                        >
                            <Icon style={{ marginRight: '7px' }}>
                                filter_list
                            </Icon>{' '}
                            Explore
                        </StyledButton>
                    </Hidden>
                </div>

                <div style={{ padding: '0 1rem' }}>
                    {' '}
                    {React.cloneElement(children, {
                        loadingInventory,
                        inventory,
                        count,
                        PER_PAGE,
                        setPage,
                        reload,
                        page,
                        jump,
                        getInv,
                        currentData,
                        handlePageChange,
                        refProp: scrollRef,
                    })}
                </div>
            </MatxSidenavContent>
        </StyledShopWrapper>
    )
}

export default EcommerceContainer
