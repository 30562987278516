import React, { Component, useState } from 'react'
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react'
import { StyledMapWrapper } from './GoogleMap.style'

const mapStyles = {
    width: '100%',
    height: '100%',
    position: 'relative',
}
// const containerStyle = {
//   position: "relative",
//   width: "100%",
//   height: "100%",
// };

export function GoogleMap(props) {
    const [mapInfo, setMapInfo] = useState({
        showingInfoWindow: false, // Hides or shows the InfoWindow
        activeMarker: {}, // Shows the active marker upon click
        selectedPlace: {},
    })

    const onMarkerClick = (props, marker, e) =>
        setMapInfo({
            ...mapInfo,
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
        })

    const onClose = (props) => {
        if (mapInfo.showingInfoWindow) {
            setMapInfo({
                ...mapInfo,
                showingInfoWindow: false,
                activeMarker: null,
            })
        }
    }

    return (
        <StyledMapWrapper>
            <Map
                // containerStyle={containerStyle}
                google={props.google}
                zoom={14}
                style={mapStyles}
                initialCenter={{
                    lat: props.lat,
                    lng: props.lng,
                }}
            >
                <Marker onClick={onMarkerClick} name={`${props.name}`} />
                <InfoWindow
                    marker={mapInfo.activeMarker}
                    visible={mapInfo.showingInfoWindow}
                    onClose={onClose}
                ></InfoWindow>
            </Map>
        </StyledMapWrapper>
    )
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(GoogleMap)
