export const defaultRequestBody = {
    workingPhone: "",
    brokenScreen: "no",
    scratchedScreen: "no",
    spotsOnScreen: "no",
    scratchedBody: "no",
    dentedPhone: "no",
    selfieCamera: "yes",
    mainCamera: "yes",
    cameraGlass: "no",
    backglass: "no",
    bluetooth: "yes",
    WiFi: "yes",
    infrared: "yes",
    flashlight: "yes",
    facesensor: "yes",
    simcardTray: "yes",
    earpiece: "yes",
    vibrator: "yes",
    battery: "no",
    chargingPort: "yes",
    powerButton: "yes",
    silentButton: "yes",
    speakers: "yes",
    microphone: "yes",
    fingerScanner: "yes",
    volumeButtons: "yes",
    pen: "no",
    charger: "no",
    cable:"no",
    box:"no",
    earphones: "no",
    reciept: "no",
    warranty: "no",
    deviceOn:""
}