import React, { useContext, useState } from 'react'
import { Icon, Hidden, MenuItem } from '@material-ui/core'
import {
    MatxMenu,
    MatxToolbarMenu,
    MatxSearchBox,
    MatxDivider,
} from 'app/components'
import styled from 'styled-components'

import ShoppingCart from 'app/components/ShoppingCart/ShoppingCart'

import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import useSettings from 'app/hooks/useSettings'
import useAuth from 'app/hooks/useAuth'
import { useHistory, useLocation } from 'react-router-dom'

import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { getCountryFromUrl } from 'utils/CountryUtils'
import { availableCountries } from 'utils/Country'
import { devices } from 'utils/screen_sizes'
import PhoneNumberDrawer from 'app/components/SideDrawer/PhoneNumberDrawer'
import MenuDrawer from './Drawer'


const StyledTopBar = styled.div`
    background-color: #fff;
    /* color: #fff; */
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    align-items: center;

    span {
        display: flex;
        align-items: center;
    }

    button {
        color: #42c8b7;
    }

    .px-7 {
        color: #fff;
        background-color: #42c8b7;
    }

    .search-bar {
        grid-column: span 3;
    }

    @media ${devices.laptop} {
        padding: 0.8rem 2rem;
        display: grid;
        grid-template-columns: 0.8fr 2fr 1fr 0.2fr;
       
    }
`

const useStyles = makeStyles(({ palette, ...theme }) => ({
    root: {
        backgroundColor: '#2b313d',
        backgroundSize: 'cover',
        borderBottom: '1px solid transparent',

        zIndex: 45,
        paddingLeft: '1rem',
        paddingRight: '1rem',
        [theme.breakpoints.down('sm')]: {
            padding: '1rem',
        },
    },
   
    menuItem: {
        minWidth: 60,
        display: 'flex',
        alignItems: 'center',
    },
}))

const StyledMobileLogo = styled.img`
    width: 100px;

    @media ${devices.laptop} {
    }
`
const StyledUserSpan = styled.span`
    display: flex;
    flex-direction: row;
    justify-content: end;

    button {
        color: #fff;
    }
`

const StyledUser = styled.div`
    display: flex;

    div,
    span {
        line-height: normal;
        margin: auto 0.2rem;
    }

    span {
        cursor: pointer;
    }
`

const MobileUserMenu = styled.div`
    div {
        color: #555 !important;
    }

    @media ${devices.laptop} {
        display: none;
    }
`

const StyledMobileLogoLink = styled(Link)`
    justify-content: center;
    @media ${devices.laptop} {
        margin-right: 4rem;
    }
`

const StyledMenuSpan = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
        color: #000;
    }
`

const StyledRelativeWrapper = styled.div`
    grid-column: span 3;
`
const CountrySelector = styled.select`
    background: #fff;
    border: 1px solid #fff;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    box-shadow: none;
    font-size: 14px;
    margin: 0 1rem;

    @media ${devices.mobileS} {
        border: 1px solid #42c8b7;
    }
    @media ${devices.laptop} {
        border: 1px solid #fff;
    }
`

const PrimaryNavbar = ({ page = 'buyback' }) => {
    const { pathname } = useLocation()
    let country = getCountryFromUrl(pathname)||"ke"
    const classes = useStyles()
    const { settings, updateSettings } = useSettings()
    const { logout, user = {}, isAuthenticated } = useAuth()
    const history = useHistory()
    const [drawerOpen, setDrawerOpen] = useState(false)
     const [panelOpen, setPanelOpen] = useState(false)
    const [nextPath, setNextPath] = useState(`/`)
    const { i18n } = useTranslation()

    const handleChangeCountry = (e) => {
        localStorage.setItem('country', e.target.value)

        history.push(`/${e.target.value}`)

        window.location.reload()
        if (e.target.value === 'tz') {
            i18n.changeLanguage('lg')
        }
    }

    const updateSidebarMode = (sidebarSettings) => {
        updateSettings({
            layout3Settings: {
                leftSidebar: {
                    ...sidebarSettings,
                },
            },
        })
    }
    const handleSidebarToggle = () => {
        
        setDrawerOpen(true)
      
        let { layout3Settings } = settings

      

        let mode =
            layout3Settings.leftSidebar.mode === 'close' ? 'mobile' : 'close'

        updateSidebarMode({ mode })
    }
   
         const handleDrawerToggle = (_nextPath = `/${country}
         |`||"ke") => {
         
             setPanelOpen(!panelOpen)
             setNextPath(_nextPath)

             return panelOpen
         }
    

    useEffect(() => {
        window._toggleDrawer = handleDrawerToggle
    }, [])

    const handleLogOut = () => {
        try {
            logout()
            history.push(pathname)
        } catch (e) {}
    }

    return (
        <StyledTopBar
            className={clsx('relative w-full', classes.root)}
            //page={page}
        >
            <Hidden mdDown>
                <span>
                    <MatxToolbarMenu
                        offsetTop="64px"
                        handleSidebarToggle={handleSidebarToggle}
                    >
                        {panelOpen && (
                            <PhoneNumberDrawer
                                page={page}
                                panelOpen={panelOpen}
                                handleDrawerToggle={handleDrawerToggle}
                                nextPath={nextPath}
                            />
                        )}
                    </MatxToolbarMenu>
                    <StyledMobileLogoLink
                        className={classes.menuItem}
                        to={
                            page === 'ecommerce'
                                ? `/${country || 'ke'}/ecommerce/shop`
                                : `/${country || 'ke'}`
                        }
                    >
                        <StyledMobileLogo
                            src={
                                page === 'ecommerce'
                                    ? '/assets/images/logo-orange.svg'
                                    : '/assets/images/logo-green.svg'
                            }
                        />
                    </StyledMobileLogoLink>
                </span>

                <div className="p-relative">
                    <MatxSearchBox />
                </div>

                <StyledUserSpan>
                    
                <CountrySelector
    className="form-control"
    name="country"
    value={country}
    placeholder=""
    onChange={handleChangeCountry}
>
    {window.location.hostname === 'badili.africa' ?
        <option value={availableCountries[0].code.toLowerCase()} >
            {console.log('dom', availableCountries[0])}
            {availableCountries[0].emoji} {availableCountries[0].name}
        </option>
        : window.location.hostname === 'badili.co.tz' ?
            <option value={availableCountries[1].code.toLowerCase()} >
                {availableCountries[1].emoji} {availableCountries[1].name}
            </option>
            : window.location.hostname === 'badili.ug' ? // Add the third condition for the desired country
                <option value={availableCountries[2].code.toLowerCase()} >
                    {availableCountries[2].emoji} {availableCountries[2].name}
                </option>
                : availableCountries.map(({ name, code, emoji }, i) => (
                    <option value={code.toLowerCase()} key={i}>
                        {emoji} {name}
                    </option>
                ))
    }
</CountrySelector>

                    <MobileUserMenu>
                        {isAuthenticated && user && user.signupComplete && (
                            <MatxMenu menuButton={<Icon>person</Icon>}>
                                <MenuItem className={classes.menuItem}>
                                    <Link
                                        className={classes.menuItem}
                                        to={`/${
                                            country || 'ke'
                                        }/device-details`}
                                    >
                                        <span className="pl-4">
                                            {' '}
                                            Track Your Order{' '}
                                        </span>
                                    </Link>
                                </MenuItem>
                                <MatxDivider />
                                <MenuItem>
                                    <Link
                                        className={classes.menuItem}
                                        to={`/${country || 'ke'}/orders`}
                                    >
                                        <span className="pl-4"> Orders </span>
                                    </Link>
                                </MenuItem>
                                <MatxDivider />
                                <MenuItem
                                    onClick={handleLogOut}
                                    className={classes.menuItem}
                                >
                                    <span className="pl-4"> Sign out </span>
                                </MenuItem>
                            </MatxMenu>
                        )}
                        {(!isAuthenticated || !user.signupComplete) && (
                            <MatxMenu menuButton={<Icon>person</Icon>}>
                                <MenuItem
                                    onClick={() =>
                                        handleDrawerToggle(
                                            `/${country || 'ke'}`
                                        )
                                    }
                                    className={classes.menuItem}
                                >
                                    <span className="pl-4">
                                        {' '}
                                        Sign In / Sign Up{' '}
                                    </span>
                                </MenuItem>
                                <MatxDivider />
                                <MenuItem
                                    onClick={() =>
                                        handleDrawerToggle(`/${country}`)
                                    }
                                    className={classes.menuItem}
                                >
                                    <span className="pl-4">
                                        {' '}
                                        Track Your Order{' '}
                                    </span>
                                </MenuItem>
                            </MatxMenu>
                        )}
                    </MobileUserMenu>

                    {isAuthenticated && user && user.signupComplete && (
                        <StyledUser>
                            <ShoppingCart cartcolor="#000" label={false} />
                            <MatxMenu menuButton={<Icon>person</Icon>}>
                                <MenuItem>
                                    <Link
                                        className={classes.menuItem}
                                        to={`/${country}/orders`}
                                    >
                                        <Icon> event_note </Icon>
                                        <span className="pl-4">Orders </span>
                                    </Link>
                                </MenuItem>
                                <MatxDivider />
                                <MenuItem
                                    onClick={handleLogOut}
                                    className={classes.menuItem}
                                >
                                    <Icon> power_settings_new </Icon>
                                    <span
                                        className="pl-4"
                                        style={{ color: '#000' }}
                                    >
                                        {' '}
                                        Sign out{' '}
                                    </span>
                                </MenuItem>
                            </MatxMenu>
                            {user?.fname && (
                                <span
                                    style={{
                                        fontSize: '13px',
                                        fontFamily: 'open-sans',
                                    }}
                                >
                                    {`Hi, ${user?.fname}`}
                                </span>
                            )}
                        </StyledUser>
                    )}

                    {(!isAuthenticated || !user.signupComplete) && (
                        <StyledUser>
                            <ShoppingCart cartcolor="#000" label={false} />
                            <MatxMenu menuButton={<Icon>person</Icon>}>
                                {' '}
                                <MenuItem
                                    onClick={() =>
                                        handleDrawerToggle(`/${country}`)
                                    }
                                    className={classes.menuItem}
                                >
                                    <span
                                        style={{ color: '#000' }}
                                        onClick={() =>
                                            handleDrawerToggle(`/${country}`)
                                        }
                                    >
                                        {' '}
                                        Sign In{' '}
                                    </span>
                                </MenuItem>
                                <MatxDivider />
                                <MenuItem
                                    onClick={() =>
                                        handleDrawerToggle(`/${country}`)
                                    }
                                    className={classes.menuItem}
                                >
                                    <span style={{ color: '#000' }}>
                                        {' '}
                                        Sign Up{' '}
                                    </span>
                                </MenuItem>
                            </MatxMenu>
                            <span
                                style={{
                                    fontSize: '13px',
                                    fontFamily: 'open-sans',
                                    color: '#000',
                                }}
                            >
                                Sign In
                            </span>
                        </StyledUser>
                    )}
                </StyledUserSpan>
            </Hidden>
            <Hidden lgUp>
                <StyledMenuSpan>
                    <MatxToolbarMenu
                        offsetTop="64px"
                        handleSidebarToggle={handleSidebarToggle}
                    >
                        {drawerOpen && (
                            <MenuDrawer
                                page={page}
                                drawerOpen={drawerOpen}
                                setDrawerOpen={setDrawerOpen}
                                handleDrawerToggle={handleDrawerToggle}
                            />
                        )}
                        {panelOpen && (
                            <PhoneNumberDrawer
                                page={page}
                                panelOpen={panelOpen}
                                handleDrawerToggle={handleDrawerToggle}
                                nextPath={nextPath}
                            />
                        )}
                    </MatxToolbarMenu>
                </StyledMenuSpan>
                <StyledMobileLogoLink
                    className={classes.menuItem}
                    to="/ecommerce/shop"
                >
                    <StyledMobileLogo
                        src={
                            page === 'ecommerce'
                                ? '/assets/images/logo-orange.svg'
                                : '/assets/images/logo-green.svg'
                        }
                    />
                </StyledMobileLogoLink>
                <StyledUserSpan>
                <CountrySelector
                        className="form-control"
                        name="country"
                        value={country}
                        placeholder=""
                        onChange={handleChangeCountry}
                    >
                        {availableCountries.map(({ name, code, emoji }, i) => (
                            <option value={code.toLowerCase()} key={i}>
                                {emoji} {name}
                            </option>
                        ))}
                    </CountrySelector>
                    <MobileUserMenu>
                        {isAuthenticated && user && user.signupComplete && (
                            <MatxMenu menuButton={<Icon>person</Icon>}>
                                <MenuItem className={classes.menuItem}>
                                    <Link
                                        className={classes.menuItem}
                                        to={`/${country}/device-details`}
                                    >
                                        <span className="pl-4">
                                            {' '}
                                            Track Your Order{' '}
                                        </span>
                                    </Link>
                                </MenuItem>
                                <MatxDivider />
                                <MenuItem>
                                    <Link
                                        className={classes.menuItem}
                                        to={`/${country}/orders`}
                                    >
                                        <span className="pl-4"> Orders </span>
                                    </Link>
                                </MenuItem>
                                <MatxDivider />
                                <MenuItem
                                    onClick={handleLogOut}
                                    className={classes.menuItem}
                                >
                                    <span className="pl-4"> Sign out </span>
                                </MenuItem>
                            </MatxMenu>
                        )}
                        {(!isAuthenticated || !user.signupComplete) && (
                            <MatxMenu menuButton={<Icon>person</Icon>}>
                                <MenuItem
                                    onClick={() =>
                                        handleDrawerToggle(`/${country}`)
                                    }
                                    className={classes.menuItem}
                                >
                                    <span> Sign In </span>
                                </MenuItem>
                                <MatxDivider />
                                <MenuItem
                                    onClick={() =>
                                        handleDrawerToggle(`/${country}`)
                                    }
                                    className={classes.menuItem}
                                >
                                    <span>Sign Up</span>
                                </MenuItem>
                            </MatxMenu>
                        )}
                    </MobileUserMenu>

                    <ShoppingCart cartcolor="#000" label={false} />
                </StyledUserSpan>
                <StyledRelativeWrapper>
                    <MatxSearchBox />
                </StyledRelativeWrapper>
            </Hidden>
        </StyledTopBar>
    )
}

export default PrimaryNavbar
