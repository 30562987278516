import styled from 'styled-components'
import { FormControlLabel } from '@material-ui/core'

export const StyledFormControlLabel = styled(FormControlLabel)`
    width: 250px;
    min-height: 44px;
    margin: 20px 20px 0 0;
    padding: 16px;
    border-radius: 6px;
    position: relative;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.06),
        0px 6px 10px 0px rgba(0, 0, 0, 0.042),
        0px 1px 18px 0px rgba(0, 0, 0, 0.036);

    .MuiIconButton-root {
        position: absolute;
        top: 0px;
        left: 0;
    }
    .MuiTypography-root {
        margin-left: 12px;
        color: black;
    }
    .MuiSvgIcon-root {
        height: 0.8em;
        width: 0.8em;
    }
    .MuiRadio-root {
        color: #42c8b7;
    }
    .MuiTypography-root:nth-child(2) {
        margin-top: -10px;
    }
    .MuiTypography-caption {
        padding-top: 10px;
        font-size: 13px;
    }
    .radio-title {
        font-weight: 500;
    }
`
