import React from 'react'
import { Card, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import styled from 'styled-components'
import { devices } from 'utils/screen_sizes'
import { handleImageError } from 'utils/imageUtils'
import { replaceWhiteSpaceWithHyphen } from 'utils/string_utils'
import {
    StyledProductImage,
    RoundModelImage,
} from '../../styles/checkout.style'
import { StyledPreviousPrice } from '../../styles/Ecommerce.style'

import { createBrowserHistory } from 'history'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    productCard: {
        '& .product-price': {
            position: 'absolute',
            right: 0,
            top: 24,
            borderTopLeftRadius: 26,
            borderBottomLeftRadius: 26,
            zIndex: 4,
        },
        '& .image-box-overlay': {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: 'rgba(0, 0, 0, 0.74)',
            zIndex: 2,
            opacity: 0,
            transition: 'all 250ms ease-in-out',
        },
        '&:hover': {
            '& .image-box-overlay': {
                opacity: 0.1,
            },
        },
    },
}))

const StyledTitleWrapper = styled.div`
    padding: 1rem;
    background: #fff;
    width: 100%;

    p {
        margin: 0 0 0.2em 0;
    }

    h5 {
        color: #58cab9;
    }

    @media ${devices.laptop} {
        text-align: left;
    }

    button {
        margin: 0.5rem;
        background-color: #f68937;
        width: 80%;
        border-radius: 1rem;
        font-size: 0.7rem;
    }
`

const StyledFeaturedTitleWrapper = styled.div`
    padding: 1rem;
    background: #fefefe;
    color: #555;
    width: 100%;
    text-align: center;
    color: #34314c;
    p {
        margin: 0 0 0.2em 0;
    }
    @media ${devices.laptop} {
    }
`

const StyledProductImageWrapper = styled.div`
    cursor: pointer;
    width: 60px;
    padding-top: 1.2rem;

    @media ${devices.laptop} {
        width: 100%;
    }
`

const StyledPrice = styled.h5`
    color: #0c8085 !important;
`

const StyledStartingFrom = styled.p`
    color: #999;
    margin-top: 0.2em;
`

const StyledGridProductCard = styled(Card)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;

    flex-direction: column;
    @media ${devices.laptop} {
    }
`

const StyledViewMoreButton = styled(Button)`
    font-family: lato;
    color: #f68937;
    border: 1px solid #f68937;
    background-color: #fff !important;
    transition: 0.3s ease-in-out;

    &:hover {
        background-color: #f68937 !important;
        color: #fff !important;
        border: 1px solid #f68937;
        transition: 0.3s ease-in-out;
    }
`

export const GridProductCard = ({ product, image, modelName, _id }) => {
    const classes = useStyles()

    const history = createBrowserHistory()

    const {
        variants = [],
        quantity = 0,
        storage,
        ram,
        discounted,
        saleprice,
    } = product
   

    try {
        return (
            <Link
                to={{
                    pathname: `/ecommerce/product-details/${replaceWhiteSpaceWithHyphen(
                        modelName
                    )}`,
                    state: { _id: _id },
                }}
            >
                <StyledGridProductCard
                    elevation={3}
                    className={clsx(
                        'text-center relative h-full',
                        classes.productCard
                    )}
                >
                    <StyledProductImageWrapper className="flex justify-center items-center relative">
                        <StyledProductImage
                            src={image}
                            alt={modelName}
                            onError={({ target }) => {
                                target.onerror = null
                                target.src =
                                    'https://media.istockphoto.com/vectors/smartphone-icon-vector-id1137284756?b=1&k=20&m=1137284756&s=170667a&w=0&h=S8OxtjTNSFmreNwBP6_LdbeHN1H2lgIgKcqh3Z5aAIg='
                            }}
                        />
                        {/* <div className="image-box-overlay flex justify-center items-center"/> */}
                    </StyledProductImageWrapper>
                    <StyledTitleWrapper>
                        <b>
                            <p>{modelName}</p>
                        </b>

                        <StyledStartingFrom>
                            {storage && `${ram} GB Ram - ${storage}GB Storage`}
                            {!storage &&
                                `${variants[0].ram} GB Ram - ${variants[0].storage}GB Storage`}
                        </StyledStartingFrom>
                        <StyledPrice>
                            {discounted &&
                                `Ksh  ${discounted.toLocaleString()}`}
                            {!storage &&
                                `Ksh  ${variants[0].discounted?.toLocaleString()}`}
                        </StyledPrice>
                        <StyledPreviousPrice>
                            {saleprice && `Ksh  ${saleprice?.toLocaleString()}`}
                            {!storage &&
                                `Ksh  ${variants[0].saleprice?.toLocaleString()}`}
                        </StyledPreviousPrice>

                        <StyledViewMoreButton
                            size="small"
                            variant="outlined"
                            color="primary"
                        >
                            VIEW MORE
                        </StyledViewMoreButton>
                    </StyledTitleWrapper>
                </StyledGridProductCard>
            </Link>
        )
    } catch (error) {
        console.log(error)
        return null
    }
}

export const GridModelCard = ({ product, image }) => {
    const classes = useStyles()

    const { _id, modelName } = product

    const placeholderImage =
        'https://media.istockphoto.com/vectors/smartphone-icon-vector-id1137284756?b=1&k=20&m=1137284756&s=170667a&w=0&h=S8OxtjTNSFmreNwBP6_LdbeHN1H2lgIgKcqh3Z5aAIg='

    try {
        return (
            <Link
                to={{
                    pathname: `/ecommerce/details/${replaceWhiteSpaceWithHyphen(
                        modelName
                    )}`,
                    state: { _id: _id },
                }}
            >
                <div className="flex justify-center items-center relative">
                    <RoundModelImage>
                        <img
                            src={image}
                            alt={modelName}
                            onError={(e) =>
                                handleImageError(e, placeholderImage)
                            }
                        />
                    </RoundModelImage>
                </div>
                <StyledFeaturedTitleWrapper>
                    <p>{modelName}</p>
                </StyledFeaturedTitleWrapper>
            </Link>
        )
    } catch (error) {
        console.log(error)
        return null
    }
}
