import axios from 'axios'
import { API_URL, API_KEY, API_SECRET } from 'utils/api_utils'

import localStorageService from 'app/services/localStorageService'
import memoize from 'memoize-one';

const { loadState} = localStorageService
if(window.location.hostname==='badili.africa'){
   
    var country = "ke"
}else if(window.location.hostname ==='badili.co.tz'){
     country = "tz"
}else if(window.location.hostname ==='badili.ug'){
    country = "ug"
}else{
    console.log('localhost')
    country = "ke"
}

console.log(country);

const headers = {
    'Content-Type': 'application/json',
    Key: API_KEY,
    Secret: API_SECRET,
    country,
}

export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST'
export const GET_INVENTORY = 'GET_INVENTORY'
export const LOADING_INVENTORY = 'LOADING_INVENTORY'
export const ADD_FILTERS = 'ADD_FILTERS'

export const GET_CART_LIST = 'GET_CART_LIST'
export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST'
export const GET_RATING_LIST = 'GET_RATING_LIST'
export const GET_BRAND_LIST = 'GET_BRAND_LIST'
export const GET_BRANDS = 'GET_BRANDS'

export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART'
export const DELETE_PRODUCT_FROM_CART = 'DELETE_PRODUCT_FROM_CART'
export const CLEAR_CART = 'CLEAR_CART'

export const UPDATE_PAGE = 'UPDATE_PAGE'
export const RESET_PAGE = 'RESET_PAGEE'

export const UPDATE_CART_QUANTITY = 'UPDATE_CART_QUANTITY'

const getAvailableModels = (query = '', page = 1, limit = 24) => {
    if (query !== '' && query !== 'instock=1') {
        return axios.get(
            API_URL + `available_models?${query}&page=${page}&limit=${limit}`,
            {
                headers: headers,
            }
        )
    } else if (query !== '' && query === 'instock=1') {
        return axios.get(
            API_URL + `available_models?${query}&page=${page}&limit=${10000}`,
            {
                headers: headers,
            }
        )
    } else if (query !== '' && query !== '') {
        return axios.get(
            API_URL + `available_models?${query}&page=${page}&limit=${10000}`,
            {
                headers: headers,
            }
        )
        }
     else
        return axios.get(
            API_URL + `available_models?${query}&page=${page}&limit=${limit}`,
            {
                headers: headers,
            }
        )
}


const getUpdatesModels = (filterPrice = '', filterBrand = '', limit = 24 ,page =1) => {
    if (filterPrice !== '' && filterBrand !== '') {
        return axios.get(
            API_URL + `available_models?${filterPrice}&${filterBrand}`, 
            {
                headers: headers,
            }
        )
    } 
    
        
}

export const getVariants = (id) => {
    return axios.get(API_URL + `get_variant_model/${id}`, {
        headers: headers,
    })
}
export const resetPage = () => async (dispatch) => {
    dispatch({
        type: RESET_PAGE,
    })
}

export const getInventory = memoize((query, page, limit) => async (dispatch) => {
    dispatch({
      type: LOADING_INVENTORY,
      payload: true,
    });
  
    try {
      const {
        data: { success, inventory, msg, count },
      } = await getAvailableModels(query, page, limit);
      if (!success) {
        dispatch({
          type: GET_INVENTORY,
          payload: [],
        });
      } else {
        dispatch({
          type: GET_INVENTORY,
          payload: inventory,
          count,
        });
      }
    } catch (error) {
    } finally {
      dispatch({
        type: LOADING_INVENTORY,
        payload: false,
      });
    }
  });
  
export const updateInventory = (filterBrand, filterPrice, page) => async (dispatch) => {
    dispatch({
        type: LOADING_INVENTORY,
        payload: true,
    })

    try {
        const {
            data: { success, inventory, msg, count },
        } = await getUpdatesModels(filterBrand, filterPrice, page, )
        if (!success) {
            dispatch({
                type: GET_INVENTORY,
                payload: [],
            })
        } else {
            dispatch({
                type: GET_INVENTORY,
                payload: inventory,
                count,
            })
        }
    } catch (error) {
    } finally {
        dispatch({
            type: LOADING_INVENTORY,
            payload: false,
        })
    }
}


export const getInventoryItem = async (_id) => {
    const {
        data: {
            inventory: { details, image },
            payload,
            variants,
            images,
            instock,
            quantity,
        },
    } = await axios.get(API_URL + `get_single_product/${_id}`, {
        headers: headers,
    })

    if (!details) {
        return null
    } else {
        return {
            ...details,
            image,
            gallery: [{ upload: image }, ...images],
            variants,
            instock,
            quantity,
        }
    }
}

export const getProductList = () => (dispatch) => {
    axios.get('/api/ecommerce/get-product-list').then((res) => {
        dispatch({
            type: GET_PRODUCT_LIST,
            payload: res.data,
        })
    })
    axios.get('/api/ecommerce/get-product-list').then((res) => {
        dispatch({
            type: GET_PRODUCT_LIST,
            payload: res.data,
        })
    })
}

export const getCategoryList = () => (dispatch) => {
    axios.get('/api/ecommerce/get-category-list').then((res) => {
        dispatch({
            type: GET_CATEGORY_LIST,
            payload: res.data,
        })
    })
}

export const getRatingList = () => (dispatch) => {
    axios.get('/api/ecommerce/get-rating-list').then((res) => {
        dispatch({
            type: GET_RATING_LIST,
            payload: res.data,
        })
    })
}

export const getBrandList = () => (dispatch) => {
    axios.get('/api/ecommerce/get-brand-list').then((res) => {
        dispatch({
            type: GET_BRAND_LIST,
            payload: res.data,
        })
    })
}

//gets all smartphone brands
//pass optional parameter to get specific device type
//smartphone id is the default query
export const getBrands = () => (dispatch) => {
    const query = '6165c8453c6ca93814dc21aa'

    axios.get(API_URL + `get_inventory_by_brand/${query}`, {
            headers: headers,
        })
        .then((res) => {
            dispatch({
                type: GET_BRANDS,
                payload: res.data.brands,
            })
        })
}
export const updatePage = (page) => (dispatch) => {
    dispatch({
        type: UPDATE_PAGE,
        payload: page,
    })
}

export const getCartList = (uid) => (dispatch) => {
    const cartList = loadState('cartList')
    dispatch({
        type: GET_CART_LIST,
        payload: cartList,
    })

    // axios.get('/api/ecommerce/get-cart-list', { data: uid }).then((res) => {
    //     dispatch({
    //         type: GET_CART_LIST,
    //         payload: res.data,
    //     })
    // })
}

export const addProductToCart =
    (cartList, product, selectedColor, selectedVariant) => (dispatch) => {
        let updatedCartList = cartList

        if (product.priorityVariant) {
            // check for a priority variant first

            let candidate_product = updatedCartList.find((cartItem) => {
                if (cartItem.priorityVariant) {
                    return (
                        cartItem.priorityVariant._id ===
                        product.priorityVariant._id
                    )
                } else {
                    return cartItem.variant._id === product.priorityVariant._id
                }
            })

            if (
                candidate_product &&
                candidate_product?.set_color?.id === selectedColor?.value
            ) {
                candidate_product.variant.quantity =
                    candidate_product.variant.quantity + 1
            } else {
                const _product = {
                    modelName: product.modelName,
                    modelId: selectedVariant?.variant?.modelID,
                    modelImage: product.image,
                    media: product.media,
                    variant: {
                        _id: selectedVariant?.variant?._id,
                        quantity: 1,
                        price: selectedVariant?.variant?.discounted,
                        color: selectedColor,
                        ram: selectedVariant?.variant?.ram,
                        storage: selectedVariant?.variant?.storage,
                    },
                }

                updatedCartList.push(_product)
            }
        } else {
            console.log('no priority variant')
        }



        dispatch({
            type: ADD_PRODUCT_TO_CART,
            payload: updatedCartList,
        })
    }
export const _addProductToCart = (uid, productId) => (dispatch) => {
    axios.post('/api/ecommerce/add-to-cart', { uid, productId }).then((res) => {
        dispatch({
            type: ADD_PRODUCT_TO_CART,
            payload: res.data,
        })
    })
}

export const deleteProductFromCart =
    (cartList, variantId, color) => (dispatch) => {
        const list = cartList.filter(
            (product) =>
                product?.variant?.color?.value !== color?.value ||
                product?.variant?._id !== variantId
        )

        dispatch({
            type: DELETE_PRODUCT_FROM_CART,
            payload: list,
        })
    }

export const clearCart = () => (dispatch) => dispatch({ type: CLEAR_CART })

export const updateCartQuantity =
    (cartList, productId, variant, quantity, color) => (dispatch) => {
        const payload = cartList.map((product) => {
            if (
                product.variant._id === variant._id &&
                product?.variant?.color?.id === color?.id
            ) {
                product.variant.quantity = quantity
            }
            return product
        })

        dispatch({
            type: UPDATE_CART_QUANTITY,
            payload,
        })
    }

export const _updateCartQuantity = (uid, productId, quantity) => (dispatch) => {
    axios
        .post('/api/ecommerce/update-cart-quantity', {
            uid,
            productId,
            quantity,
        })
        .then((res) => {
            dispatch({
                type: UPDATE_CART_QUANTITY,
                payload: res.data,
            })
        })
}

export const addFilters = (filter) => (dispatch) => {
    dispatch({
        type: ADD_FILTERS,
        payload: filter,
    })
}
