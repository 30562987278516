import React from 'react'
import { Grid } from '@material-ui/core'
import { GridProductCard } from '../gridProductCard/GridProductCard'
import { ListModelCard } from '../listProductCard/ListProductCard'

import styled from 'styled-components'

const StyledEcommerceCard = styled.div`
    margin: 0.5rem 0;

    > button {
        padding: 0.5rem 1rem;
    }
`

const verifyProduct = (details, image, view, modelName, _id) => {
    return view === 'grid' ? (
        <Grid item key={details._id} lg={3} md={4} sm={4} xs={6}>
            <GridProductCard
                product={
                    details?.priorityVariant ? details?.priorityVariant : details
                }
                image={image}
                modelName={modelName}
                _id={_id}
            />
        </Grid>
    ) : (
        <Grid item key={details._id} lg={12} md={12} sm={12} xs={12}>
            <ListModelCard
                product={
                    details?.priorityVariant ? details?.priorityVariant : details
                }
                image={image}
                modelName={modelName}
                _id={_id}
            />
        </Grid>
    )
}

export const ProductsGrid = ({ inventory = [], view = 'grid' }) => (
    <StyledEcommerceCard>
        <Grid container spacing={1}>
            {inventory.map(({ details, image }) =>
                verifyProduct(
                    details,
                    image,
                    view,
                    details.modelName,
                    details._id
                )
            )}
        </Grid>
    </StyledEcommerceCard>
)
