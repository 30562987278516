import React, {useEffect, useRef } from 'react'
import styled from 'styled-components'
import { devices } from '../../../utils/screen_sizes'
import Swiper from 'swiper'
import EcommerceLayout from 'app/components/MatxLayout/EcommerceLayout'
import InventoryContainer from 'app/ecommerceComponents/containers/InventoryContainer'
import EcommerceContainer from 'app/ecommerceComponents/containers/EcommerceContainer'


const StyledShopWrapper = styled.div`
    @media ${devices.laptop} {
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
    }

    @media ${devices.desktopL} {
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
    }
`

export const StyledSlide = styled.div`
    display: flex;
    justify-content: center;
    background-color: #fff;

    .smooth-image-wrapper {
        width: 100%;
    }

    a {
        position: absolute;
        z-index: 1;
        color: #fff;
        bottom: 2em;
        left: 4em;
        font-size: 0.8rem;
    }

    @media ${devices.mobileL} {
        a {
            font-size: 1rem;
        }
    }
    @media ${devices.tablet} {
        a {
            font-size: 1rem;
            left: 8em;
        }
    }
    @media ${devices.laptop} {
        a {
            left: unset;
            bottom: 4em;
        }
    }
    @media ${devices.desktop} {
        a {
            left: unset;
            bottom: 5em;
        }
    }
`





const ShopLandingPage = (props) => {

    const swiper = useRef(null)


    useEffect(() => {
        swiper.current = new Swiper('.swiper-container', {
            direction: 'horizontal',
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            autoplay: {
                delay: 6000,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            slidesPerView: 1,
            slidesPerColumn: 1,
            loop: false,
            reverseDirection: false,
            stopOnLastSlide: false,
            loopAdditionalSlides: 1000,
            preloadImages: true,
            updateOnImagesReady: true,
            effect: 'fade',
        })

        return function cleanUp() {
            swiper.current = null
        }
    }, [])

  

    return (
        <EcommerceLayout>
            <EcommerceContainer>
                <InventoryContainer />
            </EcommerceContainer>

        </EcommerceLayout>
    )
}

export default ShopLandingPage
