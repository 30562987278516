import React from 'react'
import Shimmer from './Shimmer'
import SkeletonElement from './SkeletonElement'
import styled from 'styled-components'

const StyledSkeletonWrapper = styled.div`
    margin: 20px auto;
    padding: 10px 15px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    &.light {
        background: #f2f2f2;
    }
    &.dark {
        background: #444;
    }
`
const StyledSkeletonProfile = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 30px;
    align-items: center;
`

const SkeletonProfile = ({ theme }) => {
    const themeClass = theme || 'light'

    return (
        <StyledSkeletonWrapper className={themeClass}>
            <StyledSkeletonProfile>
                <div>
                    <SkeletonElement type="image" />
                </div>
                <div>
                    <SkeletonElement type="title" />

                    <SkeletonElement type="text" />
                </div>
            </StyledSkeletonProfile>
            <Shimmer />
        </StyledSkeletonWrapper>
    )
}

export default SkeletonProfile
