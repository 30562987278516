import React from 'react'
import { RadioGroup, FormControl, Radio, Typography } from '@material-ui/core'
import { StyledFormControlLabel } from './Radio.style'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    root: {
        radio: {
            '&$checked': {
                color: '#42c8b7',
            },
        },
        checked: {
            color: '#42c8b7',
        },
    },
}))

const RadioCard = ({ className, options, handleOptionSelect, name }) => {
    const classes = useStyles()
    return (
        <FormControl component="fieldset" className="mb-4">
            <RadioGroup
                name={name}
                style={{ flexDirection: 'row' }}
                onChange={(e) => handleOptionSelect()}
            >
                {options.map((option, i) => (
                    <StyledFormControlLabel
                        key={i}
                        className={`radio-group ${className}`}
                        value={option.value}
                        control={
                            <Radio
                                disableRipple
                                id="radio"
                                classes={{
                                    root: classes.radio,
                                    checked: classes.checked,
                                }}
                            />
                        }
                        label={
                            <div>
                                <Typography
                                    variant="subtitle2"
                                    className="radio-title"
                                >
                                    {option.label || 'Radio Label'}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    style={{
                                        display: 'block',
                                    }}
                                >
                                    {option.caption || 'Radio Caption'}
                                </Typography>
                            </div>
                        }
                    />
                ))}
            </RadioGroup>
        </FormControl>
    )
}

export default RadioCard
