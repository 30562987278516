import React, { useState } from 'react';
import { Drawer } from '@material-ui/core';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { getCountryFromUrl } from 'utils/CountryUtils';
import RepairModal from './RepairModal';

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paperAnchorLeft {
    width: 250px;
    font-family: 'open-sans';
    padding: 1rem;
  }
`;

const StyledMobileLogo = styled.img`
  width: 100px;
  margin: 0 auto;
`;

const StyledNavList = styled.ul`
  margin: 2rem 0;
  list-style-type: none;
  padding-left: 0.5rem;

  li {
    padding: 0.8rem 0;
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }

    a {
      text-decoration: none;
      color: #000;
    }
  }
`;

const MenuDrawer = ({ drawerOpen, setDrawerOpen, handleDrawerToggle, page = 'buyback' }) => {
  const [openRepairModal, setOpenRepairModal] = useState(false);
  const { pathname } = useLocation();
  const country = getCountryFromUrl(pathname) || 'ke';

  const closeModal = () => {
    setOpenRepairModal(false);
  };

  const handleRepairClick = (event) => {
    event.preventDefault();
    console.log('check')
    setOpenRepairModal(true);
  };

  const handleModalClose = () => {
    setOpenRepairModal(false);
  };

  return (
    <StyledDrawer
      variant="temporary"
      anchor="left"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      ModalProps={{
        keepMounted: true,
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
      }}
    >
      <RepairModal open={openRepairModal} handleModalClose={handleModalClose} />
      <StyledMobileLogo
        src={page === 'ecommerce' ? '/assets/images/logo-orange.svg' : '/assets/images/logo-green.svg'}
        alt="Logo"
      />
      <StyledNavList>
        <li>
          <Link to={`/${country}/how-it-works`}>About Us</Link>
        </li>
        <li>
          <Link to={`/${country}/ecommerce/shop`}>Store</Link>
        </li>
        <li>
          <Link to={`/${country}/how-it-works`}>Help</Link>
        </li>
        <li>
          <a href="https://blog.badili.africa/" target="_blank" rel="noopener noreferrer">
            Blog
          </a>
        </li>
        {page !== 'repair' && (
          <li style={{ cursor: 'pointer' }}>
            <a href="#" onClick={handleRepairClick}>
              Repair Your Phone
            </a>
          </li>
        )}
      </StyledNavList>
    </StyledDrawer>
  );
};

export default MenuDrawer;
