import JwtLogin from './login/JwtLogin'
import NotFound from './NotFound'
import ForgotPassword from './ForgotPassword'
import FirebaseRegister from './register/FirebaseRegister'
import SellYourDevice from '../SellYourDevice/SellYourDevice'
import JwtRegister from './register/JwtRegister'

const sessionRoutes = [
    {
        path: '/sell',
        component: SellYourDevice,
    },
    {
        path: '/session/signup',
        component: JwtRegister,
    },
    {
        path: '/session/signin',
        component: JwtLogin,
    },
    {
        path: '/session/forgot-password',
        component: ForgotPassword,
    },
    {
        path: '/session/404',
        component: NotFound,
    },
]

export default sessionRoutes
