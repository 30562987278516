import * as React from 'react';
import {useRef} from 'react'
import SellYourDeviceForm from './SellYourDeviceForm';




export default function SellYourDevice(props){

  const myRef = useRef(null)

   const scrollToTop = () =>{
    window.scrollTo(0, 0)
     
    //myRef.current.scrollIntoView() 
}


    return <div className="sell-device-page"   ref={myRef}>
        <SellYourDeviceForm scrollToTop={scrollToTop}/>
    </div>
}