import {
    GET_PRODUCT_LIST,
    GET_CART_LIST,
    ADD_PRODUCT_TO_CART,
    DELETE_PRODUCT_FROM_CART,
    CLEAR_CART,
    UPDATE_CART_QUANTITY,
    GET_CATEGORY_LIST,
    GET_RATING_LIST,
    GET_BRANDS,
    GET_INVENTORY,
    LOADING_INVENTORY,
    ADD_FILTERS,
    UPDATE_PAGE,
    RESET_PAGE,
} from '../actions/EcommerceActions'

const initialState = {
    productList: [],
    cartList: [],
    inventory: [],
    brands: [],
    loadingInventory: false,
    filter: '',
    count: 0,
    page: 1,
    lipalater:{
        
    }
}

//localStorageService.setItem('state', initialState)

const EcommerceReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_PRODUCT_LIST: {
            return {
                ...state,
                productList: [...action.payload],
            }
        }
        case GET_INVENTORY: {
          
            return {
                ...state,
                inventory: [...action.payload],
                count: action.count,
            }
        }
        case LOADING_INVENTORY: {
            return {
                ...state,
                loadingInventory: action.payload,
            }
        }
        case UPDATE_PAGE: {
            return {
                ...state,
                page: action.payload,
            }
        }
        case RESET_PAGE: {
            return {
                ...state,
                page: 1,
            }
        }
        case ADD_FILTERS: {
            return {
                ...state,
                filter: action.payload,
            }
        }
        case GET_CATEGORY_LIST: {
            return {
                ...state,
                categoryList: [...action.payload],
            }
        }
        case GET_RATING_LIST: {
            return {
                ...state,
                ratingList: [...action.payload],
            }
        }
        case GET_BRANDS: {
            return {
                ...state,
                brands: [...action.payload],
            }
        }
        case GET_CART_LIST: {
            return {
                ...state,
                cartList: [...action.payload],
            }
        }
        case ADD_PRODUCT_TO_CART: {
            return {
                ...state,
                cartList: [...action.payload],
            }
        }
        case DELETE_PRODUCT_FROM_CART: {
            return {
                ...state,
                cartList: [...action.payload],
            }
        }
        case CLEAR_CART: {
            return {
                ...state,
                cartList: [],
            }
        }
        case UPDATE_CART_QUANTITY: {
            return {
                ...state,
                cartList: [...action.payload],
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default EcommerceReducer
