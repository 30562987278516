import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { devices } from 'utils/screen_sizes'

const StyledGrid = styled(Grid)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media ${devices.mobileS} {
        justify-content: flex-start; //new
    }
    @media ${devices.laptop} {
        justify-content: center;
    }

    h5 {
        color: #42c8b7;
        margin: 0;
        font-size: 0.9rem;
        font-family: 'playfair';

        @media ${devices.mobileS} {
            text-align: center; //new
        }
        @media ${devices.laptop} {
        }
    }
    p {
        text-align: center;
        margin: 12px 0;
        font-family: 'open-sans';
        font-size: 15px !important;
        @media ${devices.mobileS} {
            font-size: 14px !important; //new
        }
        @media ${devices.laptop} {
            font-size: 15px !important;
        }
    }

    img {
        max-height: 105px;
        width: 100%;
    }
`

const DescriptionCard = ({ imageUrl, title, description }) => {
    return (
        <StyledGrid item xs={6} sm={6}>
            <img src={imageUrl} alt="" />
            <h5>{title}</h5>
            <p>{description}</p>
        </StyledGrid>
    )
}

export default DescriptionCard
