import React from 'react'
import styled from 'styled-components'
import { Grid, Hidden } from '@material-ui/core'
import { Link, useLocation } from 'react-router-dom'
import { getCountryFromUrl } from 'utils/CountryUtils'
import { devices } from 'utils/screen_sizes'
import { HashLink } from 'react-router-hash-link';
const StyledFooter = styled.div`
    color: #fff;
    width: 80vw;
    margin: 0 auto;
    padding: 2rem 0;

    @media ${devices.mobileS} {
        width: 90vw;
    }
    @media ${devices.laptop} {
        width: 80vw;
    }
    p {
        font-size: 14px;
        margin-bottom: 2.5rem;
        opacity: 0.8;
    }
`

const StyledFooterList = styled.ul`
    margin: 0;
    list-style-type: none;
    padding: 0;

    li {
        font-size: 12px;
        margin: 10px 0;
        opacity: 0.8;

        a {
            text-decoration: none;
            color: #fff;
            cursor: pointer;
        }
    }
`
const FooterTitle = styled.h4`
    font-weight: normal;
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 0;
`

const StyledFooterSocials = styled.div`
    display: flex;
    a {
        padding: 5px;
    }
    i {
        font-size: 25px;
        color: #fff;
    }
`

const Footer = ({ aboutScrollRef, storeScrollRef }) => {
    const { pathname } = useLocation()
    let country = getCountryFromUrl(pathname)||"ke"
    const executeAboutScroll = () => aboutScrollRef.current.scrollIntoView({block: "end"})
    const executeStoreScroll = () => storeScrollRef.current.scrollIntoView({block: "end"})

    const openRepairModal = () => console.log('')
    return (
        <StyledFooter>
            <p>
                Hello! We're Badili Africa, the leading marketplace for renewed
                devices. Our mission? To fight e-waste by giving expertly
                restored devices a second life
            </p>
            <Hidden mdDown>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={3}>
                        <FooterTitle>About</FooterTitle>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FooterTitle>Help</FooterTitle>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FooterTitle>Legal</FooterTitle>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FooterTitle>Hello</FooterTitle>
                    </Grid>
                </Grid>
            </Hidden>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                    <Hidden mdUp>
                        <FooterTitle>About</FooterTitle>
                    </Hidden>

                    <StyledFooterList>
                        <li>
                            {/* <a onClick={executeAboutScroll} href>
                                About us
                            </a> */}
                            <HashLink to={`/${country}/#about`}
                        scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} >
                               About us</HashLink>
                        </li>
                        <li>
                            <Link to={`/${country}/ecommerce/shop`}>
                                Buy Phone from Us
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/"> Trade-in</Link>
                        </li> */}
                        <li>
                            {/* <a href onClick={executeStoreScroll}>
                                Badili Stores
                            </a> */}
                            <HashLink to={`/${country}/#stores`}
                        scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} >
                               Badili Stores</HashLink>
                           
                        </li>
                        <li>
                            <Link to={`/${country}/careers`}>
                                Careers
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/">Careers</Link>
                        </li> */}
                        {/* <li>
                            <a href onClick={openRepairModal}>
                                Repair Pro
                            </a>
                        </li> */}
                    </StyledFooterList>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Hidden mdUp>
                        <FooterTitle>Help</FooterTitle>
                    </Hidden>

                    <StyledFooterList>
                        <li>
                            <Link to={`/${country}/dealers/register`}>
                                How to Become A Dealer
                            </Link>
                        </li>
                        <li>
                            <Link to={`/${country}/register-phone`}>
                                Warranty
                            </Link>
                        </li>

                        <li>
                            <Link to="/">Contact Us</Link>
                        </li>
                    </StyledFooterList>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Hidden mdUp>
                        <FooterTitle>Legal</FooterTitle>
                    </Hidden>

                    <StyledFooterList>
                        <li>
                            <Link to={`/${country}/how-it-works`}>
                                Privacy Policy
                            </Link>
                        </li>
                        <li>
                            <Link to={`/${country}/how-it-works`}>
                                Terms and Conditions
                            </Link>
                        </li>
                    </StyledFooterList>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Hidden mdUp>
                        <FooterTitle>Hello</FooterTitle>
                    </Hidden>

                    <StyledFooterList>
                        <StyledFooterSocials>
                            <a href="https://twitter.com/badili_africa">
                                <i className="fab fa-twitter-square"></i>
                            </a>
                            <a href="https://www.facebook.com/badili.africa">
                                <i className="fab fa-facebook"></i>
                            </a>
                            <a href="https://www.instagram.com/badili_africa/">
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a href="https://www.youtube.com/channel/UCQPgE8WmL8KmyYsQ85GGZlg">
                                <i className="fab fa-youtube"></i>
                            </a>
                            <a href="https://www.linkedin.com/company/badili-commerce">
                                <i className="fab fa-linkedin"></i>
                            </a>
                        </StyledFooterSocials>
                    </StyledFooterList>
                </Grid>
            </Grid>
        </StyledFooter>
    )
}

export default Footer
