import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Icon } from '@material-ui/core'
import { devices } from 'utils/screen_sizes'

const StyledButton = styled.button`
    color: ${(props) => (props.color ? props.color : '#000')};
    border-radius: 20px;
    background: ${(props) => (props.bg ? props.bg : '#fff')};
    padding: 0.5rem 2.5rem;
    border: none;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    font-weight: bold;
    font-family: 'open-sans';

    @media ${devices.mobileS} {
        margin: 0 auto;
        padding: 0.3rem 1.7rem;
        font-size: 12px;
    }
    @media ${devices.laptop} {
        margin: 0;
        padding: 0.5rem 2.5rem;
        font-size: 14px;
    }
`

const emitButtonLabelAndColor = (type) => {
    switch (type) {
        case 'primary':
            return {
                background: '#fff',
                color: '#000',
            }
        case 'secondary':
            return {
                background: '#f68937',
                color: '#fff',
            }
        default:
            return null
    }
}
const PrimaryButton = ({ label, action, btnType = 'primary' }) => {
    let btnColors = emitButtonLabelAndColor(btnType)

    return (
        <StyledButton
            color={btnColors.color}
            bg={btnColors.background}
            onClick={() => action()}
        >
            {label}
        </StyledButton>
    )
}

PrimaryButton.propTypes = {
    label: PropTypes.string.isRequired,
    btnType: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
}

export default PrimaryButton
