import React, { useContext, useEffect, useState } from 'react'
import RepairWrapper from 'app/components/MatxLayout/RepairLayoutWrapper'
import {
    Grid,
    FormControlLabel,
    Hidden,
    RadioGroup,
    Radio,
    Snackbar,
    Button,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Rating from '@material-ui/lab/Rating'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { makeHttpRequest } from 'utils/api_utils'
import { Alert, AlertTitle } from '@material-ui/lab'
import { PriceSummary } from './components'
import { RepairContext } from 'app/contexts/RepairContext'
import { StyledToggleForm } from 'app/views/SellYourDevice/sellYourDeviceForm.style'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'

import { formatDistance } from 'date-fns'
import useAuth from 'app/hooks/useAuth'
import {
    StyledContainer,
    StyledLandingDescriptionCard,
    StyledSectionTitle,
    StyledSummaryCard,
    StyledTextValidator,
    StyledRepairList,
    StyledPoliciesSection,
    StyledSectionHeader,
    StyledFAQSection,
    StyledReviewsSection,
} from './RepairStyles'
import { AppContext } from 'app/contexts/AppContext'
import { getCountryFromUrl } from 'utils/CountryUtils'

const theme = createTheme({
    palette: {
        primary: { main: 'rgb(111,101,173)' },
    },
})

const FAQAccordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)

const FAQAccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',

        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
        '& p': {
            fontWeight: 700,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary)

const FAQAccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails)

const repairComponents = [
    {
        id: 1,
        label: 'Screen is damaged',
        price: 6000,
        component_name: 'brokenScreen',
        image: '/assets/images/icons/webIcons-24.svg',
    },
    {
        id: 2,
        label: 'Battery has issues',
        price: 4000,
        component_name: 'battery',
        image: '/assets/images/icons/webIcons-06.svg',
    },
    {
        id: 3,
        label: 'Charging Port not working',
        price: 3000,
        component_name: 'chargingPort',
        image: '/assets/images/icons/webIcons-09.svg',
    },
    {
        id: 4,
        label: 'Microphone is not working',
        price: 2000,
        component_name: 'microphone',
        image: '/assets/images/icons/webIcons-16.svg',
    },
    {
        id: 5,
        label: 'Speaker',
        price: 1500,
        component_name: 'speakers',
        image: '/assets/images/icons/webIcons-12.svg',
    },
    {
        id: 6,
        label: 'Earpiece is not working',
        price: 2000,
        component_name: 'earpiece',
        image: '/assets/images/icons/webIcons_earpiece.svg',
    },
]

const RepairLandingPage = () => {
    const { pathname } = useLocation()
    let country = getCountryFromUrl(pathname)||"ke"
    const [expanded, setExpanded] = React.useState('panel1')
    const [calculatingTotal, setCalculatingTotal] = useState(false)
    const [isSendingMessage, setIsSendingMessage] = useState(false)
    const [isCheckingWarranty, setIsCheckingWarranty] = React.useState(false)
    const [showWarrantyAlert, setShowAlert] = React.useState(false)
    const [showMessageAlert, setShowMessageAlert] = React.useState(false)
    const [showResponseAlert, setShowResponseAlert] = React.useState(false)
    const [repair, setRepair] = React.useState({})

    const [warrantyResponse, setWarrantyResponse] = React.useState({
        msg: '',
        status: '',
    })
    const [alertResponse, setAlertResponse] = React.useState({
        msg: '',
        status: '',
    })
    const [stores, setStores] = useState([])

    const history = useHistory()
    const {
        cart,
        addComponentToCart,
        total,
        setTotal,
        terms,
        setRepairProId,
        repairProId,
    } = React.useContext(RepairContext)
    const [repairDetails, setRepairDetails] = useState({
        badili_phone: '',
        imei: '',
        services: [],
        repair_store: '',
        extra_description: '',
    })
    const { state } = useLocation()
    const { user } = useAuth()
    let { id } = useParams()
    const [repairCost, setRepairCost] = useState()

    const handleClose = () => {
        setShowResponseAlert(false)
    }

    const fetchRepairDetails = async () => {
        try {
            const { success, model } = await makeHttpRequest(
                `get_repair_by_model/${id}/${state.colorId}`,
                'GET'
            )
            if (success) {
                setRepair(model)
            }
        } catch (err) {}
    }
    const fetchStores = async () => {
        try {
            const { success, stores } = await makeHttpRequest(
                `get_stores`,
                'GET'
            )
            if (success) {
                setStores(stores)
            }
        } catch (err) {}
    }
    const checkWarranty = async () => {
        setIsCheckingWarranty(true)
        try {
            const res = await makeHttpRequest(`check_status`, 'POST', {
                imei: repairDetails.imei,
            })

            if (res.success) {
                setIsCheckingWarranty(false)

                setWarrantyResponse({
                    ...warrantyResponse,
                    msg: res.msg,
                    status: 'success',
                })
                setShowAlert(true)
            } else {
                setIsCheckingWarranty(false)

                setWarrantyResponse({
                    ...warrantyResponse,
                    msg: res.msg,
                    status: 'warning',
                })
                setShowAlert(true)
            }
        } catch (err) {
            setIsCheckingWarranty(false)
        }
    }
    function itemExists(id) {
        return cart.some(function (el) {
            return el.id === id
        })
    }
    var payload
    useEffect(() => {
        fetchRepairDetails()
        fetchStores()

        if (id && cart !== 0 && total !== null) {
            repairValue()
        }
    }, [JSON.stringify(cart), total])

    const repairValue = async () => {
        let deductions = cart.reduce((el, obj) => {
            return { ...el, [obj.component_name]: 'yes' }
        }, {})
        let userId = user?._id || user?.userId
        var payload = {
            userId,
            modelId: id,
            colorId: state?.colorId,
            imei: repairDetails.imei,
            deductions,
            additionalInfo: repairDetails.extra_description,
        }
        const response = await makeHttpRequest(
            `estimate_repair_value`,
            'POST',
            payload
        )
        
        setRepairCost(response)
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }

    const calculateTotal = async () => {
        let deductions = cart.reduce((el, obj) => {
            return { ...el, [obj.component_name]: 'yes' }
        }, {})

        let userId = user?._id || user?.userId

        if (!userId) {
            window._toggleDrawer({
                pathname: `/${country}/repair/${id}`,
                state: { colorId: state?.colorId },
            })
        } else {
            payload = {
                userId,
                modelId: id,
                colorId: state?.colorId,
                imei: repairDetails.imei,
                deductions,
                additionalInfo: repairDetails.extra_description,
            }
            setCalculatingTotal(true)
            try {
                const { success, msg, warranty, cost, repairProId, currency } =
                    await makeHttpRequest(
                        `estimate_repair_value`,
                        'POST',
                        payload
                    )
                if (success) {
                    setAlertResponse({
                        ...alertResponse,
                        msg,
                        status: 'success',
                    })
                    setRepairProId(repairProId)
                    setShowResponseAlert(true)
                    setTotal({ warranty, cost, currency })

                    setCalculatingTotal(false)
                } else {
                    setAlertResponse({
                        ...alertResponse,
                        msg,
                        status: 'error',
                    })
                    setShowResponseAlert(true)
                    setCalculatingTotal(false)
                }
            } catch (err) {
                setAlertResponse({
                    ...alertResponse,
                    msg: err.message,
                    status: 'error',
                })
                setCalculatingTotal(false)
            }
        }
    }

    const nextStep = () => {
        history.push({
            pathname: `/${country}/repair/address`,
            state: {
                stores,
                repairProId,
                id,
                repairDetails,
                colorId: state?.colorId,
                repairCost,
            },
        })
    }

    const sendMessage = async () => {
        let deductions = cart.reduce((el, obj) => {
            return { ...el, [obj.component_name]: 'yes' }
        }, {})

        let userId = user?._id || user?.userId
        if (!userId) {
            window._toggleDrawer({
                pathname: `/${country}/repair/${id}`,
                state: { colorId: state?.colorId },
            })
        } else {
            let payload = {
                userId,
                modelId: id,
                colorId: state?.colorId,
                imei: repairDetails.imei,
                deductions,
                additionalInfo: repairDetails.extra_description,
            }

            setIsSendingMessage(true)
            try {
                const { success, msg, warranty, cost, repairProId } =
                    await makeHttpRequest(
                        `estimate_repair_value`,
                        'POST',
                        payload
                    )
                if (success) {
                    setAlertResponse({
                        ...alertResponse,
                        msg,
                        status: 'success',
                    })
                    setShowMessageAlert(true)
                    setTotal(cost.toString())

                    setIsSendingMessage(true)
                    history.push({
                        pathname: `/${country}`,
                    })
                } else {
                    setAlertResponse({
                        ...alertResponse,
                        msg,
                        status: 'error',
                    })
                    setShowMessageAlert(true)
                    setIsSendingMessage(false)
                }
            } catch (err) {
                setAlertResponse({
                    ...alertResponse,
                    msg: err.message,
                    status: 'error',
                })
                setShowMessageAlert(true)
                setIsSendingMessage(false)
            }
        }

        setTimeout(() => {
            setIsSendingMessage(false)
        }, 4000)
    }

    const policies = [
        {
            text: 'Old Spare part to be handed over to the technician.',
        },
        {
            text: 'Your device should not be dead/not switching on/ water damaged',
        },
        {
            text: '6 months warranty applicable on screen & 3 months on other repair services',
        },
        {
            text: 'Badili certified screens with 32 approved Quality checkpoints',
        },
        {
            text: '7 day money back guarantee is applicable only on screen repair services',
        },
    ]
    const faqs = [
        {
            question: 'What repairs can you do?',
            answer: 'If your mobile phone is damaged and out of warranty, we will try and fix it for you',
        },
        {
            question:
                'If my phone is “water damaged” (damaged by any type of liquid) can you still fix it?',
            answer: 'We can fix most water damaged phones as that is our speciality!',
        },
        {
            question: 'Can you fix my home button?',
            answer: 'Yes, we can fix the home button for all devices. Disclaimer: For Iphone 6, 7 and  8 series, the fingerprint might not work after fixing the home button.',
        },
        {
            question: 'How do I get my phone to you?',
            answer: `Hand in to one of our Drop off centers within a 5 mile radius. Anthing else further than a 5 mile radius from our drop off centers will be at an additional cost. Please, contact us for more details.`,
        },
        {
            question: 'How long will it take to fix it?',
            answer: 'Turnaround time is 2-7 days after receiving the phone depending on job and availability of parts. Most jobs are completed within 2-3 days This does depend on the fault, make of phone and parts required.',
        },
        {
            question: 'What happens if you can’t fix it?',
            answer: `On the odd occasion that we can not repair your phone, we will get in touch to let you know and return the phone. You will be charged an additional service charge only.`,
        },
        {
            question: 'Is my phone insured while you have it?',
            answer: 'No it is only worth scrap value while damaged. Insurance companies do not insure damaged phones for their full value.',
        },
        {
            question: 'Why do the charges differ?',
            answer: 'Each repair is priced on time taken, type of damage, and any parts needed. Until we assess the damage we can not know the true cost of repair. (A deposit will be taken before we assess the damage)',
        },
    ]

    return (
        <RepairWrapper>
            <MuiThemeProvider theme={theme}>
                <StyledContainer>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8}>
                            <StyledLandingDescriptionCard>
                                <div className="card-header">
                                    <img
                                        src={repair?.data?.image}
                                        alt="phone"
                                    />
                                    <div>
                                        <h2 className="title">
                                            {repair?.find?.modelName}
                                        </h2>
                                        <h4 className="sub-title">
                                            <span>Color:</span>
                                            {repair?.color?.name}
                                        </h4>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <Snackbar
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        autoHideDuration={6000}
                                        open={showResponseAlert}
                                        onClose={handleClose}
                                    >
                                        <Alert
                                            onClose={handleClose}
                                            severity={alertResponse.status}
                                        >
                                            {alertResponse.msg}
                                        </Alert>
                                    </Snackbar>
                                    <Snackbar
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        autoHideDuration={6000}
                                        open={showMessageAlert}
                                        onClose={handleClose}
                                    >
                                        <Alert
                                            onClose={handleClose}
                                            severity={alertResponse.status}
                                        >
                                            {alertResponse.msg}
                                        </Alert>
                                    </Snackbar>
                                    <ValidatorForm
                                        onSubmit={() => checkWarranty()}
                                        onError={(errors) => null}
                                        id="services"
                                        className=" mt-5 mb-15"
                                    >
                                        <h5>
                                            Was the phone bought from Badili
                                            Africa?
                                        </h5>
                                        <RadioGroup
                                            name="status"
                                            required
                                            default="yes"
                                            style={{ flexDirection: 'row' }}
                                            value={repairDetails.badili_phone}
                                            onChange={(e) =>
                                                setRepairDetails({
                                                    ...repairDetails,
                                                    badili_phone:
                                                        e.target.value,
                                                    imei: '',
                                                })
                                            }
                                        >
                                            <FormControlLabel
                                                className="h-32"
                                                value="yes"
                                                control={
                                                    <Radio color="primary" />
                                                }
                                                label="Yes"
                                                required
                                            />
                                            <FormControlLabel
                                                className="h-32"
                                                value="no"
                                                control={
                                                    <Radio color="primary" />
                                                }
                                                label="No"
                                                required
                                            />
                                        </RadioGroup>
                                        {repairDetails.badili_phone ===
                                            'yes' && (
                                            <>
                                                <TextValidator
                                                    type="text"
                                                    className=" w-full mt-5"
                                                    label="Your Device IMEI Number"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={(e) =>
                                                        setRepairDetails({
                                                            ...repairDetails,
                                                            imei: e.target
                                                                .value,
                                                        })
                                                    }
                                                    name="imei"
                                                    value={repairDetails.imei}
                                                    validators={['required']}
                                                    errorMessages={[
                                                        'this field is required',
                                                    ]}
                                                />
                                                <small
                                                    style={{
                                                        fontWeight: 'bold',
                                                        display: 'block',
                                                        padding: '.4rem 0',
                                                    }}
                                                >
                                                    <span
                                                        style={{ color: 'red' }}
                                                    >
                                                        *
                                                    </span>{' '}
                                                    IMEI number is used to
                                                    determine if you have an
                                                    active warranty
                                                </small>
                                                <Button
                                                    size="small"
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disableElevation
                                                    disabled={
                                                        isCheckingWarranty
                                                    }
                                                    style={{
                                                        display: 'block',
                                                        margin: '1rem auto',
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    {isCheckingWarranty
                                                        ? 'Please wait...'
                                                        : 'Check warranty'}
                                                </Button>
                                                {showWarrantyAlert ? (
                                                    <Alert
                                                        severity={
                                                            warrantyResponse.status
                                                        }
                                                        onClose={() => {
                                                            setShowAlert(false)
                                                        }}
                                                    >
                                                        <AlertTitle>
                                                            {`${
                                                                warrantyResponse.status ===
                                                                'success'
                                                                    ? 'Warranty Active'
                                                                    : 'Warranty Inactive'
                                                            }`}
                                                        </AlertTitle>
                                                        {warrantyResponse.msg}
                                                    </Alert>
                                                ) : null}
                                            </>
                                        )}
                                    </ValidatorForm>
                                    <StyledSectionTitle>
                                        Pick Your Repair Service
                                    </StyledSectionTitle>
                                    <StyledToggleForm>
                                        {repairComponents.map(
                                            (
                                                {
                                                    label,
                                                    price,
                                                    image,
                                                    id,
                                                    component_name,
                                                },
                                                index
                                            ) => {
                                                const _red =
                                                    'rgb(111, 101, 173)'

                                                const borderColor = itemExists(
                                                    id
                                                )
                                                    ? _red
                                                    : '#ddd'

                                                return (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            border: `1px solid ${borderColor}`,
                                                        }}
                                                        onClick={() =>
                                                            addComponentToCart({
                                                                component_name,
                                                                label,
                                                                image,
                                                                price,
                                                                id,
                                                            })
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                height: 'fit-content',
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'center',
                                                                alignItems:
                                                                    'center',
                                                            }}
                                                        >
                                                            <img
                                                                src={image}
                                                                alt=""
                                                                loading="lazy"
                                                            />
                                                        </div>
                                                        <div
                                                            style={{
                                                                padding: '16px',
                                                                flex: '1',
                                                                background: `${
                                                                    borderColor ===
                                                                    _red
                                                                        ? _red
                                                                        : '#fff'
                                                                }`,
                                                            }}
                                                        >
                                                            <p
                                                                className="mt-0 mb-1"
                                                                style={{
                                                                    color: `${
                                                                        borderColor ===
                                                                        _red
                                                                            ? '#fff'
                                                                            : '#000'
                                                                    }`,
                                                                }}
                                                            >
                                                                {label}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        )}
                                    </StyledToggleForm>

                                    <p className="other-services">
                                        Looking for any other repair service not
                                        listed above? Leave a message and our
                                        team will get in touch with you
                                    </p>
                                    <ValidatorForm //wrapped in a form to accomodate previous flow
                                        onSubmit={() => sendMessage()}
                                        onError={(errors) => null}
                                        id="services"
                                        className="mt-5 mb-15"
                                    >
                                        {' '}
                                        <StyledTextValidator
                                            type="text"
                                            className=" w-full"
                                            label="Enter your message here"
                                            variant="outlined"
                                            size="small"
                                            maxRows={6}
                                            multiline
                                            minRows={3}
                                            onChange={({
                                                target: { value, name },
                                            }) =>
                                                setRepairDetails({
                                                    ...repairDetails,
                                                    [name]: value,
                                                })
                                            }
                                            name="extra_description"
                                            value={
                                                repairDetails.extra_description
                                            }
                                        />
                                        {repairDetails.extra_description &&
                                        cart.length === 0 ? (
                                            <Button
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disableElevation
                                                disabled={isSendingMessage}
                                                style={{
                                                    display: 'block',
                                                    margin: '1rem auto',
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                {isSendingMessage
                                                    ? 'Please wait...'
                                                    : 'Send Message'}
                                            </Button>
                                        ) : null}
                                    </ValidatorForm>

                                    <PriceSummary
                                        repairCost={repairCost}
                                        list={cart}
                                        id={id}
                                        btnText={
                                            total
                                                ? 'Proceed'
                                                : 'Estimate Repair Cost'
                                        }
                                        btnFn={
                                            total
                                                ? () => nextStep()
                                                : () => calculateTotal()
                                        }
                                        disabled={cart.length === 0 || !terms}
                                        recalculate={() => calculateTotal()}
                                        icon={false}
                                        repairDetails={repairDetails}
                                        calculatingTotal={calculatingTotal}
                                    />
                                </div>
                            </StyledLandingDescriptionCard>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Hidden mdUp>
                                <StyledFAQSection>
                                    <StyledSectionTitle>
                                        FAQs
                                    </StyledSectionTitle>
                                    {faqs.map(({ question, answer }, i) => (
                                        <FAQAccordion
                                            key={question + i}
                                            square
                                            expanded={
                                                expanded ===
                                                `panel${question + i}`
                                            }
                                            onChange={handleChange(
                                                `panel${question + i}`
                                            )}
                                        >
                                            <FAQAccordionSummary
                                                aria-controls={`panel${
                                                    question + i
                                                }-content`}
                                                id={`panel${
                                                    question + i
                                                }-header`}
                                            >
                                                <Typography>
                                                    {question}
                                                </Typography>
                                            </FAQAccordionSummary>
                                            <FAQAccordionDetails>
                                                <Typography>
                                                    {answer}
                                                </Typography>
                                            </FAQAccordionDetails>
                                        </FAQAccordion>
                                    ))}
                                </StyledFAQSection>
                            </Hidden>
                            <StyledSummaryCard>
                                <StyledSectionTitle>
                                    Badili Repair
                                </StyledSectionTitle>
                                <StyledRepairList>
                                    <li>
                                        <img
                                            src="/assets/images/repair/assured-warranty.png"
                                            alt="icon"
                                        />
                                        <span>Assured Warranty</span>
                                    </li>
                                    <li>
                                        <img
                                            src="/assets/images/repair/certified-parts.png"
                                            alt="icon"
                                        />
                                        <span>Certified Parts</span>
                                    </li>
                                    <li>
                                        <img
                                            src="/assets/images/repair/money-back.png"
                                            alt="icon"
                                        />
                                        <span>7 Day Money Back Guarantee</span>
                                    </li>
                                </StyledRepairList>
                            </StyledSummaryCard>
                            <StyledSummaryCard style={{ marginTop: '2rem' }}>
                                <StyledSectionHeader>
                                    <StyledSectionTitle>
                                        Reviews
                                    </StyledSectionTitle>
                                    {/* <button
                                        style={{
                                            border: 'none',
                                            background: 'transparent',
                                        }}
                                    >
                                        Write a Review
                                    </button> */}
                                </StyledSectionHeader>

                                {/* <StyledRatingSection>
                                <div className="heading">
                                    <div className="wrapper">
                                        <div className="item">
                                            <StyledRating
                                                name="size-small"
                                                defaultValue={5}
                                                readOnly
                                            />
                                            <p>Quality</p>
                                        </div>
                                        <div className="item">
                                            <StyledRating
                                                name="size-small"
                                                defaultValue={4}
                                                readOnly
                                            />
                                            <p>Professionalism</p>
                                        </div>
                                    </div>
                                    <div className="wrapper">
                                        <div className="item">
                                            <StyledRating
                                                name="size-small"
                                                defaultValue={4}
                                                readOnly
                                            />
                                            <p>Convenience</p>
                                        </div>
                                        <div className="item">
                                            <StyledRating
                                                name="size-small"
                                                defaultValue={4}
                                                readOnly
                                            />
                                            <p>Communication</p>
                                        </div>
                                    </div>
                                </div>
                            </StyledRatingSection> */}
                                {repair?.reviews?.map(
                                    (
                                        { user, message, rating, createdAt },
                                        index
                                    ) => (
                                        <StyledReviewsSection key={index}>
                                            <img
                                                src="/assets/images/icons/avatar.png"
                                                alt="avatar"
                                            />
                                            <div className="review">
                                                <div className="title">
                                                    <div>
                                                        <h6>
                                                            {user?.fname +
                                                                ' ' +
                                                                user?.lname}
                                                        </h6>
                                                        <Rating
                                                            name="size-small"
                                                            defaultValue={
                                                                rating
                                                            }
                                                            size="small"
                                                            readOnly
                                                        />{' '}
                                                    </div>
                                                    <small>
                                                        {formatDistance(
                                                            new Date(createdAt),
                                                            new Date(),
                                                            { addSuffix: true }
                                                        )}
                                                    </small>
                                                </div>
                                                <p className="content">
                                                    {message}
                                                </p>
                                            </div>
                                        </StyledReviewsSection>
                                    )
                                )}
                            </StyledSummaryCard>
                            <Hidden mdDown>
                                <StyledFAQSection>
                                    <StyledSectionTitle>
                                        Frequently Asked Questions
                                    </StyledSectionTitle>
                                    {faqs.map(({ question, answer }, i) => (
                                        <FAQAccordion
                                            key={question + i}
                                            square
                                            expanded={
                                                expanded ===
                                                `panel${question + i}`
                                            }
                                            onChange={handleChange(
                                                `panel${question + i}`
                                            )}
                                        >
                                            <FAQAccordionSummary
                                                aria-controls={`panel${
                                                    question + i
                                                }-content`}
                                                id={`panel${
                                                    question + i
                                                }-header`}
                                            >
                                                <Typography>
                                                    {question}
                                                </Typography>
                                            </FAQAccordionSummary>
                                            <FAQAccordionDetails>
                                                <Typography>
                                                    {answer}
                                                </Typography>
                                            </FAQAccordionDetails>
                                        </FAQAccordion>
                                    ))}
                                </StyledFAQSection>
                            </Hidden>
                        </Grid>
                    </Grid>
                    {/* <div className={classes.root}>
                    <StyledSectionTitle>Service Highlights</StyledSectionTitle>
                    {service_highlight.map(({ name, description }, index) => (
                        <Accordion
                            key={index}
                            square
                            expanded={expandedService === index}
                            onChange={handleServiceChange(index)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                // aria-controls={`panel${index + 1}a-content`}
                                // id={`panel${index + 1}a-header`}
                                className={classes.header}
                            >
                                <Typography className={classes.heading}>
                                    {name}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.content}>
                                <Typography>{description}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div> */}
                    {/* <div style={{ marginTop: '4rem' }}>
                    <StyledSectionTitle>Policies</StyledSectionTitle>
                    <StyledPoliciesSection>
                        {policies.map(({ text }, index) => (
                            <li key={index}>{text}</li>
                        ))}
                    </StyledPoliciesSection>
                </div> */}
                </StyledContainer>
            </MuiThemeProvider>
        </RepairWrapper>
    )
}

export default RepairLandingPage
