import React, { Fragment, useEffect, useState, useContext } from 'react'
//import history
import { useHistory, useLocation } from 'react-router-dom'
import {
    Card,
    TextField,
    InputAdornment,
    Icon,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormControl,
    Slider,
    Checkbox,
    Fab,
    Button,
    Hidden,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { addFilters, getInventory } from 'app/redux/actions/EcommerceActions'
import { AppContext } from 'app/contexts/AppContext'

import blogs from '../../../utils/blogs'

import { handleImageError } from '../../../utils/imageUtils'

import { useSelector } from 'react-redux'

import styled from 'styled-components'
import { SkeletonElement, SkeletonFilterCard } from 'app/components/Skeletons'
import { getCountryFromUrl } from 'utils/CountryUtils'

const StyledShopSidenav = styled.div`
    span {
        font-family: lato;
    }
    h6 {
        font-family: lato;
    }

    .MuiRadio-colorSecondary.Mui-checked,
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: #0c8085 !important;
    }

    button,
    button:disabled {
        background-color: #f68937;
        border-radius: 1rem;
    }
    button:hover {
        background-color: #f69d37;
        border-radius: 1rem;
    }
`

const StyledConditionCard = styled(Card)`
    margin-top: 1rem;

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1.5rem;
        text-align: center;
    }

    img {
        width: 100%;
        margin-bottom: 1rem;
    }
`
const StyledBrandCard = styled(Card)`
    max-height: 210px;
    overflow-y: scroll;
`
const StyledBlogCard = styled(Card)`
    margin-top: 1rem;

    img {
        width: 100%;
        height: 10rem;
        object-fit: contain;
    }
    span {
    }

    div {
        padding: 1rem;
    }

    a {
        cursor: pointer;
        text-decoration: underline;
    }
`

const ShopSidenav = ({
    toggleSidenav,
    handleFilterChange,
    filter,
    setFilter,
    loadingInventory,
    handlePriceFilter,
    handleBrandFilter,
    filterBrand,
    filterPrice,
    setFilterPrice,
    setFilterBrand
    
    
    
}) => {
    const history = useHistory()
    const { pathname } = useLocation()
    let country = getCountryFromUrl(pathname)||"ke"
     const dispatch = useDispatch()

    const { brands = [] } = useSelector((state) => state.ecommerce)

    console.log('country',country)
    const renderBlogs = (blogs) =>
        blogs.slice(0, 1).map((blog, key) => (
            <StyledBlogCard key={key}>
                <span>
                    <img
                        src={blog.src}
                        alt="blog thumbnail"
                        onError={(e) => handleImageError(e)}
                    />
                </span>
                <div>
                    <h5>{blog.title}</h5>

                    <a href={blog.link} target="_blank">
                        View more
                    </a>
                    {/* <p>{blog.excerpt}</p> */}
                </div>
            </StyledBlogCard>
        ))
        
            

 

    return (
        <Fragment>
            {loadingInventory && <SkeletonFilterCard />}
            {!loadingInventory && (
                <>
                    {' '}
                    <div className="pl-4 flex items-center mb-4 mt-2">
                        <Hidden smUp>
                            <Icon onClick={toggleSidenav}>clear</Icon>
                        </Hidden>
                    </div>
                    <StyledShopSidenav className="px-4">
                        <Card elevation={3} className="p-4 mb-4">
                            <h6>Filter by Availability</h6>
                            <FormControl
                                component="fieldset"
                                className="w-full"
                            >
                                <RadioGroup
                                    aria-label="status"
                                    name="availability"
                                   
                                    onChange={(e) => {
                                        e.preventDefault()
                                        // setFilter(e.target.value)
                                        // dispatch(addFilters(e.target.value))
                                        handleFilterChange(e.target.value)
                                        
                                        // toggleSidenav()
                                    }}
                                >
                                    <FormControlLabel
                                        value="instock=1"
                                        control={<Radio color="secondary" />}
                                        label="Exclude Out Of Stock"
                                        labelPlacement="end"
                                        checked={filter.includes('instock=1')}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Card>
                        <Card elevation={3} className="p-4 mb-4">
                            <h6>Filter by Price</h6>
                            <FormControl
                                component="fieldset"
                                className="w-full"
                            >
                               
                                    <FormControlLabel
                                        value="baseprice=1"
                                        control={<Checkbox color="secondary" checked={filterPrice.includes('baseprice=1')}  onChange={(e) => {
                                            e.preventDefault()
                                            handlePriceFilter(e.target.value)
                                        }} />}

                                        label={country ==='tz'? `Less than 180,000`:`Less than 10,000`}
                                        labelPlacement="end"   
                                    />
            
                                    <FormControlLabel
                                        value="baseprice=2"
                                        control={<Checkbox color="secondary" checked={filterPrice.includes('baseprice=2')}   onChange={(e) => {
                                            e.preventDefault()
                                            handlePriceFilter(e.target.value)
                                        }}/>}
                                        label={country ==='tz'? ` 180,000 - 360,000`:` 10,000 - 20,000`}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="baseprice=3"
                                        control={<Checkbox color="secondary" checked={filterPrice.includes('baseprice=3')}  onChange={(e) => {
                                            e.preventDefault()
                                            handlePriceFilter(e.target.value)
                                        }}/>}
                                        label={country ==='tz'? ` 360,000 - 900,000`:` 20,000 - 50,000`}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="baseprice=4"
                                        control={<Checkbox color="secondary"  checked={filterPrice.includes('baseprice=4')}  onChange={(e) => {
                                            e.preventDefault()
                                            handlePriceFilter(e.target.value)
                                        }} />}
                                        label= {country ==='tz'?`More than  900,000`:`More than 50,000`}
                                        labelPlacement="end"
                                       
                                    />
                                
                            </FormControl>
                        </Card>

                        <StyledBrandCard
                            elevation={3}
                            className="relative p-4 mb-4"
                        >
                            <h6>Filter by Brands</h6>
                            <FormControl
                                component="fieldset"
                                className="w-full"
                            >
                                <RadioGroup
                                    aria-label="status"
                                    name="brands"
                                   // onChange={(e) => handleBrandFilter(e.target.value)}
                                    onChange={(e) => {
                                        e.preventDefault()
                                        // setFilter(e.target.value)
                                        // dispatch(addFilters(e.target.value))
                                      // handleFilterChange(e.target)
                                        handleBrandFilter(e.target.value)
                                        // toggleSidenav()
                                    }}
                                >
                                    {brands.map((brand) => (
                                        <div
                                            key={brand._id}
                                            className="flex items-center justify-between"
                                        >
                                            <FormControlLabel
                                                className="flex-grow"
                                                value={`brands=${brand._id}`}
                                                control={
                                                    <Radio color="secondary" />
                                                }
                                                label={brand.brand}
                                                checked={filterBrand.includes(
                                                    brand._id
                                                )}
                                            />
                                        </div>
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </StyledBrandCard>

                        <Card elevation={3} className="p-4 mb-4">
                            <Button
                                className="w-full"
                                variant="contained"
                                color="primary"
                               // disabled={!filter}
                                onClick={() => {
                                    setFilterBrand('')
                                    setFilterPrice('')
                                    dispatch(getInventory(""))
                                  
                                }}
                            >
                                Clear Filters
                            </Button>
                        </Card>

                        <StyledConditionCard>
                            <div>
                                <img
                                    src="/assets/images/condition/condition-guide.jpg"
                                    alt=""
                                />
                                <h6>Condition guide</h6>
                                <p>
                                    Read about our phone conditions here to
                                    understand what they mean.
                                </p>
                                <Button
                                    className="w-full"
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                        history.push(
                                            `/${country}/ecommerce/condition-guide`
                                        )
                                    }
                                >
                                    SEE GUIDE
                                </Button>
                            </div>
                        </StyledConditionCard>

                        {renderBlogs(blogs)}
                    </StyledShopSidenav>
                </>
            )}
        </Fragment>
    )
}

export default ShopSidenav
