
import Shop from '../views/shopInventory/ShopInventoryPage'
import ShopLanding from '../views/shopLandingPage/ShopLandingPage'
import ShopDetailsPage from '../views/shopLandingPage/ShopDetailsPage'
import Cart from '../views/cart/Cart'
import ProductDetails from '../views/productDetails/ProductDetails'
import Checkout from '../views/checkout/CheckoutPage'
import ConditionGuide from '../views/conditionGuide/ConditionGuide'

const country = localStorage.getItem('country')||"ke"

const ecommerceRoutes = [
    {
        path: `/${country}/ecommerce/product-details/:_id`,
        component: ProductDetails,
        exact: true,
        
    },
    {
        path: `/${country}/ecommerce/details/:_id`,
        component: ShopDetailsPage,
        exact: true,
        
    },

    {
        path: `/${country}/ecommerce/checkout`,
        component: Checkout,
        
    },
    {
        path: `/${country}/ecommerce/shop/products`,
        component: Shop,
        exact: true,
        
    },
    {
        path: `/${country}/ecommerce/shop`,
        component: ShopLanding,
        // exact: true,
        
    },
    {
        path: `/${country}/ecommerce/cart`,
        component: Cart,
        
    },
    {
        path: `/${country}/ecommerce/condition-guide`,
        component: ConditionGuide,
        
    },
]

export default ecommerceRoutes
