import React from 'react'
import Shimmer from './Shimmer'
import SkeletonElement from './SkeletonElement'
import styled from 'styled-components'

const StyledSkeletonWrapper = styled.div`
    margin: 20px auto;
    padding: 10px 15px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    & .light {
        background: #f2f2f2;
    }
`

const SkeletonArticle = ({ theme }) => {
    const themeClass = theme || 'light'

    return (
        <StyledSkeletonWrapper className={themeClass}>
            <div className="skeleton-article">
                <SkeletonElement type="image" />
                {/* <SkeletonElement type="banner" /> */}
                {/* <SkeletonElement type="avatar" />
                <SkeletonElement type="thumbnail" />
                <SkeletonElement type="title" />
                <SkeletonElement type="text" />
                <SkeletonElement type="text" />
                <SkeletonElement type="text" /> */}
            </div>
            <Shimmer />
        </StyledSkeletonWrapper>
    )
}

export default SkeletonArticle
