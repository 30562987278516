import React, { useState, useEffect } from 'react'
import {
    Icon,
    Card,
    Checkbox,
    FormControlLabel,
    CircularProgress,
    IconButton,
    Tooltip,
} from '@material-ui/core'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'
import { RepairContext } from 'app/contexts/RepairContext'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { makeStyles } from '@material-ui/core/styles'
import useAuth from 'app/hooks/useAuth'
import { useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { makeHttpRequest } from 'utils/api_utils'

export const StyledSummaryCard = styled(Card)`
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.06),
        0px 1px 1px 0px rgba(0, 0, 0, 0.042),
        0px 1px 3px 0px rgba(0, 0, 0, 0.036);
    border-radius: 4px;

    .header {
        background: rgb(241, 241, 241);
        padding: 1rem;
        display: flex;
        align-items: center;
    }

    .body {
        padding: 1rem;
    }

    .footer {
        display: flex;
        align-items: start;
        justify-content: space-between;

        h2 {
            font-size: 3rem;
            color: rgb(111, 101, 173);
            text-align: center;
            font-weight: 700;
        }
        p {
            font-weight: bold;
            font-size: 1rem;
            text-align: center;
        }
    }
`

export const StyledPrimaryButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem auto;
    background: ${(props) => (props.disabled ? '#ddd' : 'rgb(111, 101, 173)')};
    color: #fff;

    border: none;
    border-radius: 5px;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    font-size: 1rem;
    padding: 0.45rem 1.3rem;

    span {
        margin-left: 10px;
        color: #fff;
    }
`

export const StyledSummaryList = styled.ol`
    /* margin: 3rem 0 0 0; */
    padding: 0;

    .total {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: start;
        margin: 0.4rem 0;
        font-weight: 700;
        color: rgb(111, 101, 173);

        border-bottom: none;
        padding-bottom: 0.8rem;
        span {
            flex: 1;
            color: #000;
            margin-left: 0px;
        }
        :before {
            height: 0;
            width: 0;
            content: '';
        }
    }
    li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: start;
        margin: 1.2rem 0;
        font-weight: 700;
        color: rgb(111, 101, 173);
        counter-increment: inst;
        border-bottom: 1px solid #ddd;
        padding-bottom: 0.8rem;

        :before {
            position: absolute;
            content: counter(inst);
            top: 0;
            left: 0;
            height: 22px;
            width: 22px;
            background: rgb(223, 223, 224);
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgb(111, 101, 173);
            font-weight: 700;
        }
        /* :nth-last-child(1) {
            font-size: 1.2rem;
            border-bottom: none;
            span {
                flex: 1;
                color: #000;
                margin-left: 0px;
            }
            :before {
                height: 0;
                width: 0;
                content: '';
            }
        } */
        /* :nth-last-child(1) {
     
                border-bottom: none;
                span {
                    flex: 1;
                    color: #000;
                    margin-left: 0px;
                }
                :before {
                    height: 0;
                    width: 0;
                    content: '';
                }
            
        } */

        span {
            flex: 1;
            /* color: #000; */
            margin-left: 30px;
        }
        button {
            border: none;
            background: transparent;
            outline: none;
            color: rgb(223, 71, 89);
            cursor: pointer;
        }
    }
`

export const StyledSectionTitle = styled.h3`
    font-size: 1.1rem;
    font-family: 'lato';
    font-weight: bold;
    flex: 1;
`
export const StyledEmptyList = styled.h3`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-size: 1rem;
`

const PurpleCheckbox = withStyles({
    root: {
        color: 'rgb(111, 101, 173)',

        '&$checked': {
            color: 'rgb(111, 101, 173)',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />)

const PriceSummary = ({
    list = [],
    btnText = 'Btn Text',
    btnFn = () => console.warn('method not passed'),
    disabled = false,
    icon = false,
    calculatingTotal,
    repairDetails,
    id,
    repairCost

}) => {
    const { state } = useLocation()
    const { user } = useAuth()
   



    const { removeFromCart, setTerms, terms, cart, total } =
        React.useContext(RepairContext)
  

    const useStyles = makeStyles((theme) => ({
        btnStyles: {
            padding: 0,

            backgroundColor: 'transparent',
            position: 'relative',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
    }))

    const classes = useStyles()

    useEffect(() => {

    }, [])
    


   

    return (
        <ValidatorForm
            onSubmit={btnFn}
            onError={(errors) => null}
            id="services"
        // className="mt-15"
        >
            {' '}
            <StyledSummaryCard>
                <div className="header">
                    <StyledSectionTitle>Summary</StyledSectionTitle>
                    {list.length !== 0 && <span>{list.length} items</span>}
                </div>
                {list.length === 0 && (
                    <StyledEmptyList>No items added</StyledEmptyList>
                )}
                <div className="body">
                    <StyledSummaryList>
                        {list.map(({ label, price, id }, index) => (
                            <li key={index}>
                                <span style={{ color: '#000' }}>{label}</span>
                                <IconButton
                                    onClick={() => removeFromCart(id)}
                                    className={classes.btnStyles}
                                >
                                    <Icon
                                        fontSize="small"
                                        color="rgb(223, 71, 89)"
                                    >
                                        delete
                                    </Icon>
                                </IconButton>
                                {/* <button onClick={() => removeFromCart(id)}>
                                    remove
                                </button> */}
                            </li>
                        ))}
                        {list.length !== 0 && (
                            <>
                                {/* <li>
                            <span>Add Coupon Code</span>
                            <a href>Apply</a>
                        </li> */}

                                {total && (
                                    <>
                                        {/* here */}
                                        <li className="total">
                                            <span>Estimated Amount</span>
                                            {total.currency +
                                                ' ' +
                                                repairCost?.cost
                                                    .toLocaleString(
                                                        'en-US'
                                                    )
                                            }
                                        </li>
                                        <li className="total">
                                            <span>
                                                Cost Covered under Warranty
                                            </span>
                                            {total.waranty
                                                ? ''
                                                : total.currency + ' ' + 0}
                                        </li>
                                        <li className="total">
                                            {/* here */}
                                            <span>Final Estimated Cost</span>
                                            {total.currency}{' '}
                                            {total.warranty
                                                ? (
                                                    repairCost?.cost - 200
                                                ).toLocaleString('en-US')
                                                : (
                                                    repairCost?.cost - 0
                                                ).toLocaleString('en-US')}
                                        </li>
                                    </>
                                )}
                            </>
                        )}
                    </StyledSummaryList>

                    {list.length !== 0 && (
                        <>
                            <FormControlLabel
                                className="mt-5"
                                control={
                                    <PurpleCheckbox
                                        checked={terms}
                                        onChange={(e) => setTerms(!terms)}
                                        name="terms"
                                    />
                                }
                                label={
                                    <span>
                                        I agree to the{' '}
                                        <a
                                            href
                                            style={{
                                                color: `rgb(111, 101, 173)`,
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            Terms and conditions
                                        </a>
                                    </span>
                                }
                            />
                            <Tooltip
                                title={
                                    terms
                                        ? ''
                                        : 'Seems you have not agreed to the terms of use'
                                }
                                placement="top"
                                arrow
                            >
                                <StyledPrimaryButton
                                    type="submit"
                                    disabled={disabled || calculatingTotal}
                                >
                                    {calculatingTotal && (
                                        <>
                                            {' '}
                                            <CircularProgress
                                                color="#fff"
                                                size={20}
                                            />{' '}
                                            <span>Please wait</span>
                                        </>
                                    )}
                                    {!calculatingTotal && (
                                        <>
                                            {' '}
                                            {btnText}{' '}
                                            {icon && <Icon>arrow_forward</Icon>}
                                        </>
                                    )}
                                </StyledPrimaryButton>
                            </Tooltip>
                        </>
                    )}
                </div>
            </StyledSummaryCard>
        </ValidatorForm>
    )
}

export default PriceSummary
