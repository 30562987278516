import React from 'react'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as Illustration } from 'app/assets/images/404.svg'
import { devices } from 'utils/screen_sizes'

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    svg {
        max-height: 200px;

        @media ${devices.mobileS} {
            max-height: 130px;
        }
        @media ${devices.laptop} {
            max-height: 200px;
        }
    }

    button {
        margin-top: 2rem;
    }
`

const NotFound = () => {
    const country = localStorage.getItem('country')
    return (
        <StyledWrapper>
            <img
                className="mb-8 w-full"
                src="/assets/images/illustrations/404.svg"
                alt=""
            />
            <Illustration />
            <Link to={`/${country}`}>
                <Button
                    className="capitalize"
                    variant="contained"
                    color="primary"
                >
                    Back to Home Page
                </Button>
            </Link>
        </StyledWrapper>
    )
}

export default NotFound
