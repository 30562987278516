import React, {useState} from 'react'
import { MatxSnackbar } from 'app/components'
import { Card } from '@material-ui/core'
import styled from 'styled-components'


const StyledErrorCard = styled(Card)` 
    height: 25rem;
    max-width:1080px;
    width: 100%;
    margin: 2rem auto;
    display:flex;
    align-items:center;
    justify-content:center;
`

const ErrorComponent = ({children, error:{msg}}) => {

    const [showSnackBar, setShowSnackBar] = useState(true)
    const closeSnackbar = () => {
        setShowSnackBar(false)
    }

    return (
        <StyledErrorCard>
            <MatxSnackbar
                open={showSnackBar}
                message={msg}
                type={'error'}
                handleClose={closeSnackbar}
            />
            <div className="text-center">
                <h5>Oops, looks like an error ocurred</h5>
                <p>{msg}</p>
                {children}

            </div>
        </StyledErrorCard>
    )
}

export default ErrorComponent