import axios from 'axios'
import { API_URL, API_KEY, API_SECRET } from 'utils/api_utils'
const country = localStorage.getItem('country') || 'ke'

const headers = {
    'Content-Type': 'application/json',
    Key: API_KEY,
    Secret: API_SECRET,
    country,
}

export const getDetails = (id) => {
    return axios.get(API_URL + `get_top_calculation_by_user/${id}`, {
        headers: headers,
    })
}

export const getDetailsByID = (id) => {
    return axios.get(API_URL + `get_calculation_by_id/${id}`, {
        headers: headers,
    })
}

export const getLocations = (query) => {
    return axios.get(API_URL + `get_locations?type=${query}`, {
        headers: headers,
    })
}
export const dropOff = (query) => {
    return axios.get(API_URL + query, {
        headers: headers,
    })
}

export const handleGetPartner = async (_id) => {
    return axios.get(API_URL + 'get_location_by_partner_id/' + _id, {
        headers: headers,
    })
}

export const requestPayment = (
    { _id, user_id },
    {
        mpesa_phone,
        location,
        phone,
        status_id,
        selected_date,
        selected_time,
        method,
    }
) => {
    return axios.post(
        API_URL + `payment_request`,
        {
            userId: user_id,
            mpesaNumber: mpesa_phone,
            phoneNumber: phone,
            calculationId: _id,
            locationId: location,
            status_id: status_id,
            pickupDate: selected_date,
            pickupTime: selected_time,
            method,
        },
        { headers: headers }
    )
}
export const toggleWhatsapp = (id, preference) => {
    return axios.put(
        API_URL + `toggle_whatsapp/${id}`,
        { enabledWhatsapp: preference },
        { headers: headers }
    )
}
