import React from 'react'
import { Dialog, Button, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    changeButton: {
        background: '#42c8b7',
        color: '#fff',
        width: '100%',

        '&:hover': {
            backgroundColor: '#42c8b7',
            color: '#fff',
            opacity: 0.8,
        },
    },
}))

const SimpleDialog = ({
    children,
    open,
    onConfirmDialogClose,
    title = 'confirm',
    onYesClick,
    loading = false,
    disabled_button = false
}) => {
    const classes = useStyles()
    return (
        <Dialog
            maxWidth="xs"
            fullWidth
            open={open}
            onClose={onConfirmDialogClose}
        >
            <div className="p-8 ">
                <h4 className="capitalize m-0 mb-2 text-center">{title}</h4>

                <div className="pt-2 m--2">{children}</div>
                <div className="flex justify-center pt-2 m--2">
                    <Button
                        className={clsx('m-2  px-6 ', classes.changeButton)}
                        variant="outlined"
                        disabled={loading || !disabled_button}
                        onClick={onYesClick}
                    >
                        {loading && (
                            <CircularProgress
                                size={17}
                                className={`text-white mr-2 ${classes.buttonProgress}`}
                            />
                        )}{' '}
                        Checkout
                    </Button>
                    {/* <Button
                        className="m-2  px-6"
                        variant="outlined"
                        color="inherit"
                        onClick={onConfirmDialogClose}
                    >
                        Cancel
                    </Button> */}
                </div>
            </div>
        </Dialog>
    )
}

export default SimpleDialog
