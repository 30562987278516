import styled from 'styled-components'
import { Card, Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { devices } from 'utils/screen_sizes'
import { TextValidator } from 'react-material-ui-form-validator'

export const StyledContainer = styled.div`
    width: 85vw;
    margin: 2rem auto;
    font-family: 'lato' !important;

    .MuiTypography-body1 {
        font-family: 'lato' !important;
    }
`

export const StyledLandingDescriptionCard = styled(Card)`
    padding: 2rem;
    box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);

    .card-header {
        display: flex;
        align-items: center;
        justify-content: start;

        img {
            max-height: 85px;
            margin-right: 1rem;
        }
        .title {
            font-size: 1.2rem;
            font-family: 'lato';
            font-weight: bold;
        }
        .sub-title {
            font-size: 1rem;
            font-family: 'lato';
            font-weight: 600;
            padding-top: 0.3rem;
            span {
                color: #777;
            }
        }
    }
    .card-body {
        margin-top: 4rem;
    }
    .other-services {
        font-weight: 600;
        padding-left: 2rem;
        margin-top: 4rem;

        @media ${devices.mobileS} {
            padding-left: 0rem;
        }
        @media ${devices.mobileM} {
            padding-left: 1rem;
        }
        @media ${devices.laptop} {
            padding-left: 0rem;
        }
    }
`

export const StyledSectionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.2rem;
    h3 {
        margin-bottom: 0;
    }
`

export const StyledTextValidator = styled(TextValidator)`
    .MuiInputBase-root,
    .MuiFormLabel-root {
        font-family: 'lato' !important;
    }
`
export const StyledSummaryCard = styled(Card)`
    padding: 1rem;
    .book-now {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2rem auto;
        background: rgb(111, 101, 173);
        color: #fff;
        font-size: 1.1rem;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        padding: 0.65rem 3rem;

        span {
            margin-left: 10px;
            color: #fff;
        }
    }
    .footer {
        display: flex;
        align-items: start;
        justify-content: space-between;

        h2 {
            font-size: 3rem;
            color: rgb(111, 101, 173);
            text-align: center;
            font-weight: 700;
        }
        p {
            font-weight: bold;
            font-size: 1rem;
            text-align: center;
        }
    }
`

export const StyledMessageButton = styled.button`
    background: rgb(111, 101, 173);
    color: #fff;
    font-size: 0.85rem;

    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin: 2rem 0 0 auto;
    display: block;

    @media ${devices.mobileS} {
        padding: 5px 10px;
        font-size: 0.75rem;
    }
    @media ${devices.mobileM} {
        padding: 5px 15px;
        font-size: 0.85rem;
    }
    @media ${devices.laptop} {
        padding: 7px 15px;
    }
`
export const StyledRepairItem = styled(Grid)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;

    img {
        max-height: 90px;
        /* margin-right: 1.5rem; */

        @media ${devices.mobileS} {
            max-height: 50px;
            /* margin-right: 1rem; */
        }
        @media ${devices.mobileM} {
            max-height: 70px;
            /* margin-right: 1rem; */
        }
        @media ${devices.laptop} {
            max-height: 70px;
            /* margin-right: 1.5rem; */
        }
    }
    div {
        flex: 1;
        .component-name {
            font-size: 0.9rem;
            @media ${devices.mobileS} {
                font-size: 0.9rem;
            }
            @media ${devices.mobileM} {
                font-size: 0.9rem;
            }
        }

        .price {
            color: rgb(111, 101, 173);
            font-size: 1rem;
            @media ${devices.mobileS} {
                font-size: 0.8rem;
            }
            @media ${devices.mobileM} {
                font-size: 0.9rem;
            }
        }
        button {
            background: ${(props) =>
                props.exists ? 'rgb(223, 71, 89)' : 'rgb(111,101,173)'};
            color: #fff;
            font-size: 0.85rem;
            padding: 3px 15px;
            border: none;
            border-radius: 3px;
            cursor: pointer;

            @media ${devices.mobileS} {
                padding: 3px 10px;
                font-size: 0.75rem;
            }
            @media ${devices.mobileM} {
                padding: 3px 15px;
                font-size: 0.85rem;
            }
            @media ${devices.laptop} {
                padding: 3px 15px;
            }
        }
        a {
            font-size: 12px;
            display: block;
            padding-top: 5px;
            :hover {
                text-decoration: underline;
                color: rgb(111, 101, 173);
            }
            @media ${devices.mobileS} {
                font-size: 11px;
            }
            @media ${devices.mobileM} {
                font-size: 12px;
            }
        }
    }
`

export const StyledSummaryList = styled.ol`
    margin: 3rem 0 0 0;
    padding: 0;
    li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: start;
        margin: 1.2rem 0;
        font-weight: 700;
        color: rgb(111, 101, 173);
        counter-increment: inst;
        border-bottom: 1px solid #ddd;
        padding-bottom: 0.8rem;

        :before {
            position: absolute;
            content: counter(inst);
            top: 0;
            left: 0;
            height: 22px;
            width: 22px;
            background: rgb(223, 223, 224);
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgb(111, 101, 173);
            font-weight: 700;
        }
        :nth-last-child(1) {
            font-size: 1.2rem;
            border-bottom: none;
            span {
                flex: 1;
                color: #000;
                margin-left: 0px;
            }
            :before {
                height: 0;
                width: 0;
                content: '';
            }
        }
        :nth-last-child(2) {
            border-bottom: none;
            span {
                flex: 1;
                color: #000;
                margin-left: 0px;
            }
            :before {
                height: 0;
                width: 0;
                content: '';
            }
        }

        span {
            flex: 1;
            color: #000;
            margin-left: 30px;
        }
    }
`

export const StyledFAQSection = styled.div`
    margin-top: 4rem;
    p {
        font-size: 0.875rem;
    }
`

export const StyledRepairList = styled.ol`
    margin: 2rem 0 0 0;
    list-style-type: none;
    padding: 0;
    li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: start;
        /* margin: 1rem 0; */
        font-size: 1rem;

        img {
            max-height: 60px;
            /* margin-right: 1rem; */
        }

        span {
            color: #000;
            flex: 1;
        }
    }
`

export const StyledPoliciesSection = styled.ul`
    /* margin: 3rem 0 0 0; */
    list-style-type: none;
    padding: 0;
    li {
        margin: 1.2rem 0;
        font-size: 14px;
        /* font-weight: 600; */
    }
`

export const StyledReviewsSection = styled.div`
    display: flex;
    align-items: start;
    justify-content: center;
    margin: 2rem 0;

    img {
        height: 55px;
        width: 55px;
        border-radius: 50%;
        margin-right: 1.4rem;

        @media ${devices.mobileS} {
            height: 30px;
            width: 30px;
            margin-right: 1rem;
        }
        @media ${devices.mobileM} {
            height: 40px;
            width: 40px;
            margin-right: 1rem;
        }
    }

    .review {
        flex: 1;

        .title {
            display: flex;
            align-items: start;
            /* justify-content: center; */
            div {
                flex: 1;
                h6 {
                    font-family: 'lato';
                }
            }
            small {
                font-size: 12px;
                color: #777;
                font-family: 'lato';
            }
        }
        .content {
            font-size: 13px;
            margin: 0;
        }StyledReviewsSection
    }
`
export const StyledAlert = styled(Alert)`
    margin: 2rem;
    width: 100%;
    @media ${devices.mobileS} {
        margin: 1rem;
        .MuiAlert-icon {
            display: none;
        }
    }
    @media ${devices.laptop} {
        margin: 2rem;
        .MuiAlert-icon {
            display: flex;
        }
    }

    .MuiAlertTitle-root {
        font-weight: 700;
        margin-bottom: 20px;
    }
    p {
        margin: 0;
        padding: 0.3rem 0;
        font-weight: 600;
        span {
            font-weight: normal;
            padding-left: 5px;
        }
    }
`

export const StyledDescriptionCard = styled(Card)`
    padding: 1rem;

    .card-header {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-height: 85px;
            margin-right: 2rem;
        }
        .title {
            font-size: 1.5rem;
            font-family: 'open-sans';
            font-weight: bold;
        }
        .sub-title {
            font-size: 1.1rem;
            font-family: 'open-sans';
            font-weight: 600;
            padding-top: 0.3rem;
            span {
                color: #777;
            }
        }
    }
`

export const StyledSectionTitle = styled.h3`
    font-size: 1.1rem;
    font-family: 'lato';
    font-weight: bold;
    margin-bottom: 1.2rem;
    color: rgb(111, 101, 173);
`

// export const StyledRepairItem = styled(Grid)`
//     display: flex;
//     align-items: start;
//     justify-content: center;
//     margin-top: 2rem;

//     img {
//         max-height: 70px;
//         margin-right: 1.5rem;
//     }
//     div {
//         flex: 1;
//         .component-name {
//             font-size: 1.1rem;
//         }

//         .price {
//             color: rgb(111, 101, 173);
//             font-size: 1rem;
//         }
//         button {
//             background: rgb(111, 101, 173);
//             color: #fff;
//             font-size: 0.85rem;
//             padding: 5px 25px;
//             border: none;
//             border-radius: 3px;
//             cursor: pointer;
//         }
//         a {
//             font-size: 12px;
//             display: block;
//             padding-top: 5px;
//             :hover {
//                 text-decoration: underline;
//                 color: rgb(111, 101, 173);
//             }
//         }
//     }
// `

// export const StyledSummaryList = styled.ol`
//     margin: 3rem 0 0 0;
//     padding: 0;
//     li {
//         position: relative;
//         display: flex;
//         align-items: center;
//         justify-content: start;
//         margin: 1.2rem 0;
//         font-weight: 700;
//         color: rgb(111, 101, 173);
//         counter-increment: inst;
//         border-bottom: 1px solid #ddd;
//         padding-bottom: 0.8rem;

//         :before {
//             position: absolute;
//             content: counter(inst);
//             top: 0;
//             left: 0;
//             height: 22px;
//             width: 22px;
//             background: rgb(223, 223, 224);
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             color: rgb(111, 101, 173);
//             font-weight: 700;
//         }
//         :nth-last-child(1) {
//             font-size: 1.2rem;
//             border-bottom: none;
//             span {
//                 flex: 1;
//                 color: #000;
//                 margin-left: 0px;
//             }
//             :before {
//                 height: 0;
//                 width: 0;
//                 content: '';
//             }
//         }
//         :nth-last-child(2) {
//             border-bottom: none;
//             span {
//                 flex: 1;
//                 color: #000;
//                 margin-left: 0px;
//             }
//             :before {
//                 height: 0;
//                 width: 0;
//                 content: '';
//             }
//         }

//         span {
//             flex: 1;
//             color: #000;
//             margin-left: 30px;
//         }
//     }
// `

// export const StyledFAQSection = styled.div`
//     margin-top: 4rem;
// `

// export const StyledRepairList = styled.ol`
//     margin: 3rem 0 0 0;
//     list-style-type: none;
//     padding: 0;
//     li {
//         position: relative;
//         display: flex;
//         align-items: center;
//         justify-content: start;
//         /* margin: 3rem 0; */
//         font-size: 1rem;

//         img {
//             max-height: 40px;
//             margin-right: 1rem;
//         }

//         span {
//             color: #000;
//             flex: 1;
//         }
//     }
// `

// export const StyledPoliciesSection = styled.ul`
//     margin: 3rem 0 0 0;
//     list-style-type: none;
//     /* padding: 0; */
//     li {
//         margin: 1.2rem 0;
//         font-size: 1rem;
//         font-weight: 600;
//     }
// `

// export const StyledReviewsSection = styled.div`
//     display: flex;
//     align-items: start;
//     justify-content: center;
//     margin: 2rem 0;
//     img {
//         height: 55px;
//         width: 55px;
//         border-radius: 50%;
//         margin-right: 1.4rem;
//     }

//     .review {
//         flex: 1;
//         .title {
//             display: flex;
//             align-items: start;
//             /* justify-content: center; */
//             div {
//                 flex: 1;
//                 h5 {
//                     font-family: 'open-sans';
//                 }
//             }
//             small {
//                 font-size: 14px;
//                 color: #777;
//                 font-family: 'open-sans';
//             }
//         }
//     }
// `

export const StyledSectionSubTitle = styled.h4`
    font-size: 1rem;
    font-family: 'open-sans';
    font-weight: 600;
    margin: 1.2rem 0;
`

export const StyledScheduledCard = styled(Card)`
    padding: 1rem;
    box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
    margin: 2rem 0;
`

export const StyledDistance = styled.div`
    display: flex;
    align-items: center;
    margin: 1rem 0;

    h6 {
        flex: 1;
        font-size: 1rem;
    }
    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        small {
            display: block;
        }
    }
`
