// import './VendorCss'
 import React from 'react'

import { animationStyles } from "app/styles/utilities/_animations"
import { borderStyles } from "app/styles/utilities/_border"
import { colorStyles } from "app/styles/utilities/_color"
import { commonStyles } from "app/styles/utilities/_common"
import { positioningStyles } from "app/styles/utilities/_positionings"
import { shadowStyles } from "app/styles/utilities/_shadows"
import { spacingStyles } from "app/styles/utilities/_spacing"
import { typographyStyles } from "app/styles/utilities/_typography"
import { variableStyles } from "app/styles/_variables"

const GlobalCss = ({ children }) => {
    variableStyles()
    positioningStyles()
    spacingStyles()
    borderStyles()
    colorStyles()
    shadowStyles()
    // typographyStyles()
    commonStyles()
    animationStyles()
    // landingStyles();

    return children || null
}

export default React.memo(GlobalCss)