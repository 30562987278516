import React, { useState } from 'react'

export const FeatureFlags = React.createContext({});

export const FeatureFlagsProvider = ({ children }) => {
    const [features, setFeatures] = React.useState({});
  
    return (
      <FeatureFlags.Provider value={{ features }}>
        {children}
      </FeatureFlags.Provider>
    );
  };