import React from 'react'
const country = localStorage.getItem('country')
const invoiceRoutes = [
    {
        path: `/${country}/invoice/list`,
        exact: true,
        component: React.lazy(() => import('./InvoiceList')),
    },
    {
        path: `/${country}/invoice/:id`,
        component: React.lazy(() => import('./InvoiceDetails')),
    },
    {
        path: `/${country}/invoice/edit/:id`,
        component: React.lazy(() => import('./InvoiceList')),
    },
    {
        path: `/${country}/invoice/payment-success`,
        component: React.lazy(() => import('../paymentSuccess/paymentSuccess')),
    },
]

export default invoiceRoutes
