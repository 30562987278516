import { Slider } from 'app/components'
import { RepairContext } from 'app/contexts/RepairContext'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'

export const StyledDatePicker = styled.ul`
    list-style-type: none;
    margin: 25px 0 0 0;
    padding: 0;
    /* overflow: scroll; */

    li {
        float: left;
        margin: 0 16px 16px 0;
        width: 110px;
        height: 120px;
        position: relative;
    }
    label,
    input {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    input[type='radio'] {
        opacity: 0.011;
        z-index: 100;
    }

    input[type='radio']:checked + label {
        background: rgb(111, 101, 173, 0.7);
        color: #fff;
        border: 2px dotted #fff;
    }
    input[type='radio']:disabled + label {
        background: #ddd;
        color: #fff;
        border: 2px dotted #fff;
        cursor: not-allowed;
    }

    label {
        padding: 5px;
        border: 2px dotted #ccc;
        cursor: pointer;
        z-index: 90;
        text-align: center;

        .day {
            font-weight: 600;
            font-size: 1rem;
            font-family: 'open-sans';
            padding: 0;
            margin: 0;
        }
        .date {
            margin: 1rem 0;
            font-size: 1.3rem;
        }
    }

    label:hover {
        background: #ddd;
    }
`

export const StyledAvailable = styled.p`
    font-size: 13px;
    color: ${(props) => (props.color ? props.color : 'white')};
`

function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
            }}
            onClick={onClick}
        />
    )
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        />
    )
}

const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 1,
    infinite: false,

    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 3,
                infinite: false,
                dots: true,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                dots: false,
                arrows: false,
                infinite: false,
                slidesToShow: 2.5,
                slidesToScroll: 1,
                // nextArrow: <SampleNextArrow />,
                // prevArrow: <SamplePrevArrow />,
            },
        },
        {
            breakpoint: 320,
            settings: {
                dots: false,
                arrows: false,
                infinite: false,
                slidesToShow:2,
                slidesToScroll: 1,
                nextArrow: <SampleNextArrow />,
                prevArrow: <SamplePrevArrow />,
            },
        },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
}
const DatePicker = () => {
    const { dates, addressDetails, setAddressDetails } =
        useContext(RepairContext)

    // const [selectedDate, setSelectedDate] = useState(dates[0].date)

    const handleDateChange = (e) => {
        setAddressDetails({
            ...addressDetails,
            appointment_date: Number(e.target.value),
        })
    }
    return (
        <StyledDatePicker>
            <Slider settings={settings}>
                {dates?.map(({ date, day, isWeekend = false }) => (
                    <li className="md-radio" key={date}>
                        <input
                            id={date}
                            type="radio"
                            name="date"
                            value={date}
                            checked={date === addressDetails.appointment_date}
                            onChange={handleDateChange}
                            disabled={isWeekend}
                        />
                        <label htmlFor={date}>
                            <p className="day"> {day}</p>
                            <h5 className="date">{date}</h5>
                            <StyledAvailable color={isWeekend && '#f0ad4e'}>
                                {isWeekend ? 'Closed' : 'Available'}
                            </StyledAvailable>
                        </label>
                    </li>
                ))}
            </Slider>
        </StyledDatePicker>
    )
}

export default DatePicker
