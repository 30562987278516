import React, {useEffect} from 'react';
import {StyledHero} from '../howItWorks/HowItWorks.style'
import {StyledYellowTitle} from '../../styles/product-detail.style'
import styled from 'styled-components'

import {devices} from '../../../utils/screen_sizes'


const StyledConditionOverview = styled.section`
    margin:auto;

    div{
        margin: 4rem 2rem;
        display:flex;
        flex-direction:column;
        justify-content:center;
    }

    img{
        width:90%;
        margin:2rem auto;
    }

    h2, p{
        color:#444;
        font-family:'lato';
    }

    @media ${devices.laptop}{
        div{
            margin: 4rem 12rem;
            display:flex;
            flex-direction:column;
            justify-content:center;
        }

    }
`


const ConditionGuide = props => {

    const scrollToTop = () =>{
        window.scrollTo(0, 0)
   }

   useEffect(() => {
        scrollToTop()
    }, []) 

    return <StyledConditionOverview>
        <div>
            <h2>Condition Overview</h2>
            <StyledYellowTitle>What is a refurbished phone?</StyledYellowTitle>
            <p>
                A refurbished phone refers to a pre-owned device that has been carefully 
                checked and brought back to perfect working condition. In our case, Badili 
                technicians professionally repair each device in our state-of-the-art facility 
                thus guaranteeing you a perfect working device. It doesn’t end there, we 
                additionally include an unlimited 6 Months warranty on the refurbished 
                device so you can rest assured of getting the right device.
                Our refurbished devices have the advantage of not only being sold at half 
                the price of a similar new phone but also being professionally checked by 
                highly trained individuals which guarantees ‘As Good As New’ condition
                As Good As New Condition
                As Badili, we have committed to offering all our clients the very best. This is 
                the reason why all our phones come in one condition, as good as new 
            </p>

            <img src="/assets/images/condition/condition.jpg" />

            <StyledYellowTitle>As Good As New Condition</StyledYellowTitle>
            <p>
                As Badili, we have committed to offering all our clients the very best. This is 
                the reason why all our phones come in one condition, as good as new 
                condition. Therefore, every Badili phone owner gets the opportunity to own a
                device with very minimal first glance scratches on the screen. What’s even 
                better is the fact that there are no dents on the body.
                This condition makes it almost similar to what you’d get when purchasing a 
                brand new phone as there are minimal signs of wear and tear. We aim to 
                give every Badili phone owner an experience of owning a phone similar to a 
                new one in almost every way.
                Before being put on sale, all our refurbished phones have been thoroughly 
                checked and tested to ensure they’re in full working order of all the phone 
                components. This ranges from the cellular network, speakers, and earpiece 
                functionality to name a few.
                So, if you’re looking into owning a phone but don’t want to spend a fortune, 
                buying a refurbished phone is the way to go. 
                Let’s make Kenya a Badili Nation, Kwani Ni Kesho?
            </p>
        </div>
    </StyledConditionOverview>
}

export default ConditionGuide;