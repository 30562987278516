import axios from 'axios'
import { API_URL, API_KEY, API_SECRET } from 'utils/api_utils'

const country = localStorage.getItem('country')

const headers = {
    'Content-Type': 'application/json',
    Key: API_KEY,
    Secret: API_SECRET,
    country,
}

export const getdealers = (query) => {
    return axios.get(API_URL + query, {
        headers: headers,
    })
}
export const getstores = (query) => {
    return axios.get(API_URL + query, {
        headers: headers,
    })
}

export const submitAddress = (info) => {
    return axios.post(API_URL + `add_billing_details`, info, {
        headers: headers,
    })
}
export const authenticateLipaLater = (info) => {
    return axios.post(API_URL + `send_lipalater_otp`, info, {
        headers: headers,
    })
}
export const verifyOtpLipaLater = (info) => {
    return axios.post(API_URL + `verify_lipalater`, info, {
        headers: headers,
    })
}

export const postNewOrder = (order) => {
    return axios.post(API_URL + `confirm_order`, order, { headers: headers })
}
export const authenticateCash = (code) => {
    return axios.post(API_URL + `get_auth_code`, code, { headers: headers })
}

export const getLocations = async () => {
    const {
        data: { success, locations },
    } = await axios.get(API_URL + `get_locations?type=dropoff`, {
        headers: headers,
    })

    if (!success) {
        return null
    } else {
        return locations
    }
}

export const getBestSellers = (priceFilter = 0) => {
    try {
        if (priceFilter > 0) {
            return axios.get(
                API_URL + `get_bestsellers?baseprice=${priceFilter}`,
                {
                    headers: headers,
                }
            )
        } else {
            return axios.get(API_URL + `get_bestsellers`, {
                headers: headers,
            })
        }
    } catch (error) {}
}

export const getFeatured = () => {
    return axios.get(API_URL + `get_featured_models`, {
        headers: headers,
    })
}
export const getDeals = () => {
    return axios.get(API_URL + `get_deals`, {
        headers: headers,
    })
}
export const getFavorites = () => {
    return axios.get(API_URL + `get_favorites`, {
        headers: headers,
    })
}

export const getAvailableModels = (query = '') => {
 

    if (query) {
        return axios.get(API_URL + `available_models?${query}`, {
            headers: headers,
        })
    }
    return axios.get(API_URL + `available_models`, {
        headers: headers,
    })
}

export const getBrands = (query = '6165c8453c6ca93814dc21aa') => {
    return axios.get(API_URL + `get_inventory_by_brand/${query}`, {
        headers: headers,
    })
}

export const addCoupon = (code) => {
     // const features = { isNewDealersEnabled: true }
    //   reply.send({ features });
    return axios.post(API_URL + `get_coupon_price`, code, { headers: headers })
}