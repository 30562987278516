import React, { useEffect, Fragment, useRef, useLayoutEffect, useCallback } from 'react'
import { Grid, Card } from '@material-ui/core'
import { replaceWhiteSpaceWithHyphen } from 'utils/string_utils'
import { devices } from '../../../utils/screen_sizes'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { SkeletonProductCard } from 'app/components/Skeletons'
import Pagination from '@material-ui/lab/Pagination'
import { getCountryFromUrl } from 'utils/CountryUtils'


const StyledEcommerceCard = styled.div`
    /* margin: 4rem 0; */

    > p {
        font-size: 1.4rem;
        font-weight: 500;
        color: #34314c;
    }

    > button {
        padding: 0.5rem 1rem;
    }
`

const StyledMobileWrapper = styled.div`
    @media ${devices.mobileS} {
        display: flex;
       img{
        flex-basis:33.33%; 
       }
       div{
        flex-basis:66.66%;
        margin-left: 15px;
       }
    }
    @media ${devices.mobileL} {
        display: flex;
        flex-direction: column;
       img{
        flex-basis:33.33%; 
       }
       div{
        flex-basis:100%;
        margin-left: 0px;
       }
    }
    @media ${devices.laptop} {
        display: block}
        margin-left: 0px;
    }
`

const StyledProductCard = styled(Card)`
    border-radius: 4px;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    position: relative;
    padding: 1rem;
    margin-top: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

    :hover {
        cursor: pointer;
    }

    .bestseller {
        background-color: ${(props) =>
            props.available ? 'rgb(44, 179, 74)' : '#f68937'};
        padding: 2px 0.3rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

        font-size: 12px;
        font-weight: 600;
        position: absolute;
        top: 0px;
        right: 0px;
        color: #fff;
    }
    img {
        object-fit: contain;
        /* width: fit-content; */
        max-height: 155px;
        margin: 1.5rem auto 0 auto;

        @media ${devices.mobileS} {
            width: 100%;
           margin: 1.3rem auto 0 auto;
               max-height: 110px;
        }
        @media ${devices.laptop} {
            width: 100%;
            margin: 1.5rem auto 0 auto;
               max-height: 155px;
        }
    }
    h4 {
        margin-top: 1.2rem;
        font-size: 1rem;
        @media ${devices.mobileS} {
            margin-top: 1.5rem
            font-size: 1rem;
        }
        @media ${devices.mobileM} {
            margin-top: 1.5rem
            font-size: 1rem;
        }
        @media ${devices.laptop} {
            margin-top: 1.2rem;
        }
    }
    p {
        margin: 0;
        font-size: 13px;
        @media ${devices.mobileS} {
            font-size: 0.9rem;
        }
        @media ${devices.mobileM} {
            font-size: 0.9rem;
        }
        :last-child {
            color: #777;
            /* margin-top: .5rem; */
            span {
                text-decoration: line-through;
                margin-right: 10px;
            }
            b {
                color: #000;
                font-weight: 600;
                @media ${devices.mobileS} {
                    display: block;
                    float: right;
                }
                @media ${devices.mobileM} {
                    display: inline-block;
                    float: none;
                }
            }
        }
    }
`

const NoResults = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
    width: 100%;
`
// new styles
const StyledContentBox = styled.div`
    width: 100%;
    background: rgb(230, 231, 232);
    padding: 2rem;
    border-radius: 25px;
    margin-top: 4.5rem;
    @media ${devices.mobileS} {
        padding: 2rem 1rem;
        margin-top: 2.5rem;
    }
    @media ${devices.laptop} {
        padding: 1.5rem;
        margin-top: 4.5rem;
    }
`
const StyledBoxGrid = styled(Grid)`
    display: flex;
    align-items: start;
    img {
        height: 95px;
        width: auto;
        margin-right: 0.75rem;

        @media ${devices.mobileS} {
            margin: 0 auto 1rem auto;
        }
        @media ${devices.laptop} {
            margin: 0 0.75rem 0 0;
        }
    }
    b {
        font-weight: bold;
        font-size: 1rem;
    }
    @media ${devices.mobileS} {
        flex-direction: column;
    }
    @media ${devices.laptop} {
        flex-direction: row;
    }
`
const StyledImageBoxGrid = styled(Grid)`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-height: 325px;
        width: auto;
        margin-bottom: -90px;

        @media ${devices.mobileS} {
            max-height: 325px;

            margin-bottom: -90px;
        }
        @media ${devices.mobileM} {
            max-height: 375px;
            margin-bottom: -95px;
        }

        @media ${devices.laptopL} {
            max-height: 450px;
            margin-bottom: -145px;
            margin-top: -135px;
        }
    }
`

const StyledTitle = styled.h4` 
    font-size:1.8rem;
    font-weight: 800;
    color:#000;
    margin 0;
    font-family:'open-sans';
  @media ${devices.mobileS} {
    font-size:1.7rem;
    }
    @media ${devices.laptop} {
          font-size:1.8rem;
    }
    span{
        color:#F68937;
    }
    p{
font-size: 1.1rem;
margin: .5rem 0 1rem 0;
 font-weight: 500;
    }
`

const StyledPaginationWrapper = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 0 0 0;
`

const InventoryContainer = ({
    inventory,
    loadingInventory,
    getInv,
    count,
    page,
    handleChange,
    currentData,
    refProp,
    values,
    setValues,
    reload,
}) => {
    const history = useHistory()
    const { pathname } = useLocation()
    let country = getCountryFromUrl(pathname)||"ke"

     const firstUpdate = useRef(true)
     useLayoutEffect(() => {
         if (firstUpdate.current) {
             firstUpdate.current = false
             return
         }

       
     })
const memoizedCallback = useCallback(() => {
    if(reload===true){
        getInv()
        console.log('update',firstUpdate.current)
    }
    
}, [])
    useEffect(() => {
        if(reload===true){
            getInv()
            console.log('update',firstUpdate.current)
        }
    }, [])

    const marketting = (
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <StyledContentBox>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={8}>
                        <StyledTitle>
                            Welcome to <span>Badili</span>
                            <p>What's Inside the Box!</p>
                        </StyledTitle>
                        <Grid container spacing={3}>
                            <StyledBoxGrid item sm={6} xs={12}>
                                <img
                                    src={
                                        '/assets/images/icons/eComm-icon-functional.png'
                                    }
                                    alt="icon"
                                />
                                <div>
                                    <b>Your Next New Phone.</b> <br />A fully
                                    functional, as good as new phone, which has
                                    been through 32-quality check points. Get it
                                    for half the price
                                </div>
                            </StyledBoxGrid>
                            <StyledBoxGrid item sm={6} xs={12}>
                                <img
                                    src={
                                        '/assets/images/icons/eComm-icon-charger.png'
                                    }
                                    alt="icon"
                                />

                                <div>
                                    <b>Charger.</b> <br />
                                    Your charger and lightning cable within
                                </div>
                            </StyledBoxGrid>
                            <StyledBoxGrid item sm={6} xs={12}>
                                <img
                                    src={
                                        '/assets/images/icons/eComm-icon-warranty.png'
                                    }
                                    alt="icon"
                                />
                                <div>
                                    {' '}
                                    <b>12 Months Warranty.</b> <br />
                                    Badili assures it’s customers with a 12
                                    month warranty card inside and 30-day
                                    replace policy for any hardware default
                                </div>
                            </StyledBoxGrid>
                            <StyledBoxGrid item sm={6} xs={12}>
                                <img
                                    src={
                                        '/assets/images/ecommerce-icons/discount-coupon.svg'
                                    }
                                    alt="icon"
                                />
                                <div>
                                    <b>A Discount Coupon Code. </b>
                                    <br />
                                    Now that’s a surprise! Avail amazing
                                    discounts and share them with your friends
                                    as well!
                                </div>
                            </StyledBoxGrid>
                        </Grid>
                    </Grid>
                    <StyledImageBoxGrid item xs={12} sm={4}>
                        <img
                            src={'/assets/images/ecommerce/in-the-box.png'}
                            alt="icon"
                        />
                    </StyledImageBoxGrid>
                </Grid>
            </StyledContentBox>
        </Grid>
    )

    const renderGridItems = () => {
        const items = currentData().map((el, i) => (
            <Grid item key={el.details._id} lg={3} md={4} sm={3} xs={12}>
                <StyledProductCard
                    available={el.instock}
                    key={el.details._id}
                    onClick={() =>
                        history.push({
                            pathname: `/${country}/ecommerce/details/${replaceWhiteSpaceWithHyphen(
                                el.details?.modelName
                            )}`,
                            state: {
                                _id: el?.details?._id,
                                variantId: el?.details?.priorityVariant?._id,
                            },
                        })
                    }
                >
                    <StyledMobileWrapper>
                        <div className="bestseller">
                            {el.instock ? 'In Stock' : 'Out Of Stock'}
                        </div>
                        <img src={el.image} alt={`${el.details?.modelName}`} height ='auto' width='auto' />
                        <div>
                            <h4> {el.details?.modelName}</h4>
                            <p>Warranty : 12months</p>
                            <p>
                                Starting from
                                <br />
                                <span>
                                    {el?.prices?.currency +
                                        ' ' +
                                        el?.prices?.saleprice?.toLocaleString(
                                            'en-US'
                                        )}
                                </span>
                                <b>
                                    {el?.prices?.currency +
                                        ' ' +
                                        el?.prices?.discounted?.toLocaleString(
                                            'en-US'
                                        )}
                                </b>
                            </p>
                        </div>
                    </StyledMobileWrapper>
                </StyledProductCard>
               
            </Grid>
        ))

        
        if (items.length >= 4) {
            items.splice(8, 0, marketting)
        }

        return items
    }

    const renderSkeletons = () => {
        const items = [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24,
        ].map((el) => (
            <Grid item lg={3} md={4} sm={3} xs={12} key={el}>
                <SkeletonProductCard />
            </Grid>
        ))
        if (items.length >= 4) {
            items.splice(8, 0, marketting)
        }

        return items
    }

    return (
        <Fragment>
            <StyledEcommerceCard>
                <Grid container spacing={3} ref={refProp}>
                    {loadingInventory && renderSkeletons()}

                    {!loadingInventory && !inventory.length < 1 && (
                        <>
                            {renderGridItems()}
                            {count > 1 && (
                                <StyledPaginationWrapper item xs={12}>
                                    <Pagination
                                        count={count}
                                        variant="outlined"
                                        color="primary"
                                        page={page}
                                        onChange={handleChange}
                                    />
                                </StyledPaginationWrapper>
                            )}
                        </>
                    )}
                    {!loadingInventory && inventory.length < 1 && (
                        <NoResults>
                            <h3>No items found</h3>
                        </NoResults>
                    )}
                </Grid>
            </StyledEcommerceCard>
        </Fragment>
    )
}

export default InventoryContainer
