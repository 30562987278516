import axios from 'axios'
import { API_URL, API_KEY, API_SECRET } from 'utils/api_utils'

const country = localStorage.getItem('country')

const headers = {
    'Content-Type': 'application/json',
    Key: API_KEY,
    Secret: API_SECRET,
    country,
}

const fetchNewImageUrl = (data) => {
    return axios.post(API_URL + `get_image_by_model`, data, {
        headers: headers,
    })
}

export const handleFetchNewImageUrl = async (target, modelId, { key }) => {
    try {
        const {
            data: {
                success,
                data: { image },
            },
        } = await fetchNewImageUrl({ modelId, key })

        if (!success || !image) {
            target.onerror = null
            target.src =
                'https://media.istockphoto.com/vectors/smartphone-icon-vector-id1137284756?b=1&k=20&m=1137284756&s=170667a&w=0&h=S8OxtjTNSFmreNwBP6_LdbeHN1H2lgIgKcqh3Z5aAIg='
            return
        } else {
            target.onerror = null
            target.src = image
            return
        }
    } catch (error) {}
}
