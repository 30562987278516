import React, { useContext, useEffect, useState, useRef } from 'react'
import { AppContext } from 'app/contexts/AppContext'
import { useHistory, useLocation } from 'react-router-dom'
import { getCountryFromUrl } from 'utils/CountryUtils'
import {
    BuyCard,
    GridCard,
    DescriptionCard,
    SearchStore,
    StoreCard,
    FaqSection,
    Footer,
    SecondaryNavbar,
    PrimaryNavbar,
} from './components'
import { CardContent, Grid, Hidden, Icon } from '@material-ui/core'
import {
    StyledGraySection,
    StyledBestSellerHeader,
    StyledDescriptionTitle,
    StyledContainer,
    StyledDescriptionCard,
    StyledSectionHeader,
    StyledConditionGrid,
    StyledFooterWrapper,
    StyledCenterGrid,
    StyledAdvertWrapper,
    StyledButtonLeftWrapper,
    StyledButtonRightWrapper
} from './LandingPageStyles'
import ProductCard from './components/ProductCard'
import { Slider } from 'app/components'
import { useDispatch } from 'react-redux'
import { getInventory } from 'app/redux/actions/EcommerceActions'
import { makeHttpRequest } from 'utils/api_utils'
import brand_utils from 'utils/devices_utils'
import { SkeletonProductCard } from 'app/components/Skeletons'
import SkeletonDealerCard from './../../../components/Skeletons/SkeletonDealerCard'
import CommonCss from './components/CommonCss'
import MarketingSlider from './components/MarketingSlider'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { availableCountries } from 'utils/Country'

function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
            }}
            onClick={onClick}
        />
    )
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        />
    )
}

const storeslidersettings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: false,
    updateOnImagesReady: true,
    preloadImages: false,

    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1.5,
                slidesToScroll: 1,
                infinite: false,
                dots: false,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                dots: false,
                arrows: false,
                infinite: false,
                slidesToShow: 1.2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 320,
            settings: {
                dots: false,
                arrows: false,
                infinite: false,
                slidesToShow: 1.2,
                slidesToScroll: 1,
                nextArrow: <SampleNextArrow />,
                prevArrow: <SamplePrevArrow />,
            },
        },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
}

const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 1,
    infinite: false,

    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: false,
                dots: false,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                dots: false,
                arrows: false,
                infinite: false,
                slidesToShow: 1.5,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 320,
            settings: {
                dots: false,
                arrows: true,
                infinite: false,
                slidesToShow: 1.2,
                slidesToScroll: 1,
                nextArrow: <SampleNextArrow />,
                prevArrow: <SamplePrevArrow />,
            },
        },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
}

var countrySelection 
const RenderStores = (dealers) => {
    const { pathname } = useLocation()
    var country = getCountryFromUrl(pathname) 
    console.log('country',availableCountries);

    availableCountries.forEach(function(el){
        if(country === el.code.toLocaleLowerCase()){
            countrySelection = el.name
            
        }
      
    })

    if (dealers.length < 1) return <p>Sorry we currently dont have any store in {countrySelection}</p>
    else {
        const stores = dealers.map((el, i) => (
            <StoreCard
                key={el?.name + i}
                region={el?.region}
                name={el?.name}
                country={el?.country}
                description={el?.description}
            />
        ))


        return stores
    }
}

const renderSkeletons = () => {
    const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((el, i) => (
        <SkeletonProductCard key={el + i} />
    ))

    return items
}
const renderDealersSkeletons = () => {
    const items = [1, 2, 3, 4, 5].map((el, i) => (
        <SkeletonDealerCard key={el + i} />
    ))

    return items
}

const LandingPage = () => {
    const aboutScrollRef = useRef(null)
    const storeScrollRef = useRef(null)
    const { pathname } = useLocation();
    const location = useLocation()
   // let country = getCountryFromUrl(pathname) || "ke"
  const [country, setCountry] = useState('');
    const history = useHistory()
    const dispatch = useDispatch()
    const { setFilter, setPage } = useContext(AppContext)
    const [deals, setDeals] = useState([])
    const [loadingDeals, setLoadingDeals] = useState(false)
    const [loadingDealers, setLoadingDealers] = useState(false)
    const [dealers, setDealers] = useState([])
    const [featuredPhones, setFeaturedPhones] = useState([])
    const [filteredDealers, setFilteredDealers] = useState([])

    const handleBuyAction = () => {
        
        history.push(`/${country}/ecommerce/shop`)
    }
    const handleSellAction = () => {
        history.push(`/${country}/sell-your-phone`)
    }

    const handleFilterChange = (value, page = 1) => {
        setFilter(value)
        setPage(page)
        dispatch(getInventory(value, page))
    }

    const handleFilterCardClick = (value, page) => {
        handleFilterChange(value, page)
        history.push(`/${country}/ecommerce/shop`)
    }

    const getDeals = async () => {
        setLoadingDeals(true)
        try {
            const { devices, success } = await makeHttpRequest(
                `get_deals`,
                'GET',
                null
            )

            if (success) {
                setDeals(devices)
                setLoadingDeals(false)
            } else {
                setDeals([])
                setLoadingDeals(false)
            }
        } catch (err) {
            setDeals([])
            setLoadingDeals(false)
        }
    }

    const getDealers = async () => {
        setLoadingDealers(true)
        try {
            const { success, dealers } = await makeHttpRequest(
                `get_dealers`,
                'GET',
                null
            )

            if (success) {
                setDealers(dealers)
                setFilteredDealers(dealers)
                setLoadingDealers(false)
            } else {
                setDeals([])
                setLoadingDealers(false)
            }
        } catch (err) {
            setDeals([])
            setLoadingDealers(false)
        }
    }

    const getFeaturedPhones = async () => {
        setLoadingDealers(true)
        try {
            const { success, devices } = await makeHttpRequest(
                `get_featured_models`,
                'GET',
                null
            )

            if (success) {
                setFeaturedPhones(devices)
                //  setDealers(dealers)o
                //setFilteredDealers(dealers)
                //setLoadingDealers(false)
            } else {
                setDeals([])
                setLoadingDealers(false)
            }
        } catch (err) {
            setDeals([])
            setLoadingDealers(false)
        }
    }


   useEffect(() => {
    if (window.location.hostname === 'badili.africa') {
      setCountry('ke');
      localStorage.setItem('country','ke')
    } else if (window.location.hostname === 'badili.co.tz') {
      setCountry('tz');
      localStorage.setItem('country','tz')
    }else if (window.location.hostname === 'badili.ug') {
    setCountry('ug');
    localStorage.setItem('country','ug')
  } else {
      setCountry('ke');
    }
    
    getDeals();
    getDealers();
    getFeaturedPhones();

    // if (location?.pathname === '/register-phone') {
    //   return history.push(`/${country}/register-phone`);
    // }
  }, [setCountry]);
    return (
        <>
            <CommonCss />
            <PrimaryNavbar page="" />
            <SecondaryNavbar page="buyback" />
            <StyledContainer>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6}>
                        <BuyCard
                            title="Sell Us Your Old"
                            description="Ku-trade in simu yako has never been easier!"
                            imageUrl="/assets/images/landing-page/phone-01.png"
                            background="#42c8b7"
                            btnLabel="Sell Now"
                            btnType="primary"
                            titleColor="#fff"
                            descriptionColor="#000"
                            btnAction={handleSellAction}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        {' '}
                        <BuyCard
                            title="Upgrade to your Dream"
                            titleColor="#f68937"
                            description="Your dream phone is one click away!"
                            imageUrl="/assets/images/landing-page/phone-02.png"
                            background="#f3f3f4"
                            descriptionColor="#000"
                            btnLabel="Buy Now"
                            btnType="secondary"
                            btnAction={handleBuyAction}
                        />
                    </Grid>
                </Grid>
                <StyledAdvertWrapper>
                    <MarketingSlider />
                </StyledAdvertWrapper>

                <StyledGraySection>
                    <h2>
                        Certified Pre-Loved SmartPhones | 12 Months Warranty{' '}
                    </h2>
                    <Hidden lgUp>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <StyledCenterGrid
                                    onClick={() =>
                                        handleFilterCardClick(
                                            `brands=${brand_utils.iphone._id}`
                                        )
                                    }
                                >
                                    <div>
                                        <img
                                            src="/assets/images/landing-page/samsung-center.png"
                                            alt=""
                                            style={{ width: "unset", height: "auto" }}
                                        />
                                        <h6>Samsung A51</h6>
                                        <h4>{featuredPhones[0]? featuredPhones[0].prices.currency :''}  { parseInt( featuredPhones[0]? featuredPhones[0].prices.rate *23050:'').toLocaleString()}  </h4>
                                        <p>{featuredPhones[0]? featuredPhones[0].prices.currency :''}  { parseInt(featuredPhones[0]? featuredPhones[0].prices.rate *43900 :'').toLocaleString()}  </p>
                                    </div>
                                </StyledCenterGrid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={3}>
                                <GridCard
                                    action={() =>
                                        handleFilterCardClick(
                                            `brands=${brand_utils.iphone._id}`
                                        )
                                    }
                                    title="iPhones"
                                    description= {`Starting from ${featuredPhones[0]? featuredPhones[0].prices.currency :''} 17,332 `}
                                    imageUrl="/assets/images/landing-page/iphones.png"
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <GridCard
                                    action={() =>
                                        handleFilterCardClick(
                                            `brands=${brand_utils.oppo._id}`
                                        )
                                    }
                                    title="Oppo"
                                    description= {`Starting from ${featuredPhones[0]? featuredPhones[0].prices.currency :''}  ${ parseInt(featuredPhones[0]? featuredPhones[0].prices.rate *6899 :'').toLocaleString()}  `}
                                    imageUrl="/assets/images/landing-page/oppo.png"
                                />
                            </Grid>

                            <Grid item xs={6} sm={3}>
                                <GridCard
                                    action={() =>
                                        handleFilterCardClick(
                                            `brands=${brand_utils.samsung._id}`
                                        )
                                    }
                                    title="Samsung"
                                    description={`Starting from ${featuredPhones[0]? featuredPhones[0].prices.currency :''}  ${ parseInt(featuredPhones[0]? featuredPhones[0].prices.rate *5750 :'').toLocaleString()}  `}
                                    imageUrl="/assets/images/landing-page/samsungs.png"
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <GridCard
                                    action={() =>
                                        handleFilterCardClick(
                                            `brands=${brand_utils.xiaomi._id}`
                                        )
                                    }
                                    title="Xiaomi"
                                    description={`Starting from ${featuredPhones[0]? featuredPhones[0].prices.currency :''}  ${ parseInt(featuredPhones[0]? featuredPhones[0].prices.rate *8669 :'').toLocaleString()}  `}
                                    imageUrl="/assets/images/landing-page/xiaomi.png"
                                />
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Hidden mdDown>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={3}>
                                <GridCard
                                    mb={4}
                                    action={() =>
                                        handleFilterCardClick(
                                            `brands=${brand_utils.iphone._id}`
                                        )
                                    }
                                    title="iPhones"
                                    margin
                                    description= {`Starting from ${featuredPhones[0]? featuredPhones[0].prices.currency :''}  ${ parseInt(featuredPhones[0]? featuredPhones[0].prices.rate *17322 :'').toLocaleString()}  `}
                                    imageUrl="/assets/images/landing-page/iphones.png"
                                />
                                <GridCard
                                    action={() =>
                                        handleFilterCardClick(
                                            `brands=${brand_utils.oppo._id}`
                                        )
                                    }
                                    title="Oppo"
                                    description={`Starting from ${featuredPhones[0]? featuredPhones[0].prices.currency :''}  ${ parseInt(featuredPhones[0]? featuredPhones[0].prices.rate *6899 :'').toLocaleString()}  `}
                                    imageUrl="/assets/images/landing-page/oppo.png"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Carousel
                                    showThumbs={false}
                                    autoPlay={true}
                                    showStatus={false}
                                    renderArrowPrev={(
                                        clickHandler,
                                        hasPrev
                                    ) => {
                                        return (
                                            <StyledButtonLeftWrapper
                                                hasPrev={hasPrev}
                                            >
                                                <button onClick={clickHandler}>
                                                    <Icon
                                                        style={{ fontSize: 16 }}
                                                    >
                                                        arrow_back_ios_new
                                                    </Icon>
                                                </button>
                                            </StyledButtonLeftWrapper>
                                        )
                                    }}
                                    renderArrowNext={(
                                        clickHandler,
                                        hasNext
                                    ) => {
                                        return (
                                            <StyledButtonRightWrapper
                                                hasNext={hasNext}
                                            >
                                                <button onClick={clickHandler}>
                                                    <Icon
                                                        style={{ fontSize: 16 }}
                                                    >
                                                        arrow_forward_ios_outlined
                                                    </Icon>
                                                </button>
                                            </StyledButtonRightWrapper>
                                        )
                                    }}
                                >
                                    {[1, 2, 3, 4].map((el, i) => (
                                        <StyledCenterGrid
                                            key={i}
                                            onClick={() =>
                                                handleFilterCardClick(
                                                    `brands=${brand_utils.iphone._id}`
                                                )
                                            }
                                        >
                                            <div>
                                              <img
                                                    src="/assets/images/landing-page/samsung-center.png"
                                                    alt=""
                                                    style={{ width: "unset", height: "auto" }}
                                                /> 

                                                <h6>Samsung A51</h6>
                                                
                                             <h4>{featuredPhones[0]? featuredPhones[0].prices.currency :''}  {parseInt(featuredPhones[0]? featuredPhones[0].prices.rate * 23050 :'').toLocaleString()}  </h4>
                                                <p>{featuredPhones[0]? featuredPhones[0].prices.currency :''}  { parseInt(featuredPhones[0]? featuredPhones[0].prices.rate *43900 :'').toLocaleString()}  </p> 
                                            </div>
                                        </StyledCenterGrid>
                                    ))}
                                </Carousel>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <GridCard
                                    action={() =>
                                        handleFilterCardClick(
                                            `brands=${brand_utils.samsung._id}`
                                        )
                                    }
                                    title="Samsung"
                                    description={`Starting from ${featuredPhones[0]? featuredPhones[0].prices.currency :''}  ${ parseInt(featuredPhones[0]? featuredPhones[0].prices.rate *5750 :'').toLocaleString()}  `}
                                    imageUrl="/assets/images/landing-page/samsungs.png"
                                    margin
                                />
                                <GridCard
                                    action={() =>
                                        handleFilterCardClick(
                                            `brands=${brand_utils.xiaomi._id}`
                                        )
                                    }
                                    title="Xiaomi"
                                    description={`Starting from ${featuredPhones[0]? featuredPhones[0].prices.currency :''}  ${ parseInt(featuredPhones[0]? featuredPhones[0].prices.rate *8699 :'').toLocaleString()} `}
                                    imageUrl="/assets/images/landing-page/xiaomi.png"
                                />
                            </Grid>
                        </Grid>
                    </Hidden>
                </StyledGraySection>
                {console.log(featuredPhones)}
                {featuredPhones.length !== 0 && (
                    <StyledGraySection>
                        <StyledBestSellerHeader>
                            Haven't Decided Yet? Check Out Our Bestsellers
                        </StyledBestSellerHeader>

                        {featuredPhones.length === 0 && (
                            <Slider buttoncolor="#000" settings={settings}>
                                {renderSkeletons()}
                            </Slider>
                        )}
                        {console.log(featuredPhones)}


                        {featuredPhones.length !== 0 && (
                            <Slider buttoncolor="#000" settings={settings}>

                                {featuredPhones.map(
                                    (phone,

                                        i
                                    ) => (
                                        <ProductCard
                                            key={phone.details._id}
                                            imageUrl={phone.image}
                                            title={phone.details.modelName}
                                            initialPrice={phone.prices.discounted}
                                            discountedPrice={phone.prices.discounted}
                                            currency={phone.prices.currency}
                                            _id={phone.details._id}

                                        />
                                    )
                                )}
                            </Slider>
                        )}
                    </StyledGraySection>
                )}
                <StyledGraySection id="about" ref={aboutScrollRef}>
                    <StyledDescriptionCard>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <StyledDescriptionTitle>
                                        <span className="strike-thrugh">
                                            <span
                                                style={{
                                                    color: 'black',
                                                }}
                                            >
                                                A little about Badili
                                            </span>{' '}
                                        </span>
                                    </StyledDescriptionTitle>

                                    <p>
                                        We are the first re-commerce platform in
                                        Africa.
                                    </p>
                                    <p>
                                        Our mission is to have every African
                                        hold a smartphone- by maximizing
                                        affordability, as well as, build trust
                                        and desire for pre-loved gadgets.
                                    </p>
                                    <p>
                                        At Badili, you can sell your mobile
                                        phones as well as buy certified
                                        pre-loved smartphones at prices you will
                                        love or shall we say at almost half the
                                        price of a new one.
                                    </p>
                                    <p>
                                        With a 12-months warranty on our phones,
                                        we visualize a well-connected tomorrow
                                        for Africa.
                                    </p>
                                    <p>
                                        What happens after 12-months? You sell
                                        it back to us
                                    </p>
                                    <p>
                                        Badili, not just enables you to sell or
                                        buy a phone at affordable prices, but
                                        also gets your phone repaired. So that
                                        every time you visit us at our physical
                                        stores, you get all that you need
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Grid container spacing={2}>
                                        <DescriptionCard
                                            imageUrl="/assets/images/landing-page/Icon_QualityGuaranteed.svg"
                                            title="Quality Guaranteed"
                                            description="100% functional phones.Free 30-day
                                            warranty"
                                        />
                                        <DescriptionCard
                                            imageUrl="/assets/images/landing-page/Icon_Affordability.svg"
                                            title="Affordability"
                                            description="Serious about money? Our fine
                                            selecton and prices will delight you"
                                        />
                                        <DescriptionCard
                                            imageUrl="/assets/images/landing-page/Icon_Transparency.svg"
                                            title="Transparency & Legitimacy"
                                            description="All phones are original and comply
                                            with the latest regulations"
                                        />
                                        <DescriptionCard
                                            imageUrl="/assets/images/landing-page/Icon_CustomerCare.svg"
                                            title="Customer Care"
                                            description=" We always give you the best shopping
                                            experience"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </StyledDescriptionCard>
                </StyledGraySection>
                <div id="stores" ref={storeScrollRef}>
                    <StyledSectionHeader>
                        Exclusive Stores 
                    </StyledSectionHeader>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            {' '}
                            <SearchStore
                                dealers={dealers}
                                setDealers={setDealers}
                                filteredDealers={filteredDealers}
                                setFilteredDealers={setFilteredDealers}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Slider
                                buttoncolor="#000"
                                settings={storeslidersettings}
                            >
                                {loadingDealers && renderDealersSkeletons()}
                                {!loadingDealers &&
                                    RenderStores(filteredDealers)}
                            </Slider>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <StyledSectionHeader>
                        Your Questions Answered
                    </StyledSectionHeader>

                    <FaqSection />
                </div>
                <StyledGraySection>
                    <h3 className="product-condition-title">
                        Our Products Condition{' '}
                    </h3>
                    <Grid container spacing={2}>
                        <StyledConditionGrid item xs={6} sm={3}>
                            <div className="header-section">
                                <img
                                    src="/assets/images/landing-page/Icon_PreLoved.svg"
                                    alt="icon"
                                />
                                <div>
                                    <h4>As Good As New!</h4>
                                    <div className="badge">Excellent</div>
                                </div>
                            </div>
                            <div className="content-section">
                                <h5>What to Expect?</h5>
                                <p>
                                    Screen and body are in very good condition
                                </p>
                            </div>
                        </StyledConditionGrid>
                        <StyledConditionGrid item xs={6} sm={3}>
                            <div className="header-section">
                                <img
                                    src="/assets/images/landing-page/Icon_PreLoved.svg"
                                    alt="icon"
                                />
                                <div>
                                    <h4>Just Right!</h4>
                                    <div className="badge">Good</div>
                                </div>
                            </div>
                            <div className="content-section">
                                <h5>What to Expect?</h5>
                                <p>
                                    Body has a few microscratches that are
                                    barely noticeable from far
                                </p>
                            </div>
                        </StyledConditionGrid>
                        <StyledConditionGrid
                            item
                            xs={6}
                            sm={3}
                            bgcolor="rgba(246, 137, 55,.3)"
                            textcolor="rgb(246, 137, 55)"
                        >
                            <div className="header-section">
                                <img
                                    src="/assets/images/landing-page/Icon_NeverUsed.svg"
                                    alt="icon"
                                />
                                <div>
                                    <h4>Value For Money</h4>
                                    <div className="badge">Fair</div>
                                </div>
                            </div>
                            <div className="content-section">
                                <h5>What to Expect?</h5>
                                <p>
                                    Body and screen has a few microscratches
                                    that are barely noticeable from far
                                </p>
                            </div>
                        </StyledConditionGrid>
                        <StyledConditionGrid
                            item
                            xs={6}
                            sm={3}
                            bgcolor="rgb(241,27,26,.3)"
                            textcolor="rgb(241,27,26)"
                        >
                            <div className="header-section">
                                <img
                                    src="/assets/images/landing-page/Icon_NeverUsed.svg"
                                    alt="icon"
                                />
                                <div>
                                    <h4>Beyond Economic Repair</h4>
                                    <div className="badge">For Parts</div>
                                </div>
                            </div>
                            <div className="content-section">
                                <h5>What to Expect?</h5>
                                <p>
                                    Sold at very discounted prices. Can be used
                                    for parts
                                </p>
                            </div>
                        </StyledConditionGrid>
                    </Grid>
                </StyledGraySection>
            </StyledContainer>
            <StyledFooterWrapper>
                <Footer
                    aboutScrollRef={aboutScrollRef}
                    storeScrollRef={storeScrollRef}
                />
            </StyledFooterWrapper>
        </>
    )
}

export default LandingPage
