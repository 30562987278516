import React from 'react'
import { devices } from 'utils/screen_sizes'
import styled, { keyframes } from 'styled-components'

const loading = keyframes`
 
  100% { transform: translateX(100%) }
`

const StyledSkeleton = styled.div`
    background: #ebebeb;
    overflow: hidden;
    margin: 10px 0;
    position: relative;
    border-radius: 4px;
    &.text {
        width: 100%;
        height: 8px;
    }

    &.title {
        width: 50%;
        height: 12px;
        margin-bottom: 15px;
    }

    &.avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }

    &.thumbnail {
        width: 50px;
        height: 50px;
    }
    &.image {
        height: 155px;
        margin: 0;

        @media ${devices.mobileS} {
            width: 100%;
            height: 110px;
        }
        @media ${devices.laptop} {
            width: 100%;
            height: 155px;
        }
    }
    &.tag {
        padding: 0.2rem 1.6rem;
        border-radius: 4px;
        width: fit-content;
    }
    &.banner {
        width: 100%;
        height: 340px;
    }
    &.block-big {
        width: 100%;
        height: 150px;
    }
    &.block-small {
        width: 100%;
        height: 75px;
    }

    :after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0,
            rgba(255, 255, 255, 0.2) 20%,
            rgba(255, 255, 255, 0.5) 60%,
            rgba(255, 255, 255, 0)
        );
        animation-name: ${loading};
        animation-duration: 2s;
        animation-iteration-count: infinite;
        content: '';
    }
`

function SkeletonElement({ type }) {
    return <StyledSkeleton className={type} />
}

export default SkeletonElement
