import React, { useEffect, useRef } from 'react'
import Swiper from 'swiper'
import clsx from 'clsx'
import styled from 'styled-components'
import SmoothImage from 'app/components/SmoothImage/SmoothImage'
import { makeStyles } from '@material-ui/core/styles'
import { devices } from 'utils/screen_sizes'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { isMobile } from 'utils'
import { useHistory,useLocation } from 'react-router-dom'
import { getCountryFromUrl } from 'utils/CountryUtils'
const MarketingSlider = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    let country = getCountryFromUrl(pathname) || 'ke';
    const images = [
        // '/assets/images/landing-page/Valentines-Website-Banner.png',
        '/assets/images/landing-page/ad1.png',
        '/assets/images/landing-page/add1.png',
        '/assets/images/landing-page/add2.png',
        '/assets/images/landing-page/add3.png',
    ]

    const mobileImages = [
        // '/assets/images/landing-page/mobile/Valentines-Website-Banner-Final-M.png',
        '/assets/images/landing-page/mobile/ad1.png',
        '/assets/images/landing-page/mobile/ad2.png',
        '/assets/images/landing-page/mobile/ad3.png',
        '/assets/images/landing-page/mobile/ad4.png',
    ]
    const handleBuyAction = () => {
        history.push(`/${country}/ecommerce/shop`)
    }
    return (
        <Carousel showThumbs={false} autoPlay={true} showStatus={false}>
            {isMobile()
                ? mobileImages.map((imageUrl, i) => (
                      <div key={i}
                      onClick={() =>
                        i == 0 ? handleBuyAction(): ''
                    }>
                          <img src={imageUrl} alt="" />
                          {/* <p className="legend">Legend 1</p> */}
                      </div>
                  ))
                : images.map((imageUrl, i) => (
                      <div
                          key={i}
                          onClick={() =>
                              i == 0 ? handleBuyAction(): ''
                          }
                      >
                          <img src={imageUrl} alt="" />
                          {/* <p className="legend">Legend 1</p> */}
                      </div>
                  ))}
        </Carousel>
    )
}

export default MarketingSlider
