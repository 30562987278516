import React, { useContext, useState, useEffect } from 'react'
import RepairWrapper from 'app/components/MatxLayout/RepairLayoutWrapper'
import {
    Grid,
    Hidden,
    Icon,
    TextField,
    MenuItem,
    Snackbar,
} from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

import { useTheme } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import { AddressCard, DatePicker, PriceSummary } from './components'
import { RepairContext } from 'app/contexts/RepairContext'
import { SearchLocationInput } from 'app/components'
import { useHistory, useLocation } from 'react-router-dom'
import { makeHttpRequest } from 'utils/api_utils'
import {
    StyledSectionSubTitle,
    StyledDistance,
    StyledScheduledCard,
    StyledSectionTitle,
    StyledContainer,
} from './RepairStyles'
import useAuth from 'app/hooks/useAuth'
import { getCountryFromUrl } from 'utils/CountryUtils'

// Inline Modal Override Styles

const style = {
    fontFamily: 'lato',
    color: '#000',
}
const buttonStyle = {
    fontFamily: 'lato',
    color: 'rgb(111, 101, 173)',
    fontWeight: 'bold',
}
const titleStyle = {
    fontFamily: 'lato',
    color: '#28a745',
    fontWeight: 'bold',
}

const RepairLandingPage = (props) => {

    const repairDetails = props.location.state.repairDetails
    const id = props.history.location.state.id
    const { pathname } = useLocation()
    let country = getCountryFromUrl(pathname)||"ke"

    // repairDetails

    const [selectedShop, setSelectedShop] = useState('')
    const [newId, setNewId] = useState('')
    const [isRequesting, setIsRequesting] = useState(false)
    const [showAlert, setShowAlert] = React.useState(false)
    const [isOpen, setIsOpen] = React.useState(false)
    const [response, setResponse] = React.useState({
        msg: '',
        status: '',
    })
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const {
        state: { stores },
    } = useLocation()
    const history = useHistory()
    const {
        cart,
        addressDetails,
        terms,
        setCart,
        setTerms,
        setTotal,
        setRepairProId,
        recalculate,
        total

    } = useContext(RepairContext)
    const { user } = useAuth()
    const [repairCost, setRepairCost] = useState()
    var repairProId

    const handleClose = () => {
        setShowAlert(false)
    }
    // const handleModalClose = () => {
    //     setIsOpen(false)
    //     history.push(`/${country}`)
    // }


    const repairValue = async () => {
        let deductions = cart.reduce((el, obj) => {
            return { ...el, [obj.component_name]: 'yes' }
        }, {})
        let userId = user?._id || user?.userId
        var payload = {
            userId,
            modelId: id,
            colorId: state?.colorId,
            imei: repairDetails.imei,
            deductions,
            additionalInfo: repairDetails.extra_description,
        }
        const response =
            await makeHttpRequest(
                `estimate_repair_value`,
                'POST',
                payload
            )

        setRepairCost(response)
        repairProId = response.repairProId
       



    }
    const { state } = useLocation()

    useEffect(() => {


        if (id && (cart !== 0 && total !== null)) {

            repairValue()
        }

    }, [JSON.stringify(cart), total])



    const bookDiagnosis = async () => {

        repairProId = repairCost.repairProId
        setIsRequesting(true)
       
        if (repairProId) {
            try {
                const { success, msg } = await makeHttpRequest(
                    `complete_repairpro_booking`,
                    'PUT',
                    { shopId: selectedShop, repairProId },
                )
                if (success) {
                    setResponse({
                        ...response,
                        msg,
                        status: 'success',
                    })
                    setIsRequesting(false)
                    window.scrollTo(0, 0)

                    setCart([])
                    setTerms(false)
                    setTotal(null)
                    setRepairProId('')
                    history.push({
                        pathname: `/${country}/repair/summary`,
                        state: {
                            repairProId,
                            cart,
                            // id
                        },
                    })
                } else {
                    setResponse({
                        ...response,
                        msg: msg || 'An error occured. Try again later',
                        status: 'error',
                    })
                    setIsRequesting(false)
                    setShowAlert(true)
                }

            } catch (err) {
                setResponse({
                    ...response,
                    msg: 'An error occured. Try again later',
                    status: 'error',
                })
                setIsRequesting(false)
                setShowAlert(true)
            }
        }
    }

    return (
        <RepairWrapper>
            <StyledContainer>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        <div className="card-body">
                            <span role="button">Go back</span>
                            <StyledSectionTitle>
                                Get Expert Diagnosis
                            </StyledSectionTitle>
                            <Snackbar
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                autoHideDuration={6000}
                                open={showAlert}
                                onClose={handleClose}
                            >
                                <Alert
                                    onClose={handleClose}
                                    severity={response.status}
                                >
                                    {response.msg}
                                </Alert>
                            </Snackbar>

                            {/* <Dialog
                                fullScreen={fullScreen}
                                open={isOpen}
                         
                                aria-labelledby="responsive-dialog-title"
                            >
                                <DialogTitle id="responsive-dialog-title">
                                    <span style={titleStyle}>
                                        Diagnosis requested successfully
                                    </span>
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText style={style}>
                                        {response.msg}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={handleModalClose}
                                        autoFocus
                                        style={buttonStyle}
                                    >
                                        Done
                                    </Button>
                                </DialogActions>
                            </Dialog> */}

                            <AddressCard />

                            <TextField
                                className="input-select mb-4"
                                label="Select Store near you"
                                variant="outlined"
                                size="small"
                                style={buttonStyle}
                                fullWidth
                                defaultValue={''}
                                onChange={(e) =>
                                    setSelectedShop(e.target.value)
                                }
                                select
                            >
                                {stores.map(({ _id, name }) => (
                                    <MenuItem key={_id} value={_id}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        {addressDetails.option === 'home' && (
                            <StyledScheduledCard>
                                <StyledSectionSubTitle>
                                    Enter your location
                                </StyledSectionSubTitle>
                                <Hidden mdDown>
                                    <SearchLocationInput
                                        width="70%"
                                        borderColor="rgb(111, 101, 173)"
                                    />
                                </Hidden>
                                <Hidden mdUp>
                                    <SearchLocationInput
                                        width="100%"
                                        borderColor="rgb(111, 101, 173)"
                                    />
                                </Hidden>

                                <StyledDistance>
                                    <h6>Kasarani </h6>
                                    <div>
                                        <Icon
                                            style={{
                                                color: 'rgb(111, 101, 173',
                                            }}
                                            fontSize="large"
                                        >
                                            location_on
                                        </Icon>
                                        <small>4.8 km to Nearest Store</small>
                                    </div>
                                </StyledDistance>

                                <StyledSectionSubTitle>
                                    Schedule your appointment
                                </StyledSectionSubTitle>
                                <DatePicker />
                            </StyledScheduledCard>
                        )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <PriceSummary
                            repairCost={repairCost}
                            repairDetails={repairDetails}
                            id={id}
                            list={cart}
                            btnText="Request Diagnosis"
                            btnFn={() => bookDiagnosis()}
                            disabled={isRequesting || !terms}
                            calculatingTotal={isRequesting}
                        />
                    </Grid>
                </Grid>
            </StyledContainer>
        </RepairWrapper>
    )
}

export default RepairLandingPage