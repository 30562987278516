import React from 'react'
import { MatxLayouts } from './index'
import { MatxSuspense } from 'app/components'

const EcommerceLayout = (props) => {
    const Layout = MatxLayouts['layout3']

    return (
        <MatxSuspense>
           
            <Layout {...props} />
         
        </MatxSuspense>
    )
}

export default EcommerceLayout
