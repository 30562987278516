import axios from 'axios'
import { API_URL, API_KEY, API_SECRET } from 'utils/api_utils'

const token = localStorage.getItem('accessToken')
const country = localStorage.getItem('country')

const headers = {
    'Content-Type': 'application/json',
    Key: API_KEY,
    Secret: API_SECRET,
    country,
    Authorization: `${token}`,
}

export const getProfile = () => {
    return axios.get(API_URL + 'profile', { headers: headers })
}

export const updatePassword = (id, password) => {
    return axios.put(
        API_URL + `update_password/${id}`,
        { password },
        {
            headers: headers,
        }
    )
}
