import React, { useEffect, useState, useContext } from 'react'
import { makeHttpRequest } from 'utils/api_utils'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import useAuth from 'app/hooks/useAuth'
import phoneimage from '../assets/images/badili/phone.png'
import Alert from '@material-ui/lab/Alert'
import {
  Grid,
  MenuItem,
  item,
  Icon,
  Radio,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Checkbox,
  Button,
} from '@material-ui/core'
import {
  MatxLoading,
  SimpleDialog,
  MatxSnackbar,
  SimpleCard,
  MatxListItem1,
} from 'app/components'
import styled from 'styled-components'
import {
  ImageWrapper,
  StyledGrid,
  IconWrapper,
  StyledContainer,
  GreyStyledCard,
  StyledSwitch,
  NoCalculationsCard,
} from '../styles/deviceDetails.style'
import {
  getDetails,
  getDetailsByID,
  requestPayment,
  getLocations,
  dropOff,
  handleGetPartner,
  toggleWhatsapp,
} from '../views/DeviceDetails/DeviceService'
import RepairWrapper from 'app/components/MatxLayout/RepairLayoutWrapper'
import { RepairContext } from '../contexts/RepairContext'

function RepairProPage(props) {
  
  const repairItem = props.history.location.state.cart
  

  return (
    <>
      <RepairWrapper>
        <Grid container rowSpacing={1} spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={4}>
            <SimpleCard>


              <ImageWrapper>
                <img
                  src={
                    phoneimage
                  }
                  alt="device"
                />
              </ImageWrapper>

            </SimpleCard>
          </Grid>
          <Grid item xs={4}>
            <span>
              <p>
                Estimated Costs
              </p>

              <h3 className="mb-6">
                Ksh 2942.00
              </h3>



            </span>
            <Button
              className="px-7"
              variant="contained"
              color="primary"
              style={{ marginTop: '40px', marginBottom: '80px' }}

            >
              {/* {handleButtonText()} */}
              Estimated Repair Cist
            </Button>
            <Alert
              severity="info"

            >Thank you for choosing Badili. Our customer service agent will reach out to you
              \
            </Alert>
            <IconWrapper>
              <div>
                <img
                  src="/assets/images/icons/shield.png"
                  alt="device"
                />
                <span>
                  Safe & <br />
                  Secure
                </span>
              </div>
              <div>
                <img
                  src="/assets/images/icons/certificate.png"
                  alt="device"
                />
                <span>
                  {' '}
                  Free <br />
                  Doorstep Pickup
                </span>
              </div>
              <div>
                <img
                  src="/assets/images/icons/noHaggling-01.svg"
                  alt="device"
                />
                <span>
                  {' '}
                  No
                  <br />
                  Haggling
                </span>
              </div>
            </IconWrapper>

          </Grid>
          <Grid item xs={4}>


            <SimpleCard title="Device Defects" >
              {Array.isArray(repairItem) ? repairItem.map((i) =>

                <span>
                  <img src={i.image} width='80px' />
                  <li>{i.component_name}</li>
                  <li>{i.price}</li>
                  <li>{i.label}</li>

                </span>
          

              )
                : ''}

            </SimpleCard>
          </Grid>

        </Grid>


      </RepairWrapper>


    </>

  )
}
export default RepairProPage